import React from "react";

const ReadOnlyRow = ({ contact, handleEditClick , handleDeleteClick }) => {
  return (
    
    <tr>
      <td><img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" /> &nbsp;{contact.employee}<br></br><div style={{fontSize:"12px", color:" #7D90B2", marginLeft:"38px", marginTop:"-10px"}}> Emp Code:  </div></td>
      <td className="text-left">{contact.mail}</td> 
      <td className="text-left">{contact.joindate}</td>
      <td >
                                                            <div className="selectWrapper">
                                                        <select className="selectBox">
                                                        <option>Adjuster Trainee</option>
                                                            <option>Claims Representative</option>
                                                        <option>Claim Adjuster</option>
                                                        <option>Sales Executive</option>
                                                        <option>Accountant</option>
                                                        <option>Team Leader</option>
                                                           <option>Manager</option>                         
                                                          <option>Marketing Manager</option>                                                           
                                                             <option>Insurance Product Manager</option>
                                                             <option>Insurance Intern</option>
                                                             
                                                        </select></div>
                                                        </td>
      <td className="text-center">{contact.salary}</td>
      <td> <button type="button" className=" btn-sm btn-primary w-70 rounded-0 icuePayeBtn" data-bs-toggle="modal" data-bs-target="#" style={
        {marginLeft:"30px"}
      }>
        Generate Payslip 
        </button></td>
     
      <td>
        <button className="btn btn-sm btn-transparent " style={{marginLeft:"10px", marginTop:"-15px"}}
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
         <i className='bi bi-pencil'></i>
        </button>
        <button className="btn btn-sm btn-transparent "  style={{ marginLeft:"-30px", marginTop:"-15px"}}
        type="button" onClick={() => handleDeleteClick(contact.id)}>
       <i className='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;