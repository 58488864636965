import React from "react";
import { stringFormat } from "../../utils/method";

const SourceOfFunds = ({
  personalchk = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {

    function checkBankCopyName(value) {
        if (isDisabled && data?.source_of_fund === value) {
        return true;
        }
        return false;
    }

  return (
    <>  
      <div className="row row-cols-1 row-cols-lg-5 form cattleForm">
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Salary") ? (
            <input
              type="radio"
              id="Salary"
              name="source_of_fund"
              className="me-3"
              value="Salary"
              onChange={(e) => personalchk("source_of_fund", e)}
              disabled={isDisabled}
              checked={personalDetail.source_of_fund === "Salary"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Salary</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Business") ? (
            <input
              type="radio"
              id="Business"
              name="source_of_fund"
              className="me-3"
              value="Business"
              onChange={(e) => personalchk("source_of_fund", e)}
              checked={personalDetail.source_of_fund === "Business"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Business</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBankCopyName("Others") ? (
            <input
              type="radio"
              id="Others"
              name="source_of_fund"
              className="me-3"
              onChange={(e) => personalchk("source_of_fund", e)}
              value="Others"
              disabled={isDisabled}
              checked={personalDetail.source_of_fund === "Others"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Others</span>
        </div>

        
        {personalDetail.source_of_fund === "Others" && <div className="col position-relative mt-3" style={{ flexGrow: 1 }}>
          <input
            className="w-100"
            id="offers"
            type="text"
            name="offers"
            onChange={(e) => personalchk("please_specify", e)}
            value={
              !isDisabled ? personalDetail.please_specify : stringFormat(data.please_specify)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Please Specify
          </label>
        </div>
        }
      </div>
      <p className="p-error">{errors?.source_of_fund || formErrors?.source_of_fund}</p>

    </>
  );
};

export default SourceOfFunds;
