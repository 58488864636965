import React from 'react';
import Modal from 'react-bootstrap/Modal';

export const Model = ({ show, onHide, children }) => {

    return (
        <Modal scrollable={true} show={show} onHide={onHide}>
            <Modal.Header closeButton/>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

