import React, { useEffect, useState } from "react";
import useCountDown from 'react-countdown-hook';
import { Prompt } from 'react-router-dom';
import {
  traningCompl,
  traningPause,
  GetTrainingduration
} from "../../../store/actions/getPosPersonalDetails";

export function MyTimer({ expiryTimestamp = "", onChange = void 0 }) {
  const [isDirty, setDirty] = useState(false);
  const [resumeBtn, setResumeBtn] = useState(false);
  const [pauseBtn, setpauseBtn] = useState(true);
  const [timerexpire, setTimeexpire] = useState(false);
  const interval = 1000;
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(expiryTimestamp, interval);
  useEffect(() => {
    if (!timerexpire) {
      setTimeout(() => { 
        setTimeexpire(true)
      }, 1000);
    }
    if (timeLeft === 0 && timerexpire) {
      setDirty(false);
      onChange(true);
      traningCompl();
    }
    localStorage.setItem('tKey', timeLeft);
  }, [timeLeft]);

  const onConfirmRefresh = function (event) {

    traningPause(window.currentValue);
    event.preventDefault();
    return event.returnValue = "";
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onConfirmRefresh, { capture: false });
    window.addEventListener("unload", onConfirmRefresh, { capture: false });
    return () => {
      window.addEventListener("beforeunload", onConfirmRefresh, { capture: false });
      window.addEventListener("unload", onConfirmRefresh, { capture: false });
    }
  }, [isDirty])

  useEffect(() => {
    startTheTimer();
  }, []);

  const startTheTimer = () => {
    setDirty(true);
    GetTrainingduration().then(res => {
      res?.data?.training_duration !== "0" ? start(parseInt(res?.data?.training_duration)) : start(expiryTimestamp)
      if(!res.data) {start(expiryTimestamp);}
    });
  }

  const pauseButtonClick = () => {

    traningPause(window.currentValue)
      .then(() => {
        pause();
      });
      setResumeBtn(true)
      setpauseBtn(false)

  };
  const resumeButtonClick = () => {
      setpauseBtn(true)
      setResumeBtn(false)
  };

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "120px",
        width: "600px",
      }}
    >
      <Prompt when={isDirty} message={(location, action) => {
        if (action === 'POP') {
          if (window.confirm('Changes you made may not be saved.')) {
            pauseButtonClick()
            return true;
          } else {
            return false;
          }
        }
      }} />
      <span>{`Time Left :${Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}|${Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))}|${Math.floor((timeLeft % (1000 * 60)) / 1000)}`}</span>
      <span>
        {pauseBtn && <button onClick={() => pauseButtonClick()}>Pause</button> }
        {"   "}
        {resumeBtn && <button onClick={() => {resume(); resumeButtonClick()}}>Resume</button>}
      </span>
    </div>
  );
}
