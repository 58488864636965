import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_QUALIFICATION_FAILURE, FETCH_QUALIFICATION_REQUEST, FETCH_QUALIFICATION_SUCCESS } from "./types"



export const fetchQualificationRequest = () => {
    return {
        type: FETCH_QUALIFICATION_REQUEST
    }
}
export const fetchQualificationSuccess = model => {
    return {
        type: FETCH_QUALIFICATION_SUCCESS,
        payload: model
    }
}
export const fetchQualificationFailure = error => {
    return {
        type: FETCH_QUALIFICATION_FAILURE,
        payload: error
    }
}


export const getQualification = () => async (dispatch) => {
    dispatch(fetchQualificationRequest)
    await axios.get(Constant.BASE_URL + 'pos/getQualification')
        .then(response => {
            const result = response.data
            dispatch(fetchQualificationSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchQualificationFailure(errorMsg))
        })
}