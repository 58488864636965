import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../.././assets/img/x.png";
import ImagePlaceholder from "../.././assets/img/img_placeholder.png";
import { file, stringFormat } from "../../utils/method";
import { useEffect } from "react";

const Attachments = ({
  setDocumentCount = void 0,
  documentCount = 0,
  loaderCount,
  personalDetail = [],
  appFormLoad = false,
  appFormImg = "",
  certLoad = false,
  doctorCertImg = "",
  uploadSingleFile = void 0,
  deleteFile = void 0,
  otherPhotoUpload = "",
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
  const otherdata = data?.others?.split(",")
  useEffect(() => {
    setDocumentCount && setDocumentCount(otherdata?.length)
  }, [data?.otherdata])
  return (
    <>
      <div className="d-flex flex-wrap form">
        <div style={{ padding: "19px" }}>
            <label
              htmlFor="Application Form"
              style={{ color: "#29166F", fontSize: "18px" }}
            >
              Application Form
            </label>
          {!appFormImg.length > 0 &&
          !personalDetail.application_form &&
          !isDisabled ? (
            <div>
              <label>
                {!appFormImg.length && appFormLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={appFormImg.length === 2}
                  onChange={(e) => uploadSingleFile(e, "app_form")}
                />
              </label>
              {!appFormImg.length > 0 && (
                <p className="p-error">
                  {errors.appFormImg || formErrors.appFormImg}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.application_form !== null && (
                <div className="img-preview">
                  {data?.application_form?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.application_form)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.application_form)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    appFormImg?.[0] || personalDetail?.application_form
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        appFormImg[0] || personalDetail?.application_form
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        appFormImg?.[0] || personalDetail?.application_form
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "app_form")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ padding: "19px" }}>
            <label
              htmlFor="Doctor Certificate"
              style={{ color: "#29166F", fontSize: "18px" }}
            >
              Doctor Certificate
            </label>
          {!doctorCertImg.length > 0 &&
          !personalDetail.doctor_certificate &&
          !isDisabled ? (
            <div>
              <label>
                {!doctorCertImg.length && certLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={doctorCertImg.length === 2}
                  onChange={(e) => uploadSingleFile(e, "doctor_Certificate")}
                />
              </label>
              {!doctorCertImg.length > 0 && (
                <p className="p-error">
                  {errors.doctorCertImg || formErrors.doctorCertImg}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.doctor_certificate !== null && (
                <div className="img-preview">
                  {data?.doctor_certificate?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.doctor_certificate)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.doctor_certificate)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    doctorCertImg?.[0] || personalDetail?.doctor_certificate
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        doctorCertImg[0] || personalDetail?.doctor_certificate
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        doctorCertImg?.[0] || personalDetail?.doctor_certificate
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "doctor_Certificate")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {!isDisabled && new Array(documentCount).fill(0).map((item, idx) => {
          return (
            <div key={idx} style={{ padding: "19px" }}>
              {!isDisabled && (
                <label
                  htmlFor="Other"
                  style={{ color: "#29166F", fontSize: "18px" }}
                >
                  Other Document {idx + 1}
                </label>
              )}
              {!otherPhotoUpload[idx] &&
              !personalDetail[`others-${idx}`] &&
              !isDisabled ? (
                <div>
                  <label>
                    {!otherPhotoUpload[idx] && loaderCount[idx] ? (
                      <div
                        className="Group"
                        src="images/not-found.png"
                        alt="alternative"
                      >
                        <span className="spinner-border spinner-border-lg"></span>
                      </div>
                    ) : (
                      <img
                        style={{ height: "190px" }}
                        src={ImagePlaceholder}
                        alt="alternative"
                      ></img>
                    )}
                    <input
                      type="file"
                      className="d-none"
                      size="60"
                      name="file"
                      // disabled={otherPhotoUpload.length === 10}
                      onChange={(e) => uploadSingleFile(e, "others-" + idx)}
                    />
                  </label>
                  {!otherPhotoUpload[idx] && (
                    <p className="p-error">
                      {errors.otherPhotoUpload || formErrors.otherPhotoUpload}
                    </p>
                  )}
                </div>
              ) : (
                <div className="row form-group">
                  {isDisabled && data?.[`others-${idx}`] !== null && (
                    <div className="img-preview">
                      {data[`others-${idx}`]?.includes(".pdf") == true ? (
                        <a
                          className="btn btn-primary"
                          href={file(data?.[`others-${idx}`])}
                          target="_blank"
                        >
                          Preview PDF File
                        </a>
                      ) : (
                        <LazyLoadImage
                          effect="blur"
                          src={file(data?.[`others-${idx}`])}
                          alt="preview"
                        />
                      )}
                    </div>
                  )}
                  {!isDisabled && (
                    <div className="img-preview">
                      {(
                        otherPhotoUpload?.[idx] ||
                        personalDetail[`others-${idx}`]
                      )?.includes(".pdf") == true ? (
                        <a
                          className="btn btn-primary"
                          href={file(
                            otherPhotoUpload[idx] ||
                              personalDetail[`others-${idx}`]
                          )}
                          target="_blank"
                        >
                          Preview PDF File
                        </a>
                      ) : (
                        <img
                          src={file(
                            otherPhotoUpload?.[idx] ||
                              personalDetail[`others-${idx}`]
                          )}
                          alt="preview"
                        />
                      )}
                      <img
                        onClick={() => deleteFile(idx, "others")}
                        className="clear"
                        src={X}
                        alt="clear-preview"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}


        {isDisabled && otherdata.map((item, idx) => {
          // if( item !==null) return
          return (
          item !="" && <div key={idx} style={{ padding: "19px" }}>
              <label
                htmlFor="Other"
                style={{ color: "#29166F", fontSize: "18px" }}
              >
                Other Document {idx + 1}
              </label>

              <div className="row form-group">
                {isDisabled && item !== null && (
                  <div className="img-preview">
                    {item?.includes(".pdf") == true ? (
                      <a
                        className="btn btn-primary"
                        href={file(item)}
                        target="_blank"
                      >
                        Preview PDF File
                      </a>
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        src={file(item)}
                        alt="preview"
                      />
                    )}
                  </div>
                )}
              </div>
            </div> 
          );
        })}
      </div>
      { isDisabled==false && documentCount < 5 && (
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setDocumentCount(documentCount + 1)}
        >
          <button className="btn btn-primary d-block m-2 m-auto text-center">
            Add More +
          </button>
        </h6>
      )}
      <hr />
    </>
  );
};

export default Attachments;
