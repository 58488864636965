import React from 'react';
// import { useState } from "react";

import './Connect.css';


export default function Connect(){
  
// let [loading, setLoading] = useState(true);

   
    return(<div className="connect">
              <div className="fill-window">             
                  <div className="center-screen">
                      {/* <div className="sweet-loading">  */}
                           
                            {/* <ReactSpinner type="border" color="black" size="3" />
                            <button onClick={() => setLoading(!loading)}></button> */}
                           
                           <div className="loader">Loading...</div>
                           {/* <div className="pe-2">
                                       <small> <img src="images/loading1.png " alt="loading" className="w-100 bg-transparent "/> </small>
                                        </div> */}
                               <p style={{fontStyle:"bold", color:"black", marginTop:"-20px"}}>Connecting to Insurance Company Portal </p>
                                <p style={{fontStyle:"normal", fontFamily:"ErasMediumITC", color:"black"}}> to Buy the Policy through the API</p>
                               
                    </div>
               </div>      
</div>)
}