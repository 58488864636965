import React from "react";
import { useEffect, useState, useRef } from "react";
import "./MyCertificate.scss";
import { ImDownload3 } from "react-icons/im";
import { certificate } from "../../../store/actions/getPosPersonalDetails";
import { file } from "../../../utils/method";
import CertificateContent from "./CertificateContent";
import { useReactToPrint } from 'react-to-print';

export default function MyCertificate() {
  const certificateContentRef = useRef();
  const User = JSON.parse(localStorage.getItem("user"));
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [val, setVal] = useState({});

  const handlePrint = useReactToPrint({
    content: () => certificateContentRef.current,
  });

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  useEffect(() => {
    certificate()
      .then((res) => {
        if (res.status) {
          setVal(res.data);
          // console.log(res.data);
        } else {
          alert(res.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
              className="useBreadCrumb"
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Certificate</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted"
                    aria-current="page"
                  >
                    Account Information
                  </li>
                  <li className="breadcrumb-item bg-transparent">
                    My Certificate
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="MyCertificate">
            <div className="agreement__btn-agreement">
              {/* <a
                href={val?.url ? val.url : ""}
                download
                target="_blank"
                height="80%"
              > */}
              <button onClick={handlePrint} style={{paddingTop:"0.5rem", paddingBottom:"0.5rem"}}>
                Download / Print Certificate
              </button>
              {/* </a> */}
            </div>
            <CertificateContent val={val} User={User} ref={certificateContentRef} />
          </div>
        </div>
      </main>
    </div>
  );
}
