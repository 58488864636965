import React from "react";
import "../BrandNew/CarStep4.css";
import Footer from "../../ReusableComponents/Footer";
import Stepper from "../../ReusableComponents/Stepper";
import NavBar from "../../ReusableComponents/NavBar";
// import { TextField } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core';
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// const useStyles = makeStyles({
//     input: {
//         paddingRight: '80px',
//         width: '355px',
//         height: '30px',
//         // outline:'red',
//         [`& fieldset`]: {
//             borderRadius: '10px',
//             // borderColor:'red',
//         },
//         marginTop: '50px'
//     }
// })

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;

const syqSchema = yup
  .object({
    name: yup.string().required(),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, "Invalid Phone Number")
      .min(10)
      .max(10),
    email: yup.string().email().required(),
  })
  .required();

export default function MoreThan3SYQ() {
  // const classes = useStyles();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(syqSchema),
  });
  const onSubmit = (data) => {
    if (data) {
      history.push("/", data);
    }
  };
  return (
    <>
      <div style={{ background: "#F2F6FF" }}>
        <NavBar />
        <br />
        <br />
        <div className="step4-car-insurance">
          <div className="container-fluid ">
            <div style={{ textAlign: "center" }}>
              <img
                src="images/quote.png"
                alt="top-img"
                className="VD-top-img"
              ></img>
            </div>

            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-lg-2 col-md-0 ">
                <Stepper
                  current={4}
                  thirdStep={true}
                  fouthStep={true}
                  src="images/active.png"
                />
              </div>
              <div className="step4-content col-lg-4 col-md-6">
                <h2>Save Your Quotes - </h2>
                <h6>Saved quotes will be sent to you via email or sms</h6>
                <br />
                <br />
                <div className="row">
                  <div className="car2-form-groupp">
                    <input
                      type="text"
                      id="icueRegistrationNo"
                      name="icueRegistrationNo"
                      className="car2-form-controll"
                      required
                      {...register("name")}
                    />
                    <label
                      className="ms-3 car2-form-control-placeholderr"
                      for="icueRegistrationNo"
                    >
                      Name
                    </label>
                    <p className="text-danger">{errors.name?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="car2-form-groupp">
                    <input
                      type="number"
                      id="icueRegistrationNo"
                      name="icueRegistrationNo"
                      className="car2-form-controll"
                      required
                      {...register("phone")}
                    />
                    <label
                      className="ms-3 car2-form-control-placeholderr"
                      for="icueRegistrationNo"
                    >
                      Mobile number
                    </label>
                    <p className="text-danger">{errors.phone?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="car2-form-groupp">
                    <input
                      type="text"
                      id="icueRegistrationNo"
                      name="icueRegistrationNo"
                      className="car2-form-controll"
                      required
                      {...register("email")}
                    />
                    <label
                      className="ms-3 car2-form-control-placeholderr"
                      for="icueRegistrationNo"
                    >
                      Email
                    </label>
                    <p className="text-danger">{errors.email?.message}</p>
                  </div>
                </div>
                {/* <br/><br/> */}
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "12rem",
                    textDecoration: "none",
                  }}
                >
                  <a style={{ color: "#0093DD" }} href="/">
                    Skip
                  </a>
                </div>

                <div className="step4-car-nextbtn">
                  <button onClick={handleSubmit(onSubmit)}>Next</button>
                </div>
              </div>
              <div className=" step4-img col-lg-6 col-md-6">
                <img src="images/car_step1.jpg" alt="alternative"></img>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
