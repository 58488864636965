import React, { useState } from "react";
import "./Healthstep1.css";
import NavBar from "../ReusableComponents/NavBar";
import Footer from "../ReusableComponents/Footer";
import { makeStyles } from "@material-ui/core";
import { InputLabel, Select, FormControl, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Stepper from "../ReusableComponents/Stepper";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles({
  relation: {
    width: "230px",
    marginLeft: "20px",
    // outline:'red',
    [`& fieldset`]: {
      borderRadius: "10px",
      // borderColor:'red',
      // paddingBottom:'25px
    },
  },
  dob: {
    width: "230px",
    [`& fieldset`]: {
      borderRadius: "10px",
    },
  },
});

export default function Health() {
  const classes = useStyles();
  const [self, setSelf] = useState(false);
  const [spouse, setSpouse] = useState(false);
  const [children, setChildren] = useState(false);
  const [mother, setMother] = useState(false);
  const [father, setFather] = useState(false);
  const [nxtbtn, setNxtbtn] = useState(false);
  const [enterDOB, setEnterDOB] = useState(false);
  const [counter, setCounter] = useState(1);
  // const [value] = useState(new Date());

  const [childrenInputs, setChildrenInputs] = useState([
    { DOB: "", relation: "" },
  ]);

  const healthSchema = yup.object({
    Self: self ? yup.string().required() : yup.string().notRequired(),
    Spouse: spouse ? yup.string().required() : yup.string().notRequired(),
    Children: children ? yup.string().required() : yup.string().notRequired(),
    Relation: children ? yup.string().required() : yup.string().notRequired(),
    Mother: mother ? yup.string().required() : yup.string().notRequired(),
    Father: father ? yup.string().required() : yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(healthSchema),
  });
  const onSubmit = (data) => {
    if (data) {
      history.push({
        pathname: "/HealthCover",
        state: {
          Self: self ? "Self - " : "",
          Spouse: spouse ? "Spouse - " : "",
          Children: children ? "Children - " : "",
          Mother: mother ? "Mother - " : "",
          Father: father ? "Father - " : "",
          Count: counter ? counter : "",
        },
      });
    }
  };

//   let today = new Date().toISOString().slice(0, 10);

  // const values = {
  //     defaultValue: today
  // };

  const history = useHistory();

  const handlenxtbtn = () => {
    if ((self || spouse || children || mother || father) === false) {
      alert("Please select atleast one member !!");
    } else {
      setEnterDOB(true);
    }
  };
  // const childrenDOB = [];

  const addchildreninput = () => {
    if (counter < 8) {
      setCounter(counter + 1);
      setChildrenInputs([...childrenInputs, { DOB: "", relation: "" }]);
    }
  };
  const removechildreninput = () => {
    if (counter > 1) {
      setCounter(counter - 1);
      const value = [...childrenInputs];
      value.pop();
      setChildrenInputs(value);
    }
  };
  return (
    <div className="main-div" style={{ background: "#F2F6FF" }}>
      <NavBar />
      <br />
      <br />
      <div className="step4-car-insurance">
        <div className="container-fluid ">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="H-step1-topimg">
              <img src="images/Component 17.png" alt="comp"></img>
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col-lg-1 col-md-0 ">
              <Stepper
                current={1}
                thirdStep={true}
                fouthStep={true}
                src="images/healthActive.png"
              />
            </div>
            <div className="H-step1-content col-lg-6 col-md-6">
              {enterDOB ? (
                <div>
                  <h6 style={{ fontSize: "26px" }}>
                    Tell the Date of Birth of selected member:
                  </h6>
                  <br />
                  <br />
                  {self ? (
                    <div>
                      <div className="d-flex flex-wrap">
                        <p className="H-enterDOB-p">Self - </p>

                        <div className="car2-form-groupp">
                          <input
                            type="date"
                            id="icueRegistrationNo"
                            name="icueRegistrationNo"
                            className="car2-form-controll"
                            style={{ width: "230px" }}
                            {...register("Self")}
                          />
                          <label
                            className="ms-3 car2-form-control-placeholderr"
                            for="icueRegistrationNo"
                          >
                            Date of Birth
                          </label>
                          <p className="text-danger  d-flex flex-wrap">
                            {errors.Self?.message}
                          </p>
                        </div>
                        <br />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {spouse ? (
                    <div>
                      <div className="d-flex flex-wrap mt-4">
                        <p className="H-enterDOB-p">Spouse - </p>
                        <div className="car2-form-groupp">
                          <input
                            type="date"
                            id="icueRegistrationNo"
                            name="icueRegistrationNo"
                            className="car2-form-controll"
                            style={{ width: "230px" }}
                            {...register("Spouse")}
                          />
                          <label
                            className="ms-3 car2-form-control-placeholderr"
                            for="icueRegistrationNo"
                          >
                            Date of Birth
                          </label>
                          <p className="text-danger  d-flex flex-wrap">
                            {errors.Spouse?.message}
                          </p>
                        </div>
                        <br />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {children ? (
                    <div>
                      {childrenInputs.map((inf, index) => {
                        // console.log("inf ---> ", inf);
                        return (
                          <div className="d-sm-flex mt-4">
                            <p className="H-enterDOB-p">Children-</p>
                            <div className="car2-form-groupp">
                              <input
                                type="date"
                                id="icueRegistrationNo"
                                name="icueRegistrationNo"
                                className="car2-form-controll ml-2"
                                style={{ width: "230px" }}
                                {...register("Children")}
                              />
                              <label
                                className="ms-3 car2-form-control-placeholderr"
                                for="icueRegistrationNo"
                              >
                                Date of Birth
                              </label>

                              <p className="text-danger d-flex flex-wrap">
                                {errors.Children?.message}
                              </p>
                            </div>
                            <FormControl
                              variant="outlined"
                              className={classes.relation}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Relation
                              </InputLabel>
                              <Select
                                label="Relation"
                                {...register("Relation")}
                              >
                                <MenuItem value="Son">Son</MenuItem>
                                <MenuItem value="Daughter">Daughter</MenuItem>
                              </Select>
                              <p className="text-danger  d-flex flex-wrap">
                                {errors.Relation?.message}
                              </p>
                            </FormControl>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {mother ? (
                    <div>
                      <div className=" d-flex flex-wrap mt-4">
                        <p className="H-enterDOB-p">Mother - </p>
                        <div className="car2-form-groupp">
                          <input
                            type="date"
                            id="icueRegistrationNo"
                            name="icueRegistrationNo"
                            className="car2-form-controll"
                            style={{ width: "230px" }}
                            {...register("Mother")}
                          />
                          <label
                            className="ms-3 car2-form-control-placeholderr"
                            for="icueRegistrationNo"
                          >
                            Date of Birth
                          </label>
                          <p className="text-danger  d-flex flex-wrap">
                            {errors.Mother?.message}
                          </p>
                        </div>
                        <br />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {father ? (
                    <div>
                      <div className=" d-flex flex-wrap mt-4">
                        <p className="H-enterDOB-p">Father - </p>
                        <div className="car2-form-groupp">
                          <input
                            type="date"
                            id="icueRegistrationNo"
                            name="icueRegistrationNo"
                            className="car2-form-controll"
                            style={{ width: "230px" }}
                            {...register("Father")}
                          />
                          <label
                            className="ms-3 car2-form-control-placeholderr"
                            for="icueRegistrationNo"
                          >
                            Date of Birth
                          </label>
                          <p className="text-danger  d-flex flex-wrap">
                            {errors.Father?.message}
                          </p>
                        </div>
                        <br />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="H-step1-nextbtn1">
                    <button onClick={handleSubmit(onSubmit)}>Next</button>
                  </div>
                </div>
              ) : (
                <>
                  <h6 className="H-step1-h6">
                    Who would you like to insure ?{" "}
                  </h6>

                  <div className="H-ck-buttons">
                    <div id="ck-button">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSelf(e.target.checked);
                            setNxtbtn(true);
                          }}
                        />
                        <span>Self</span>
                      </label>
                    </div>

                    <div id="ck-button">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setSpouse(e.target.checked);
                            setNxtbtn(true);
                          }}
                        />
                        <span>Spouse</span>
                      </label>
                    </div>

                    <div id="ck-button">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setChildren(e.target.checked);
                            setNxtbtn(true);
                          }}
                        />
                        <span>Children</span>
                      </label>
                      {children ? (
                        <div className="H-children-count">
                          <button
                            onClick={addchildreninput}
                            style={{ marginRight: "20px" }}
                          >
                            +
                          </button>
                          <span className="H-children-counter">{counter}</span>
                          <button onClick={removechildreninput}>-</button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <br />
                    <br />
                    <br />

                    <div id="ck-button">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setMother(e.target.checked);
                            setNxtbtn(true);
                          }}
                        />
                        <span>Mother</span>
                      </label>
                    </div>
                    <div id="ck-button">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setFather(e.target.checked);
                            setNxtbtn(true);
                          }}
                        />
                        <span>Father</span>
                      </label>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  {nxtbtn ? (
                    <div className="H-step1-nextbtn1">
                      <button onClick={handlenxtbtn}>Next</button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>

            <div className=" H-step1-rightimg col-lg-5 col-md-6">
              <img src="images/im2 4.png" alt="im234"></img>
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
      {/* {
                self ? (<h1> Abhinav is great</h1 >) : ("")
            } */}

      <Footer />
      {/* <div id='ck-button'>
                <label >
                <input type='checkbox' /><span className='span'>red</span>
                </label>
            </div> */}
    </div>
  );
}
