
import React from 'react'
import { useHistory } from 'react-router-dom'
import './product.css'
export default function Products() {
  const redirect=()=> {
    var url = "https://www.youtube.com";
    window.location(url);
    }
    const history=useHistory();
    return (
        <div className='products-card'>
            <div className='products'>
                <a href="https://policicue.com/#/HealthInsurance"><div className='item' >
                    <img src="images/landing/health.png" className="img-mail" />
                    <span className='span-text' >Health Insurance</span>
                </div></a>
                <a href="https://policicue.com/#/carstep1"> <div className='item' >
                    <img src="images/landing/car.png" className="img-mail" />
                    <span className='span-text' >Car Insurance</span>
                </div></a>
                 <a href="https://policicue.com/#/Two-wheeler"><div className='item' >
                    <img src="images/landing/bike.png" className="img-mail" />
                    <span className='span-text' >Two-wheeler Insurance</span>
                </div></a>
                <a href="https://policicue.com/#/LifeInsurance"> <div className='item'>
                    <img src="images/landing/term.png" className="img-mail" />
                    <span className='span-text' >Term Insurance</span>
                </div></a>
                <a href="https://policicue.com/#/cattleInsurance"> <div className='item'>
                    <img src="images/landing/cattle-img.svg" className="img-mail" />
                    <span className='span-text' >Cattle Insurance</span>
                </div></a>
                 <a href="https://policicue.com/#/OtherInsurance"><div className='item'>
                    <img src="images/landing/others.png" className="img-mail" />
                    <span className='span-text' >Other Insurance</span>
                </div></a>
                {/* <div>
<ul>
<h4>Motor Insurance</h4>

<li className='products-li'><a href='/carstep1'>Car Insurance</a></li>
<li className='products-li'><a href='/BikeInsurance'>Two Wheelar Insurance</a></li>
<li className='products-li'><a href='#'>Commercial Vehicle Insurance</a></li>
<li className='products-li'><a href='#'>Used Car Insurance</a></li>
</ul>
</div>
<div>
<ul>
<h4>Health Insurance</h4>

<li className='products-li'><a href='#'>Base Idemnity</a></li>
<li className='products-li'><a href='#'>Critical Illness</a></li>
<li className='products-li'><a href='#'>Super Top-Up</a></li>
<li className='products-li'><a href='#'>Sanjivini</a></li>
</ul>
</div>
<div>
<ul>
<h4>Life Insurance</h4>

<li className='products-li'><a href='/LifeStep1'>Term Insurance</a></li>
</ul>
</div>
<div>
<ul>
<h4>Other Insurance</h4>

<li className='products-li'><a href='#'>Marine Insurance</a></li>
<li className='products-li'><a href='#'>Fire Insurance</a></li>
<li className='products-li'><a href='#'>Liability Insurance</a></li>
</ul>
</div> */}
            </div>
        </div>
    )
}