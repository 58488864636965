import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Stepper from '../ReusableComponents/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import { getBikeMakes } from '../../store/actions/bikeMake';
import { getRTO } from '../../store/actions/rto';
import { getBikePolicyTypes } from '../../store/actions/bikePolicyTypes';
import { getBikeModels } from '../../store/actions/bikeModel';
import { getBikeMakeModelVariants } from '../../store/actions/bikeMakeModalVariant';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


function VehicleDetails2() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [bikeMake, setBikeMake] = useState()

    const list = useSelector(state => state.bikeMake.data.data);
    const models = useSelector(state => state.bikeModels.data.data);
    const bikeVariants = useSelector(state => state.bikeMakeModalVaraint.data.data)
    const rtoList = useSelector(state => state.rto.data.data);
    const policyTypes = useSelector(state => state.bikePolicyTypes.data.data);


    const vd2Schema = yup.object({
        bikeMake: bikeMake ? yup.string().notRequired() : yup.string().required(),
        model: yup.string().required(),
        variant: yup.string().required(),
        rto: yup.string().required(),
        policy: yup.string().required()
    })


    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(vd2Schema)
    });


    const onSubmit = data => {
        if (data) {
            history.push('/SaveYourQuotes')
        }
    }

    useEffect(() => {
        dispatch(getBikeMakes())
        dispatch(getRTO())
        dispatch(getBikePolicyTypes())
    }, [dispatch])


    const getDataByID = (id) => {
        setBikeMake(id)
        dispatch(getBikeModels(id))
        dispatch(getBikeMakeModelVariants(id))
    }

    return (
        <>
            <NavBar />
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div className='step3-car-insurance'>
                    <div className="container-fluid ">
                        <div style={{ textAlign: 'center' }}><img src='images/centralbike.png' className='VD-top-img' alt="alternative"></img></div>

                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className="step3-progress-img col-lg-2 col-md-0">
                                <Stepper current={2} thirdStep={true} src="images/bikeactive.png" />
                            </div>
                            <div className="step3-content col-lg-6 col-md-12">
                                <h2 className="ml-3">Your Vehicle Details   - </h2>

                                {list && list.length > 0 && list.map(({ id, name, logo }) => {
                                    return (
                                        <img
                                            onClick={() => getDataByID(id)}
                                            className='car-step3-img'
                                            src={`/images/${logo}`}
                                            name={`${name}`}
                                            value={id}
                                            alt=""
                                            style={{ cursor: 'pointer' }} />
                                    )
                                })}

                                <br />
                                <div className='col-lg-10'>
                                    <div className='d-sm-flex'>
                                        <div className="form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarMake"
                                                name="icueCaeMake"
                                                className="form-controll BrandNewSelect"
                                                required
                                                {...register("bikeMake")}
                                                value={bikeMake}
                                                onChange={(e) => getDataByID(e.target.value)}
                                            >

                                                <option value="" selected></option>
                                                {list && list.length > 0 && list.map(({ id, name }) => {
                                                    return (
                                                        <option
                                                            key={id}
                                                            id={id}
                                                            value={id}
                                                        >
                                                            {name}
                                                        </option>
                                                    )
                                                })}

                                            </select>
                                            <label
                                                className="ms-3 form-control-placeholderr"
                                                for="icueCarMake"
                                            >
                                                Bike Make
                                            </label>
                                            <p className="text-danger">{errors.bikeMake?.message}</p>
                                        </div>


                                        <div className="form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarModel"
                                                name="icueCarModel"
                                                className="form-controll BrandNewSelect"
                                                required

                                            >
                                                {models && models.length > 0 ? (
                                                    models.map(({ modelName }) => {
                                                        return (
                                                            <option {...register("model")}>{modelName}</option>
                                                        )
                                                    })) : (<option>{""}</option>)}
                                            </select>
                                            <label
                                                className="ms-3 form-control-placeholderr"
                                                for='icueCarModel'
                                            >
                                                Model
                                            </label>
                                            <p className="text-danger">{errors.model?.message}</p>
                                        </div>
                                    </div>
                                    <div className='d-sm-flex'>
                                        <div className="form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarVariant"
                                                name="icueVariant"
                                                className="form-controll BrandNewSelect"
                                                required
                                            >
                                                {bikeVariants && bikeVariants.length > 0 ? (
                                                    bikeVariants.map(({ variant, model }) => (
                                                        <optgroup label={model}>
                                                            {variant.map(({ variantId, variantName }) => (
                                                                <option {...register("variant")} key={variantId}>{variantName}</option>
                                                            ))}
                                                        </optgroup>
                                                    ))
                                                ) : (<option>{""}</option>)}
                                            </select>
                                            <label
                                                className="ms-3 form-control-placeholderr"
                                                for='icueCarModel'
                                            >
                                                Variant
                                            </label>
                                            <p className="text-danger">{errors.variant?.message}</p>
                                        </div>
                                        <div className="form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarRto"
                                                name="icueCarRto"
                                                className="form-controll BrandNewSelect"
                                                required
                                                {...register("rto")}
                                            >
                                                <option value="" selected disabled></option>
                                                {rtoList && rtoList.map(({ name, codeName, districts }) => {
                                                    return <optgroup label={name}>
                                                        {districts.map(({ code, districts }) => {
                                                            return <option>
                                                                {`${codeName}-${code} ${districts}`}
                                                            </option>
                                                        })}
                                                    </optgroup>

                                                })}
                                            </select>
                                            <label
                                                className="ms-3 form-control-placeholderr"
                                                for='icueCarRto'
                                            >
                                                RTO
                                            </label>
                                            <p className="text-danger">{errors.rto?.message}</p>
                                        </div>
                                    </div>
                                    <div className='d-sm-flex'>
                                        <div className="form-groupp mt-3 mb-2">
                                            <select
                                                id="icuePolicyType"
                                                name="icuePolicyType"
                                                className="form-controll BrandNewSelect"
                                                required
                                                {...register("policy")}

                                            >
                                                <option value="" selected disabled></option>
                                                {policyTypes && policyTypes.map(({ name }) => {
                                                    return (
                                                        <option>{name}</option>
                                                    )
                                                })}

                                            </select>
                                            <label
                                                className="ms-3 form-control-placeholderr"
                                                for='icuePolicyType'
                                            >
                                                Plan Type
                                            </label>
                                            <p className="text-danger">{errors.policy?.message}</p>
                                        </div>
                                    </div>


                                    <div style={{ textAlign: 'center' }}><button className="step3-car-nextbtn" onClick={handleSubmit(onSubmit)}>Next</button></div>
                                </div>
                            </div>

                            <div className="step3-img col-lg-4 col-md-12">
                                <img src='images/im4 2.png' alt="alternative"></img>
                            </div>
                        </div>
                    </div>


                    <Footer />
                </div>
            </div>
        </>
    )
}

export default React.memo(VehicleDetails2)