import React, { useEffect, useState } from "react";
import "./MyExamination.scss";
import { BsCheckAll } from 'react-icons/bs';
import Countdown from "react-countdown";
import { getQuestion, doAnswer, getResult, certificate, updateStatus, examFinish } from '../../../store/actions/getPosPersonalDetails'
import { alertShow } from '../../../store/actions/alert'
import { file } from "../../../utils/method"



const MyExamination = () => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [changePage, setChangePage] = useState(false);
  const [results, setResults] = useState(false);
  const [isFinish, setFinish] = useState(false);
  const [timer, setTimer] = useState(Date.now() + 1800000);
  const [question, setQuestion] = useState([]);
  const [current, setcurrent] = useState(0);
  const [quesId, setquesId] = useState();
  const [ansId, setansId] = useState();
  const [finalResult, setFinalResult] = useState([]);
  const [downloadurl, setDownload] = useState('');
  const [Exam, setExam] = useState(true);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    getQuestion().then(res => {
      if (res.status) {
        setQuestion(res.data)
      } else {
        alertShow('err', res.message)
      }
    })
      .catch((error) => {
        alertShow('err', error.message)
      });
    result_get()
  }, [])


  const handlechangePage = () => {
    setTimer(Date.now() + 1800000);
    setChangePage(true);

  }

  const get_exam_result = () => {
    getResult().then(res => {
      if (res.status) {
        setFinalResult(res.data)
        setResults(true)
        console.log("tes",1)
        if (res.data?.result === 'Pass') {
           console.log("tes",2)
          updateStatus({ posStatus: 'Pass' })
           setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else if (res.data?.result === 'Fail') {
           console.log("tes",3)
          updateStatus({ posStatus: 'Fail' })
           setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      }
    })
      .catch((error) => {
         console.log("tes",4)
        alertShow('err', error.message);
      });
  }
  const result_get_refresh = () => {
    getResult().then(res => {
      if (res.status) {
        setFinalResult(res.data)
        setResults(true)
        if (res.data?.result === 'Pass') {
          updateStatus({ posStatus: 'Pass' })
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else if (res.data?.result === 'Fail') {
          updateStatus({ posStatus: 'Fail' })
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      }
    })
      .catch((error) => {
        alertShow('err', error.message);
      });
  }
  const result_get = () => {
    getResult().then(res => {
      if (res.status) {
        setFinalResult(res.data)
        setResults(true)
        if (res.data?.result !== 'Fail') {
          certificate().then(res => {
            if (res.status) {
              setDownload(res.data?.url)
            }
          })
            .catch((error) => {
              alertShow('err', error.message);
            });
        }  
      }
    })
      .catch((error) => {
        alertShow('err', error.message);
      });
  }
  const result_arr = (arr, obj) => {
    setArr([...arr.filter((e) => { return e.quesId !== obj.quesId; }), obj])
  }
  const handlecBar = () => {
    if (question.length >= current + 1) {
      if (quesId !== '' && ansId !== '') {
        let data = { question_id: quesId, answer_id: ansId }
        doAnswer(data).then(res => {
          if (res.status == 0 && res.message == 'Answer already given') {
            question.length !== current + 1 && setcurrent(current + 1)
            question.length !== current + 1 && result_arr([...arr, { quesId, ansId }], { quesId, ansId })
            question.length === current + 1 && setFinish(true)
          }
          if (res.status === 1 || res.status == 0 && res.message == 'updated') {
            if (question.length !== current + 1) {
              setquesId(''); setansId('');
              result_arr([...arr, { quesId, ansId }], { quesId, ansId })
              setcurrent(current + 1)
            }

            if (question.length === current + 1) {
              setFinish(true)
            }
          }
        })
          .catch((error) => {
            alertShow('err', error.message);
          });
      }

    }
  }
  const handlecBarans = (quesId, ansId) => {
    result_arr([...arr], { quesId, ansId })
  }
  const Completionist = () =>

    <span>
      Your Exam is completed
    </span>
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      get_exam_result();
      setTimeout(() => {
          window.location.reload();
      }, 4000);

      
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const optionAlpha = (ind) => {
    if (ind == 0) return "A";
    else if (ind == 1) return "B";
    else if (ind == 2) return "C";
    else if (ind == 3) return "D";
    else if (ind == 4) return "E";
    else if (ind == 5) return "F";
  }
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  const answerSelect = (ques, ans) => {
    setquesId(ques)
    setansId(ans)
  }

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent  rounded-3">
              <nav
              className="useBreadCrumb"
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Examination</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    Account Information
                  </li>

                  <li className="breadcrumb-item bg-transparent">My Examination</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </main>
      {Exam ?
        <div className="maindiv">
          <img src="images/examination.jpeg" width="99%" />
          <button onClick={() => setExam(false)}  >Next</button>
        </div>
        : <>
          {!results ? <>
            {!changePage ?
              <div className="section-examination">
                <div className="tbody-section">
                  <div className="tbody-section__main-content" >
                    <h1>Examination Instructions</h1>
                    <div className="tbody-section__main-content__examination-information  " >
                      <div className="display_flex" >
                        <span><BsCheckAll /></span>
                        <p>Exam time is 30 minutes</p>
                      </div>
                      <div className="display_flex  " >
                        <span><BsCheckAll /></span>
                        <p>30 Questions will be given</p>
                      </div>
                      <div className="display_flex" >
                        <span><BsCheckAll /></span>
                        <p>12 marks has to be obtained to pass the examination </p>
                      </div>
                      <div className="display_flex" >
                        <span><BsCheckAll /></span>
                        <p>Multiple Choice Questions will be asked and you have to choose any one as answer</p>
                      </div>
                      <div className="display_flex" >
                        <span><BsCheckAll /></span>
                        <p>Mobile, Calculator or similar gadgets are not allowed</p>
                      </div>
                      <div className="tbody-section__main-content__examination-information__btn-exam ">
                        <button className="btn-button" onClick={handlechangePage}>START EXAM</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : <>

                <div className="examination-block" >
                  <div className="examination-block__table-form" >
                    <div><h3>Question No: {current + 1}</h3></div>
                    <div><h3>Time Left: {question.length > 0 ? <Countdown date={timer} renderer={renderer} /> : '00:00:00'}</h3></div>
                  </div>
                  <div className="examination-block__table-form2" >
                    <div className="examination-block__table-form2__question-click">
                      <div className="examination-block__table-form2__question-click__btn-pointer" >
                        {
                          question.map((v, i) => {
                            // return <button onClick={()=>setcurrent(i)} className={i<current?"attemp":i==current?"quesactive":null}>{i+1}</button>
                            return <button onClick={() => setcurrent(i)} className={(arr.map((e) => (e.quesId)).indexOf(v.question_id) != -1 && i != current) ? "attemp" : i == current ? "quesactive" : null}>{i + 1}</button>

                          })
                        }

                      </div>
                      <div className="selections">
                        <div className="select-options">
                          <div className="display_flex" ><span className="select-options__btn-select"></span><p>Not Viewed yet</p></div>
                          <div className="display_flex" ><span className="select-options__btn-select color-blue "></span><p>Attempted</p></div>
                        </div>
                        <div className="select-options">
                          {/* <div className="display_flex" ><span className="select-options__btn-select color-red " ></span><p>Not Attempted</p></div> */}
                          <div className="display_flex" ><span className="select-options__btn-select color-grazz "></span><p>Active Question</p></div>
                        </div>
                      </div>

                    </div>
                    <div className="examination-block__table-form2__question-click2">
                      <div className="examination-block__table-form2__question-click2__Answer">
                        <div className="examination-block__table-form2__question-click2__Answer-click" >
                          <span className="questionClass">{!!question.length && question[current].question}</span>
                          {!!question.length && question[current]?.options.map((opt, ind) => {

                            return (<div className="examination-block__table-form2__question-click2__Answer-click__A1-btn" >
                              <div>
                                {arr.map((e) => { return e.quesId; }).indexOf(opt.question_id) == -1 ? <button onClick={() => answerSelect(opt.question_id, opt.id)} className={(opt.question_id == quesId && opt.id == ansId) ? "attemp" : null}>{optionAlpha(ind)}</button>
                                  : <button onClick={() => { answerSelect(opt.question_id, opt.id); handlecBarans(opt.question_id, opt.id) }} className={((arr.map((e) => (e.quesId)).indexOf(opt.question_id) != -1 && arr.map((e) => (e.ansId)).indexOf(opt.id) != -1)) ? "attemp" : null}>{optionAlpha(ind)}</button>
                                }
                              </div>
                              <span className="optionClass">{opt?.option}</span>
                            </div>)
                          })
                          }

                        </div>
                      </div>
                      <div className="examination-block__table-form2__question-click2__Answer-click__start-btn" >
                        {/* <div><button onClick={()=>{  setquesId('');setansId('')}} className="examination-block__table-form2__question-click2__Answer-click__start-btn__clear-selection" >CLEAR SELECTION</button></div>  */}
                        <div></div>
                        <div>
                          <span>
                            {/* <button className="examination-block__table-form2__question-click2__Answer-click__start-btn__select-btn mr-s "  >PREVIOUS</button> */}
                          </span>
                          <span>

                            {isFinish ? <button className="examination-block__table-form2__question-click2__Answer-click__start-btn__select-btn" style={{ background: '#F30F27', color: '#fff' }} onClick={result_get_refresh}  >FINISH EXAM</button>
                              : <button className="examination-block__table-form2__question-click2__Answer-click__start-btn__select-btn" onClick={handlecBar}  >NEXT</button>
                            }</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </>
            }
          </>
            : <>
              <div className="bg-white result-section " >
                <div className="result-section__lg-congress">
                  {finalResult?.result == 'Pass' && <h3 className="result-section__lg-congress__congratulations">Congratulations</h3>}
                  {finalResult?.result == 'Pass' ? <p className="result-section__lg-congress__lg-pera-result" >Thanks you for competing the test. You've successfully passed the test.</p> :
                    <p className="result-section__lg-congress__lg-pera-result" >Thank you for completing the test. You did not answer enough questions to qualify atleast passing grade.</p>}
                  <div className="result-section__lg-congress__lg-Questions" >Questions Correct : <span>{finalResult?.correctQuestion}/{finalResult?.totalQuestion}</span></div>
                  <div className="result-section__lg-congress__lg-Score" >Your Score : <span>{finalResult?.score}/100</span></div>
                    <div className="result-section__lg-congress__lg-Status" ><p>Status : {finalResult?.result === 'Pass' ? <span>{finalResult?.result}</span> : <span style={{ color: "red" }}>{finalResult?.result}</span>}</p></div>
                  {finalResult?.result == 'Pass' ?
                    <span>{downloadurl != '' ?
                      <a href="/#/MyCertificate" target="_self"><button className="result-section__lg-congress__lg-Download-btn" >Download / Print Your Certificate</button></a>
                      :
                      <button onClick={() => { alertShow('err', 'No Certificate Found') }} className="result-section__lg-congress__lg-Download-btn" >Download Your Certificate</button>
                    }

                    </span>
                    :
                    <button onClick={() => { setChangePage(false); setResults(false); setcurrent(0); setFinish(false) }} className="result-section__lg-congress__lg-Download-btn" >Retake Exam</button>
                  }
                </div>
              </div>            
            </>
          }
        </>}

    </div>
  );
};
export default MyExamination;