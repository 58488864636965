import React from "react";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <tr>
      <td>
        <input
       
          type="text"
          required="required"
          placeholder="Enter employee name"
          name="employee"
          value={editFormData.employee}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
         
          type="text"
          required="required"
          placeholder="Enter leave"
          name="leavetype"
          value={editFormData.leavetype}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
         id="date"
          type="date"         
          required="required"
          placeholder="Enter from date"
          name="from"
          value={editFormData.from}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="date"         
          required="required"
          placeholder="Enter to date"
          name="to"
          value={editFormData.to}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="text"         
          required="required"
          placeholder="Enter no. of days"
          name="days"
          value={editFormData.days}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="text"         
          required="required"
          placeholder="Enter reason"
          name="reason"
          value={editFormData.reason}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="text"         
          required="required"
          placeholder="Enter manager name"
          name="manager"
          value={editFormData.manager}
          onChange={handleEditFormChange}
         ></input>
      </td>
   
      <td>
        <button type="submit">Save</button>
        <button type="button" onClick={handleCancelClick}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;