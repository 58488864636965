


import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import "./DropDown.css";

export default function DropDownSelect({onChange,data,placeholder,type}) {
  // const options = data.length>0 && data.map(v => ({ value: v?.state }))
  const [options, setoptions] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [selection, setSelection] = React.useState("");
  React.useEffect(() => {
    const optios = data !=undefined ? data.map(v => ({ 'label':type==='state'? v?.state:type==='city'?v?.city:v?.pincode })):[]
    setoptions(optios)
    setSelection(placeholder);
  }, [data]);
  return (
    <Autocomplete
      // disablePortal
      freeSolo
      clearIcon 
      id="free-solo-2-demo"
      disableClearable
      options={options}
      // onChange={(event, newValue) => {
      //   console.log(event,"event")
      // }}
      onChange={(event, newValue) => {
        newValue?.label&& onChange(newValue.label,"event")
      }}
      sx={{ width: 300 }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params} label={type==='state'?'State*':type==='city'?'City*':'Pincode*'} />}
    />
  );
}




// import { makeStyles } from "@material-ui/core/styles";
// import { MenuItem, InputBase, Menu, Divider , Button  } from "@material-ui/core";
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { useEffect, useState } from "react";
// import "./ProfileDetail.css";
// // import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// // import SearchIcon from "@mui/icons-material/Search";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';

// const useStyles = makeStyles((theme) => ({
//   DropDownButton: {
//     margin: "0px 0px",
//     fontSize: "1.125rem",
//     width: "320px",
//     height: "55px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     border: "1px solid #d3d3d3",
//     backgroundColor: "white !important" ,
//     cursor: "pointer",
//     padding: "0px 20px",
//     borderRadius: "4px",
//   },
//   inputRoot: {
//     color: "inherit",   
//     width: "100%"
//   },
//   search: {
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     marginRight: "20px",
//     marginLeft: 0,
//     width: "100%",
//     border: "1px solid grey"
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//     transition: theme.transitions.create("width"),
//     width: "100%"
//   },
//   searchBarContainer: {
//     minWidth: "inherit",
//     display: "flex",
//     justifyContent: "space-evenly",
//     cursor: "default",
//     "&.MuiListItem-button": {
//       "&:hover": {
//         backgroundColor: "white !important"
//       }
//     }
//   },
//   menuDivider: {
//     margin: "0 20px"
//   },
//   dashboardSelectMenu: {
//     "& .MuiPopover-paper": {
//         top:'12rem!important',
//         left:'39.4%!important',
//       minWidth: "327px",
//       maxWidth: "fit-content",
//       position: "fixed !important"

//     }
//   },
//   externalLinkIcon: {
//     borderLeft: "1px solid var(--color-gray-eighty-five)",
//     padding: "10px 0px 10px 10px",
//     color: "var(--color-primary)",
//     cursor: "pointer"
//   },
//   checkedItem: {
//     color: "indigo",
//   }
// }));



// function DropDownSelect({onChange,data,placeholder,type}) {
  //   const options = data.length>0 && data.map(v => ({ value: v?.state }))
  // const classes = useStyles();
  // const [options, setoptions] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [searchText, setSearchText] = useState("");
  // const [selection, setSelection] = useState("");

  // useEffect(() => {
  //   const optios = data !=undefined ? data.map(v => ({ 'value':type==='state'? v?.state:type==='city'?v?.city:v?.pincode })):[]
  //   setoptions(optios)
  //   setSelection(placeholder);
  // }, [data]);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (e) => {
//     if (e.target.innerText !== selection && e.target.innerText !== "") {
//       setSelection(e.target.innerText);
//       onChange(e.target.innerText)
//     }
//     setSearchText("");
//     setAnchorEl(null);
//   };

//   const handleSearchChange = (e) => {
//     setSearchText(e.target.value);
//   };
//   return (
//     <div className="positive-relative  btn-buton" >
        
//       <Button
//         type="button"
//         className={classes.DropDownButton}
//         onClick={handleMenuOpen}
//       >
//         {selection}
//         <span className="iconselect"><ArrowDropDownIcon/></span>
//       </Button>

//       <label className="state">{type==='state'?'State*':type==='city'?'City*':'Pincode*'}</label>
//       {renderDashboardMenu()}
//     </div>
//   );

//   function renderDashboardMenu() {
//     const displayOptions = options
//       .map((item) => {
//         if (item.value.toLowerCase().includes(searchText.toLowerCase())) {
//           return item;
//         }
//         return undefined;
//       })
//       .filter((item) => item !== undefined);
//     function renderOption(value) {
//       if (selection === value) {
//         return (
//           <div className={classes.checkedItem}>
//             {value}
//           </div>
//         );
//       }
//       return value;
//     }

//     return (
//       <Menu
//         anchorEl={anchorEl}
//         keepMounted={true}
//         open={!!anchorEl}
//         onClose={handleClose}
//         className={classes.dashboardSelectMenu}
//         anchorReference="anchorPosition"
//         anchorPosition={{ top: 510, left: 240 }}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "center"
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "center"
//         }}
//       >
//         <MenuItem
//           className={classes.searchBarContainer}
//           disableTouchRipple={true}
//         >
//           <div className={classes.search}>
          
//             <InputBase
//               placeholder="Search"
//               classes={{
//                 root: classes.inputRoot,
//                 input: classes.inputInput
//               }}
//               onChange={handleSearchChange}
//               value={searchText}
//             />
//           </div>
//         </MenuItem>
//         <Divider />
//         {displayOptions.map((item, index) => {
//           return (
//             <div key={index}  >
//               <MenuItem onClick={(e) => handleClose(e)}>
//                 {renderOption(item.value)}
//               </MenuItem>
//               <Divider className="car2-form-controll w-100" />
//             </div>
//           );
//         })}
//       </Menu>
//     );
//   }
// }

// export default DropDownSelect;