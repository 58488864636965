import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import './PrivacyPolicy.css'

export default function PrivacyPolicy() {
    const [checked, setChecked] = useState(true);
    return (
        <div>
            <NavBar />

            <br />
            <br />
            <br />
            <div className='header-block'>
                <h4 className='h4-text-header'> Privacy Policy</h4>
                <h4 className='h4-text-header'>WE RESPECT YOUR PRIVACY</h4>
                <p className='container des-data'>
                    PoliciCue owns following website: www.policicue.com and application:
                    <br />

                    This Privacy Policy applies to the service provided via the aforesaid website and application.
                    <br />

                    This policy describes the personal information we collect from you and how we use and secure it. “Identifiable Personal Information” or “IPI” means information that can be used to identify you personally, such as an email address, a photograph with unique identifying features, etc.
                    <br />


                    Information we collect: If you choose to provide us with your IPI by or for using the Services, you are agreeing to the processing and storage of your information in India, which may have data protection laws that differ from laws in your country.
                    <br />


                    At PoliciCue, we are committed to protecting your privacy and adhere with the applicable data protection and privacy laws. Please take some time to read through our privacy policy as this is your sole guide to understand how we aim to use the information we collect from you.

                </p>
                <h4 className='h4-text-header'> INFORMATION WE COLLECT</h4>
                <p className='container des-data'>

                    IPI: We may collect personal information from you under circumstances, when you provide such information voluntarily at our website and application, such as,
                    <br />
                    <ol>
                        <li>
                            User's name,
                        </li>
                        <li>
                            Location/ address(optional, only if user allow location permission or enter in textbox),

                        </li>
                        <li>
                            Phone number (required for login and  OTP etc),

                        </li>
                        <li>
                            Photo (optional to be used as profile pic),

                        </li>
                        <li>
                            Details of insurance product, policies etc. they are looking to buy/port/close/claim/switch etc.,

                        </li>
                        <li>
                            Photographs and videos as applicable,

                        </li>
                        <li>
                            Facebook permissions (only if the user wants to share on their pages.),
                        </li>
                        <li>
                            User activities such as liking of policy or showing interest in particular policy.

                        </li>
                    </ol>
                </p>

                <p className='container des-data'>
                    PoliciCue Services are intended for use by persons over 18 years old. In addition, it does not knowingly solicit or collect IPI from persons under 18 years old. We may delete from the Service IPI, as well as Posts and other information, from users we believe are under the age of 18.
                    <br />

                    To enhance and individualize your experience with the Service, we may use small files called “cookies” on our websites that let us know that you are a prior user and help us ensure that your information is directed to you. We also use services on the web that help us improve your customer experience. We do not use these services to collect information about you for any other purpose.

                </p>

                <h4 className='h4-text-header'> NON-PERSONALLY IDENTIFIABLE INFORMATION</h4>
                <p className='container des-data'>
                    We also collect non-personally identifiable information about visitors’ web activity. This information includes the operating system a visitor is using, the date and time the user visited a website, referring URLs (what website the visitor came from), Internet service provider, browser type, device identifier, and information on the pages that visitors’ access or visit. Most non-personally identifiable information is collected via cookies or other analysis technologies.
                </p>

                <h4 className='h4-text-header'> HOW WE USE INFORMATION</h4>
                <p className='container des-data'>
                    We'll use this data to personalize and improve their search and experience on the application and web page.
                    <br />
                    We will share your personal information with third parties only in the ways that are described in this privacy policy.
                    <br />

                    PoliciCue may use, share, transfer, or disclose your IPI and/or usage statistics, itself or in conjunction with third parties, for fraud prevention, customer service, research, and to tailor PoliciCue content and services to its users’ needs. These third parties are authorized to use your IPI only as necessary for the purpose(s) of PoliciCue agreements with them.
                    <br />

                    PoliciCue may share your IPI with any parent company, subsidiaries, joint ventures, or other companies under common control, in which event it will require that such companies follow this Policy with respect to your IPI.
                    <br />

                    We use the information provided by you to enable us deliver necessary services, to assist you better, helping us to understand your needs and requirements in detail. We use the information collected to enable us to fulfil your requests more efficiently. For example, we may use your information to:
                    <br />
                    Execute your request for products and services.
                    <br />
                    Interact with you to build relationship with you.
                    <br />
                    Conduct business with you.
                    <br />
                    Contact you to provide the relevant information regarding any events, promotions.
                    <br />
                    Attain your feedback to improve our services, products, effectiveness of website etc.
                    <br />
                    To notify you regarding any new products, services, offers that may be of interest to you.

                    <br />
                    To process your application with us.

                    <br />
                    Keeping clients and other contacts informed of the services we offer and industry developments that may be of interest to them, and to notify them of service offerings, seminars and other events we organise.
                    <br />
                    General management and reporting purposes, such as invoicing and account management.

                    <br />
                    Recruitment of staff and providing internal services to them.
                    <br />
                    All other lawful purposes related to our business and in furtherance to fulfilment of lawful contract.

                </p>
                <h4 className='h4-text-header'> MANDATORY SHARING OF YOUR INFORMATION</h4>
                <p className='container des-data'>
                    PoliciCue will share or disclose your information where deemed to be necessarily disclosed in response to any law enquiry, judicial process or government enquiry, or in event of suspicion of violation of our policies or to protect the right or safety of any person or entity.
                </p>
                <h4 className='h4-text-header'> COOKIES</h4>
                <p className='container des-data'>
                    Cookies may be used on some pages of our websites. Cookies are small text files placed on a visitor’s hard drive that assist us in providing a more customised website experience. It is PoliciCue's policy to use cookies to make navigation of our websites easier for visitors and to facilitate efficient registration procedures. Cookies are primarily used to enhance the visitor’s online experience and are not used to track the navigational habits of identified visitors, unless we obtain the visitor’s specific and prior written permission to do so. If a visitor is concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our websites; however, other functionality in the site may be impaired. After termination of the visit to our site, a visitor can always delete the cookie from his or her system if he or she wishes. In order to properly manage our website we may anonymously log information on our operational systems and identify categories of visitors by items such as domains and browser types. These statistics are reported in the aggregate to our webmasters. This is to ensure that our website enhances the web experience for visitors and is an effective information resource.
                </p>

                <h4 className='h4-text-header'> PERSONAL INFORMATION RETENTION </h4>
                <p className='container des-data'>

                    Some of the information we receive is not retained. Contact information about visitors (such as information generated though registration for access to areas on the site) will be kept as long as the information is required for the purposes for which it was collected or until a user requests that we delete that information or as required by law. Mailing list information, discussion posts and email are kept for only the period of time considered reasonable to facilitate the visitor's requests. Information that is kept by us will be destroyed or deleted after a reasonable period from the time when it becomes outdated or is no longer required. We may, however, need to retain copies of information (which may include personal information) for internal record-keeping purposes and/or for compliance with applicable law or professional standards, whose requirements may be more than that of laws and rules requiring data protection and privacy.
                </p>
                <h4 className='h4-text-header'> CHOICES  </h4>
                <p className='container des-data'>
                    Personal information provided to PoliciCue by availing its services or through its website  is provided voluntarily by customers/ visitors. In case visitors subsequently choose to withdraw their consent in respect of their personal information, unsubscribe from mailing lists or any registrations, PoliciCue will provide instructions on the appropriate website area or in communications to our visitors.

                </p>

                <h4 className='h4-text-header'> CHOICES  </h4>
                <p className='container des-data'>
                    Personal information provided to PoliciCue by availing its services or through its website  is provided voluntarily by customers/ visitors. In case visitors subsequently choose to withdraw their consent in respect of their personal information, unsubscribe from mailing lists or any registrations, PoliciCue will provide instructions on the appropriate website area or in communications to our visitors.
                </p>

                <h4 className='h4-text-header'> SECURITY  </h4>
                <p className='container des-data'>
                    PoliciCue takes adequate steps to protect the information we collect. With the risks associated with technology in context of tampering and hacking and understanding that there are risks associated with sharing information via internet or otherwise, we take reasonable measures to prevent and minimize such risks wherever possible. We maintain administrative, technical and physical safeguards to protect the security, confidentiality and integrity of the personal information we collect. PoliciCue has implemented generally accepted standards of technology and operational security in order to protect personal information from loss, misuse, alteration or destruction. We follow a network-wide security policy. Only authorised personnel are provided access to sensitive personal information and these personnel have agreed to maintain confidentiality, security and integrity of this information. While PoliciCue follows the standard industry practices and takes reasonable security measures to protect the personal information under our control, information may be disclosed through any unlawful or unauthorised access or regulatory environment and measures or interception of transmissions or private communications, and other users and third parties may abuse or misuse an individual’s personal information. We disclaim all liability for error in transmissions and unauthorised access or interceptions, abuse or misuse by third parties and other users.

                </p>
                <h4 className='h4-text-header'> CHANGES TO THIS POLICY  </h4>
                <p className='container des-data'>
                    PoliciCue reserves the right to make changes to this privacy policy without the need of your consent. However, we will post all the policy changes on this page and notify through a prominent notice, if the changes are significant.

                    By using our services & website and submitting any information to us or any of our business partners, you agree to abide by our privacy policy.
                    <br />
                    If you have any questions regarding our privacy, feel free to mail us at info@policicue.com or you may contact us at:
                    <br />


                    PoliciCue Insurance Brokers Private Limited
                    <br />

                    Address: A-42/6, Sector-62 Noida, Gautam Buddha Nagar UP 201301

                </p>

                <h4 className='h4-text-header'> TERMS OF USAGE/ SERVICE</h4>
                <h4 className='h4-text-header'>Introduction</h4>

                <p className='container des-data'>
                    Following are the Terms of Usage/ Service for PoliciCue’s services (“Service,” “PoliciCue” “we,” or “us”).  These terms and conditions govern your use of Service at PoliciCue’s Application (“App”) or Website (“Web”) (collectively as “Platform”); by using the Services on any Platform, you accept these terms and conditions in full.   If you disagree with these terms and conditions or any part of these terms and conditions, you must not use the Services.
                    <br />
                    The provision of Services (whether aap or web) uses cookies.  By using this Service and agreeing to these terms and conditions, you consent to our PoliciCue’s use of cookies in accordance with the terms of PoliciCue’s privacy policy.
                    <br />

                    By using the Service in any way, you are agreeing to comply with and be bound by this Privacy Policy and Terms of Usage, as applicable (collectively “Related Agreements”), and all rules, policies and disclaimers posted on the Service or about which you are notified (collectively “Terms”). If you do not agree with all the Terms, or if you are under the age of Eighteen (18), do not use the Service. Please review all of the Terms carefully before using the Service.
                    <br />

                    By using the Service, you (i) agree to be bound by the Terms and (ii) represent that you are over the age of Eighteen (18) and able to form legally binding contracts.
                    <br />

                    Please note that these Terms provide that if you and PoliciCue are unable to resolve any disputes that arise between you and PoliciCue, the dispute will be resolved by a specific dispute resolution process that may include arbitration. Arbitration is binding and is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. You also agree that any claims or disputes cannot be brought as a class action. Please carefully review the Dispute Resolution section.

                </p>
                <h4 className='h4-text-header'>License to use application and website</h4>

                <p className='container des-data'>
                    Unless otherwise stated, PoliciCue and/or its licensors own the intellectual property rights in the Platform and material on the Platform.  Subject to the license below, all these intellectual property rights are reserved.
                    <br />

                    PoliciCue hereby grants you a non-exclusive, non-transferable subscription to use the Service during the Term, solely for your personal/ internal use in accordance with the use parameters described in the order form utilized to order such subscription and subject to these Terms of Service.
                    <br />

                    You shall not modify, reverse engineer, decompile, disassemble, or attempt to derive the source code of PoliciCue web Service, or assist any other person or entity in doing so. You acknowledge that all content, including but not limited to text, software, music, sound, photographs, video, graphics or other material contained in listings, sponsor advertisements or email-distributed, commercially produced information presented to you by the Service, by PoliciCue, Users, or other content providers, is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute in any way content available through the Service, including code and software for commercial purposes. For permission to use third-party materials appearing on the Service, please contact the copyright owner. You do not acquire ownership rights to any content, document or other materials viewed through the Service. The posting of information or materials on the Service does not constitute a waiver of any right in such information and materials.
                    <br />

                    User may categorically note that any use or reliance on any content posted or obtained from usage is completely at your own risk and consequences. PoliciCue do not subscribe, endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content or communications posted or endorse any opinions expressed herein. User must know that they may be exposed to Content that might be unpleasant, offensive, harmful, inaccurate, deceptive, frivolous, fake or otherwise inappropriate. All Content is the sole responsibility of the person who originated, posted, left or published in any manner such Content. PoliciCue may not monitor or control the content posted via the Services and, therefore cannot, in any manner, take responsibility for such content.
                    <br />

                    We reserve the right to remove Content that violates the User Agreement, including but not limited to intellectual property violation, infringement, misappropriation, impersonation, unlawful conduct, or harassment. User may report any such content by email at _________________.

                    <br />

                    You grant to PoliciCue a non-exclusive, irrevocable, perpetual, worldwide, royalty-free, sublicensable (through multiple tiers) license to use, reproduce, display, edit, copy, modify, transmit, publicly perform, extract, analyse, research and create derivative works from, content that you have provided or have left in your Posts, in any media now known or not currently known.
                    <br />

                    This licensed right may be exercised in order to provide the Service and for other purposes.
                    <br />

                    You are consciously aware that while using PoliciCue, you are solely responsible for your act, omission or conduct and PoliciCue shall be responsible in any manner whatsoever. You shall be solely liable for any violation of any applicable central or state laws. You undertake to unconditionally indemnify both PoliciCue against any loss, liability and consequences arising out of your act or omission.
                    <br />

                    By using PoliciCue to collect data from and optimize your social media channels or with any third party, you’re also agreeing to be bound by their Terms of Service.

                </p>
                <h4 className='h4-text-header'>Setting up Your Account</h4>

                <p className='container des-data'>
                    You must provide accurate and complete information, including your legal full name, a working email address, and any other information requested during the account signup process to obtain an account and use the Service, and update this information if it changes.
                    <br />

                    You must be a human being to set up an account and use the Service. Accounts may not be created by “bots” or other automated methods.
                    <br />

                    You are responsible for keeping your account and password secure, and are also responsible for all activities using your account or password. PoliciCue is not liable for any loss or damage that results from your failure to comply with this obligation or unauthorized use of your account.
                    <br />

                    You may never use another’s account without permission.
                    <br />

                    You must notify PoliciCue immediately of any breach of security or unauthorized use of your account.
                    <br />

                    You must inform PoliciCue’s Admin immediately about any sort of harassment, abuse, verbal abuse or trolling etc. However, under any circumstances the user cant held PoliciCue liable for any of aforesaid consequences.

                </p>
                <h4 className='h4-text-header'>Fees and Payment</h4>

                <p className='container des-data'>
                    Services offered herein by PoliciCue is free of cost and no payment of any sort is being solicited or provided for the services / tools offered in the PoliciCue sessions. However, in future with due intimation, PoliciCue may introduce certain paid services/ features.
                    <br />

                    PoliciCue shall not be liable to you or to any third party for any modifications to the Service or if it opts to charge prices in future.

                </p>

                <h4 className='h4-text-header'>Fees and Payment</h4>

                <p className='container des-data'>
                    You are solely responsible for properly closing your account.
                    <br />

                    You can close your account at any time by emailing us at __________________.
                    <br />

                    PoliciCue Services are free at  this moment, however, as and when paid services are introduced, Payments made will be applicable for the upcoming billing cycle. PoliciCue does not provide pro-rated refunds for accounts that are cancelled during their subscription term.
                </p>

                <h4 className='h4-text-header'>Acceptable use</h4>

                <p className='container des-data'>
                    You must not use this Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or accessibility of the Platform; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
                    <br />

                    You must not use this Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
                    <br />

                    You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this Platform without PoliciCue’s express written consent.
                    <br />

                    You must not use this Platform to transmit or send unsolicited commercial communications.
                    <br />

                    PoliciCue uses all applicable data protection tools and techniques and is compliant with data protection requirement as per Indian Laws (Information Technology Act, 2000) and the terms of usage is bound to be updated/ amended periodically. As and when terms are amended, updated, an intimation will be sent and even otherwise any subsequent usage of PoliciCue post amendment shall be considered as deemed acceptance. User are advised to check the terms of usage from time to time and shall use PoliciCue only upon acceptance of the terms of usage.


                </p>
                <h4 className='h4-text-header'>Acceptable use</h4>

                <p className='container des-data'>
                    You must not use this Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or accessibility of the Platform; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
                    <br />

                    You must not use this Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
                    <br />

                    You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this Platform without PoliciCue’s express written consent.
                    <br />

                    You must not use this Platform to transmit or send unsolicited commercial communications.
                    <br />

                    PoliciCue uses all applicable data protection tools and techniques and is compliant with data protection requirement as per Indian Laws (Information Technology Act, 2000) and the terms of usage is bound to be updated/ amended periodically. As and when terms are amended, updated, an intimation will be sent and even otherwise any subsequent usage of PoliciCue post amendment shall be considered as deemed acceptance. User are advised to check the terms of usage from time to time and shall use PoliciCue only upon acceptance of the terms of usage.

                </p>
                <h4 className='h4-text-header'>Exclusion of Warranties</h4>

                <p className='container des-data'>
                    PoliciCue services and related documentation are provided “as is” and without any warranty of any kind either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. No information, whether oral or written, obtained by you from us through this service shall create any warranty, representation or guarantee not expressly stated in these terms. PoliciCue does not represent or warrant that the service will be uninterrupted or error-free, that defects will be corrected, or that this service or the server that makes it available, are free of viruses or other harmful components. PoliciCue does not warrant or represent that the use or the results of the use of the materials available through the service, from third parties or a linked service will be correct, accurate, timely, reliable or otherwise. Some states or other jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to you. You may also have other rights that vary from state to state and jurisdiction to jurisdiction.  However, any action against PoliciCue is unequivocally subject to Dispute Resolution as stated below and to which the User unconditionally agrees. Further, use of PoliciCue services is strictly as per Indian Jurisdiction and therefore PoliciCue shall not be liable in any manner whatsoever for any usage or law outside Indian Jurisdiction. User is strictly cautioned to check their respective jurisdiction and seek necessary legal advice.
                    <br />

                    Under no circumstances will PoliciCue be liable for any loss or damage caused by a user’s reliance on information obtained through the service, from third parties (such as professionals or others) or a linked service, or user’s reliance on any product or service obtained from a third party or a linked service. Use of this service is at users’ sole risk.
                    <br />

                    No advice or information, whether oral or written, obtained by you from PoliciCue or through or from the PoliciCue services shall create any warranty.

                </p>
                <h4 className='h4-text-header'>Restricted access</h4>

                <p className='container des-data'>

                    Access to certain areas of this Platform is restricted.  PoliciCue reserves the right to restrict access to areas of this Platform, or indeed this entire Platform, at PoliciCue’s discretion.
                    <br />

                    If PoliciCue provides you with a user ID and password to enable you to access restricted areas of this Platform or other content or services, you must ensure that the user ID and password are kept confidential.
                    <br />

                    PoliciCue may disable your user ID and password in PoliciCue’s sole discretion without notice or explanation.

                </p>

                <h4 className='h4-text-header'>Limitations of liability</h4>

                <p className='container des-data'>
                    PoliciCue will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this Platform:
                    <br />
                    For any direct loss;
                    <br />
                    For any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.
                    <br />

                    These limitations of liability apply even if PoliciCue has been expressly advised of the potential loss.

                </p>

                <h4 className='h4-text-header'>Exceptions</h4>

                <p className='container des-data'>
                    Nothing in this Platform disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this Platform disclaimer will exclude or limit PoliciCue’s liability in respect of any:
                    <br />

                    Death or personal injury caused by PoliciCue’s negligence;

                    <br />
                    Fraud or fraudulent misrepresentation on the part of PoliciCue; or matter which it would be illegal or unlawful for PoliciCue to exclude or limit, or to attempt or purport to exclude or limit, its liability.
                </p>

                <h4 className='h4-text-header'>Reasonableness</h4>

                <p className='container des-data'>
                    By using this Platform, you agree that the exclusions and limitations of liability set out in this Platform disclaimer are reasonable.
                    <br />

                    If you do not think they are reasonable, you must not use this Platform.

                </p>

                <h4 className='h4-text-header'>Other parties</h4>

                <p className='container des-data'>
                    You accept that, as a limited liability entity, PoliciCue has an interest in limiting the personal liability of its officers and employees.  You agree that you will not bring any claim personally against PoliciCue’s officers or employees in respect of any losses you suffer in connection with the Platform.
                    <br />

                    You agree that the limitations of warranties and liability set out in this Platform disclaimer will protect PoliciCue’s officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as PoliciCue.

                </p>
                <h4 className='h4-text-header'>Unenforceable provisions</h4>

                <p className='container des-data'>
                    If any provision of this Platform disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this Platform disclaimer.
                </p>

                <h4 className='h4-text-header'>Indemnity</h4>

                <p className='container des-data'>
                    You hereby indemnify PoliciCue and undertake to keep PoliciCue indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by PoliciCue to a third party in settlement of a claim or dispute on the advice of PoliciCue’s legal advisers) incurred or suffered by PoliciCue arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.
                </p>
                <h4 className='h4-text-header'>Breaches of these terms and conditions</h4>

                <p className='container des-data'>
                    Without prejudice to PoliciCue’s other rights under these terms and conditions, if you breach these terms and conditions in any way, PoliciCue may take such action as PoliciCue deems appropriate to deal with the breach, including suspending your access to the Platform, prohibiting you from accessing the Platform, blocking computers using your IP address from accessing the Platform, contacting your internet service provider to request that they block your access to the Platform and/or bringing court proceedings against you.
                </p>
                <h4 className='h4-text-header'>Variation</h4>

                <p className='container des-data'>
                PoliciCue may revise these terms and conditions from time-to-time.  Revised terms and conditions will apply to the use of this Platform from the date of the publication of the revised terms and conditions on this Platform.  Please check this page regularly to ensure you are familiar with the current version.
                </p>

            </div>
        </div>
    )
}