import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_CARMAKES_FAILURE, FETCH_CARMAKES_REQUEST, FETCH_CARMAKES_SUCCESS } from "./types"

export const fetchCarMakesRequest = () => {
    return {
        type: FETCH_CARMAKES_REQUEST
    }
}
export const fetchCarMakesSuccess = carMakes => {
    return {
        type: FETCH_CARMAKES_SUCCESS,
        payload: carMakes
    }
}
export const fetchCarMakesFailure = error => {
    return {
        type: FETCH_CARMAKES_FAILURE,
        payload: error
    }
}


export const getCarMakes = () => async (dispatch) => {
    dispatch(fetchCarMakesRequest)
    await axios.get(Constant.BASE_URL + 'motor/getCarMakes')
        .then(response => {
            const result = response.data
            dispatch(fetchCarMakesSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCarMakesFailure(errorMsg))
        })
}


