import React from "react";
import "./HealthCover.css";
import NavBar from "../ReusableComponents/NavBar";
import Footer from "../ReusableComponents/Footer";
import { useHistory } from "react-router-dom";
import Stepper from "../ReusableComponents/Stepper";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;

const healthsaveQuoteSchema = yup.object({
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Invalid Phone Number")
    .min(10)
    .max(10),
  email: yup.string().email().required(),
});

export default function HealthSaveQuotes() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(healthsaveQuoteSchema),
  });
  const onSubmit = (data) => {
    if (data) {
      history.push("/");
    }
  };
  return (
    <>
      <div style={{ background: "#F2F6FF" }}>
        <NavBar />
        <br />
        <br />
        <div className="step4-car-insurance">
          <div className="container-fluid ">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="H-step1-topimg">
                <img src="images/Component 17.png" alt="comp"/>
              </div>
            </div>
            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-lg-1 col-md-0 ">
                <Stepper
                  current={4}
                  thirdStep={true}
                  fouthStep={true}
                  src="images/healthActive.png"
                />
              </div>
              <div className="H-step1-content col-lg-6 col-md-6">
                <h3 style={{ marginBottom: "10px", marginLeft: "1.1rem" }}>
                  Save Your Quotes -{" "}
                </h3>
                <h6 style={{ marginLeft: "1.1rem" }}>
                  Saved quotes will be sent to you via email or sms
                </h6>

                <div className="car2-form-groupp form-Container">
                  <input
                    type="number"
                    id="icueRegistrationNo"
                    name="icueRegistrationNo"
                    className="car2-form-controll label"
                    required
                    {...register("phone")}
                  />
                  <label
                    className="ms-3 car2-form-control-placeholderr"
                    for="icueRegistrationNo"
                  >
                    Phone number
                  </label>
                  <p className="text-danger ml-4">{errors.phone?.message}</p>
                </div>

                <div className="car2-form-groupp form-Container">
                  <input
                    type="text"
                    id="icueRegistrationNo"
                    name="icueRegistrationNo"
                    className="car2-form-controll label"
                    required
                    {...register("email")}
                  />
                  <label
                    className="ms-3 car2-form-control-placeholderr"
                    for="icueRegistrationNo"
                  >
                    Email
                  </label>
                  <p className="text-danger ml-4">{errors.email?.message}</p>
                </div>

                <br />

                <div className="H-step1-nextbtn2 ml-4">
                  <button onClick={handleSubmit(onSubmit)}>Next</button>
                </div>
              </div>

              <div className=" H-step1-rightimg col-lg-5 col-md-6">
                <img src="images/im2 4.png" alt="alternative"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
