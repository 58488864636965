import React from 'react';

import './ConnectToPay.css';

export default function ConnectToPay(){  

   
    return(<div className="connecttopay">
              <div className="fill-window">             
                  <div className="center-screen">
                  <div className="loader">Loading...</div>             
                               <p style={{fontStyle:"bold", color:"black"}}>Connecting to Insurance Company Portal </p>
                                <p style={{fontStyle:"normal", fontFamily:"ErasMediumITC", color:"black"}}> to pay the Policy through the API</p>
                       
                    </div>
               </div>      
</div>)
}