import { FETCH_CARMODELS_FAILURE, FETCH_CARMODELS_REQUEST, FETCH_CARMODELS_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
    error: ''
};

export const carModelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARMODELS_REQUEST:
            return {
                ...state,
            }
        case FETCH_CARMODELS_SUCCESS:
            return {
                data: action.payload
            }
        case FETCH_CARMODELS_FAILURE:
            return {
                data: [],
                error: action.payload
            }
        default:
            return state;
    }
}