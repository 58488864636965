import axios from "../../.././src/config/axios";
import Constant from "../../utils/constant";
import {
  FETCH_CATTLEINFO_FAILURE,
  FETCH_CATTLEINFO_REQUEST,
  FETCH_CATTLEINFO_SUCCESS,
} from "./types";

export const fetchCATTLEInfoRequest = () => {
  return {
    type: FETCH_CATTLEINFO_REQUEST,
  };
};
export const fetchCATTLEInfoSuccess = (model) => {
  return {
    type: FETCH_CATTLEINFO_SUCCESS,
    payload: model,
  };
};
export const fetchCATTLEInfoFailure = (error) => {
  return {
    type: FETCH_CATTLEINFO_FAILURE,
    payload: error,
  };
};

export const getCattleInfo = (Id) => async (dispatch) => {
  dispatch(fetchCATTLEInfoRequest);
  await axios
    .post(Constant.BASE_URL + `cattle/cattlepreview`, {case_ID:Id})
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchCATTLEInfoSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchCATTLEInfoFailure(errorMsg));
    });
};
