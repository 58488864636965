import React from 'react';
import './style.css';

export default function licence() {
    return (
        <div className='logo_container'>
            <img className='licence' src="images/IRDA_Certificates.png" alt="logo"/>
        </div>
    )
}

