import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_CARPOLICYTYPES_FAILURE, FETCH_CARPOLICYTYPES_REQUEST, FETCH_CARPOLICYTYPES_SUCCESS } from "./types"

export const fetchCarPolicyTypesRequest = () => {
    return {
        type: FETCH_CARPOLICYTYPES_REQUEST
    }
}
export const fetchCarPolicyTypesSuccess = model => {
    return {
        type: FETCH_CARPOLICYTYPES_SUCCESS,
        payload: model
    }
}
export const fetchCarPolicyTypesFailure = error => {
    return {
        type: FETCH_CARPOLICYTYPES_FAILURE,
        payload: error
    }
}


export const getCarPolicyTypes = () => async (dispatch) => {
    dispatch(fetchCarPolicyTypesRequest)
    await axios.get(Constant.BASE_URL + 'motor/getCarPolicyTypes')
        .then(response => {
            const result = response.data
            dispatch(fetchCarPolicyTypesSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCarPolicyTypesFailure(errorMsg))
        })
}