import axios from "axios"
import Constant from "../../utils/constant"
import {
    FETCH_CARMAKEMODALVARIANT_FAILURE,
    FETCH_CARMAKEMODALVARIANT_REQUEST,
    FETCH_CARMAKEMODALVARIANT_SUCCESS
} from "./types";


export const fetchCarMakeModalVariantRequest = () => {
    return {
        type: FETCH_CARMAKEMODALVARIANT_REQUEST
    }
}
export const fetchCarMakeModalVariantSuccess = model => {
    return {
        type: FETCH_CARMAKEMODALVARIANT_SUCCESS,
        payload: model
    }
}
export const fetchCarMakeModalVariantFailure = error => {
    return {
        type: FETCH_CARMAKEMODALVARIANT_FAILURE,
        payload: error
    }
}


export const getCarMakeModelVariants = (Id) => async (dispatch) => {
    dispatch(fetchCarMakeModalVariantRequest)
    await axios.post(Constant.BASE_URL + 'motor/getCarMakeModelVariants', {
        makeId: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchCarMakeModalVariantSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCarMakeModalVariantFailure(errorMsg))
        })
}