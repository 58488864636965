import axios from "axios"
import Constant from "../../utils/constant"


const User = JSON.parse(localStorage.getItem("user"));
export const getPosPersonalDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getPosPersonalDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getAadhaarDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getAadhaarDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const doAnswer = (data) => {
    return new Promise(resolve => {
        axios.post(Constant.BASE_URL + 'exam/doAnswer', data, { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getResult = () => {
    return new Promise(resolve => {
        axios.post(Constant.BASE_URL + 'exam/getResult', {}, { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}

export const getRunningStatus = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/runningstatus', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getQuestion = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'exam/getQuestion', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const certificate = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/certificate', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getPancardDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getPancardDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getContactDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getContactDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getEducationalDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getEducationalDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const getBankDetails = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getBankDetails', { headers: { "Authorization": `Bearer ${User?.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}
export const trainingStart = () => {

    const config = {
        headers: { "Authorization": `Bearer ${User?.token}` }
    }

    return new Promise(resolve => {
        axios.patch(Constant.BASE_URL + 'pos/trainingStart', null, config)
            .then(response => {

                resolve(response.data)
            })
            .catch(error => {

                resolve(error.message)
            })
    }
    )
}


export const isTrainingCompleted = () => {

    const config = {
        headers: { "Authorization": `Bearer ${User?.token}` }
    }

    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/trainingiscompl', config)
            .then(response => {

                resolve(response)
            })
            .catch(error => {

                resolve(error.message)
            })
    }
    )
}


export const traningPause = (pageNo) => {
    const config = {
        headers: { "Authorization": `Bearer ${User.token}` }
    }
    const timeLeft = localStorage.getItem('tKey');
    return new Promise(resolve => {
        axios.post(Constant.BASE_URL + 'pos/trainingduration', { training_duration: timeLeft, page:pageNo }, config)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            });
    }
    )
}

export const updateStatus = (json) => {
    const config = {
        headers: { "Authorization": `Bearer ${User.token}` }
    }


    return new Promise(resolve => {
        axios.post(Constant.BASE_URL + 'pos/updatePosStatus', json, config)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}


export const GetTrainingduration = () => {
    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/posGetTrainingduration', { headers: { "Authorization": `Bearer ${User.token}` } })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}

export const traningCompl = () => {
    const config = {
        headers: { "Authorization": `Bearer ${User?.token}` }
    }


    return new Promise(resolve => {
        axios.patch(Constant.BASE_URL + 'pos/traningCompl', null, config)
            .then(response => {
                resolve(response.data)
                // window.location.reload();
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}


export const traningStatus = () => {
    const config = {
        headers: { "Authorization": `Bearer ${User?.token}` }
    }


    return new Promise(resolve => {
        axios.get(Constant.BASE_URL + 'pos/getTraningStatus', config)
            .then(response => {
                resolve(response.data);
                return {
                    type: 'FETCH_STATUS_SUCCESS',
                    data: response.data
                }
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}

export const examFinish = () => {
    const config = {
        headers: { "Authorization": `Bearer ${User?.token}` }
    }


    return new Promise(resolve => {
        axios.patch(Constant.BASE_URL + 'pos/examFinish', null, config)
            .then(response => {
                resolve(response.data);
                return {
                    type: 'FETCH_STATUS_SUCCESS',
                    data: response.data
                }
            })
            .catch(error => {
                resolve(error.message)
            })
    }
    )
}

export const addPosCattleInfo = (data) => {
    return new Promise((resolve) => {
      axios
        .post(Constant.BASE_URL + "cattle/cattleOnSubmitPos", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };