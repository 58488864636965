import axios from "axios"
import Constant from "../../utils/constant"

export const updateTextDetails = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateTextDetails',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const addCattleInfo = (data) => {
  return new Promise((resolve) => {
    axios
      .post(Constant.BASE_URL + "cattle/cattleOnSubmit", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const updateStatusDetails = (payload) => {
  return new Promise((resolve) => {
    axios
      .post(Constant.BASE_URL +"cattle/editPolicyStatus", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const updateEarTagNo = (payload) => {
  return new Promise((resolve) => {
    axios
      .post(Constant.BASE_URL + "cattle/updateEarTag", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const deleteRowsCattle = (payload) => {
  return new Promise((resolve) => {
    axios
      .post(Constant.BASE_URL + "cattle/deleteRowsCattle", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};

export const deleteAnimalData = (id) => {
  return new Promise((resolve) => {
    axios
      .post(Constant.BASE_URL + "cattle/deleteAnimal", id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error.message);
      });
  });
};