import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormat, file } from "../../utils/method";
import Select from "react-select";
import { getState } from "../../store/actions/listStates";
import { getCity } from "../../store/actions/city";
import { getPinCode } from "../../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../.././assets/img/x.png";
import ImagePlaceholder from "../.././assets/img/img_placeholder.png";

const ProposerDetails = ({
  personalchk = void 0,
  personalDetail = [],
  formErrors = [],
  errors = [],
  selectState = "",
  setSelectState = void 0,
  selectCity = "",
  setSelectCity = void 0,
  selectPinCode = "",
  setSelectPinCode = void 0,
  selectProposerState = "",
  setSelectProposerState = void 0,
  selectProposerCity = "",
  setSelectProposerCity = void 0,
  selectProposerPinCode = "",
  setSelectProposerPinCode = void 0,
  AadhaarLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  aadharFile = "",
  isDisabled = false,
  data = [],
  appFormLoad= false,
  appFormImg = void 0
}) => {
    const [checked, setChecked] = useState(false)

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
    
    const dispatch = useDispatch();

    function checkSegmentType(value) {
        if (isDisabled && data?.proposer_segment_type === value) {
        return true;
        }
        return false;
    }

    function checkBusinessType(value) {
        if (isDisabled && data?.proposer_business_type === value) {
        return true;
        }
        return false;
    }

    useEffect(() => {
        if (selectPinCode !== "") {
        delete formErrors.animal_zipcode;
        }
    }, [selectState, selectCity, selectPinCode]);
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectState(id);
        if (id === "") {
        setSelectCity("");
        setSelectPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectCity(id);
        if (id === "") {
        setSelectPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectPinCode(id);
    };

    useEffect(() => {
        if (selectProposerPinCode !== "") {
        delete formErrors.proposer_zipcode;
        }
    }, [selectProposerState, selectProposerCity, selectProposerPinCode]);

    const setProposerState = (id) => {
        setSelectProposerState(id);
        if (id === "") {
        setSelectProposerCity("");
        setSelectProposerPinCode("");
        }
        dispatch(getCity(id));
    };

    const setProposerCity = (id) => {
        setSelectProposerCity(id);
        if (id === "") {
        setSelectProposerPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setProposerPinCode = (id) => {
        setSelectProposerPinCode(id);
    };

    const banks = [
        {
          label: 'Agriculture Insurance Company of India Ltd',
          value: 1
        },
        {
          label: 'Bajaj Allianz General Insurance Co. Ltd',
          value: 2
        },
        {
        label: 'Future Generali India Insurance Co. Ltd',
        value: 3
        },
        {
        label: 'Go Digit General Insurance Limited',
        value: 4
        },
        {
        label: 'HDFC Ergo General Insurance Co. Ltd',
        value: 5
        },
        {
        label: 'ICICI Lombard General Insurance Co. Ltd',
        value: 6
        },
        {
        label: 'IFFCO Tokio General Insurance Co. Ltd',
        value: 7
        },
        {
        label: 'Kotak Mahindra General Insurance Co. Ltd',
        value: 8
        },
        {
        label: 'Liberty General Insurance Limited',
        value: 9
        },
        {
        label: 'Reliance General Insurance Co. Ltd',
        value: 10
        },
        {
        label: 'Royal Sundaram General Insurance Co. Ltd',
        value: 11
        },
        {
        label: 'SBI General Insurance Co. Ltd',
        value: 12
        },
        {
        label: 'Tata AIG General Insurance Co. Ltd',
        value: 13
        },
        {
        label: 'The New India Assurance Co. Ltd',
        value: 14
        },
        {
        label: 'The Oriental Insurance Co. Ltd',
        value: 15
        },
        {
        label: 'United India Insurance Company Limited',
        value: 16
        },
        {
        label: 'National Insurance Company Limited',
        value: 17
        },
    ]

    const duration = [
        {
            label: '1 year',
            value: 1
        },
        {
            label: '2 years',
            value: 2
        },
        {
            label: '3 years',
            value: 3
        },
        {
            label: '4 years',
            value: 4
        },
        {
            label: '5 years',
            value: 5
        },
    ]

  return (
    <>  
      
      <div className="row row-cols-1 row-cols-lg-6 form">
        <div className="col d-flex align-items-center">
          <span className="radio-label-text">Segment Type</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkSegmentType("Corporate") ? (
            <input
              type="radio"
              id="Corporate"
              name="proposer_segment_type"
              className="me-3"
              value="Corporate"
              onChange={(e) => personalchk("proposer_segment_type", e)}
              disabled={isDisabled}
              checked={personalDetail.proposer_segment_type === "Corporate"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Corporate</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkSegmentType("Retail") ? (
            <input
              type="radio"
              id="Retail"
              name="proposer_segment_type"
              className="me-3"
              value="Retail"
              onChange={(e) => personalchk("proposer_segment_type", e)}
              checked={personalDetail.proposer_segment_type === "Retail"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Retail</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkSegmentType("SME") ? (
            <input
              type="radio"
              id="SME"
              name="proposer_segment_type"
              className="me-3"
              onChange={(e) => personalchk("proposer_segment_type", e)}
              value="SME"
              disabled={isDisabled}
              checked={personalDetail.proposer_segment_type === "SME"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>SME</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkSegmentType("Government") ? (
            <input
              type="radio"
              id="Government"
              name="proposer_segment_type"
              className="me-3"
              onChange={(e) => personalchk("proposer_segment_type", e)}
              value="Government"
              disabled={isDisabled}
              checked={personalDetail.proposer_segment_type === "Government"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Government</span>
        </div>
      </div>
      <p className="p-error">{errors?.proposer_segment_type || formErrors?.proposer_segment_type}</p>

      <div className="row row-cols-1 row-cols-lg-6 form">
        <div className="col d-flex align-items-center">
          <span className="radio-label-text">Business Type</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBusinessType("New") ? (
            <input
              type="radio"
              id="New"
              name="proposer_business_type"
              className="me-3"
              value="New"
              onChange={(e) => personalchk("proposer_business_type", e)}
              disabled={isDisabled}
              checked={personalDetail.proposer_business_type === "New"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>New</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBusinessType("Roll-Over") ? (
            <input
              type="radio"
              id="Roll-Over"
              name="proposer_business_type"
              className="me-3"
              value="Roll-Over"
              onChange={(e) => personalchk("proposer_business_type", e)}
              checked={personalDetail.proposer_business_type === "Roll-Over"}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Roll-Over</span>
        </div>
        <div className="col d-flex align-items-center">
          {!checkBusinessType("Renewal") ? (
            <input
              type="radio"
              id="Renewal"
              name="proposer_business_type"
              className="me-3"
              onChange={(e) => personalchk("proposer_business_type", e)}
              value="Renewal"
              disabled={isDisabled}
              checked={personalDetail.proposer_business_type === "Renewal"}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Renewal</span>
        </div>
      </div>
      <p className="p-error">{errors?.proposer_business_type || formErrors?.proposer_business_type}</p>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        <div className="col position-relative">
            {!isDisabled ? <>
              <Select
                  options={banks}
                  isClearable={true}
                  onChange={(e) => (e != null ? personalchk("proposer_insurer", e) : personalchk("proposer_insurer", ""))}
                  className="searchable-select"
                  name="proposer_insurer"
                  value={!isDisabled
                    ? personalDetail.proposer_insurer
                    : stringFormat(data?.proposer_insurer)}
                  isDisabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                  Insurer Name
              </label>
              <p className="p-error">{errors?.proposer_insurer || formErrors?.proposer_insurer}</p>
            </> :
              <> 
                <input
                  className="w-100"
                  id="proposer_insurer"
                  type="text"
                  name="proposer_insurer"
                  value={stringFormat(data.proposer_insurer)}
                  disabled={isDisabled}
                />
                <label className="ms-3 position-absolute" htmlFor="Insurer Name">
                  Insurer Name 
                </label>
              </>
            }
        </div>
        <div className="col position-relative">
            {!isDisabled ? <>
                <Select
                    options={duration}
                    isClearable={true}
                    onChange={(e) => (e != null ? personalchk("proposer_duration_coverage_required", e) : personalchk("proposer_duration_coverage_required", ""))}
                    className="searchable-select"
                    name="proposer_duration_coverage_required"
                    value={personalDetail.proposer_duration_coverage_required}
                    isDisabled={isDisabled}
                />
                <label className="ms-3 position-absolute" htmlFor="Duration of coverage required">
                    Duration of coverage required
                </label>
                <p className="p-error">{errors?.proposer_duration_coverage_required || formErrors?.proposer_duration_coverage_required}</p>
            </> :
            <>
              <input
                className="w-100"
                id="proposer_duration_coverage_required"
                type="text"
                name="proposer_duration_coverage_required"
                value={stringFormat(data.proposer_duration_coverage_required)}
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Duration of coverage required">
                Duration of coverage required
              </label>
            </>}
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_aadhar"
            type="number"
            name="proposer_aadhar"
            onChange={(e) => personalchk("proposer_aadhar", e)}
            value={
              !isDisabled
                ? personalDetail.proposer_aadhar
                : stringFormat(data.proposer_aadhar)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Aadhaar No.">
            Aadhaar No. 
          </label>
          <p className="p-error">{errors?.proposer_aadhar || formErrors?.proposer_aadhar}</p>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_first_name"
            type="text"
            name="proposer_first_name"
            onChange={(e) => personalchk("proposer_first_name", e)}
            value={
              !isDisabled ? personalDetail?.proposerDetails?.proposer_first_name : stringFormat(data.proposer_first_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Proposer First Name">
            Proposer First Name
          </label>
          <p className="p-error">{errors?.proposer_first_name || formErrors?.proposer_first_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_middle_name"
            type="text"
            name="proposer_middle_name"
            onChange={(e) => personalchk("proposer_middle_name", e)}
            value={
              !isDisabled ? personalDetail.proposer_middle_name : stringFormat(data.proposer_middle_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Proposer Middle Name">
            Proposer Middle Name
          </label>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_last_name"
            type="text"
            name="proposer_last_name"
            onChange={(e) => personalchk("proposer_last_name", e)}
            value={
              !isDisabled ? personalDetail.proposer_last_name : stringFormat(data.proposer_last_name)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Proposer Last Name">
            Proposer Last Name
          </label>
          <p className="p-error">{errors?.proposer_last_name || formErrors?.proposer_last_name}</p>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_address"
            type="text"
            name="proposer_address"
            onChange={(e) => personalchk("proposer_address", e)}
            value={
              !isDisabled ? personalDetail.proposer_address : stringFormat(data.proposer_address)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Proposer Address">
            Proposer Address
          </label>
          <p className="p-error">{errors?.proposer_address || formErrors?.proposer_address}</p>
        </div>
        <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listState}
                isClearable={!isDisabled}
                // onChange={(e) => (e != null ? setProposerState(e.value) : setProposerState(""))}
                onChange={(e) => {
                  setProposerState(e.value)
                  personalchk("proposer_state", e)
                }}
                className="searchable-select"
                name="proposer_state"
                value={personalDetail.proposer_state}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormat(data.proposer_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="Select State">
            Select State
            </label>
            {selectProposerState === "" && (
            <p className="p-error">{errors?.proposer_state || formErrors?.proposer_state}</p>
            )}
        </div>
        <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listCity}
                isClearable={true}
                isDisabled={selectProposerState === "" ? true : false}
                // onChange={(e) => (e != null ? setProposerCity(e.value) : setProposerCity(""))}
                onChange={(e) => {
                  setProposerCity(e.value)
                  personalchk("proposer_city", e)
                }}
                className="searchable-select"
                name="proposer_city"
                value={personalDetail.proposer_city}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormat(data.proposer_city)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="Select City">
            Select City
            </label>
            {selectProposerCity === "" && (
            <p className="p-error">{errors?.proposer_city || formErrors?.proposer_city}</p>
            )}
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-2 pb-3">
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectProposerCity === "" ? true : false}
                    // onChange={(e) =>
                    // e != null ? setProposerPinCode(e.value) : setProposerPinCode("")
                    // }
                    onChange={(e) => {
                      setProposerPinCode(e.value)
                      personalchk("proposer_zipcode", e)
                    }}
                    className="searchable-select"
                    name="proposer_zipcode"
                    value={personalDetail.proposer_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormat(data.proposer_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="Select Pincode">
                  Select Pincode
                </label>
                {selectProposerPinCode === "" && (
                <p className="p-error">{errors?.proposer_zipcode || formErrors?.proposer_zipcode}</p>
                )}
            </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_block"
            type="text"
            name="proposer_block"
            onChange={(e) => personalchk("proposer_block", e)}
            value={
              !isDisabled ? personalDetail.proposer_block : stringFormat(data.proposer_block)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Block">
            Block
          </label>
          <p className="p-error">{errors?.proposer_block || formErrors?.proposer_block}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_village"
            type="text"
            name="proposer_village"
            onChange={(e) => personalchk("proposer_village", e)}
            value={
              !isDisabled ? personalDetail.proposer_village : stringFormat(data.proposer_village)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Village">
            Village
          </label>
          <p className="p-error">{errors?.proposer_village || formErrors?.proposer_village}</p>
        </div>
        
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_email"
            type="text"
            name="proposer_email"
            onChange={(e) => personalchk("proposer_email", e)}
            value={
              !isDisabled ? personalDetail.proposer_email : stringFormat(data.proposer_email)
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Email Address">
            Email Address
          </label>
          <p className="p-error">{errors?.proposer_email || formErrors?.proposer_email}</p>
        </div>
       
        <div className="col position-relative">
          <input
            className="w-100"
            id="proposer_phone"
            type="number"
            name="proposer_phone"
            onChange={(e) => personalchk("proposer_phone", e)}
            value={!isDisabled ? personalDetail.proposer_phone : stringFormat(data.proposer_phone)}
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="Phone No.">
            Phone No.
          </label>
          <p className="p-error">{errors?.proposer_phone || formErrors?.proposer_phone}</p>
        </div>
      </div>

      <div className="row">
      <div className="form col-lg-3  d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Upload Aadhaar File</label>}
        {isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Aadhaar File</label>}
        <div>
          {!aadharFile?.length > 0 &&
          !personalDetail?.proposer_aadhar_photo &&
          !isDisabled ? (
            <div>
              <label>
                {!aadharFile?.length && AadhaarLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="Aadhaar"
                  disabled={aadharFile?.length === 2}
                  onChange={(e) => uploadSingleFile(e, "Aadhaar")}
                />
              </label>
              {!aadharFile?.length > 0 && (
                <p className="p-error">
                  {errors?.aadharFile || formErrors?.aadharFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.proposer_aadhar_photo !== null && (
                <div className="img-preview">
                  {data?.proposer_aadhar_photo?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.proposer_aadhar_photo)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.proposer_aadhar_photo)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    aadharFile?.[0] || personalDetail?.proposer_aadhar_photo
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        aadharFile[0] || personalDetail?.proposer_aadhar_photo
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        aadharFile?.[0] || personalDetail?.proposer_aadhar_photo
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "Aadhaar")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {<div className="form col-lg-3  d-flex flex-column">
      {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Attach Application Form</label>}
      {isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px"}}>Application Form</label>}
        <div>
          {!appFormImg?.length > 0 &&
          !personalDetail?.application_form &&
          !isDisabled ? (
            <div>
              <label>
                {!appFormImg?.length && appFormLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="Aadhaar"
                  disabled={appFormImg?.length === 2}
                  onChange={(e) => uploadSingleFile(e, "app_form")}
                />
              </label>
              {!appFormImg?.length > 0 && (
                <p className="p-error">
                  {errors?.appFormImg || formErrors?.appFormImg}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.application_form !== null && (
                <div className="img-preview">
                  {data?.application_form?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.application_form)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.application_form)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    appFormImg?.[0] || personalDetail?.application_form
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        appFormImg[0] || personalDetail?.application_form
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        appFormImg?.[0] || personalDetail?.application_form
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "app_form")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>}
      </div>

        <div className="row row-cols-1 row-cols-lg-1 pt-3 pb-3 ">
            <div className="col d-flex align-items-center">
              
            {!isDisabled ?
              <>
                <span className="label-text">
                  Is Animal Address different?
                </span> &nbsp;
                <input
                    className="me-2"
                    type="checkbox"
                    onChange={(e) => {
                        setChecked(!checked);
                        personalchk("is_animal_address_different", e)
                    }}
                    name="is_animal_address_different"
                    value={personalDetail?.is_animal_address_different}
                />
              </> :
              <>
              <span className="label-text">
                Is Animal Address different?
              </span> &nbsp;
              <input
                  className="me-2"
                  type="checkbox"
                  checked={data?.is_animal_address_different == 1 ? true : false}
              />
            </>
            }
            </div>
            {/* {!checked && <p className="p-error">{formErrors.isChecked}</p>} */}
        </div>

        {!isDisabled && personalDetail.is_animal_address_different ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                id="offers"
                type="text"
                name="offers"
                onChange={(e) => personalchk("animal_address", e)}
                value={
                !isDisabled
                    ? personalDetail.animal_address
                    : stringFormat(data.animal_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Animal Address
            </label>
            <p className="p-error">{errors?.animal_address || formErrors?.animal_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listState}
                isClearable={!isDisabled}
                // onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                onChange={(e) => {
                  setState(e.value)
                  personalchk("animal_state", e)
                }}
                className="searchable-select"
                name="animal_state"
                value={personalDetail?.animal_state}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormat(data.animal_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="Select State">
            Select State
            </label>
            {selectState === "" && (
            <p className="p-error">{errors?.animal_state || formErrors?.animal_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectState === "" ? true : false}
                    // onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    onChange={(e) => {
                      setCity(e.value)
                      personalchk("animal_city", e)
                    }}
                    className="searchable-select"
                    name="animal_city"
                    value={personalDetail?.animal_city}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormat(data.animal_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="Select City">
                Select City
                </label>
                {selectCity === "" && (
                <p className="p-error">{errors?.animal_city || formErrors?.animal_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectCity === "" ? true : false}
                    // onChange={(e) =>
                    // e != null ? setPinCode(e.value) : setPinCode("")
                    // }
                    onChange={(e) => {
                      setPinCode(e.value)
                      personalchk("animal_zipcode", e)
                    }}
                    className="searchable-select"
                    name="animal_zipcode"
                    value={personalDetail?.animal_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormat(data.animal_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="Select Pincode">
                Select Pincode
                </label>
                {selectPinCode === "" && (
                <p className="p-error">{errors?.animal_zipcode || formErrors?.animal_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="animal_block"
                type="text"
                name="animal_block"
                onChange={(e) => personalchk("animal_block", e)}
                value={
                  !isDisabled ? personalDetail.animal_block : stringFormat(data.animal_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.animal_block || formErrors?.animal_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="animal_village"
                type="text"
                name="animal_village"
                onChange={(e) => personalchk("animal_village", e)}
                value={
                  !isDisabled ? personalDetail.animal_village : stringFormat(data.animal_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.animal_village || formErrors?.animal_village}</p>
            </div>
        </div> : ''
        }

        {isDisabled && data?.is_animal_address_different ? <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                value={stringFormat(data.animal_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Animal Address
            </label>
            </div>
            <div className="col position-relative">
              <input
                  className="w-100"
                  value={stringFormat(data.animal_state)}
                  disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="Select State">
              Select State
              </label>
            </div>
            <div className="col position-relative">
              <input
                  className="w-100"
                  value={stringFormat(data.animal_city)}
                  disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="Select City">
              Select City
              </label>
            </div>
            <div className="col position-relative">
              <input
                  className="w-100"
                  value={stringFormat(data.animal_zipcode)}
                  disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="Select Pincode">
              Select Pincode
              </label>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                value={data.animal_block}
                disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Block
              </label>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                value={data.animal_village}
                disabled={true}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
            </div>
        </div> : ''
        }

    </>
  );
};

export default ProposerDetails;
