import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_PINCODE_FAILURE, FETCH_PINCODE_REQUEST, FETCH_PINCODE_SUCCESS } from "./types"



export const fetchPinCodeRequest = () => {
    return {
        
        type: FETCH_PINCODE_REQUEST
    }
}
export const fetchPinCodeSuccess = model => {
    return {
        type: FETCH_PINCODE_SUCCESS,
        payload: model
    }
}
export const fetchPinCodeFailure = error => {
    return {
        type: FETCH_PINCODE_FAILURE,
        payload: error
    }
}


export const getPinCodelogin = (Id) => async (dispatch) => {
    dispatch(fetchPinCodeRequest)
    await axios.post(Constant.BASE_URL + 'pos/listPincode', {
        cityName: Id
    })
        .then(response => {
            const result = response.data
            result.data.sort((a, b) => {
                let fa = a.pincode.toLowerCase(),
                    fb = b.pincode.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            
            dispatch(fetchPinCodeSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchPinCodeFailure(errorMsg))
        })
}

export const getPinCode = (Id) => async (dispatch) => {
  dispatch(fetchPinCodeRequest);
  await axios
    .post(Constant.BASE_URL + "pos/listPincode", {
      cityName: Id,
    })
    .then(({ data }) => {
      const temp = [];
      const d = data.data;
      d.forEach((element) => {
        temp.push({
          value: element.pincode,
          label: element.pincode,
        });
      });
      temp.sort((a, b) => {
        let fa = a.value.toLowerCase(),
            fb = b.value.toLowerCase();
    
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
      dispatch(fetchPinCodeSuccess(temp));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchPinCodeFailure(errorMsg));
    });
};
