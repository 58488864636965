import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import './Irdai.css'

export default function Irdai() {
    const [checked, setChecked] = useState(true);
    document.addEventListener('contextmenu', event => event.preventDefault());
    return (
        <div>
            <NavBar />
            
            <br />
            <br />
            <br />
            <div className='header-block'>
            <embed  src="images/IRDA_Certificate.pdf#toolbar=0" width="100%" height="1020px" />
            </div>
            {/* <Footer/> */}
        </div>
    )
}