import React from "react";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <tr>
      <td>
        <input
       
          type="text"
          required="required"
          placeholder="Enter employee name"
          name="employee"
          value={editFormData.employee}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
         
          type="text"
          required="required"
          placeholder="Enter mail"
          name="mail"
          value={editFormData.mail}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
         
          type="date"         
          required="required"
          placeholder="Enter date"
          name="date"
          value={editFormData.date}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="dropdown"         
          required="required"
          placeholder="Select role"
          name="role"
          value={editFormData.role}
          onChange={handleEditFormChange}
         ></input>
      </td>
      <td>
        <input
         
          type="text"         
          required="required"
          placeholder="Enter salary"
          name="salary"
          value={editFormData.salary}
          onChange={handleEditFormChange}
         ></input>
      </td>
   
      <td>
        <button type="submit">Save</button>
        <button type="button" onClick={handleCancelClick}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;