import React from 'react'
import { FormControl, makeStyles } from '@material-ui/core'

function FormControlInput({ children}) {
    const useStyles = makeStyles({
        relation: {
            width: '230px',
            marginLeft: '20px',
            [`& fieldset`]: {
                borderRadius: '10px',
            },
        },
        dob: {
            width: '230px',
            [`& fieldset`]: {
                borderRadius: '10px',
            },

        }
    })
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.relation} >
            {children}
        </FormControl>
    )
}

export default FormControlInput
