
import React, { useState } from "react"
import './Editpolicy.css';
export default function EditPolicy(){
const [policynumber, setPolicyNumber]= useState("845689");
// function changePolicyNumber(e) {
//     setPolicyNumber(e.target.value);
//   }
  const submitValue = () => {
    const frmdetails = {
        'Policy Number' : policynumber,
        
    }
    console.log(frmdetails);
}

  
    return(<div className="Editpolicy">
          <div className="container-fluid py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className="icueMail px-2">
                        <i className="bi bi-headset"></i>&nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem ">
                                    <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem active">
                                    <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                                </li>

                                {/* <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li> */}
                                 <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill" ></i></a>
                                </li>
                                <hr></hr>
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem ">
                                <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem active">
                                <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                            </li>

                            {/* <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem" style={{marginTop:"-0.75rem"}}>
                                        <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li> */}
                            
                            <li className="icueNavBarItem danger">
                                <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill" ></i></a>
                            </li>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="text" style={{fontFamily:"ErasDemiITC", marginLeft:"12px"}}>Edit Policy Details</h4>
                                <div className="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0" style={{backgroundColor:"transparent"}}>
                                        <li className="breadcrumb-item"><a href="dashboard.html" className="text-decoration-none text-info" >Dashboard</a></li>
                                        <li className="breadcrumb-item text-muted text-capitalize" aria-current="page">leads information</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="shadow rounded-3 p-5 bg-white">
                            <div className="row">
                                <div className="col-12 col-lg-12 m-auto">
                                   <form className="multisteps-form__form">                
                            
                            <div className="multisteps-form__content">
                              <div className="row">
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4">
                                  <label htmlFor="icueGenFormCueMember" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Policy Number</label>
                                  <input type="text" id="icueGenFormTypeInsured" name="icueGenFormTypeInsured" className="form-control  icueGenFormInput bg-white text-muted" readOnly defaultValue="845689" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4"style={{marginLeft:"120px"}}>
                                  <label htmlFor="icueGenFormTypeInsured" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Policy Name</label>
                                  <input type="text" id="icueGenFormTypeInsured" name="icueGenFormTypeInsured" className="form-control  icueGenFormInput  bg-white text-muted" readOnly defaultValue="Aviva" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4"style={{marginLeft:"120px"}}>
                                  <label htmlFor="icueGenFormTypeInsurance" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Insurance Type</label>
                                  <input type="text" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput  bg-white text-muted" readOnly defaultValue="Life Insurance" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label htmlFor="icueGenFormAlreadyInsured"className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Sum Assured</label>
                                  <input type="text" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput  bg-white text-muted" readOnly defaultValue="25,00,000" />

                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4"style={{marginLeft:"120px"}}>
                                  <label htmlFor="icueGenFormTypeInsurance" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Monthly Premium Amount</label>
                                  <input type="text" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput  bg-white text-muted" readOnly defaultValue="5,630" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4"style={{marginLeft:"120px"}}>
                                  <label htmlFor="icueGenFormTypeInsurance" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Date Of Purchased</label>
                                  <input type="date" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput  bg-white text-muted" readOnly defaultValue="25-Sep-2011" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-4">
                                  <label htmlFor="icueGenFormTypeInsurance" className="form-label icueTextPrimary text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px"}}>Expiry/Maturity Date</label>
                                  <input type="date" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput  bg-white text-muted" readOnly defaultValue="25-Sep-2035" />
                                </div>
                              </div>
                            </div>
                            {/* <div className="row justify-content-end">
                                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12">
                                            <div className="row justify-content-end">
                                                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
                                                <button type="button" className="btn icueBgPrimary  text-white mb-0 mt-0 "  onClick={submitValue} style={{fontFamily:"Calibri", color:"white", width:"140px", height:"45px" }}>Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                          <div className="row justify-content-end">                              
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12">
                                            <div className="row justify-content-end">
                                                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
                                <button type="button" className="btn icueBgPrimary  text-white mb-0 mt-0" id="icueEditGenInfoDetails" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"45px" }}> Update</button>
                                <button type="button" className="btn icueBgPrimary  text-white mb-0 mt-0" id="icueSaveGenInfoDetails" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"45px" }}> Save</button>
                              </div>
                            </div>
                            </div>
                            </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                   
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                    <div className="pe-2" style={{ alignItems:"right"}}>
                                        <img src="images/Help.png" alt="help" className="w-1 rounded-circle" style={{marginLeft:"970px", height:"125px", marginTop:"-85px"}}/>
                                        </div>                                   
                                    </div> 
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    </div>)
}