import Axios from "axios";
import Constant from "../utils/constant";

const token = JSON.parse(localStorage.getItem("superUser"))?.token;

const axios = Axios.create({
  baseURL: Constant.BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
