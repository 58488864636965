import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CodeConduct from '../../OtherInsurance/CodeConduct';
import Disclaimer from '../../OtherInsurance/Disclaimer';
import Licence from '../../OtherInsurance/licence';
import PrivacyPolicy from '../../OtherInsurance/PrivacyPolicy';
import Redressal from '../../OtherInsurance/Redressal';
import TermsUsage from '../../OtherInsurance/TermsUsage';
import { useParams } from 'react-router-dom';
import './styles.css';
import { useLocation } from "react-router-dom";


export default function VerticalTabs(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();

  React.useEffect(() => {
       if(location.search === "?0") {
        setTabIndex(0)
      }else if(location.search === "?1") {
        setTabIndex(1)
      }else if(location.search === "?2") {
        setTabIndex(2)
      }else if(location.search === "?3") {
        setTabIndex(3)
      }
    }, [location]);

  const tabStyle = {
    width: 200,
  }
  const tabPanelStyle = {
    marginRight: '4rem'
  }

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab style={tabStyle}>
            <p>Privacy Policy</p>
          </Tab>
          <Tab style={tabStyle}>
            <p>Terms of Usage</p>
          </Tab>
          {/* <Tab style={tabStyle}>
            <p>Code of Conduct</p>
          </Tab>
          <Tab style={tabStyle}>
            <p>Grievance Redressal</p>
          </Tab> */}
          <Tab style={tabStyle}>
            <p>Disclaimer</p>
          </Tab>
          <Tab style={tabStyle}>
            <p>License</p>
          </Tab>
        </TabList>

        <TabPanel style={tabPanelStyle}>
          <PrivacyPolicy />
        </TabPanel>

        <TabPanel style={tabPanelStyle}>
          <TermsUsage />
        </TabPanel>

        {/* <TabPanel>
          <CodeConduct />
        </TabPanel>

        <TabPanel>
          <Redressal />
        </TabPanel> */}

        <TabPanel style={tabPanelStyle}>
          <Disclaimer />
        </TabPanel>

        <TabPanel>
          <Licence />
        </TabPanel>
      </Tabs>
    </div>
  );
}

