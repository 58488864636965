import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./SideBar.css";
import { Navbar, Nav } from "react-bootstrap";
import { traningStatus } from "../../../store/actions/getPosPersonalDetails";
import { getResult } from "../../../store/actions/getPosPersonalDetails";
const SideBar = (props) => {
  const { pathname } = window.location;
  // console.log(window.location, "=======");
  const [active, setactive] = useState("");
  const [trainingStatus, setTrainingStatus] = useState("");
  const [timer, setTimer] = useState(Date.now() + 54000000);
  const [posResult, setPosResult] = useState(false);
  const [open, setOpen] = useState("1");

  const getTrainingStatus = async () => {
    let res = await traningStatus();
    setTrainingStatus(res.status);
  };
const clicker = () => {
  //setOpen(!open)
} 
  const result_get = () => {
    getResult()
      .then((res) => {
        console.log(res);
        if (res.status) {
          if (res.data?.result === "Pass") {
            setPosResult(true);
          }
        }
      })
  };
  useEffect(() => {
    getTrainingStatus();
    result_get();
  },[]);

  return (
    <div
      className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white shadow-sm position-fixed fixed-top parent_shit  "
      style={{
        marginTop: "64px",
        height: "calc(100vh - 64px)",
        width: "250px",
        
      }}
    >
      <div className="icueRespSideBar d-none " id="icueRespSideBar">
        <button
          type="button"
          className="btn btn-primary"
          id="icueRespHumburger"
        >
          <i className="bi bi-grid-3x3-gap"></i>
        </button>
        <div className="icueSideBar" id="icueSideBar">
          <nav className="navbar icueSideNavContainer ">
            <ul className="icueNavBarLst">
              <li className="icueNavBarItm active">
                <a href="/Hrdashboard" className="icueNavBarLink">
                  Dashboard
                </a>
              </li>
              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink">
                  User Management
                </a>
              </li>

              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Add Members
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a
                      href="/ListMembers"
                      className="icueSubNavLink  text-capitalize"
                    >
                      List of Members
                    </a>
                  </li>
                </ul>
              </li>
              <hr></hr>

              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink" id="">
                  Leave Management
                </a>
              </li>

              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                    <a
                      href="/Leaves"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Leaves
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a
                      href="/LeaveType"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Leave Type
                    </a>
                  </li>
                </ul>
              </li>
              <hr></hr>
              <li className="icueNavBarItm border-bottom-1">
                <a href="/Holidays" className="icueNavBarLink" id="">
                  Holidays
                </a>
              </li>
              <li className="icueNavBarItm border-bottom-1">
                <a href="/Attendance" className="icueNavBarLink" id="">
                  Attendance
                </a>
              </li>

              <li className="icueNavBarItm">
                <a href="/Department" className="icueNavBarLink" id="">
                  Department
                </a>
              </li>

              <li className="icueNavBarItm">
                <a href="/AddRoles" className="icueNavBarLink" id="">
                  Roles
                </a>
              </li>

              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink" id="">
                  Payroll
                </a>
              </li>
              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    <a
                      href="/EmpSalary"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Employee Salary
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a
                      href="/Payitems"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Payroll Items
                    </a>
                  </li>
                </ul>
                <hr></hr>
              </li>

              <li
                className="icueNavBarItm border-bottom-0"
                style={{ marginTop: "-18px" }}
              >
                <a href="#" className="icueNavBarLink" id="">
                  Settings
                </a>
              </li>
              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Change Password
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Profile
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="icueNavSideBar d-block" id="icueNavSideBar">
        <nav>
          <ul className="icueNavBarLst">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0" style={{ borderBottom: "2px solid lightgrey" }}>
                <Accordion.Header>
                  <p>
                    <li 
                      className={
                        pathname == "/MyProfile"
                          ? "icueNavBarItm active"
                          : "icueNavBarItm"
                      }
                      data-toggle="collapse"
                      data-target="#account"
                      style={{
                        fontFamily: "ErasDemiITC",
                        color: "#334d6e",
                        fontSize: "15px",
                      }}
                    >
                      Account Information
                    </li>{" "}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <p>
                      <li
                        className={
                          pathname == "/MyProfile"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a onClick={clicker} href="/#/MyProfile" className="icueNavBarLink" id="">
                          My Profile
                        </a>
                      </li>
                      <li
                        className={
                          pathname == "/MyTraining"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a
                          href="/#/MyTraining"
                          className="icueNavBarLink"
                          id=""
                          onClick={clicker}  >
                          My Training
                        </a>
                      </li>
                      {trainingStatus == 1 && (
                        <>
                          <li
                            className={
                              pathname == "/MyExamination"
                                ? "icueNavBarSubItm active"
                                : "icueNavBarSubItm"
                            }
                          >
                            <a
                              href="/#/MyExamination"
                              className="icueNavBarLink"
                              id=""
                              onClick={clicker}  >
                              My Examination
                            </a>
                          </li>

                          {posResult && (
                            <>
                              <li
                                className={
                                  pathname == "/MyCertificate"
                                    ? "icueNavBarSubItm active"
                                    : "icueNavBarSubItm"
                                }
                              >
                                <a
                                  href="/#/MyCertificate"
                                  className="icueNavBarLink"
                                  id=""
                                  onClick={clicker}  >
                                  My Certificate
                                </a>
                              </li>
                              <li
                                className={
                                  pathname == "/MyAgreement"
                                    ? "icueNavBarSubItm active"
                                    : "icueNavBarSubItm"
                                }
                              >
                                <a
                                  href="/#/MyAgreement"
                                  className="icueNavBarLink"
                                  id=""
                                  onClick={clicker}  >
                                  My Agreement
                                </a>
                              </li>
                            </>
                          )}
                          {/* {posResult && (
                            <li
                              className={
                                pathname == "/MyCommission"
                                  ? "icueNavBarSubItm active"
                                  : "icueNavBarSubItm"
                              }
                            >
                              <a
                                href="/MyCommission"
                                className="icueNavBarLink"
                                id=""
                              >
                                My Commission
                              </a>
                            </li>
                          )} */}
                        </>
                      )}
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" >
                <Accordion.Header>
                  <p>
                    <li 
                      className={
                        pathname == "/cattle-insurance/add"
                          ? "icueNavBarItm active"
                          : "icueNavBarItm"
                      }
                      data-toggle="collapse"
                      data-target="#cattle"
                      style={{
                        fontFamily: "ErasDemiITC",
                        color: "#334d6e",
                        fontSize: "15px",
                      }}
                    >
                      Cattle Insurance
                    </li>{" "}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <p>
                        <li
                        className={
                          pathname == "/cattle-insurance/all"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a onClick={clicker} href="/#/cattle-insurance/all" className="icueNavBarLink" id="">
                         Cattle Insurance Details
                        </a>
                      </li>
                      <li
                        className={
                          pathname == "/cattle-insurance/add"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a onClick={clicker} href="/#/cattle-insurance/add" className="icueNavBarLink" id="">
                          Add Cattle Insurance
                        </a>
                      </li>
                      {/* <li
                        className={
                          pathname == "/NewAddCattleInfo"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a onClick={clicker} href="/#/NewAddCattleInfo" className="icueNavBarLink" id="">
                          New Add Cattle Insurance
                        </a>
                      </li> */}
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <li className={pathname=="/MyQuotes"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyQuotes" className="icueNavBarLink" id="">My Quotes </a>
                    </li> 
                    <li className={pathname=="/MyProposal"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyProposal" className="icueNavBarLink" id="">My Proposal </a>
                    </li>   */}
            {/* <Accordion> */}
              {/* <Accordion.Item eventKey="2" style={{border:"0"}}>
                <Accordion.Header >
                 
                  <p >
                    <li className={pathname=="/MyQueries"?"icueNavBarItm active":"icueNavBarItm"} style={{fontFamily:"ErasDemiITC", color:"#334d6e", fontSize:"15px"}} >
                       My Queries
                    </li> 
                  </p>

                  </Accordion.Header>
                <Accordion.Body >
                  <div>
                  <p>
                      
                      
                  <li className={pathname=="/RaiseTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"}>
                  <a href="/RaiseTickets" className="icueNavBarLink" id="">Raise Tickets </a>                  
                </li>
                <li className={pathname=="/ViewTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} >
                  <a href="/ViewTickets" className="icueNavBarLink" id="">View Tickets </a>                  
                </li>
                
                </p>
                </div>
                </Accordion.Body>
              </Accordion.Item> */}
            {/* </Accordion> */}
            {/* <li className={pathname=="/MyQueries"?"icueNavBarItm active":"icueNavBarItm"} data-toggle="collapse" data-target="#MyQueries">
                        <a href="/MyQueries" className="icueNavBarLink" id="">My Queries <i className="bi bi-chevron-down" style={{marginLeft:"4.5rem"}}></i></a>
                    </li>  
                    <ul className="sub-menu collapse" id="MyQueries">
                  <li className={pathname=="/RaiseTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#RaiseTickets">
                  <a href="/RaiseTickets" className="icueNavBarLink" id="">Raise Tickets </a>                  
                </li>
                <li className={pathname=="/ViewTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#ViewTickets">
                  <a href="/ViewTickets" className="icueNavBarLink" id="">View Tickets </a>                  
                </li>
                
                </ul>    */}
            {/* <li className={pathname=="/MyLeads"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyLeads" className="icueNavBarLink" id="">My Leads </a>
                    </li> 
                    <li className={pathname=="/MyReports"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyReports" className="icueNavBarLink" id="">My Reports </a>
                    </li>                  */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
