import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_RTO_FAILURE, FETCH_RTO_REQUEST, FETCH_RTO_SUCCESS } from "./types"

export const fetchRTOrequest = () => {
    return {
        type: FETCH_RTO_REQUEST
    }
}
export const fetchRTOsucess = model => {
    return {
        type: FETCH_RTO_SUCCESS,
        payload: model
    }
}
export const fetchRTOfailure = error => {
    return {
        type: FETCH_RTO_FAILURE,
        payload: error
    }
}


export const getRTO = () => async (dispatch) => {
    dispatch(fetchRTOrequest)
    await axios.get(Constant.BASE_URL + 'motor/getRTO')
        .then(response => {
            const result = response.data
            dispatch(fetchRTOsucess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchRTOfailure(errorMsg))
        })
}


