import * as React from "react";
import { Steps } from "rsuite";
import "../../css/stepper.css";

export default function Stepper({ current, src, thirdStep, fouthStep, fifthStep }) {

  const styles = {
    width: "200px",
    display: "inline-table",
    verticalAlign: "top",
    marginLeft: "30px"
  };
  return (
    <Steps current={current} vertical style={styles}>
      <Steps.Item
        icon={current === 1 && <img src={src} alt='stepper' className="icon" />}
      />
      <Steps.Item
        icon={current === 2 && <img src={src} alt='stepper' className="icon" />}
      />
      {thirdStep && <Steps.Item
        icon={current === 3 && <img src={src} alt='stepper' className="icon" />}
      />}
      {fouthStep && <Steps.Item
        icon={current === 4 && <img src={src} alt='stepper' className="icon" />}
      />}
      {fifthStep && <Steps.Item
        icon={current === 5 && <img src={src} alt='stepper' className="icon" />}
      />}


    </Steps>
  );
}
