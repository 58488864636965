import React, { useState } from "react";
import "../../css/styles.css";
import Products from "../HomePage/Products";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/auth";
import { Container, Navbar } from "react-bootstrap";
import Login from "../RegistrationPage/Login/login";
import Alert from "../ReusableComponents/Alert";
export default function NavBar() {
  const show = useSelector((state) => state.alertState);

  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setModalShow] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const logOut = () => {
    setTimeout(() => {
      dispatch(logout());
      window.location.reload();
      history.push("/");
    }, 1000);
  };
  const navSpacing = {
    borderRight: "2px solid lightgray",
  };

  return (
    <div>
      {/* <!-- Navigation --> */}
      <Navbar
        collapseOnSelect
        className="py-1 navHeight"
        expand="lg"
        bg="light"
        fixed="top"
        variant="light"
        style={{ height: 78 }}
      >
        <Container>
          <a className="navbar-brand logo-image" href="https://policicue.com/">
            <img src="images/logo_new.png" alt="alternative" />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav ml-auto navBarResponsive" style={{ width: "60%" }}>
              <div className="row justify-content-end navBarResponsiveSection" style={{ width: "100%" }}>
                <div className="col-md-3 p-2 navWidth">
                  <li
                    className="nav-item dropdown"
                    style={{
                      position: "relative",
                      fontFamily: "ErasMediumITC",
                      marginTop: "10px",
                    }}
                  >
                    <a
                      className="nav-link navSpacing"
                      href="#"
                      id="dropdown01"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ color: "#29166F" }}
                    >
                      <span
                        className="ProductsAlign"
                        style={{
                          color: "#29166F",
                          display: "flex",
                          fontSize: 16,
                          float: "right",
                        }}
                      >
                        Products
                        <img
                          src="images/chevron-bottom.svg"
                          height="22"
                          className="img-chevron-down"
                          style={{ marginTop: "-2px" }}
                        />
                      </span>
                    </a>
                    {/* <div className="dropdown-menu" aria-labelledby="dropdown01"> */}
                    <div
                      className="dropdown-menu"
                      style={{
                        width: "220px",
                        top: "46px",
                        position: "absolute",
                        left: "40px",
                        backgroundColor: "white",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                      aria-labelledby="dropdown01"
                    >
                      <Products />
                    </div>
                    {/* </div> */}
                  </li>
                </div>
                {/* <div className="col-md-3 p-2">
                  <li
                    className="nav-item"
                    style={{
                      fontFamily: "ErasMediumITC",
                      marginTop: "10px",
                      paddingLeft: "2rem",
                    }}
                  >
                    <Link
                      to="/registration_pos"
                      className="font-weight-bold"
                      style={{ color: "#29166F" }}
                    >
                      <span style={{ color: "#29166F", fontSize: 16 }}>
                        Become POS
                      </span>
                    </Link>
                  </li>
                </div> */}

                <div className="col-md-2 navWidth">
                  <li onClick={() => setModalShow(!showModal)} className="nav-item">
                    <div className="outbtn">
                      <p className="outbtnp">Login</p>
                    </div>
                  </li>
                  {/* <li className="nav-item" >
                                <div className='outbtn' onClick={logOut}><p className='outbtnp'>logout</p></div>
                                </li> */}
                </div>
                <div className="col-md-4 p-2 navWidth">
                  <li
                    className="nav-item"
                    style={{ fontFamily: "ErasMediumITC", marginTop: "10px" }}
                  >
                    <div className="barline"></div>
                    {/* <a className="nav-link file-a-claim" href="#features"><img src="images/file-checked.svg" className="img-file-checked" />File a Claim</a> */}
                    <a
                      className="nav-link file-a-claim"
                      href="mailto:info@policicue.com"
                    >
                      <img
                        src="images/landing/mail.png"
                        style={{ height: "15px" }}
                        className="img-mail"
                      />
                      <span style={{ color: "#29166F", fontSize: 14 }}>
                        info@policicue.com
                      </span>
                    </a>
                  </li>
                </div>
              </div>
              {/* <li className="nav-item" style={{ fontFamily: "ErasMediumITC" }}>
                                <a className="nav-link contact-feature" href="#details"><img src="images/landing/dial.png" className="img-dial" />18003654897</a>
                            </li> */}
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showModal && (
        <Login
          showModal={showModal}
          onClicked={() => setModalShow(!showModal)}
        />
      )}
      {show.show && <Alert />}
    </div>
  );
}
