import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux'
import { alertShow } from '../../store/actions/alert'
import { TiDelete } from "react-icons/ti";

 const Alert = () => {
    const show = useSelector(state => state.alertState);

    return (
        <Modal  show={show.show} onHide={alertShow}>
            {/* <Modal.Header closeButton/> */}
            <Modal.Body>
                    <div className="modal-block-close-btn">
                      <button
                        type="button"
                        className="btn text-muted modal-btn-size"
                        onClick={alertShow}
                      >
                        <i className="bi bi-x-circle fas fa-lg modal-btn-icon"></i>
                      </button>
                    </div>
                    
                    <div className="modal-block">
                    { show.message !== "" ? <img
                        className="thankyou"
                        src="images/thankyou.png"
                        alt="alternative"
                        style={{ width: "100px" }}
                      />:
                      <TiDelete style={{fontSize:50,color:"red"}}/>
                      }
                      <div className="">
                      <br/><br/>
                        <div className="d-flex justify-content-center">
                          <label
                            className=""
                            style={{
                                lineHeight:1,
                              textAlign: "center",
                              fontFamily: "Calibri",
                              color: "black",
                              fontSize:22
                            }}
                          >
                            {show.message || show.error}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
        </Modal>
    )
}
export default Alert;