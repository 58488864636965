import axios from "axios"
import Constant from "../../utils/constant"
import {
    FETCH_BIKEMAKEMODALVARIANT_FAILURE,
    FETCH_BIKEMAKEMODALVARIANT_REQUEST,
    FETCH_BIKEMAKEMODALVARIANT_SUCCESS
} from "./types";


export const fetchBikeMakeModalVariantRequest = () => {
    return {
        type: FETCH_BIKEMAKEMODALVARIANT_REQUEST
    }
}
export const fetchBikeMakeModalVariantSuccess = model => {
    return {
        type: FETCH_BIKEMAKEMODALVARIANT_SUCCESS,
        payload: model
    }
}
export const fetchBikeMakeModalVariantFailure = error => {
    return {
        type: FETCH_BIKEMAKEMODALVARIANT_FAILURE,
        payload: error
    }
}


export const getBikeMakeModelVariants = (Id) => async (dispatch) => {
    dispatch(fetchBikeMakeModalVariantRequest)
    await axios.post(Constant.BASE_URL + 'motor/getBikeMakeModelVariants', {
        makeId: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchBikeMakeModalVariantSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBikeMakeModalVariantFailure(errorMsg))
        })
}