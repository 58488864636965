import React, { useState } from 'react'
import './Cust_detail.css';
export default function Cust_detail() {

  const [isActive, setIsActive] = useState(0)
  const [lastActive, setLastActive] = useState(0)

  const nextStep = (active) => {
    if (lastActive + 1 === active) {
      setIsActive(active)
      setLastActive(isActive + 1)
    }
    else if (active < lastActive) {
      setIsActive(active)
      setLastActive(active)
    }
  }

  let previous = e => {
    if (e < lastActive) {
      return true
    }
    return false
  }


  return (<div className="Customer">
    <div className="container-fluid py-2 icueTopBarContainer bg-white">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="topBarLfContainer d-flex">
            <div className="icueLogoContainer">
              <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
            </div>
            <div className="icueSearchBox w-75 mx-3">
              <div className="input-group ">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-search" />
                </span>
                <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="d-flex flex-row-reverse">
            <div className="icueProfile px-2">
              <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
            </div>
            <div className="position-relative px-2">
              <a href="#" id="icueNotifyBoxHandler">
                <i className="bi bi-bell">
                  <span className="position-absolute icueNofIndic" />
                </i>
              </a>
              <div className="icueNotifyBox" id="icueNotifyBox">
                Notifications
              </div>
            </div>
            <div className="icueUserName fs-6 px-2">
              Viral Patel
            </div>
            <div className="icueMail px-2">
              <i className="bi bi-headset" /> &nbsp;
              adminhelpdesk@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
          <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
            <button type="button" className="btn btn-primary" id="icueRespHumburger">
              <i className="bi bi-grid-3x3-gap" id="icueOpenIcon" />
              <i className="bi bi-x-lg" id="icueCloseIcon" />
            </button>
            <div className="icueSideBar" id="icueSideBar">
              <nav className="navbar icueSideNavContainer">
                <ul className="icueNavBarList">
                  <li className="icueNavBarItem">
                    <a href="/Superdashboard" className="icueNavBarLink" id="">Dashboard</a>
                  </li>

                  <li className="icueNavBarItem">
                    <a href="userManagement.html" className="icueNavBarLink" id="">User Management</a>
                  </li>

                  <li className="icueNavBarItem">
                    <a href="/Hrdashboard" className="icueNavBarLink" id="">HRMS</a>
                  </li>

                  {/* <!-- <li className="icueNavBarItem icueNavDot">
                                    <a href="userManagement.html" className="icueNavBarLink" id="">LMS</a>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li> --> */}

                  <li className="icueNavBarItem border-bottom-0 active">
                    <a href="/lms" className="icueNavBarLink" id="">LMS</a>
                  </li>

                  <li>
                    <ul className="icueSubNavBar">
                      <li className="icueSubNavItem">
                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Retail</a> --> */}
                        <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                      </li>
                      <li className="icueSubNavItem">
                        <a href="/leads" className="icueSubNavLink  text-capitalize">Corporate</a>
                      </li>
                    </ul>
                  </li>
                  <hr></hr>
                  {/* <!-- <li className="icueNavBarItem">
                                    <a href="userManagement.html" className="icueNavBarLink text-capitalize" id="">Claim Management</a>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                                        </li>
                                    </ul>
                                </li> --> */}

                  <li className="icueNavBarItem" style={{ marginTop: "-0.625rem" }}>
                    <a href="claim-management.html" className="icueNavBarLink text-capitalize" id="">Claim Management</a>
                  </li>
                  <li>
                    <ul className="icueSubNavBar">
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                      </li>
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                      </li>
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                      </li>
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                      </li>
                    </ul>
                  </li>
                  <hr></hr>
                  <li className="icueNavBarItem">
                    <a href="/Department" className="icueNavBarLink" id="">Department</a>
                  </li>

                  <li className="icueNavBarItem">
                    <a href="/AddRoles" className="icueNavBarLink" id="">Roles</a>
                  </li>

                  {/* <!-- <li className="icueNavBarItem">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li> --> */}
                  <li className="icueNavBarItem border-bottom-0">
                    <a href="settings.html" className="icueNavBarLink" id="">Settings</a>
                  </li>
                  <li>
                    <ul className="icueSubNavBar">
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                      </li>
                      <li className="icueSubNavItem">
                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <hr></hr>
              </nav>
            </div>
          </div>

          <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
            <nav>
              <ul className="icueNavBarList">
                <li className="icueNavBarItem">
                  <a href="/Superdashboard" className="icueNavBarLink" id="">Dashboard</a>
                </li>

                <li className="icueNavBarItem">
                  <a href="userManagement.html" className="icueNavBarLink" id="">User Management</a>
                </li>

                <li className="icueNavBarItem">
                  <a href="/Hrdashboard" className="icueNavBarLink" id="">HRMS</a>
                </li>

                {/* <!-- <li className="icueNavBarItem icueNavDot active">
                                <a href="lms-dashboard-1.html" className="icueNavBarLink" id="">LMS</a>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Corporate</a>
                                    </li>
                                </ul>
                            </li> --> */}

                <li className="icueNavBarItem border-bottom-0">
                  <a href="/lms" className="icueNavBarLink" id="">LMS</a>
                </li>

                <li>
                  <ul className="icueSubNavBar">
                    <li className="icueSubNavItem" style={{ marginTop: "-0.75rem" }}>
                      {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                      <a href="#" className="icueSubNavLink  text-capitalize">Retail</a>
                    </li>
                    <li className="icueSubNavItem">
                      <a href="/leads" className="icueSubNavLink  text-capitalize">Corporate</a>
                    </li>
                  </ul>
                </li>
                <hr></hr>
                {/* <!-- <li className="icueNavBarItem icueNavDotClaim">
                                <a href="#" className="icueNavBarLink" id="">Claim Management</a>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Life Insurance</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Car Insurance</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Two-wheeler Insurance</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Marine Insurance</a>
                                    </li>
                                </ul>
                            </li> --> */}

                <li className="icueNavBarItem border-bottom-0" style={{ marginTop: "-0.625rem" }}>
                  <a href="#" className="icueNavBarLink" id="">Claim Management</a>
                </li>

                <li>
                  <ul className="icueSubNavBar">
                    <li className="icueSubNavItem" style={{ marginTop: "-0.75rem" }}>
                      <a href="#" className="icueSubNavLink  text-capitalize">Life Insurance</a>
                    </li>
                    <li className="icueSubNavItem">
                      <a href="#" className="icueSubNavLink  text-capitalize">Car Insurance</a>
                    </li>
                    <li className="icueSubNavItem">
                      <a href="#" className="icueSubNavLink  text-capitalize">Two-wheeler Insurance</a>
                    </li>
                    <li className="icueSubNavItem">
                      <a href="#" className="icueSubNavLink  text-capitalize">Marine Insurance</a>
                    </li>
                  </ul>
                </li>
                <hr></hr>
                <li className="icueNavBarItem" style={{ marginTop: "-0.813rem" }}>
                  <a href="/Department" className="icueNavBarLink" id="">Department</a>
                </li>

                <li className="icueNavBarItem">
                  <a href="/AddRoles" className="icueNavBarLink" id="">Roles</a>
                </li>

                {/* <!-- <li className="icueNavBarItem icueNavDot">
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li> --> */}

                <li className="icueNavBarItem border-bottom-0">
                  <a href="settings.html" className="icueNavBarLink" id="">Settings</a>
                </li>
                <li>
                  <ul className="icueSubNavBar">
                    <li className="icueSubNavItem" style={{ marginTop: "-0.75rem" }}>
                      <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                    </li>
                    <li className="icueSubNavItem">
                      <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                    </li>
                  </ul>
                </li>
              </ul>
              <hr></hr>
            </nav>
          </div>
        </div>

        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div className="py-3">
                  <h4 className="text-uppercase text-center" style={{ fontFamily: "Mulish", fontWeight: "bold" }}>Lead Generation</h4>
                </div>
              </div>
            </div>
            <div className="row py-0">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0 px-3">
                <div className="px-2 rounded-3">
                  <hr className="my-0" />
                </div>
              </div>
            </div>


            <div className="row py-0">

              {/*multisteps-form*/}
              <div className="multisteps-form">
                <div className="row">
                  <div className="col-12 col-lg-10 d-flex mx-auto mb-4 mt-3">
                    <button className="shape-left px-4 text-start fs-8 text-uppercase" style={{ color: previous(0) ? "white" : isActive === 0 ? 'white' : 'black', backgroundColor: previous(0) ? "#697386" : isActive === 0 ? '#3A3285' : 'white' , lineHeight:"15px", width:"180px"}} onClick={() => nextStep(0)}>
                      lead Infomation <br />
                    </button>
                    <button className="shape px-4 text-start fs-8 text-uppercase " style={{ color: previous(1) ? "white" : isActive === 1 ? 'white' : 'black', backgroundColor: previous(1) ? "#697386" : isActive === 1 ? '#3A3285' : 'white', borderColor: '#3EB6E9', borderStyle: 'solid' }} onClick={() => nextStep(1)}>
                      RFQ (share details) <br />step 2
                    </button>
                    <button className="shape px-4 text-start fs-8 text-uppercase" style={{ color: previous(2) ? "white" : isActive === 2 ? 'white' : 'black', backgroundColor: previous(2) ? "#697386" : isActive === 2 ? '#3A3285' : 'white' }} onClick={() => nextStep(2)}>
                      RFQ status <br />step 3
                    </button>
                    <button className="shape px-4 text-start fs-8 text-uppercase" style={{ color: previous(3) ? "white" : isActive === 3 ? 'white' : 'black', backgroundColor: previous(3) ? "#697386" : isActive === 3 ? '#3A3285' : 'white' }} onClick={() => nextStep(3)}>
                      comparison <br />step 4
                    </button>
                    <button className="shape px-4 text-start fs-8 text-uppercase" style={{ color: previous(4) ? "white" : isActive === 4 ? 'white' : 'black', backgroundColor: previous(4) ? "#697386" : isActive === 4 ? '#3A3285' : 'white' }} onClick={() => nextStep(4)}>
                      suggested to customer <br />step 5
                    </button>
                    <button className="shape-right px-4 text-start fs-8 text-uppercase" style={{ color: previous(5) ? "white" : isActive === 5 ? 'white' : 'black', backgroundColor: previous(5) ? "#697386" : isActive === 5 ? '#3A3285' : 'white' }} onClick={() => nextStep(5)}>
                      payment <br />step 6
                    </button>
                  </div>
                </div>
                {/* <!--form panels--> */}
                <div className="row">
                  <div className="col-12 col-lg-12 m-auto">
                    <form className="multisteps-form__form">
                      {isActive === 0 ? (
                        <div className="multisteps-form__panel rounded js-active" data-animation="scaleIn">
                          <div className="bg-white shadow p-4 pb-5 rounded-3">
                            <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                              <div className="bg-white">
                                <h4 className="text-capitalize icueTextPrimary">General information</h4>
                              </div>
                              <div className="bg-white">
                                <button type="button" className="btn btn-transparent icueTextInfoLight" id="icueEditGenInfoDetails"><i className="bi bi-pencil" /> Edit</button>
                                <button type="button" className="btn btn-primary text-white" id="icueSaveGenInfoDetails"><i className="bi bi-save" /> Update</button>
                              </div>
                            </div>
                            <div className="multisteps-form__content">
                              <div className="row">
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label htmlFor="icueGenFormCueMember" className="form-label icueTextPrimary icueGenInfoLabel mt-3">Already Polici Cue Member?</label>
                                  <select id="icueGenFormCueMember" name="icueGenFormCueMember" className="form-select  text-uppercase icueGenFormInput border-0 ps-0 bg-white icueGenFormSel text-muted" disabled>
                                    <option value="no">no</option>
                                    <option value="yes">yes</option>
                                  </select>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label htmlFor="icueGenFormTypeInsured" className="form-label icueTextPrimary icueGenInfoLabel mt-3">Type of Insured</label>
                                  <input type="text" id="icueGenFormTypeInsured" name="icueGenFormTypeInsured" className="form-control text-uppercase icueGenFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="individual" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label htmlFor="icueGenFormTypeInsurance" className="form-label icueTextPrimary icueGenInfoLabel mt-3">Type of insurance Policy</label>
                                  <input type="text" id="icueGenFormTypeInsurance" name="icueGenFormTypeInsurance" className="form-control text-uppercase icueGenFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="private car" />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label htmlFor="icueGenFormAlreadyInsured" className="form-label icueTextPrimary icueGenInfoLabel mt-3">Already Insured</label>
                                  <select id="icueGenFormAlreadyInsured" name="icueGenFormAlreadyInsured" className="form-select text-uppercase icueGenFormInput border-0 ps-0 bg-white icueGenFormSel text-muted" disabled>
                                    <option value="no">no</option>
                                    <option value="yes">yes</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bg-white p-4 shadow mt-5 pb-5 rounded-3">
                            <div className="d-flex justify-content-between border-bottom pb-3">
                              <div className="bg-white">
                                <h4 className="text-capitalize icueTextPrimary">Personal information</h4>
                              </div>
                              <div className="bg-white">
                                <button type="button" className="btn btn-transparent icueTextInfoLight" id="icueEditPersonalInfoDetails"><i className="bi bi-pencil" /> Edit</button>
                                <button type="button" className="btn btn-primary" id="icueSavePersonalInfoDetails"><i className="bi bi-save" /> Update</button>
                              </div>
                            </div>
                            <div className="multisteps-form__content">
                              <div className="row pt-4">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemTitle" className="form-label icueTextPrimary icuePersonalInfoLabel">title</label>
                                  <select id="icuePersonalFormCueMemTitle" name="icuePersonalFormCueMemTitle" className="form-select text-uppercase icuePersonalFormInput border-0 ps-0 bg-white icuePerFormSel text-muted" disabled>
                                    <option value="mr.">mr.</option>
                                    <option value="ms.">ms.</option>
                                    <option value="mrs.">mrs.</option>
                                  </select>
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemFname" className="form-label icueTextPrimary icuePersonalInfoLabel">first name</label>
                                  <input type="text" id="icuePersonalFormCueMemFname" name="icuePersonalFormCueMemFname" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="annie" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemLName" className="form-label icueTextPrimary icuePersonalInfoLabel">last name</label>
                                  <input type="text" id="icuePersonalFormCueMemLName" name="icuePersonalFormCueMemLName" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="rose" />
                                </div>
                                <div><hr /></div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemRegAdd" className="form-label icueTextPrimary icuePersonalInfoLabel">registered address</label>
                                  <input type="text" id="icuePersonalFormCueMemRegAdd" name="icuePersonalFormCueMemRegAdd" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="sector 56, gurugram" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemPin" className="form-label icueTextPrimary icuePersonalInfoLabel">pincode</label>
                                  <input type="text" id="icuePersonalFormCueMemPin" name="icuePersonalFormCueMemPin" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue={12211} />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemEmail" className="form-label icueTextPrimary icuePersonalInfoLabel">e-mail</label>
                                  <input type="text" id="icuePersonalFormCueMemEmail" name="icuePersonalFormCueMemEmail" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="xyz@gmail.com" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                  <label htmlFor="icuePersonalFormCueMemPhoneNo" className="form-label icueTextPrimary icuePersonalInfoLabel">phone number</label>
                                  <input type="text" id="icuePersonalFormCueMemPhoneNo" name="icuePersonalFormCueMemPhoneNo" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="xxxxxxxxxx" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                  <label htmlFor="icuePersonalFormCueMemMobNo" className="form-label icueTextPrimary icuePersonalInfoLabel">mobile number</label>
                                  <input type="text" id="icuePersonalFormCueMemMobNo" name="icuePersonalFormCueMemMobNo" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="xxxxxxxxxx" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                  <label htmlFor="icuePersonalFormCueMemFaxNo" className="form-label icueTextPrimary icuePersonalInfoLabel">fax number</label>
                                  <input type="text" id="icuePersonalFormCueMemFaxNo" name="icuePersonalFormCueMemFaxNo" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue={+13235551234} />
                                </div>
                                <div><hr /></div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemPanNo" className="form-label icueTextPrimary icuePersonalInfoLabel">pan number</label>
                                  <input type="text" id="icuePersonalFormCueMemPanNo" name="icuePersonalFormCueMemPanNo" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="alwpg5809l" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemGstIn" className="form-label icueTextPrimary icuePersonalInfoLabel">GSTIN</label>
                                  <input type="text" id="icuePersonalFormCueMemGstIn" name="icuePersonalFormCueMemGstIn" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="37aadcso472n1z1" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemProfession" className="form-label icueTextPrimary icuePersonalInfoLabel">profession</label>
                                  <select id="icuePersonalFormCueMemProfession" name="icuePersonalFormCueMemProfession" className="form-select text-capitalize icuePersonalFormInput border-0 ps-0 bg-white icuePerFormSel text-muted" disabled>
                                    <option value="selft employed">self employed</option>
                                    <option value="self">self</option>
                                    <option value="employed">employed</option>
                                  </select>
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                  <label htmlFor="icuePersonalFormCueMemAnnInc" className="form-label icueTextPrimary icuePersonalInfoLabel">annual income</label>
                                  <input type="text" id="icuePersonalFormCueMemAnnInc" name="icuePersonalFormCueMemAnnInc" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="upto rs. 15 lakh" />
                                </div>
                                <div><hr /></div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemNomiName" className="form-label icueTextPrimary icuePersonalInfoLabel">nominee name</label>
                                  <input type="text" id="icuePersonalFormCueMemNomiName" name="icuePersonalFormCueMemNomiName" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="rqr" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemRelation" className="form-label icueTextPrimary icuePersonalInfoLabel">relation</label>
                                  <input type="text" id="icuePersonalFormCueMemRelation" name="icuePersonalFormCueMemRelation" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue="brother" />
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                  <label htmlFor="icuePersonalFormCueMemAge" className="form-label icueTextPrimary icuePersonalInfoLabel">age</label>
                                  <input type="text" id="icuePersonalFormCueMemAge" name="icuePersonalFormCueMemAge" className="form-control text-uppercase icuePersonalFormInput border-0 ps-0 bg-white text-muted" readOnly defaultValue={35} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ''}



                      {/* <!--single form panel--> */}
                      {isActive === 1 ? (
                        <div className="multisteps-form shadow p-4 rounded bg-white" data-animation="scaleIn">
                          <div className="d-flex justify-content-between border-bottom py-3 mb-4">
                            <div className="bg-white">
                              <h4 className="text-capitalize icueTextPrimary">RFQ - share details to</h4>
                            </div>
                            <div className="bg-white">
                              <a href="/Followup" className="btn btn-sm  text-white" id="icueCreateFollowUpBtn" style={{backgroundColor:"#3a3285", lineHeight:"15px"}}> Follow Up</a>
                            </div>
                          </div>
                          <div className="multisteps-form__content">
                            <div className="row  px-5 pb-3 table-responsive">
                              <table className="table table-striped table-hover">
                                <tbody className="text-capitalize">
                                  <tr className="border-bottom pb-0" >
                                    <td>HDFC argo</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <label htmlFor="choose-file" className="custom-file-upload btn btn-sm icueBtnPrimary w-auto text-white me-2 px-3 " id="choose-file-label" style={{backgroundColor:"#0093dd", fontFamily:"Calibri", lineHeight:"10px"}}>
                                          Attach
                                        </label>
                                        <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                                        <div style={{ width: 'auto' , marginTop:'10px' }}>
                                          <input type="text" readOnly id="icueRfqUploadedDoc" placeholder='Document.pdf' />
                                        </div>
                                      </div> 
                                    </td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-sm text-white px-4 icueBtnPrimary icueRfqSendBtn" style={{ width:"90px", lineHeight:"12px", textAlign:"left"}}>Send</button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ICICI Term Life</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <label htmlFor="choose-file" className="custom-file-upload btn btn-sm icueBtnPrimary w-auto text-white me-2 px-3 " id="choose-file-label" style={{backgroundColor:"#0093dd", fontFamily:"Calibri",lineHeight:"10px"}}>
                                          Attach
                                        </label>
                                        <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                                        <div style={{ width: 'auto' , marginTop:'10px'}}>
                                          <input type="text" readOnly id="icueRfqUploadedDoc"  placeholder='Document.pdf' />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-sm text-white px-4 icueBtnPrimary icueRfqSendBtn"style={{ width:"90px", lineHeight:"12px", textAlign:"left"}}>Send</button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>royal sundaram</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <label htmlFor="choose-file" className="custom-file-upload btn btn-sm icueBtnPrimary w-auto text-white me-2 px-3 " id="choose-file-label" style={{backgroundColor:"#0093dd", fontFamily:"Calibri",lineHeight:"10px"}}>
                                          Attach
                                        </label>
                                        <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                                        <div style={{ width: 'auto' , marginTop:'10px' }}>
                                          <input type="text" readOnly id="icueRfqUploadedDoc"  placeholder='Document.pdf' />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-sm text-white icueBtnPrimary px-4 icueRfqSendBtn" style={{ width:"90px", lineHeight:"12px", textAlign:"left"}}>Send</button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>sompa</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <label htmlFor="choose-file" className="custom-file-upload btn btn-sm icueBtnPrimary w-auto text-white me-2 px-3 " id="choose-file-label" style={{backgroundColor:"#0093dd", fontFamily:"Calibri",lineHeight:"10px"}}>
                                          Attach
                                        </label>
                                        <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                                        <div style={{ width: 'auto' , marginTop:'10px'}}>
                                          <input type="text" readOnly id="icueRfqUploadedDoc" placeholder='Document.pdf' />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-sm icueBtnPrimary text-white px-4 icueRfqSendBtn" style={{ width:"90px", lineHeight:"12px", textAlign:"left"}}>Send</button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>SBI general</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <label htmlFor="choose-file" className="custom-file-upload btn btn-sm icueBtnPrimary w-auto text-white me-2 px-3 " id="choose-file-label"  style={{backgroundColor:"#0093dd", fontFamily:"Calibri",lineHeight:"10px"}}>
                                          Attach
                                        </label>
                                        <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                                        <div style={{ width: 'auto', marginTop:'10px' }}>
                                          <input type="text" readOnly id="icueRfqUploadedDoc" placeholder='Document.pdf'/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-sm icueBtnPrimary text-white px-4 icueRfqSendBtn" style={{ width:"90px", lineHeight:"12px", textAlign:"left"}}>Send</button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : ''}



                      {/* <!--single form panel--> */}
                      {isActive === 2 ? (
                        <div className="multisteps-form shadow p-4 rounded bg-white" data-animation="scaleIn">
                          <div className="col-12">
                            <div className="d-flex justify-content-between border-bottom pb-3 mb-4">
                              <div className="bg-white">
                                <h4 className="text-capitalize icueTextPrimary">RFQ Status</h4>
                              </div>
                              <div className="d-flex justify-content-between">
                                {/* <button type="button" className="btn btn-sm text-white text-capitalize px-3 js-btn-next" style={{backgroundColor:"#3a3285", lineHeight:"15px", width:"100px"}}>update</button> */}
                                <button href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px", fontFamily:"Calibri"}}>Update</button>
                              </div>
                            </div>
                          </div>
                          <div className="multisteps-form__content">
                            <div className="row  px-5 pb-5 table-responsive">
                              <table className="table table-striped table-hover">
                                <thead>
                                  <tr className="text-uppercase border-bottom" >
                                    <th className="py-3 border-0" style={{fontFamily:"Calibri"}}>insurance company name</th>
                                    <th className="py-3 border-0"style={{fontFamily:"Calibri"}}>sent on</th>
                                    <th className="text-center py-3 border-0"style={{fontFamily:"Calibri"}}>status</th>
                                    <th className="text-center py-3 border-0"style={{fontFamily:"Calibri"}}>updated quote</th>
                                    <th className="text-center py-3 border-0"style={{fontFamily:"Calibri"}}>action</th>
                                  </tr>
                                </thead>
                                <tbody className="text-capitalize" style={{fontFamily:"Calibri"}}>
                                  <tr>
                                    <td>HDFC Agro</td>
                                    <td>04-sep-2021</td>
                                    <td className="text-center text-danger fs-5">
                                      <select name="icueRfqStatussSelBox" className="form-select icueRfqStatussSelBox" style={{fontFamily:"Calibri"}}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                        <option value="more info">More Info</option>
                                        <option value="pending">Pending</option>
                                      </select>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm btn-primary text-capitalize fs-7 icueRfqStatusUptQut" style={{lineHeight:"10px"}}>update quote</a>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px"}}>follow up</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ICICI Term Life</td>
                                    <td>08-sep-2021</td>
                                    <td className="text-center text-danger fs-5">
                                      <select name="icueRfqStatussSelBox" className="form-select icueRfqStatussSelBox" style={{fontFamily:"Calibri"}}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                        <option value="more info">More Info</option>
                                        <option value="pending">Pending</option>
                                      </select>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm btn-primary text-capitalize fs-7 icueRfqStatusUptQut" style={{lineHeight:"10px"}}>update quote</a>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px"}}>follow up</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>royal sundaram</td>
                                    <td>08-sep-2021</td>
                                    <td className="text-center text-danger fs-5">
                                      <select name="icueRfqStatussSelBox" className="form-select icueRfqStatussSelBox" style={{fontFamily:"Calibri"}}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                        <option value="more info">More Info</option>
                                        <option value="pending">Pending</option>
                                      </select>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm btn-primary text-capitalize fs-7 icueRfqStatusUptQut" style={{lineHeight:"10px"}}>update quote</a>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px"}}>follow up</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>sompa</td>
                                    <td>10-sep-2021</td>
                                    <td className="text-center text-danger fs-5">
                                      <select name="icueRfqStatussSelBox" className="form-select icueRfqStatussSelBox" style={{fontFamily:"Calibri"}}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                        <option value="more info">More Info</option>
                                        <option value="pending">Pending</option>
                                      </select>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm btn-primary text-capitalize fs-7 icueRfqStatusUptQut" style={{lineHeight:"10px"}}>update quote</a>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px"}}>follow up</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>SBI general</td>
                                    <td>08-sep-2021</td>
                                    <td className="text-center text-danger fs-5">
                                      <select name="icueRfqStatussSelBox" className="form-select icueRfqStatussSelBox" style={{fontFamily:"Calibri"}}>
                                        <option value="yes">YES</option>
                                        <option value="no">NO</option>
                                        <option value="more info">More Info</option>
                                        <option value="pending">Pending</option>
                                      </select>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm btn-primary text-capitalize fs-7 icueRfqStatusUptQut" style={{lineHeight:"10px"}}>update quote</a>
                                    </td>
                                    <td className="text-center">
                                      <a href="agent-followup.html" className="btn btn-sm text-white text-capitalize fs-7 icueRfqStatusFlwUp" style={{backgroundColor:"#3a3285", lineHeight:"10px"}}>follow up</a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                      ) : ''}


                      {/* <!--single form panel--> */}
                      {isActive === 3 ? (
                        <div className="multisteps-form shadow p-4 rounded bg-white" data-animation="scaleIn">
                          <div className="d-flex justify-content-between  border-bottom mb-4 py-3">
                            <div className="bg-white">
                              <h4 className="text-capitalize icueTextPrimary">comparison</h4>
                            </div>
                            <div className="bg-white">
                              <a href="/Followup" className="btn btn-sm  text-white" id="icueCreateFollowUpBtn" style={{backgroundColor:"#3a3285", lineHeight:"15px"}}> Follow Up</a>
                            </div>
                          </div>
                          <div className="multisteps-form__content">
                            <div className="row">
                              <div className="col-2">
                                <div className="form-check d-flex justify-content-center">
                                  <input className="form-check-input icuePlanAllCheckBox fs-4" type="checkbox" defaultValue id="icuePlanAllCheckBox" style={{marginLeft:"-95px"}}/> 
                                  <label className="d-inline-block pt-1 ps-4 icueTextPrimary" style={{marginTop:"5px", fontFamily:"Calibri"}}>Select All</label>
                                </div>
                              </div>
                              <div className="col-2">
                                <button type="button" className="btn text-white btn-sm text-capitalize px-4 icueSendCustBtn js-btn-next rounded-3" style={{backgroundColor:"#3a3285", lineHeight:"15px"}}>send customer</button>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input icuePlanCheckBox fs-4" type="checkbox" defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>HDFC Agro</h5>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,40,756</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan" >
                                      <h5 className="text-capitalize text-muted" style={{fontFamily:"Calibri"}}>premium</h5>
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>Rs. 5,630</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary text-center" style={{fontFamily:"Calibri"}}>document</h5>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>description</h5>
                                    <div className="icueTextPrimary fs-7" style={{fontFamily:"Calibri"}}>We can offer upto 5 lakh</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input icuePlanCheckBox fs-4" type="checkbox" defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>ICICI Lombard</h5>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,55,300</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h5 className="text-capitalize text-muted" style={{fontFamily:"Calibri"}}>premium</h5>
                                      <h5 className="text-capitalize icueTextPrimary"  style={{fontFamily:"Calibri"}}>Rs. 5,800</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary text-center" style={{fontFamily:"Calibri"}}>document</h5>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>description</h5>
                                    <div className="icueTextPrimary fs-7">Sompa Provide Good returns</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input icuePlanCheckBox fs-4" type="checkbox" defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>royal sundaram</h5>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,55,300</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h5 className="text-capitalize text-muted" style={{fontFamily:"Calibri"}}>premium</h5>
                                      <h5 className="text-capitalize icueTextPrimary">---</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary text-center" style={{fontFamily:"Calibri"}}>document</h5>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>description</h5>
                                    <div className="icueTextPrimary fs-7">---</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input icuePlanCheckBox fs-4" type="checkbox" defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>sompa</h5>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,55,300</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h5 className="text-capitalize text-muted" style={{fontFamily:"Calibri"}}>premium</h5>
                                      <h5 className="text-capitalize icueTextPrimary">---</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary text-center" style={{fontFamily:"Calibri"}}>document</h5>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>description</h5>
                                    <div className="icueTextPrimary fs-7">---</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input icuePlanCheckBox fs-4" type="checkbox" defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>SBI general</h5>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,55,300</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h5 className="text-capitalize text-muted" style={{fontFamily:"Calibri"}}>premium</h5>
                                      <h5 className="text-capitalize icueTextPrimary">---</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary text-center" style={{fontFamily:"Calibri"}}>document</h5>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h5 className="text-capitalize icueTextPrimary" style={{fontFamily:"Calibri"}}>description</h5>
                                    <div className="icueTextPrimary fs-7">---</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ''}


                      {/* <!--single form panel--> */} 
                      {isActive === 4 ? (
                        <div className="multisteps-form shadow p-4 rounded bg-white" data-animation="scaleIn">
                          <div className="d-flex justify-content-between py-3 border-bottom mb-3">
                            <div className="bg-white">
                              <h4 className="text-capitalize icueTextPrimary">suggested to customer</h4>
                            </div>
                            <div className="bg-white">
                            <a href="/Followup" className="btn btn-sm  text-white" id="icueCreateFollowUpBtn" style={{backgroundColor:"#3a3285", lineHeight:"15px"}}> Follow Up</a>
                            </div>
                          </div>
                          <div className="multisteps-form__content">
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <label className="customcheck">
                                        <input type="checkbox" className="icuePlanCheckBox" />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h4 className="text-capitalize icueTextPrimary">HDFC Agro</h4>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,40,756</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h6 className="text-capitalize text-muted">premium</h6>
                                      <h5 className="text-capitalize icueTextPrimary">Rs. 5,630</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h6 className="text-capitalize icueTextPrimary text-center">document</h6>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h6 className="text-capitalize icueTextPrimary">description</h6>
                                    <div className="icueTextPrimary fs-7">We can offer upto 5 lakh</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12 col-12">
                                <div className="d-block">
                                  <button type="button" className="btn btn-primary w-75 btn-sm text-capitalize" disabled>
                                    <i className="bi bi-check-circle-fill" /> Approve
                                  </button>
                                </div>
                                <div className="d-block mt-2">
                                  <button type="button" className="btn btn-light w-75 text-danger border border-danger btn-sm text-capitalize" disabled>
                                    <i className="bi bi-x-circle-fill" /> Reject
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row shadow py-2 icueCompPlansList rounded-3  align-items-center align-self-center my-4">
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="form-check d-flex justify-content-center">
                                      <label className="customcheck">
                                        <input type="checkbox" className="icuePlanCheckBox" />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-9 col-sm-9 col-9 offset-xxl-2 offset-xl-2 offset-lg-2">
                                    <div className="icueCompTitle">
                                      <h4 className="text-capitalize icueTextPrimary">ICICI Lombard</h4>
                                      <p className="text-muted fs-7">Insured Declared Value<br />(IDV) Rs. 1,55,300</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="row align-items-center align-self-center">
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-start text-start">
                                    <div className="icueCompPlan">
                                      <h6 className="text-capitalize text-muted">premium</h6>
                                      <h5 className="text-capitalize icueTextPrimary">Rs. 5,800</h5>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h6 className="text-capitalize icueTextPrimary text-center">document</h6>
                                    <div className="text-center"><i className="bi bi-file-earmark-pdf-fill text-danger" /></div>
                                  </div>
                                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h6 className="text-capitalize icueTextPrimary">description</h6>
                                    <div className="icueTextPrimary fs-7">Sompa Provide Good returns</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12 col-12">
                                <div className="d-block">
                                  <button type="button" className="btn btn-primary w-75 btn-sm text-capitalize" disabled>
                                    <i className="bi bi-check-circle-fill" /> Approve
                                  </button>
                                </div>
                                <div className="d-block mt-2">
                                  <button type="button" className="btn btn-light w-75 text-danger border border-danger btn-sm text-capitalize" disabled>
                                    <i className="bi bi-x-circle-fill" /> Reject
                                  </button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      ) : ''}



                      {/* <!--single form panel--> */}
                      {isActive === 5 ? (
                        <div className="multisteps-form shadow p-4 rounded bg-white" data-animation="scaleIn">
                          <div className="d-flex justify-content-between border-bottom py-2">
                            <div className="bg-white">
                              <h4 className="text-capitalize icueTextPrimary">payment details</h4>
                            </div>
                            <div className="bg-white">
                              <button type="button" className="btn icueBgPrimary text-white btn-sm px-3">Update</button>
                              <a href="agent-followup.html" className="btn btn-sm btn-primary text-white" id="icueCreateFollowUpBtn"> Follow Up</a>
                            </div>
                          </div>
                          <div className="multisteps-form__content">
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormPremAmt" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Premium Amount</label>
                                <input type="text" id="icuePaymentFormPremAmt" name="icuePaymentFormPremAmt" className="form-control icuePaymentFormInput" defaultValue={27075.00} />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormDOP" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Date of Payment</label>
                                <input type="date" id="icuePaymentFormDOP" name="icuePaymentFormDOP" className="form-control icuePaymentFormInput" defaultValue="2021-06-26" />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormPayMode" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Payment Mode</label>
                                <select name="icuePaymentFormPaySelBox" className="form-select icuePaymentFormPaySelBox">
                                  <option value="cheque">Cheque</option>
                                  <option value="cash">Cash</option>
                                  <option value="online">Online</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormCheqNum" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Chq. No.</label>
                                <input type="text" id="icuePaymentFormCheqNum" name="icuePaymentFormCheqNum" className="form-control text-uppercase icuePaymentFormInput" defaultValue="asib0288430" />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormBankName" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Bank Name</label>
                                <input type="text" id="icuePaymentFormBankName" name="icuePaymentFormBankName" className="form-control text-uppercase icuePaymentFormInput" defaultValue="State Bank of India" />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormBankAccNo" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Bank Account No.</label>
                                <input type="text" id="icuePaymentFormBankAccNo" name="icuePaymentFormBankAccNo" className="form-control icuePaymentFormInput" defaultValue={10236656040} />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label htmlFor="icuePaymentFormBankAddress" className="form-label icueTextPrimary icuePaymentLabel mt-5 mb-3">Bank Address</label>
                                <input type="text" id="icuePaymentFormBankAddress" name="icuePaymentFormBankAddress" className="form-control icuePaymentFormInput" defaultValue={10236656040} />
                              </div>
                            </div>
                            {/* <div className="button-row d-flex mt-4">
                            <button className="btn btn-primary js-btn-prev" type="button" title="Prev">Prev</button>
                            <button className="btn btn-success ml-auto" type="button" title="Send">Send</button>
                        </div> */}
                          </div>
                        </div>
                      ) : ''}

                    </form>
                  </div>
                </div>
              </div>
            </div>


            {/* <!-- Edit RFQ Details Modal --> */}
            <div className="modal fade" id="editRfqDetailsModal" tabIndex={-1} aria-labelledby="editRfqDetailsModalLabel" aria-hidden="true">
              {/* <div className="modal-dialog modal-dialog-centered"> */}
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header py-1 shadow-sm">
                    <h5 className="modal-title" id="editRfqDetModalLabel">RFQ Update</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body" style={{ backgroundColor: '#edf5fb' }}>
                    <form className>
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            {/* <input className="form-control" type="text" name="icueMemEmail" placeholder="Enter Name/Code" value="" /> */}
                            <select id="icueRfqDetailsSelBox" name="icueRfqDetailsSelBox" className="form-select text-capitalize">
                              <option value="hdfc ergo">HDFC ERGO</option>
                              <option value="ICICI Term Life">ICICI Term Life</option>
                              <option value="royal sundaram">royal sundaram</option>
                              <option value="sompa">sompa</option>
                              <option value="SBI general">SBI general</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            <textarea className="form-control" id="icueRfqDetailsDesc" rows={3} placeholder="Description of quotation" defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="choose-file" className="custom-file-upload icueBtnPrimary text-white" id="choose-file-label">
                                Upload Document
                              </label>
                              <input name="uploadDocument" type="file" id="choose-file" accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{ display: 'none' }} />
                              <div style={{ width: '55%' }}>
                                <input type="text" readOnly id="icueRfqUploadedDoc" defaultValue />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                          <div className="d-grid gap-2">
                            <button type="button" className="btn btn-primary">Update</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Edit RFQ Details Modal -->

<!-- icueCreateFollowUpBtn -->
<!-- Create Follow Up Modal --> */}
            <div className="modal fade " id="icueCreateFollowUpModal" tabIndex={-1} aria-labelledby="editRfqDetailsModalLabel" aria-hidden="true">
              {/* <div className="modal-dialog modal-dialog-centered"> */}
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header py-1 shadow-sm">
                    <h5 className="modal-title text-capitalize" id="editRfqDetModalLabel">Create Follow Up</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body" style={{ backgroundColor: '#edf5fb' }}>
                    <form className>
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            <input className="form-control" type="date" name="icueFollowUpDate" defaultValue />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            <textarea className="form-control" id="icueRfqDetailsDesc" rows={3} placeholder="Description of follow up" defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                          <div className="d-grid gap-2">
                            <button type="button" className="btn btn-primary">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Create Follow Up Modal -->

<!-- Edit Follow Up Modal --> */}
            <div className="modal fade" id="icueEditFollowUpModal" tabIndex={-1} aria-labelledby="editFollowUpModalLabel" aria-hidden="true">
              {/* <div className="modal-dialog modal-dialog-centered"> */}
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header py-1 shadow-sm">
                    <h5 className="modal-title text-capitalize" id="editRfqDetModalLabel">Edit Follow Up</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body" style={{ backgroundColor: '#edf5fb' }}>
                    <form className>
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            <input className="form-control" type="date" name="icueFollowUpDate" defaultValue />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                          <div className="form-group pb-2">
                            <textarea className="form-control" id="icueRfqDetailsDesc" rows={3} placeholder="Description of follow up" defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                          <div className="d-grid gap-2">
                            <button type="button" className="btn btn-primary">Send</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Edit Follow Up Modal --> */}

            {/* <!-- <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div> --> */}
          </main>
        </div>
      </div>
    </div>
  </div>)
}