import React from 'react'
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core'
import Stepper from '../../ReusableComponents/Stepper';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const sfcInvestSchema = yup.object({
    invest: yup.string().required(),
    amount: yup.string().required(),
    policy: yup.string().required(),
}).required();

const useStyles = makeStyles({
    relation: {
        width: '230px',
        marginRight: '30px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
            // paddingBottom:'25px
        },

    }
})
export default function SFCinvest() {
    const classes = useStyles()
    const history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(sfcInvestSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/SFCsaveQuotes")
        }
    };

    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div className='step4-car-insurance'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='H-step1-topimg'>
                            <img src='images/Component 4.png' ></img>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '50px' }}>
                        <div className='col-lg-1 col-md-0 ' >
                            <Stepper current={2} thirdStep={true} src="images/healthActive.png" />
                        </div>
                        <div className="H-step1-content col-lg-5 col-md-6">
                            <h6 style={{ color: 'black' }}>How much you like to invest - </h6>


                            <div className="d-sm-flex" style={{ marginTop: '30px' }}>

                                <div className="health-form-groupp ">
                                    <select
                                        id="SCFinvest"
                                        name="SCFinvest"
                                        className="health-form-controll "
                                        required
                                        {...register("invest")}

                                    >
                                        <option value="" selected disabled hidden>Invest</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Half Yearly">Half Yearly</option>
                                        <option value="Yearly">Yearly</option>
                                    </select>
                                    <p className="text-danger">{errors.invest?.message}</p>
                                </div>


                                <div className="health-form-groupp ">
                                    <select
                                        id="SCFamount"
                                        name="SCFamount"
                                        className="health-form-controll "
                                        required
                                        {...register("amount")}

                                    >
                                        <option value="" selected disabled hidden>Amount</option>
                                        <option value="100000">100000</option>
                                        <option value="300000">300000</option>
                                        <option value="500000">500000</option>
                                        <option value="800000">800000</option>
                                        <option value="1000000">1000000</option>
                                        <option value="1500000">1500000</option>
                                        <option value="2000000">2000000</option>
                                        <option value="More than 2000000">More than 2000000</option>
                                    </select>
                                    <p className="text-danger">{errors.amount?.message}</p>
                                </div>
                                <br /><br /><br />


                            </div>
                            <br />
                            <div className="health-form-groupp ">
                                <select
                                    id="SCFpolicyterm"
                                    name="SCFpolicyterm"
                                    className="health-form-controll "
                                    required
                                    {...register("policy")}
                                >
                                    <option value="" selected disabled hidden>Policy Term</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Half Yearly">Half Yearly</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                                <p className="text-danger">{errors.policy?.message}</p>
                            </div>

                            <div className='H-step1-nextbtn ' >
                                <button
                                    onClick={handleSubmit(onSubmit)}
                                >Next</button>
                            </div>
                        </div>



                        <div className=" H-step1-rightimg col-lg-6  col-md-6">
                            <img src='images/im2 4.png'></img>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}