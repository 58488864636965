import axios from "axios"
import Constant from "../../utils/constant"
import { IMAGE_FAILURE, IMAGE_REQUEST, IMAGE_SUCCESS } from "./types"



export const uploadImageRequest = () => {
    return {
        type: IMAGE_REQUEST
    }
}
export const uploadImageSuccess = model => {
    return {
        type: IMAGE_SUCCESS,
        payload: model
    }
}
export const uploadImageFailure = error => {
    return {
        type: IMAGE_FAILURE,
        payload: error
    }
}
export const updateBankCopy = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateBankCopy',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateEducationCert = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateEducationCert',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updatePan = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updatePancardImage',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const updateAdhaarImagesFront = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateAdhaarImagesFront',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateAdhaarImagesBack = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateAdhaarImagesBack',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const uploadProfile = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updatePhoto',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const updateBankImage = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoBankDetails',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updatePaymentImage = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotopaymentDetails',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateAppFormImage = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoApplicationForm',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateDoctorCertificate = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoDoctorCertificate',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updatePhotoCopy = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoAnimalPhoto',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateGoodHealthCopy = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoAnimalHealthPhoto',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateOtherCopy = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoOther',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateAadharCopy = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoAadharNumber',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const updateInvestigatorCertificate = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoInvestigatorCertificate',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const updatePhotoDoctorUpload = (photo) => {
    return new Promise(resolve => {
     axios.post(Constant.BASE_URL + 'cattle/addPhotoDoctorUpload', photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}