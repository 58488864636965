import React from "react";

const ReadOnlyRow = ({ contact, handleEditFormChange, handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.code}</td>
      <td>{contact.name}</td>
      <td>{contact.role}</td>
      <td>{contact.date}</td>
      <td style={{ textDecoration: 'underline', color: '#00A0E4' }}>{contact.permissions}</td>
      <td><span style={{ backgroundColor: '#00A0E4' }} className="badge rounded-pill w-100 text-uppercase px-4 py-2"
      >{contact.status1}</span>
        <span className="badge rounded-pill bg-secondary w-100  text-uppercase px-4 py-2"
        >{contact.status2}</span>
      </td>

      <td>
        <button className="btn btn-sm btn-transparent " style={{ marginLeft: "30px", marginTop: "-15px" }}
          type="button"
          // onClick={(event) => handleEditFormChange(event, contact.name)}
        >
          <i className='bi bi-pencil'></i>
        </button>
        <button className="btn btn-sm btn-transparent " style={{ marginLeft: "-20px", marginTop: "-15px" }}
          type="button" onClick={() => handleDeleteClick(contact.code)}>
          <i className='bi bi-trash'></i>
        </button>
        <button className="btn btn-sm btn-transparent " style={{ marginLeft: "-20px", marginTop: "-15px" }}
          type="button">
          <i className="bi bi-three-dots"></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;