import React, { useRef, useState, useEffect } from "react";
import Footer from "../ReusableComponents/Footer";
import NavBar from "../ReusableComponents/NavBar";
import { signUp, logout } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Terms from "../ReusableComponents/Terms";
import { Model } from "../ReusableComponents/Modal";

// Styling
import "./RegistrationPos.css";
import { Breadcrumb } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import RegistrationLeft from "./RegistrationLeft";

const RegistrationPos = (props) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [showModal, setModalShow] = useState(false);
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);
  const History = useHistory();

  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const initialValues = {
    firstName: "",
    email: "",
    lastName: "",
    mobile: "",
    isChecked: false,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [validtion, setvalidtion] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validtion && setFormErrors(validate(formValues));
  };

  const OtpVarification = () => {
    History.push("/otp_verification", formValues);
  };
  const handleblue = () => {
    validtion && setFormErrors(validate(formValues));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setvalidtion(true);
    dispatch(logout());
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    if (
      !validate(formValues).email &&
      !validate(formValues).firstName &&
      !validate(formValues).isChecked &&
      !validate(formValues).mobile
    ) {
      setLoading(true);
      dispatch(
        signUp(
          formValues.firstName,
          formValues.lastName,
          formValues.mobile,
          formValues.email
        )
      )
        .then((response) => {
          if (localStorage.getItem("user")) {
            OtpVarification();
            // setFormValues(initialValues);
            // window.location.reload();
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (location?.state != undefined) {
      let {emailId,firstName,lastName,mobileNo}=location?.state?.User
      setFormValues({firstName,
      email : emailId,
      lastName,
      mobile : mobileNo
      });
    }
  }, []);
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    const lname_regex = /([a-zA-Z]{1,30})$/;
    const mobile_regex = /^[0-9\b]+$/;

    if (!values.firstName) {
      errors.firstName = "First Name is Required!";
    } else if (!fname_regex.test(values.firstName)) {
      errors.firstName = "Enter Valid First Name!";
    }
    if (!values.email) {
      errors.email = "Email is Required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Enter Valid Email!";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile No. is Required";
    } else if (values.mobile.length < 10) {
      errors.mobile = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.mobile)) {
      errors.mobile = "Enter valid Mobile No.";
    }
    if (!values.isChecked) {
      errors.isChecked = "Kindly accepts the terms and conditions to submit";
    }
    return errors;
  };

  return (
    <div className="main-div">
      <NavBar />

      {/* Breadcrumb Start */}
      <section className="Breadcrumb__Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Become POS</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}

      {/* Register Area Start */}
      <section className="Registration__Section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <RegistrationLeft />
            </div>
            <div className="col-md-12 col-lg-5 top">
              <div className="py-4 px-5 shadow-lg radius">
                <h3 className="Register__Pos__Head">Register</h3>
                <form className="mt-4" onSubmit={handleSubmit}>
                  <div className="car2-form-groupp w-100">
                    <div className="car3-form-groupp mt-3 mb-2 w-100">
                      <input
                        className="car2-form-controll w-100"
                        id="firstName"
                        type="text"
                        onBlur={handleblue}
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                        style={{ textTransform: "capitalize" }}
                      />
                      <label
                        className="ms-3 register-form-control-placeholderr mt-n2 fs-7 fw-bold"
                        for="firstName"
                      >
                        First Name*
                      </label>
                    </div>
                    <p className="p-error">{formErrors.firstName}</p>
                  </div>
                  <div className="car2-form-groupp w-100">
                    <div className="car3-form-groupp mt-3 mb-2 w-100">
                      <input
                        className="car2-form-controll w-100"
                        id="lastName"
                        type="text"
                        name="lastName"
                        onBlur={handleblue}
                        value={formValues.lastName}
                        onChange={handleChange}
                        style={{ textTransform: "capitalize" }}
                      />
                      <label
                        className="ms-3 register-form-control-placeholderr mt-n2 fs-7 fw-bold"
                        for="lastName"
                      >
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="car2-form-groupp w-100">
                    <div className="car3-form-groupp mt-3 mb-2 w-100">
                      <input
                        className="car2-form-controll w-100"
                        id="mobile"
                        type="number"
                        name="mobile"
                        onBlur={handleblue}
                        value={formValues.mobile}
                        onChange={handleChange}
                        style={{ textTransform: "lowercase" }}
                      />
                      <label
                        className="ms-3 register-form-control-placeholderr mt-n2 fs-7 fw-bold"
                        for="mobile_no"
                      >
                        Mobile No*
                      </label>
                    </div>
                    <p className="p-error">{formErrors.mobile}</p>
                  </div>
                  <div className="car2-form-groupp w-100">
                    <div className="car3-form-groupp mt-3 mb-2 w-100">
                      <input
                        className="car2-form-controll w-100"
                        id="email"
                        type="email"
                        onBlur={handleblue}
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <label
                        className="ms-3 register-form-control-placeholderr mt-n2 fs-7 fw-bold"
                        for="emailId"
                      >
                        Email Id*
                      </label>
                    </div>
                    <p className="p-error">{formErrors.email}</p>
                  </div>
                  <div className="privacypolicy-chechbox">
                    <input
                      className="acceptTandC"
                      style={{ width: "20px", color: "#0093dd" }}
                      type="checkbox"
                      checked={formValues.isChecked}
                      onBlur={handleblue}
                      onChange={() => {
                        setChecked(!checked);
                        setFormValues({
                          ...formValues,
                          isChecked: !formValues.isChecked,
                        });
                        handleblue();
                      }}
                    />
                    <a
                      href="#"
                      onClick={handleShowModal}
                      className="accept"
                      className="ms-2 mt-n1"
                      style={{
                        textDecoration: "none",
                        color: "#8B8B8B",
                        fontSize: "16px",
                        fontFamily: "ErasMediumITC",
                        cursor: "default",
                      }}
                    >
                      {" "}
                      Please accept{" "}
                      <u
                        className="TandC"
                        style={{
                          fontFamily: "ErasMediumITC",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        Terms and Conditions
                      </u>{" "}
                    </a>
                  </div>
                  {!checked && (
                    <p className="p-error">{formErrors.isChecked}</p>
                  )}
                  <Model show={showModal} onHide={handleCloseModal}>
                    <Terms />
                  </Model>
                  <button
                    disabled={loading}
                    className="btn-primary Continue__Button mt-3 btn-block btn_verify_continue btn"
                    style={{ fontSize: "28px", fontWeight: "normal" }}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Continue"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Register Area End */}

      <Footer />
    </div>
  );
};

export default RegistrationPos;
