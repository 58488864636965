import React from 'react'

function PrivacyPolicy() {
    return (
        <div>
            <h3 className='heading'>Privacy Policy</h3>
            <h5 className='heading'>WE RESPECT YOUR PRIVACY</h5>
            <p className='text'>PoliciCue owns following website: www.policicue.com and application :<br /><br />
                This Privacy Policy applies to the service provided via the aforesaid website and application. This policy describes the personal information we collect from you and how we use and secure it. “Identifiable Personal Information” or “IPI” means information that can be used to identify you personally, such as an email address, a photograph with unique identifying features, etc.<br /><br />

                Information we collect: If you choose to provide us with your IPI by or for using the Services, you are agreeing to the processing and storage of your information in India, which may have data protection laws that differ from laws in your country. <br /><br />

                At PoliciCue, we are committed to protecting your privacy and adhere with the applicable data protection and privacy laws. Please take some time to read through our privacy policy as this is your sole guide to understand how we aim to use the information we collect from you.<br /><br />
            </p>


            <h5 className='heading'>INFORMATION WE COLLECT</h5>

            <p className='text'>
                IPI: We may collect personal information from you under circumstances, when you provide such information voluntarily at our website and application, such as, <br />
            </p>
            <p className='text' style={{ marginLeft: '1rem' }}>
                1.	User's name, <br />
                2.	Location/ address(optional, only if user allow location permission or enter in textbox), <br />
                3.	Phone number (required for login and  OTP etc), <br />
                4.	Photo (optional to be used as profile pic), <br />
                5.	Details of insurance product, policies etc. they are looking to buy/port/close/claim/switch etc., <br />
                6.	Photographs and videos as applicable, <br />
                7.	Facebook permissions (only if the user wants to share on their pages.), <br />
                8.	User activities such as liking of policy or showing interest in particular policy. <br /></p>

            <p className='text'>
                PoliciCue Services are intended for use by persons over 18 years old. In addition, it does not knowingly solicit or collect IPI from persons under 18 years old. We may delete from the Service IPI, as well as Posts and other information, from users we believe are under the age of 18. <br /><br />

                To enhance and individualize your experience with the Service, we may use small files called “cookies” on our websites that let us know that you are a prior user and help us ensure that your information is directed to you. We also use services on the web that help us improve your customer experience. We do not use these services to collect information about you for any other purpose.
            </p><br />


            <h5 className='heading'>NON-PERSONALLY IDENTIFIABLE INFORMATION</h5>

            <p className='text'>We also collect non-personally identifiable information about visitors’ web activity. This information includes the operating system a visitor is using, the date and time the user visited a website, referring URLs (what website the visitor came from), Internet service provider, browser type, device identifier, and information on the pages that visitors’ access or visit. Most non-personally identifiable information is collected via cookies or other analysis technologies.</p><br />

            <h5 className='heading'>HOW WE USE INFORMATION</h5>

            <p className='text'>We'll use this data to personalize and improve their search and experience on the application and web page. <br /><br />

                We will share your personal information with third parties only in the ways that are described in this privacy policy.<br /><br />

                PoliciCue may use, share, transfer, or disclose your IPI and/or usage statistics, itself or in conjunction with third parties, for fraud prevention, customer service, research, and to tailor PoliciCue content and services to its users’ needs. These third parties are authorized to use your IPI only as necessary for the purpose(s) of PoliciCue agreements with them.<br /><br />

                PoliciCue may share your IPI with any parent company, subsidiaries, joint ventures, or other companies under common control, in which event it will require that such companies follow this Policy with respect to your IPI.<br /><br />

                We use the information provided by you to enable us deliver necessary services, to assist you better, helping us to understand your needs and requirements in detail. We use the information collected to enable us to fulfil your requests more efficiently. For example, we may use your information to:
            </p><br /><br />

            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Execute your request for products and services.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Interact with you to build relationship with you.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Conduct business with you.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Contact you to provide the relevant information regarding any events, promotions.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Attain your feedback to improve our services, products, effectiveness of website etc.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>To notify you regarding any new products, services, offers that may be of interest to you.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>To process your application with us.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Keeping clients and other contacts informed of the services we offer and industry developments that may be of interest to them, and to notify them of service offerings, seminars and other events we organise.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>General management and reporting purposes, such as invoicing and account management.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>Recruitment of staff and providing internal services to them.</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>All other lawful purposes related to our business and in furtherance to fulfilment of lawful contract.</p>
            </div><br />

            <h5 className='heading'>MANDATORY SHARING OF YOUR INFORMATION</h5>
            <p className='text'>PoliciCue will share or disclose your information where deemed to be necessarily disclosed in response to any law enquiry, judicial process or government enquiry, or in event of suspicion of violation of our policies or to protect the right or safety of any person or entity.</p><br />

            <h5 className='heading'>COOKIES</h5>
            <p className='text'>Cookies may be used on some pages of our websites. Cookies are small text files placed on a visitor’s hard drive that assist us in providing a more customised website experience. It is PoliciCue's policy to use cookies to make navigation of our websites easier for visitors and to facilitate efficient registration procedures. Cookies are primarily used to enhance the visitor’s online experience and are not used to track the navigational habits of identified visitors, unless we obtain the visitor’s specific and prior written permission to do so. If a visitor is concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our websites; however, other functionality in the site may be impaired. After termination of the visit to our site, a visitor can always delete the cookie from his or her system if he or she wishes. In order to properly manage our website we may anonymously log information on our operational systems and identify categories of visitors by items such as domains and browser types. These statistics are reported in the aggregate to our webmasters. This is to ensure that our website enhances the web experience for visitors and is an effective information resource.</p><br />

            <h5 className='heading'>PERSONAL INFORMATION RETENTION</h5>
            <p className='text'>Some of the information we receive is not retained. Contact information about visitors (such as information generated though registration for access to areas on the site) will be kept as long as the information is required for the purposes for which it was collected or until a user requests that we delete that information or as required by law. Mailing list information, discussion posts and email are kept for only the period of time considered reasonable to facilitate the visitor's requests. Information that is kept by us will be destroyed or deleted after a reasonable period from the time when it becomes outdated or is no longer required. We may, however, need to retain copies of information (which may include personal information) for internal record-keeping purposes and/or for compliance with applicable law or professional standards, whose requirements may be more than that of laws and rules requiring data protection and privacy.</p><br />

            <h5 className='heading'>CHOICES</h5>
            <p className='text'>Personal information provided to PoliciCue by availing its services or through its website  is provided voluntarily by customers/ visitors. In case visitors subsequently choose to withdraw their consent in respect of their personal information, unsubscribe from mailing lists or any registrations, PoliciCue will provide instructions on the appropriate website area or in communications to our visitors. </p><br />

            <h5 className='heading'>SECURITY</h5>
            <p className='text'>PoliciCue takes adequate steps to protect the information we collect. With the risks associated with technology in context of tampering and hacking and understanding that there are risks associated with sharing information via internet or otherwise, we take reasonable measures to prevent and minimize such risks wherever possible. We maintain administrative, technical and physical safeguards to protect the security, confidentiality and integrity of the personal information we collect. PoliciCue has implemented generally accepted standards of technology and operational security in order to protect personal information from loss, misuse, alteration or destruction. We follow a network-wide security policy. Only authorised personnel are provided access to sensitive personal information and these personnel have agreed to maintain confidentiality, security and integrity of this information. While PoliciCue follows the standard industry practices and takes reasonable security measures to protect the personal information under our control, information may be disclosed through any unlawful or unauthorised access or regulatory environment and measures or interception of transmissions or private communications, and other users and third parties may abuse or misuse an individual’s personal information. We disclaim all liability for error in transmissions and unauthorised access or interceptions, abuse or misuse by third parties and other users. </p><br />

            <h5 className='heading'>CHANGES TO THIS POLICY</h5>
            <p className='text'>PoliciCue reserves the right to make changes to this privacy policy without the need of your consent. However, we will post all the policy changes on this page and notify through a prominent notice, if the changes are significant.<br /><br />

                By using our services & website and submitting any information to us or any of our business partners, you agree to abide by our privacy policy.<br /><br />

                If you have any questions regarding our privacy, feel free to mail us at info@policicue.com or you may contact us at:
            </p><br />


            <h5 className='footer_text'>PoliciCue Insurance Brokers Private Limited</h5>
            <p className='text'>
                <b>Address:</b> A-42/6, Sector-62 Noida, Gautam Buddha Nagar UP 201301 <br />
                or Call us at :
            </p><br />



        </div>
    )
}

export default PrivacyPolicy
