export const FETCH_CITY_REQUEST = "FETCH_CITY_REQUEST"
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS"
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE"
export const FETCH_QUALIFICATION_REQUEST = "FETCH_QUALIFICATION_REQUEST"
export const FETCH_QUALIFICATION_SUCCESS = "FETCH_QUALIFICATION_SUCCESS"
export const FETCH_QUALIFICATION_FAILURE = "FETCH_QUALIFICATION_FAILURE"

export const ALERT_REQUEST = "ALERT_REQUEST"
export const ALERT_SUCCESS = "ALERT_SUCCESS"
export const ALERT_FAILURE = "ALERT_FAILURE"

export const FETCH_PINCODE_REQUEST = "FETCH_PINCODE_REQUEST"
export const FETCH_PINCODE_SUCCESS = "FETCH_PINCODE_SUCCESS"
export const FETCH_PINCODE_FAILURE = "FETCH_PINCODE_FAILURE"

export const IMAGE_REQUEST = "IMAGE_REQUEST"
export const IMAGE_SUCCESS = "IMAGE_SUCCESS"
export const IMAGE_FAILURE = "IMAGE_FAILURE"

export const FETCH_STATE_REQUEST = "FETCH_STATE_REQUEST"
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS"
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE"

export const FETCH_CARMAKES_REQUEST = "FETCH_CARMAKES_REQUEST"
export const FETCH_CARMAKES_SUCCESS = "FETCH_CARMAKES_SUCCESS"
export const FETCH_CARMAKES_FAILURE = "FETCH_CARMAKES_FAILURE"

export const FETCH_BIKEMAKES_REQUEST = "FETCH_BIKEMAKES_REQUEST"
export const FETCH_BIKEMAKES_SUCCESS = "FETCH_BIKEMAKES_SUCCESS"
export const FETCH_BIKEMAKES_FAILURE = "FETCH_BIKEMAKES_FAILURE"

export const FETCH_CARMODELS_REQUEST = "FETCH_CARMODELS_REQUEST"
export const FETCH_CARMODELS_SUCCESS = "FETCH_CARMODELS_SUCCESS"
export const FETCH_CARMODELS_FAILURE = "FETCH_CARMODELS_FAILURE"

export const FETCH_BIKEMODELS_REQUEST = "FETCH_BIKEMODELS_REQUEST"
export const FETCH_BIKEMODELS_SUCCESS = "FETCH_BIKEMODELS_SUCCESS"
export const FETCH_BIKEMODELS_FAILURE = "FETCH_BIKEMODELS_FAILURE"

export const FETCH_RTO_REQUEST = "FETCH_RTO_REQUEST"
export const FETCH_RTO_SUCCESS = "FETCH_RTO_SUCCESS"
export const FETCH_RTO_FAILURE = "FETCH_RTO_FAILURE"

export const FETCH_CARPOLICYTYPES_REQUEST = "FETCH_CARPOLICYTYPES_REQUEST"
export const FETCH_CARPOLICYTYPES_SUCCESS = "FETCH_CARPOLICYTYPES_SUCCESS"
export const FETCH_CARPOLICYTYPES_FAILURE = "FETCH_CARPOLICYTYPES_FAILURE"

export const FETCH_BIKEPOLICYTYPES_REQUEST = "FETCH_BIKEPOLICYTYPES_REQUEST"
export const FETCH_BIKEPOLICYTYPES_SUCCESS = "FETCH_BIKEPOLICYTYPES_SUCCESS"
export const FETCH_BIKEPOLICYTYPES_FAILURE = "FETCH_BIKEPOLICYTYPES_FAILURE"

export const FETCH_CARMAKEMODALVARIANT_REQUEST = "FETCH_CARMAKEMODALVARIANT_REQUEST"
export const FETCH_CARMAKEMODALVARIANT_SUCCESS = "FETCH_CARMAKEMODALVARIANT_SUCCESS"
export const FETCH_CARMAKEMODALVARIANT_FAILURE = "FETCH_CARMAKEMODALVARIANT_FAILURE"

export const FETCH_BIKEMAKEMODALVARIANT_REQUEST = "FETCH_BIKEMAKEMODALVARIANT_REQUEST"
export const FETCH_BIKEMAKEMODALVARIANT_SUCCESS = "FETCH_BIKEMAKEMODALVARIANT_SUCCESS"
export const FETCH_BIKEMAKEMODALVARIANT_FAILURE = "FETCH_BIKEMAKEMODALVARIANT_FAILURE"

export const FETCH_CATTLEDETAIL_REQUEST = "FETCH_CATTLEDETAIL_REQUEST";
export const FETCH_CATTLEDETAIL_SUCCESS = "FETCH_CATTLEDETAIL_SUCCESS";
export const FETCH_CATTLEDETAIL_FAILURE = "FETCH_CATTLEDETAIL_FAILURE";


export const FETCH_CATTLEINFO_REQUEST = "FETCH_CATTLEINFO_REQUEST";
export const FETCH_CATTLEINFO_SUCCESS = "FETCH_CATTLENFO_SUCCESS";
export const FETCH_CATTLEINFO_FAILURE = "FETCH_CATTLEINFO_FAILURE";

export const GET_SEARCH_RESULT_SUCCESS ="GET_SEARCH_RESULT_SUCCESS";
export const GET_SEARCH_RESULT_FAILURE = "GET_SEARCH_RESULT_FAILURE"

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAIL = "OTP_FAIL";


export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


