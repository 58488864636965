import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_CITY_FAILURE, FETCH_CITY_REQUEST, FETCH_CITY_SUCCESS } from "./types"



export const fetchCityRequest = () => {
    return {
        type: FETCH_CITY_REQUEST
    }
}
export const fetchCitySuccess = model => {
    return {
        type: FETCH_CITY_SUCCESS,
        payload: model
    }
}
export const fetchCityFailure = error => {
    return {
        type: FETCH_CITY_FAILURE,
        payload: error
    }
}


export const getCitylogin = (Id) => async (dispatch) => {
    dispatch(fetchCityRequest)
    await axios.post(Constant.BASE_URL + 'pos/listCities', {
        stateName: Id
    })
        .then(response => {
            const result = response.data
            result.data.sort((a, b) => {
                let fa = a.city.toLowerCase(),
                    fb = b.city.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            dispatch(fetchCitySuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCityFailure(errorMsg))
        })
}

export const getCity = (Id) => async (dispatch) => {
  dispatch(fetchCityRequest);
  await axios
    .post(Constant.BASE_URL + "pos/listCities", {
      stateName: Id,
    })
    .then(({ data }) => {
      const temp = [];
      const d = data.data;
      d.forEach((element) => {
        temp.push({
          value: element.city,
          label: element.city,
        });
      });
      temp.sort((a, b) => {
        let fa = a.value.toLowerCase(),
            fb = b.value.toLowerCase();
    
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
      dispatch(fetchCitySuccess(temp));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchCityFailure(errorMsg));
    });
};