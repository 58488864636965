import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringFormatHandleEmpty, file, format_date, cattleformat_date } from "../../utils/method";
import Select from "react-select";
import { getState } from "../../store/actions/listStates";
import { getCity } from "../../store/actions/city";
import { getPinCode } from "../../store/actions/pinCode";
import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../../assets/img/x.png";
import ImagePlaceholder from "../../assets/img/img_placeholder.png";
import axios from 'axios';
import { alertShow } from "../../store/actions/alert";
import Constant from "../../utils/constant";


const DoctorDetails = ({
  personalchk = void 0,
  personalDetail = [],
  searchDoctorNumber = [],
  formErrors = [],
  errors = [],
  selectDoctorState = "",
  setSelectDoctorState = void 0,
  selectDoctorCity = "",
  setSelectDoctorCity = void 0,
  selectDoctorPinCode = "",
  setSelectDoctorPinCode = void 0,
  doctorFileLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  doctorFile = "",
  isDisabled = false,
  data = [],
  isShowView
}) => {

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isShow, setIsShow] = useState(isShowView);

    const listState = useSelector((state) => state.posState.data);
    const listCity = useSelector((state) => state.posCity.data);
    const listPinCode = useSelector((state) => state.posPinCode.data);
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (selectDoctorPinCode !== "") {
      delete formErrors.pincode;
      }
    }, [selectDoctorState, selectDoctorCity, selectDoctorPinCode]);
    
    useEffect(() => {
        !isDisabled && dispatch(getState());
    }, []);

    const setState = (id) => {
        setSelectDoctorState(id);
        if (id === "") {
        setSelectDoctorCity("");
        setSelectDoctorPinCode("");
        }
        dispatch(getCity(id));
    };

    const setCity = (id) => {
        setSelectDoctorCity(id);
        if (id === "") {
        setSelectDoctorPinCode("");
        }
        dispatch(getPinCode(id));
    };

    const setPinCode = (id) => {
        setSelectDoctorPinCode(id);
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
    }
  
    const searchByNumber = () => {
          if (searchInput !== '') {
              axios.post(Constant.BASE_URL + 'doctor/searchDoctor', {doctor_phone : searchInput})
              .then(response => {
                setFilteredResults(response)
                setIsShow(response?.data?.status)
                if(response?.data?.status == 0){
                    alertShow('err', "Doctor not found")
                }
                if(response?.data?.status == 1){
                  alertShow('succ', "Doctor is Assigned")
                }
              })
              .catch((error) => {
                alertShow('err', error.message);
              });
          }
          else{
              setFilteredResults([])
          }
    }

  const {data: assignedUser} = filteredResults;
    console.log(assignedUser, 'assignedUser')
  return (
    <> 
      {!isDisabled && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
        <div className="col position-relative">
          <input
            className="w-100"
            type="number"
            maxLength={10}
            onChange={(e) => {
                searchItems(e.target.value)
                personalchk("doctor_phone_search", e)
            }}
            value={searchDoctorNumber.doctor_phone_search}
            name="doctor_phone_search"
            placeholder="Search by phone No."
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Doctor Search
          </label>
        </div>
        <div style={{ width: '20%' }}>
          <button style={{height:"45px", width:"115px", fontSize: '16px', marginTop: '5px'}} className="btn btn-primary" onClick={()=>searchByNumber()}>Assign</button>
        </div>
        {/* {assignedUser?.data ? <div style={{ alignSelf: 'center', fontFamily: "calibri", fontSize: '16px', width: '46%' }}>
          <b>Assigned Doctor :</b> {assignedUser?.data?.doctor_name || ''}, {assignedUser?.data?.doctor_phone || ''}, {assignedUser?.data?.doctor_location || ''}, {format_date(assignedUser?.data?.createdAt)}
        </div> : ''} */}
      </div>}

      {assignedUser?.data && <label htmlFor="" style={{color:"#29166F", fontSize:"18px"}}>Assigned Doctor</label>}
      {assignedUser?.data && <div className="row row-cols-1 row-cols-lg-4 gy-4 form pt-3 pb-3">
        <div>
          <b>Name :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_name || ''}</span>
        </div>
        <div>
          <b>Phone :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_phone || ''}</span>
        </div>
        <div>
          <b>Location :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px', textTransform:"capitalize" }}>{assignedUser?.data?.doctor_location || ''}</span>
        </div>
        <div>
          <b>Date :</b> <span style={{ fontWeight: 300, fontFamily: 'Calibri', fontSize: '16px',textTransform:"capitalize" }}>{cattleformat_date(new Date().toISOString().slice(0, 10)) || ''}</span>
        </div>
      </div>}

      {!isDisabled && <div>
        {filteredResults.length > 1 && 
            filteredResults.map((item) => {
                return (
                    <div className="py-2">
                      {item.mobile}
                    </div>
                )
            })
        }
        {filteredResults === "" && <div className="mb-3">{(filteredResults.length === 0) && "No result found"}</div>}
      </div>}

      {isShow == 0 && !isDisabled && <label htmlFor="" style={{color:"#29166F", fontSize:"18px", marginBottom: '20px', marginBottom: '20px'}}>Or Add New Doctor</label>}

        {isShow == 0 && <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3">
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_name"
                type="text"
                name="doctor_name"
                onChange={(e) => personalchk("doctor_name", e)}
                value={
                !isDisabled ? personalDetail.doctor_name : stringFormatHandleEmpty(data.doctor_name)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Veterinary Doctor Name
            </label>
            <p className="p-error">{errors?.doctor_name || formErrors?.doctor_name}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_designation"
                type="text"
                name="doctor_designation"
                onChange={(e) => personalchk("doctor_designation", e)}
                value={
                !isDisabled ? personalDetail.doctor_designation : stringFormatHandleEmpty(data.doctor_designation)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Designation
            </label>
            <p className="p-error">{errors?.doctor_designation || formErrors?.doctor_designation}</p>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                id="doctor_reg_ID"
                type="text"
                name="doctor_reg_ID"
                onChange={(e) => personalchk("doctor_reg_ID", e)}
                value={
                !isDisabled ? personalDetail.doctor_reg_ID : stringFormatHandleEmpty(data.doctor_reg_ID)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Reg ID
            </label>
            </div>
            <div className="col position-relative">
            <input
                className="w-100"
                type="text"
                name="doctor_address"
                onChange={(e) => personalchk("doctor_address", e)}
                value={
                !isDisabled ? personalDetail.doctor_address : stringFormatHandleEmpty(data.doctor_address)
                }
                disabled={isDisabled}
            />
            <label className="ms-3 position-absolute" htmlFor="others">
                Doctor Address
            </label>
            <p className="p-error">{errors?.doctor_address || formErrors?.doctor_address}</p>
            </div>
            <div className="col position-relative">
            {!isDisabled ? (
            <Select
                options={listState}
                isClearable={!isDisabled}
                onChange={(e) => (e != null ? setState(e.value) : setState(""))}
                className="searchable-select"
                name="doctor_state"
                // value={personalDetail.doctor_state}
            />
            ) : (
            <input
                className="w-100"
                value={stringFormatHandleEmpty(data.doctor_state)}
                disabled={true}
            />
            )}
            <label className="ms-3 position-absolute" htmlFor="landmark">
            Select State
            </label>
            {selectDoctorState === "" && (
            <p className="p-error">{errors?.doctor_state || formErrors?.doctor_state}</p>
            )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listCity}
                    isClearable={true}
                    isDisabled={selectDoctorState === "" ? true : false}
                    onChange={(e) => (e != null ? setCity(e.value) : setCity(""))}
                    className="searchable-select"
                    name="doctor_city"
                    // value={personalDetail.doctor_city}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_city)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select City
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_city || formErrors?.doctor_city}</p>
                )}
            </div>
            <div className="col position-relative">
                {!isDisabled ? (
                <Select
                    options={listPinCode}
                    isClearable={true}
                    isDisabled={selectDoctorCity === "" ? true : false}
                    onChange={(e) =>
                    e != null ? setPinCode(e.value) : setPinCode("")
                    }
                    className="searchable-select"
                    name="doctor_zipcode"
                    // value={personalDetail.doctor_zipcode}
                />
                ) : (
                <input
                    className="w-100"
                    value={stringFormatHandleEmpty(data.doctor_zipcode)}
                    disabled={true}
                />
                )}
                <label className="ms-3 position-absolute" htmlFor="landmark">
                Select Pincode
                </label>
                {selectDoctorState === "" && (
                <p className="p-error">{errors?.doctor_zipcode || formErrors?.doctor_zipcode}</p>
                )}
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_block"
                type="text"
                name="doctor_block"
                onChange={(e) => personalchk("doctor_block", e)}
                value={
                  !isDisabled ? personalDetail.doctor_block : stringFormatHandleEmpty(data.doctor_block)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Block
              </label>
              <p className="p-error">{errors?.doctor_block || formErrors?.doctor_block}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_village"
                type="text"
                name="doctor_village"
                onChange={(e) => personalchk("doctor_village", e)}
                value={
                  !isDisabled ? personalDetail.doctor_village : stringFormatHandleEmpty(data.doctor_village)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Village">
                Village
              </label>
              <p className="p-error">{errors?.doctor_village || formErrors?.doctor_village}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_phone"
                type="number"
                name="doctor_phone"
                onChange={(e) => personalchk("doctor_phone", e)}
                value={
                  !isDisabled ? personalDetail.doctor_phone : stringFormatHandleEmpty(data.doctor_phone)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Block">
                Mobile No.
              </label>
              <p className="p-error">{errors?.doctor_phone || formErrors?.doctor_phone}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_email"
                type="text"
                name="doctor_email"
                onChange={(e) => personalchk("doctor_email", e)}
                value={
                  !isDisabled ? personalDetail.doctor_email : stringFormatHandleEmpty(data.doctor_email)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Email
              </label>
              <p className="p-error">{errors?.doctor_email || formErrors?.doctor_email}</p>
            </div>
            <div className="col position-relative">
              <input
                className="w-100"
                id="doctor_location"
                type="text"
                name="doctor_location"
                onChange={(e) => personalchk("doctor_location", e)}
                value={
                  !isDisabled ? personalDetail.doctor_location : stringFormatHandleEmpty(data.doctor_location)
                }
                disabled={isDisabled}
              />
              <label className="ms-3 position-absolute" htmlFor="Email">
                Location
              </label>
              <p className="p-error">{errors?.doctor_location || formErrors?.doctor_location}</p>
            </div>
        </div>}
        

      {isShow == 0 && <div className="row form col-12 d-flex flex-column">
        {!isDisabled && <label htmlFor="Upload DOC File" style={{color:"#29166F", fontSize:"18px", marginLeft:"10px"}}>Upload File</label>}
        <div>
          {!doctorFile.length > 0 &&
          !personalDetail.doctor_upload &&
          !isDisabled ? (
            <div>
              <label>
                {!doctorFile.length && doctorFileLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="doctorDocs"
                  disabled={doctorFile.length === 2}
                  onChange={(e) => uploadSingleFile(e, "doctorDocs")}
                />
              </label>
              {!doctorFile.length > 0 && (
                <p className="p-error">
                  {errors.doctorFile || formErrors.doctorFile}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.doctor_upload !== null && (
                <div className="img-preview">
                  {data?.doctor_upload?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.doctor_upload)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.doctor_upload)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    doctorFile?.[0] || personalDetail?.doctor_upload
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        doctorFile[0] || personalDetail?.doctor_upload
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        doctorFile?.[0] || personalDetail?.doctor_upload
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "doctorDocs")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>}           

    </>
  );
};

export default DoctorDetails;
