import React, { useRef, useState, useEffect } from "react";
import "../../../css/new_car_step1.css";
import Footer from "../../ReusableComponents/Footer";
// import Footer from '../ReusableComponents/Footer'
import NavBar from "../../ReusableComponents/NavBar";
// import gimg from '../../HomePage/Group 154.png'
// import { useHistory } from 'react-router-dom';
import { Model } from "../../ReusableComponents/Modal";
import SubmitQuery from "../../../services/insurance-query.service";
// import TermsUsage from '../../OtherInsurance/TermsUsage';
import "./CarStep1.css";
// import { Form } from 'rsuite';
import Accordion from "react-bootstrap/Accordion";
import { Modal } from "react-bootstrap";
import Terms from "../../ReusableComponents/Terms";

export default function CarStep1() {
  const [checked, setChecked] = useState(true);
  // const history = useHistory();

  const [show, setShow] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const ShowModal = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return () => {};
  }, []);
  let car_insurance = useRef();
  let different_types_car_insurance = useRef();
  let addOn = useRef();
  let topic1 = useRef();
  let topic5 = useRef();
  let faqs = useRef();
  // const sectionContent = {
  //   textDecoration: "none",
  //   fontSize: 14,
  // }

  const initialValues = {
    firstName: "",
    email: "",
    lastName: "",
    mobile: "",
    isChecked: true,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  console.log(isSubmit);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(Object.assign({ ...formValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    if (true) {
      await SubmitQuery(
        formValues.firstName,
        formValues.lastName,
        formValues.mobile,
        formValues.email,
        "motor/purchaseCarInsurance"
      )
        .then((response) => response.json())
        .then(async (response) => {
          console.log(JSON.stringify(response));
          if (response.status === 1) {
            handleShow();
            setFormValues(initialValues);
          } else if (response.status === 0) {
            alert(response.message);
          }
        });
    }
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  // useEffect(() => {
  //   console.log(formErrors);
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     console.log(formValues);
  //   }
  // }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    // const lname_regex = /([a-zA-Z]{1,30})$/;
    const mobile_regex = /^[0-9\b]+$/;

    if (!values.firstName) {
      errors.firstName = "First Name is Required!";
    } else if (!fname_regex.test(values.firstName)) {
      errors.firstName = "Enter Valid First Name!";
    }
    // if (!values.lastName) {
    //   errors.lastName = "Last Name is Required!";
    // } else if (!lname_regex.test(values.lastName)) {
    //   errors.lastName = "Enter Valid Last Name!";
    // }
    if (!values.email) {
      errors.email = "Email is Required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Enter Valid Email!";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile No. is Required";
    } else if (values.mobile.length < 10) {
      errors.mobile = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.mobile)) {
      errors.mobile = "Enter valid Mobile No.";
    }
    if (!values.isChecked) {
      errors.isChecked = "Kindly accepts the terms and conditions to submit";
    }

    return errors;
  };

  return (
    <div className="main-div">
      <NavBar />

      <br />
      <br />
      <br />

      <div className="car-insurance-top">
        <br />
        <div className="container">
          <div className="row my-2 form-container">
            <div className="col-lg-8">
              <div
                className="row"
                style={{
                  marginTop: "20px",
                  marginLeft: "1.25rem",
                  paddingTop: "10px",
                }}
              >
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3">
                  <div className="bg-white p-2">
                    <div className="px-2 py-3">
                      <div
                        className="container"
                        style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}
                      >
                        <div
                          className="row"
                          style={{ marginTop: "-30px", marginLeft: "-70px" }}
                        ></div>
                        <form onSubmit={handleSubmit}>
                          <h2 className="icueTextPrimary">Car Insurance </h2>
                          <p className="textPrimary">
                            Secure your Car with best Car Insurance plans
                            offered by insurers.
                          </p>{" "}
                          <br />
                          <div
                            className="step2-content col-lg-5 col-md-7"
                            style={{ marginLeft: "-3rem" }}
                          >
                            <div className="col-lg-10">
                              <div className="d-sm-flex">
                                <div className="car3-form-groupp mt-3 mb-2">
                                  <input
                                    className="car2-form-controll"
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    value={formValues.firstName}
                                    onChange={handleChange}
                                    style={{
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                      textTransform: "capitalize",
                                    }}
                                  />
                                  <label
                                    className="ms-3 car2-form-control-placeholderr"
                                    for="firstName"
                                  >
                                    First Name
                                  </label>
                                  <p className="p-error">
                                    {formErrors.firstName}
                                  </p>
                                </div>
                                <div className="car3-form-groupp mt-3 mb-2">
                                  <input
                                    className="car2-form-controll"
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    value={formValues.lastName}
                                    onChange={handleChange}
                                    style={{
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                      textTransform: "capitalize",
                                    }}
                                  />
                                  <label
                                    className="ms-3 car2-form-control-placeholderr"
                                    for="lastName"
                                  >
                                    Last Name
                                  </label>

                                  {/* <p className='p-error'>{formErrors.lastName}</p> */}
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="car3-form-groupp mt-3 mb-2">
                                  <input
                                    className="car2-form-controll"
                                    id="mobile"
                                    type="text"
                                    name="mobile"
                                    value={formValues.mobile}
                                    onChange={handleChange}
                                    maxLength={10}
                                    style={{
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                      textTransform: "lowercase",
                                    }}
                                  />
                                  <label
                                    className="ms-3 car2-form-control-placeholderr"
                                    for="mobile"
                                  >
                                    Mobile
                                  </label>

                                  <p className="p-error">{formErrors.mobile}</p>
                                </div>
                                <div className="car3-form-groupp mt-3 mb-2">
                                  <input
                                    id="email"
                                    className="car2-form-controll"
                                    type="text"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    style={{
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                      textTransform: "lowercase",
                                    }}
                                  />
                                  <label
                                    className="ms-3 car2-form-control-placeholderr"
                                    for="email"
                                  >
                                    Email
                                  </label>

                                  <p className="p-error">{formErrors.email}</p>
                                </div>
                              </div>
                              {/* <button className="fluid ui button blue">Submit</button> */}
                            </div>
                          </div>
                          <br />
                          <div className="privacypolicy-chechbox">
                            <input
                              className="acceptTandC"
                              style={{ width: "20px" }}
                              type="checkbox"
                              defaultChecked={formValues.isChecked}
                              onChange={() => {
                                setChecked(!checked);
                                setFormValues({
                                  ...formValues,
                                  isChecked: !formValues.isChecked,
                                });
                              }}
                            />
                            <a href="#!">
                              {" "}
                              I/We accept the{" "}
                              <span
                                className="link-terms"
                                onClick={handleShowModal}
                              >
                                Terms and conditions
                              </span>{" "}
                            </a>

                            <Model show={showModal} onHide={handleCloseModal}>
                              <Terms />
                            </Model>

                            <p className="p-error">{formErrors.isChecked}</p>
                            <br />
                            <span
                              style={{
                                fontSize: "0.9rem",
                                fontFamily: "Calibri",
                                paddingTop: "1rem !important",
                              }}
                            >
                              (You authorise PoliciCue Insurance Brokers Pvt Ltd
                              (PoliciCue) to contact you through call, SMS,
                              email, WhatsApp or any other mode in future. You
                              hereby override your NDNC registration.)
                            </span>
                          </div>
                          <div
                            className="step2-car-nextbtn "
                            style={{
                              marginLeft: "-55px",
                              lineHeight: "20px",
                              height: "40px",
                            }}
                          >
                            <button
                              className=""
                              onDoubleClick={handleSubmit}
                              type="submit"
                              onClick={ShowModal}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center"
              style={{
                backgroundColor: "#F4F7FC",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="images/cars-step1.png" alt="car-step-1" className="car-step1-img"></img>
            </div>
          </div>
        </div>
        {/* <div className="container" >
          <div className="row my-2 form-container">
            <div className="col-lg-8" >
              <div className='row' style={{ marginTop: '20px', marginLeft: '6.25rem', paddingTop: '10px' }}>
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                  <div className="bg-white p-2" >
                    <div className="px-2 py-3">
                      <div className="container" style={{ Shadow: "0px 30px 50px rgb(0 0 0 / 10%)" }}>
                        <div className="row" style={{ marginTop: "-30px", marginLeft: "-70px" }}>
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <h2 className="ml-3 icueTextPrimary">Car Insurance </h2>
                            <p>Secure your Car with best Car Insurance plans offered by insurers.</p> <br />
                            <div className="step2-content col-lg-5 col-md-7" >
                              <div className='col-lg-10'>
                                <div className='d-sm-flex'>
                                  <div className="car3-form-groupp mt-3 mb-2" >
                                    <input
                                      type="text"
                                      id="firstName"
                                      name="firstName"
                                      className="car2-form-controll"
                                      required
                                      style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                      value={formValues.firstName}
                                      onChange={handleChange}

                                    />
                                    <label className="ms-3 car2-form-control-placeholderr" for="firstName">
                                      First Name
                                    </label>
                                    <p className='p-error'>{formErrors.firstName}</p>
                                  </div>

                                  <div className="car3-form-groupp mt-3 mb-2">
                                    <input
                                      type="text"
                                      id="lastName"
                                      name="lastName"
                                      className="car2-form-controll"
                                      required
                                      style={{ marginLeft: '20px', marginRight: '20px', textTransform: "capitalize" }}
                                      value={formValues.lastName}
                                      onChange={handleChange}

                                    />
                                    <label className="ms-3 car2-form-control-placeholderr" for="lastName">
                                      Last Name
                                    </label>
                                    <p className='p-error'>{formErrors.lastName}</p>

                                  </div>


                                </div>
                                <div className='d-sm-flex'>
                                  <div className="car3-form-groupp mt-3 mb-2" >
                                    <input
                                      type="text"
                                      id="mobile"
                                      name="mobile"
                                      maxLength={10}
                                      className="car2-form-controll"
                                      required
                                      style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                      value={formValues.mobile}
                                      onChange={handleChange}
                                    />
                                    <label className="ms-3 car2-form-control-placeholderr" for="mobile">
                                      Mobile No
                                    </label>
                                    <p className='p-error'>{formErrors.mobile}</p>

                                  </div>

                                  <div className="car3-form-groupp mt-3 mb-2">
                                    <input
                                      type="text"
                                      id="email"
                                      name="email"
                                      className="car2-form-controll"
                                      required
                                      style={{ marginLeft: '20px', marginRight: '20px', textTransform: "lowercase" }}
                                      value={formValues.email}
                                      onChange={handleChange}
                                    />
                                    <label className="ms-3 car2-form-control-placeholderr" for="email">
                                      Email ID
                                    </label>
                                    <p className='p-error'>{formErrors.email}</p>
                                  </div>
                                </div>

                              </div>

                            </div>
                            <br />

                            <div className='privacypolicy-chechbox' >
                              <input className='acceptTandC' style={{ width: '20px' }} type='checkbox' defaultChecked={checked} onChange={() => setChecked(!checked)} /><a href="#!"> I/We accept the Terms and conditions </a>
                            </div>
                            <div className='step2-car-nextbtn ' data-bs-toggle="modal" data-bs-target="#thankyouModal" style={{ marginLeft: "-55px", lineHeight: "20px", height: "40px" }}><button className='' type='submit'  >Submit</button></div>
                          </form>

                        </div>
                      </div>
                    </div></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center" style={{ backgroundColor: "#F4F7FC", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img src='images/cars-step1.png' className='car-step1-img'  ></img>
            </div>
          </div>
        </div> */}
        {/* <img src={gimg} style={{ marginTop: '-625px', width: '100%' }}></img> */}
      </div>

      <div ref={car_insurance} className="car-insurance">
        <div className="container" style={{ maxWidth: "80%" }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h2 className="h2-header">What is Car Insurance ?</h2>
                <p className="paragraph-header">
                  A Car Insurance provides cover against the financial risk that
                  may occur due to the damage caused to the car. It covers
                  damage when caused due to an accident, natural or man-made
                  calamity. It also provides financial cover against the
                  third-party liability that arises due to bodily injury/
                  property damage to a third party/property. Under the
                  provisions of Motor Vehicles Act all the vehicles, which are
                  operating in public places should have insurance policy at
                  least to cover third party liability as specified under the
                  Act.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img
                  className="img-fluid img-details"
                  style={{ height: 280 }}
                  src="images/im4 1.jpg"
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of car-insurance -->
<!-- end of details 2 --> */}

      {/* <!-- different_types_car_insurance --> */}
      <div
        ref={different_types_car_insurance}
        className="different_types_car_insurance"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading-different_types_car_insurance">
                What are the different type of Car Insurance ?
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-cls">
              <div
                className="card_diff_car_left1"
                style={{ minWidth: "350px" }}
              >
                <div className="text-container diff_car_left_text">
                  <div
                    className="image-container"
                    style={{ marginTop: "-4rem" }}
                  >
                    <img
                      className="img-cls"
                      src="images/tparty.png"
                      alt="icon"
                    />
                  </div>
                  <h6 className="h6-text-heading">
                    Third Party (TP or Act Only Policy)
                  </h6>
                  <p className="p-diff_car">
                    Buying car insurance in India is mandatory on the legal
                    ground under Motor Vehicle Act. To drive a car on the public
                    road, you need to possess a valid insurance policy that at
                    least provides coverage against third party damage.This type
                    of Insurance Policy only covers the third party -
                    person/property who has been damaged or injured in an
                    accident where the car owner is accountable.It covers the
                    insured person's liability to third parties' loss caused by
                    an accident involving the vehicle insured.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-cls">
              <div
                className="card_diff_car_right1"
                style={{ minWidth: "350px" }}
              >
                <div className="image-container" style={{ marginTop: "-4rem" }}>
                  <img
                    className="img-cls"
                    src="images/package.png"
                    alt="icon"
                  />
                </div>
                <div className="text-container">
                  <h6 className="h6-text-heading">
                    Comprehensive (Package Policy)
                  </h6>
                  <p className="p-diff_car">
                    Comprehensive Car Insurance Policy has wider scope and cover
                    all the above-mentioned liabilities along with
                    owner’s/insured person’s vehicle damage, theft & expenses
                    due to accident of the Car. Along with the Third Party
                    Cover, Comprehensive Insurance Policy also provide cover
                    towards damage to your own vehicle which may be caused due
                    various reason as per policy. Also, this policy covers
                    bodily injury to you under personal accident cover. This
                    type of policy can be extended to increase benefits as an
                    additional feature called Add On Covers.
                  </p>{" "}
                  <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of different_types_car_insurance -->
<!-- end of different_types_car_insurance --> */}

      {/* <!-- topic 1 --> */}
      <div className="topic-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h2 className="h2-topic-1">
                  Comprehensive Policy with Long- Term Third Party Insurance
                  (1+3 Year) -{" "}
                </h2>
                <p className="p-topic-1">
                  As it suggests the damage to car is covered for one year
                  whereas the Third-Party Cover is for 3 years. Own Damage part
                  cover has to get renewed on next year renewal date.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- topic 2 --> */}
      <div className="topic-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h2 className="h2-topic-2">
                  Comprehensive Insurance Plan for 3 Years (3+3 Year) -{" "}
                </h2>
                <p className="p-topic-2">
                  This Policy give cover for 3 complete long years against both
                  Own Damage section as well as Third Party. But it’s cost to
                  your pocket may be on higher side.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- topic 3 --> */}
      <div className="topic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h2 className="h2-topic-3">
                  Long - Term Third - Party Liability Only Insurance (3 Years)-
                </h2>
                <p className="p-topic-3">
                  Buying new vehicle and opting Liability only cover does not
                  make sense and one must have comprehensive cover for their
                  brand new vehicle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!------------------------addons-----------------------------------------> */}
      {/* <!-- Basic --> */}
      <div ref={addOn} className="ex-basic-1 pt-5 pb-5">
        <div className="container custom-extend">
          {/* <!-- Card --> */}
          <div className="card card-addons">
            <div className="card-block">
              <h2 className="h2-heading-addon">Car Insurance Add -Ons</h2>
              {/* <!-----------------------row one-----------------------------------------> */}
              <div className="row addons">
                <div className="col-lg-4 ">
                  <div className="card addon card-size-1">
                    <div className="card-body addon-content">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Zero Depriciation Cover
                        </h6>
                        <img src="images/zero.png" alt="zero" />
                      </div>
                      <p className="card-text p-addon-text ">
                        In case of any damage under insurance plan you need to
                        replace some parts of your car, the car insurance plan
                        pays you the depreciated value of the part and not the
                        invoice value of the part. If you have opted for a
                        Depreciation Cover, you would be paid the actual price
                        of the part and not the depreciated value. This benefit
                        comes with a small increase in premium.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card addon card-size-2">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Engine Protect Cover
                        </h6>
                        <img src="images/engine.png" alt="engine" />
                      </div>
                      <p className="card-text p-addon-text">
                        This add on protection gives cover against damage to
                        your car engine and electronic circuit caused due to
                        flooding, or water logging. This cover helps you avoid
                        huge repair costs, as your insurance company will pay
                        off for the damages. It’s quite useful, especially
                        during the monsoon season.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4  ">
                  <div className="card addon card-size-3">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Consumable Cover
                        </h6>
                        <img src="images/consum.png" alt="consum" />
                      </div>
                      <p className="card-text p-addon-text ">
                        Under the normal comprehensive private car policy cost
                        of Engine oil, lubricants, gearbox oil, screw, nut and
                        bolt are not covered. With an add on consumable cover
                        these expenses are made payable under the policy.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--------end row 1-->
                          <!-------------------------------------row two---------------------------------------------------> */}
              <div className="row addons">
                <div className="col-lg-4 ">
                  <div className="card addon addon card-size-1">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Invoice Price Cover (Return to Invoice)
                        </h6>
                        <img src="images/cover.png" alt="cover" />
                      </div>
                      <p className="card-text p-addon-text ">
                        In case of a "total loss" of your car due to severe
                        damage/Theft, the insurance companies will pay you the
                        depreciated value of your car and not the price to
                        purchase a new car. If you opt for this add-on cover,
                        you effectively receive an amount to purchase a brand
                        new car. Companies also pay the road tax and
                        registration costs with this cover. It is also called
                        the "Return to Invoice" cover.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <div className="card addon card-size-2">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          No Claim Bonus Protection
                        </h6>
                        <img src="images/bonus.png" alt="bouns" />
                      </div>
                      <p className="card-text p-addon-text ">
                        This add-on helps in saving your NCB upto one or two
                        claims (depending on the insurer) during the policy
                        period. In case you have not made any claims in your
                        policy, you get discounts on renewal in the form of
                        No-claims Bonus. In case, you have accumulated a good
                        No-claims bonus %, this add-on cover will protect your
                        against the loss of this bonus in case of a claim you
                        make. You will be able to carry on your NCB % on
                        renewal.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <div className="card addon card-size-3">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Keys and Locks Replacement Cover
                        </h6>
                        <img src="images/key.png" alt="key" />
                      </div>
                      <p className="card-text p-addon-text  ">
                        In case the keys to your car are lost, this will cover
                        the costs of replacement. In case new locks need to be
                        procured, this will cover that cost also. Usually this
                        is available only once during a policy period.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!----- end row two ------->
                 <!--------------------------row three-------------------------------> */}
              <div className="row addons">
                <div className="col-lg-4 ">
                  <div className="card addon card-size-1">
                    <div className="card-body">
                      <div className="card-heading-row">
                        <h6 className="card-title h6-heading-addon">
                          Roadside assistance Cover
                        </h6>
                        <img src="images/cover.png" alt="cover" />
                      </div>
                      <p className="addon-text p-addon-text ">
                        There are stances when you have to face major issues
                        during your travel such as a mechanical breakdown or
                        flat tires etc. In this scenario, you don’t know how to
                        deal with such issue at that point of time where nothing
                        is available. With this add-on cover, you are provided
                        with 24x7 roadside assistance to help you on the spot.
                        There is a variety of assistance which insurance
                        companies offer as add-on covers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!------ end row 3 --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of basic -->
<!-- end addons --> */}

      {/* <!-- What is Not Covered under a Basic Car Insurance Plan --> */}
      <div ref={topic1} className="topic-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h2 className="h2-topic-4">
                  What is Not Covered under a Basic Car Insurance Plan{" "}
                </h2>
                <p className="p-topic-4">
                  Mechanical expenses that occur because of normal wear and tear
                  of the car and its parts are usually not covered in a standard
                  plan. However, you can get the cover on the same by opting for
                  zero dep cover.
                </p>
                <p className="p-topic-4">
                  Usually, a car’s engine is not liable to get the cover for
                  malfunctions that are non-accidental. You can cover the same
                  by option for an engine protector cover. It will protect the
                  same against non-accidental electrical and mechanical
                  breakdowns.
                </p>
                <p className="p-topic-4">
                  Passengers in a car or a hired driver are not included under a
                  basic car insurance policy. However, you can add-on an
                  Extended Accident Cover for the required cover.
                </p>
                <p className="p-topic-4">
                  A standard car insurance policy is valid only within the
                  geography of India, but it can be extended to include
                  neighboring countries like Bangladesh, Sri Lanka, Bhutan,
                  Pakistan, Nepal and the Maldives for an additional premium.
                </p>
                <p className="p-topic-4">
                  It is always advisable to go through the policy documents
                  carefully and understand the conditions included therein. It
                  will help you at the time of filing claim, as it avoids
                  confusion and provides a better clarity of the whole process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Exclusion in Car Insurance --> */}
      <div ref={topic5} className="topic-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container">
                <h2 className="h2-topic-5">Exclusion in Car Insurance</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h4 className="h4-topic-5">
                Your Car Insurance Policy does not cover:
              </h4>
              <p className="p-topic-5">Steady wear & tear.</p>
              <p className="p-topic-5">
                Loss/damage when driving under the influence of alcohol.
              </p>
              <p className="p-topic-5">
                Loss/damage when driving with an invalid driving license.
              </p>
              <p className="p-topic-5">Loss due to war, civil war, etc.</p>
              <p className="p-topic-5">Consequential loss.</p>
              <p className="p-topic-5">
                Claims that don’t include under the terms of the contract.
              </p>
              <p className="p-topic-5">
                Use of vehicle for any other purpose as mentioned under
                ‘limitations as to use’
              </p>
            </div>
            <div className="col-lg-5">
              <div className="image-container">
                <img
                  className="img-fluid img-details"
                  style={{ height: "17rem" }}
                  src="images/im4 1.jpg"
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={faqs} className="faqs">
        <div className="container">
          <div className="row">
            <div className="col-align">
              <h2 className="h2-heading-faqs">Insurance FAQ’s</h2>
              <p className="p-heading-faqs">
                If You Are Looking To Buy Insurance You Ought to Check Below
                Frequently Asked Questions
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion-header-1">
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">
                    How the value of my car is decided ?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-1">
                  <h3 className="h3-faqs">For Own Damage:</h3>
                  <p className="p-faqs">
                    The Sum Insured under a Motor Insurance policy reflects the
                    value of the motor vehicle determined based on the concept
                    known as Insured's Declared Value. Insured's Declared Value
                    is the value arrived at based on the Manufacturer's present
                    value and depreciation based on the Age of the Vehicle.
                  </p>
                  <div className="row">
                    <div className="col-lg-5">
                      <h3 className="h3-faqs">AGE OF THE VEHICLE</h3>
                      <p className="p-faqs-points">Not exceeding 6 months</p>
                      <p className="p-faqs-points">
                        Exceeding 6 months but not exceeding 1 year
                      </p>
                      <p className="p-faqs-points">
                        Exceeding 1 year but not exceeding 2 years
                      </p>
                      <p className="p-faqs-points">
                        Exceeding 2 years but not exceeding 3 years
                      </p>
                      <p className="p-faqs-points">
                        Exceeding 3 years but not exceeding 4 years
                      </p>
                      <p className="p-faqs-points">
                        Exceeding 4 years but not exceeding 5 years
                      </p>
                    </div>
                    <div className="col-lg-7">
                      <h3 className="h3-faqs">
                        % OF DEPRECIATION FOR FIXING IDV
                      </h3>
                      <p className="p-faqs-points-right">5%</p>
                      <p className="p-faqs-points-right">15%</p>
                      <p className="p-faqs-points-right">20%</p>
                      <p className="p-faqs-points-right">30%</p>
                      <p className="p-faqs-points-right">40%</p>
                      <p className="p-faqs-points-right">50%</p>
                    </div>
                  </div>
                  <h3 className="h3-faqs-third-party">For Third Party:</h3>
                  <p className="p-faqs">
                    Coverage is as per requirements of the Motor Vehicles Act,
                    1988. Compulsory Personal accident cover for owner-driver is
                    also included. Policy can also be extended to cover various
                    other risks like Personal Accident to occupants of vehicle,
                    Workmen's Compensation to Driver etc over and above the
                    cover available to him under statute.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordion-header-2">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <p className="faqText">
                    {" "}
                    How is the premium of my car insurance determined ?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-2">
                  <p className="p-faqs-para">
                    Many factors determine the premium you will pay. For Own
                    Damage cover different insurance companies charge different
                    premiums for similar coverage. PoliciCue website will help
                    you getting comparison quotes from various insurers. Check
                    the insurers’s quotes & comparison and proceed to buy the
                    best suited one.{" "}
                  </p>
                  <p className="p-faqs-para">
                    With so many Plans & coverage been offered by various
                    Insurance companies it is indeed difficult for anyone to
                    choose single insurance policy from an insurer. PoliciCue
                    portal gives you an ease to decide while comparing the
                    benefit on offer from various Insurer according to your need
                    in single go and facilitates the opting of an insurance
                    Policy which is best fit to your specific purpose.
                  </p>
                  <p className="p-faqs-para">
                    The Own Damage coverage is left to be rated by individual
                    insurance companies after duly filed rates with the
                    Insurance Regulatory and Development Authority. The same is
                    determined on following factors amongst others -- Age and
                    Cubic Capacity of vehicle, Registration, IDV (Insured
                    Declared Value), Discounts / loadings,No Claim Bonusalong
                    with past claims experience are taken into account while
                    calculating premium. Third Party Liability Premium rates are
                    laid down by IRDA.{" "}
                  </p>
                  <p className="p-faqs-para">
                    In case of break in insurance, vehicle inspection would be
                    required.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="accordion-header-1">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">
                    {" "}
                    Which policy should I buy , Comprehensive Packed Policy or
                    Third Party?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-1">
                  <p className="p-faqs-para">
                    As per law, only third-party liability only policy is
                    required without which you cannot use the vehicle on road.
                    However, under third party liability only policy, any damage
                    to your vehicle is not covered and it could result into a
                    huge financial losses. Therefore, it is recommended to buy a
                    comprehensive cover as it provides financial protection
                    along with protection from third party liability.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="accordion-header-2">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">What is No Claim Bonus (NCB) ?</p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-2">
                  <p className="p-faqs-para">
                    No Claim Bonus (NCB) is the benefit accrued to an insured
                    for not making any claims during the previous policy period.
                    As per current norms in India, it ranges from 20% on the Own
                    Damage premium (and not on Liability premium) and
                    progressively increases to a maximum of 50%.
                  </p>{" "}
                  <br />
                  <div className="row">
                    <div className="col-lg-8">
                      <h3 className="h3-faqs">All types of Vehicles</h3>
                      <p className="p-faqs-points">
                        No claim made or pending during the preceding full year
                        of insurance
                      </p>
                      <p className="p-faqs-points">
                        No claim made or pending during the preceding 2
                        consecutive years of insurance
                      </p>
                      <p className="p-faqs-points">
                        No claim made or pending during the preceding 3
                        consecutive years of insurance
                      </p>
                      <p className="p-faqs-points">
                        No claim made or pending during the preceding 4
                        consecutive years of insurance
                      </p>
                      <p className="p-faqs-points">
                        No claim made or pending during the preceding 5
                        consecutive years of insurance
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <h3 className="h3-faqs" style={{ marginLeft: "-15px" }}>
                        % of Discount on Own Damage premium
                      </h3>
                      <p className="p-faqs-points-right">20%</p>
                      <p className="p-faqs-points-right">25%</p>
                      <p className="p-faqs-points-right">35%</p>
                      <p className="p-faqs-points-right">45%</p>
                      <p className="p-faqs-points-right">50%</p>
                    </div>
                  </div>
                  <br />
                  <p className="p-faqs-para">
                    If, however, a claim is lodged, the No Claim Bonus is lost
                    in the subsequent policy period.{" "}
                  </p>
                  <p className="p-faqs-para">
                    NCB is given to the insured and not to the insured vehicle.
                    Hence, on transfer of the vehicle, the insurance policy can
                    be transferred to new owner but not the NCB. The new owner
                    has to pay the difference on account of NCB for the balance
                    policy period. The original owner can, however, use the NCB
                    on a new vehicle purchased by him/her.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="accordion-header-1">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">
                    {" "}
                    What happens to No Claim Bonus (NCB) for an expired policy ?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-1">
                  <p className="p-faqs-para">
                    No Claim Bonus is valid up to 90 days from the previous
                    policy expiry date. If the policy is not renewed within 90
                    days, No Claim Bonus will become 0% and no benefit shall be
                    passed on to the renewed policy.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="accordion-header-2">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">What is deductible ?</p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-2">
                  <p className="p-faqs-para">
                    Deductible or “excess” is the amount over and above, which
                    the claim will be payable. There is a normal
                    standard/compulsory excess for most of the vehicles
                    depending upon the cubic capacity/carrying capacity of the
                    vehicle.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header className="accordion-header-1">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">
                    {" "}
                    If I fit CNG or LPG kit in my vehicle, is it necessary to
                    inform the insurance company ?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-1">
                  <p className="p-faqs-para">
                    If a CNG / LPG kit is fitted in the vehicle, the (Road
                    Transport Authority (RTA) office where the vehicle was
                    registered should be informed so that they make a note of
                    the change in the registration certificate (RC) of the
                    vehicle. The insurance company should also be informed so
                    that the kit is covered on payment of extra premium on the
                    value of the kit under “OD” section and also under
                    “Liability” section.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header className="accordion-header-2">
                  {" "}
                  <img src="./images/Rectangle 22.svg" alt="" />
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <p className="faqText">
                    What are the documents that are required to be submitted for
                    a Motor Insurance claim ?
                  </p>
                </Accordion.Header>
                <Accordion.Body className="accordion-header-2">
                  <p className="p-faqs-para">
                    Generally, the following documents are required to be
                    submitted. However, read through your policy to see the
                    complete list—duly filled in claim form, RC copy of the
                    vehicle, Original estimate of repairs, Original repair
                    invoice and payment receipt. In case cashless facility is
                    availed, only repair invoice would need to be submitted and
                    FIR, if required. For theft claims, the keys are to be
                    submitted. Theft claims would also require non-traceable
                    certificate to be submitted.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="modal-block-close-btn">
            <button
              type="button"
              className="btn text-muted modal-btn-size"
              onClick={handleClose}
            >
              <i className="bi bi-x-circle fas fa-lg modal-btn-icon"></i>
            </button>
          </div>
          <div className="modal-block">
            <img
              className="thankyou"
              src="images/thankyou.png"
              alt="alternative"
              style={{ width: "100px" }}
            />
            <h2>Thank You!</h2>
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <label
                  className=""
                  style={{
                    textAlign: "center",
                    fontFamily: "Calibri",
                    color: "black",
                  }}
                >
                  Your query has been submitted successfully and our executive
                  will contact you shortly.
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div
        className="modal fade "
        id="thankyouModal"
        tabindex="-1"
        aria-labelledby="thankyouModalLabel"
        aria-hidden="true"
        style={{ marginTop: "170px" }}
      >
        {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
        <div className="modal-dialog modal-sm rounded-circle">
          <div
            className="modal-content"
            style={{ borderRadius: "12px", width: "130%" }}
          >
            {/* <div className="modal-header ">                */}
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginLeft:"265px"}}></button> */}
            <button
              type="button"
              className="btn text-muted"
              data-bs-dismiss="modal"
              style={{ marginLeft: "305px" }}
            >
              <i className="bi bi-x-circle fas fa-lg"></i>
            </button>
            {/* </div> */}
            <div className="modal-body " style={{ backgroundColor: "white" }}>
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                  <div className="form-group pb-2">
                    <img
                      className="thankyou"
                      src="images/thankyou.png"
                      alt="alternative"
                      style={{ width: "108px", marginLeft: "75px" }}
                    />
                    <h2 style={{ marginLeft: "60px" }}>Thank You!</h2>

                    {/* <h2 ><i className="bi bi-check-circle fas fa-lg" style={{marginLeft:"80px", color:"#00A0E4"}}></i></h2> */}
                  </div>
                </div>
                <div
                  className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto"
                  style={{ marginTop: "-15px" }}
                >
                  <div className="mb-4">
                    <div className="d-flex justify-content-between">
                      <label
                        className=""
                        style={{
                          textAlign: "center",
                          fontFamily: "Calibri",
                          color: "black",
                        }}
                      >
                        Your query had been submitted successfully and our
                        executive will contact you shortly.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
