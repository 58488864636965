import { LazyLoadImage } from "react-lazy-load-image-component";
import X from "../.././assets/img/x.png";
import ImagePlaceholder from "../.././assets/img/img_placeholder.png";
import { cattleformat_date, editformat_date, file, stringFormat } from "../../utils/method";
import { useState } from "react";
import DatePicker from "react-datepicker";
const PaymentDetailsForm = ({
  updateValue = void 0,
  personalDetail = [],
  paymentLoad = false,
  uploadSingleFile = void 0,
  deleteFile = void 0,
  paymentImg = "",
  formErrors = [],
  errors = [],
  isDisabled = false,
  data = [],
}) => {
    const [startDate, setStartDate] = useState("");
  function isExistingValue(value) {
    if (isDisabled && data?.payment_mode === value) {
      return true;
    }
    return false;
  }
    const onStartDate = (date) => {
        setStartDate(date)
        updateValue("payment_date", cattleformat_date(date))
        
    }
  let totalAmount = 100000;
  let balance = totalAmount - parseInt(personalDetail?.recieved_amount);

  return (
    <>
      <div className="row row-cols-1 row-cols-lg-auto form">
        <div className="col d-flex align-items-center">
          <h6 style={{ marginRight: "2rem", marginBottom: "0", color:"#29166F", fontSize:"18px" }}>
            Payment Mode
          </h6>
          {!isExistingValue("Online") ? (
            <input
              type="radio"
              id="Online"
              name="radio-group-payment-mode"
              className="me-3"
              value="Online"
              onChange={(e) => updateValue("payType", e)}
              disabled={isDisabled}
              checked={(personalDetail.payType === "Online"  )}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Online</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue("Cheque") ? (
            <input
              type="radio"
              id="Cheque"
              name="radio-group-payment-mode"
              className="me-3"
              value="Cheque"
              onChange={(e) => updateValue("payType", e)}
              checked={(personalDetail.payType === "Cheque" )}
              disabled={isDisabled}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>Cheque</span>
        </div>
        <div className="col d-flex align-items-center">
          {!isExistingValue("DD") ? (
            <input
              type="radio"
              id="DD"
              name="radio-group-payment-mode"
              className="me-3"
              onChange={(e) => updateValue("payType", e)}
              value="DD"
              disabled={isDisabled}
              checked={(personalDetail.payType === "DD" )}
            />
          ) : (
            <i className="bi bi-check-circle-fill text-primary me-2"></i>
          )}
          <span>DD</span>
        </div>
      </div>
      {(personalDetail.payType === "Online" || (!personalDetail.payType && data?.payment_mode =="Online") ) && <h6 style={{color:"#29166F", fontSize:"18px"}}>Online Payment </h6>}
      {(personalDetail.payType === "Cheque" || (!personalDetail.payType && data?.payment_mode =="Cheque")) && <h6 style={{color:"#29166F", fontSize:"18px"}}>Cheque Payment  </h6>}
      {(personalDetail.payType === "DD" || (!personalDetail.payType && data?.payment_mode =="DD" )) && <h6 style={{color:"#29166F", fontSize:"18px"}}>Demand Draft Payment  </h6>}
      <div className="row row-cols-1 row-cols-lg-3 gy-4 form pt-3 pb-3 mt-1">
        {(personalDetail.payType === "Online" || (!personalDetail.payType && data?.payment_mode =="Online")) &&
          <div className="col position-relative">
          <input
            className="w-100"
            id="transaction_id"
            type="text"
              maxLength={10}
            name="transaction_id"
            onChange={(e) => updateValue("transaction_id", e)}
            value={
              !isDisabled
                ? personalDetail.transaction_id
                : stringFormat(data.chequeNo_DD_TransactionID)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="others">
            Transaction ID
            </label>
                 <p className="p-error">{errors?.transaction_id || formErrors?.transaction_id}</p>
          </div>
        }
         {(personalDetail.payType === "Cheque" || (!personalDetail.payType && data?.payment_mode =="Cheque")) && <div className="col position-relative">
          <input
            className="w-100"
            id="Cheque_No"
            type="text"
            name="Cheque_No"
            maxLength={10}
            onChange={(e) => updateValue("Cheque_No", e)}
            value={
              !isDisabled
                ? personalDetail.Cheque_No
                : stringFormat(data.chequeNo_DD_TransactionID)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Cheque_No">
            Cheque No.
          </label>
           <p className="p-error">{errors?.Cheque_No || formErrors?.Cheque_No}</p>
        </div>}
         {(personalDetail.payType === "DD" || (!personalDetail.payType && data?.payment_mode =="DD")) && <div className="col position-relative">
          <input
            className="w-100"
            id="DD"
            type="text"
            name="DD"
            maxLength={10}
            onChange={(e) => updateValue("DD", e)}
            value={
              !isDisabled
                ? personalDetail.DD
                : stringFormat(data.chequeNo_DD_TransactionID)
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="DD">
            DD No.
          </label>
           <p className="p-error">{errors?.DD || formErrors?.DD}</p>
        </div> }

        <div className="col position-relative">
           {!isDisabled ? (
          <>
          <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText="Select Date"
                selected={startDate || editformat_date(data?.payment_date)}
                onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
                onChange={(date) => onStartDate(date)}
              />
                              </>
        ) : (
          <input
            className="w-100"
           value={stringFormat(data.payment_date)}
            disabled={true}
          />
        )}
          <label className="ms-3 position-absolute" htmlFor="Date">
            Date
          </label>
           <p className="p-error">{errors?.payment_date || formErrors?.payment_date}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="payee_name"
            type="text"
            maxLength={50}
            name="payee_name"
            onChange={(e) => updateValue("payee_name", e)}
            value={
              !isDisabled
                ? (personalDetail.payee_name)
                : (data.payee_name===undefined ? "" : data.payee_name )
            }
            disabled={false}
          />
          <label className="ms-3 position-absolute" htmlFor="Payee Name">
            Payee Name
          </label>
           <p className="p-error">{errors?.payee_name || formErrors?.payee_name}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="paymentBankName"
            type="text"
            name="paymentBankName"
            maxLength={50}
            
            onChange={(e) => updateValue("paymentBankName", e)}
            value={
              !isDisabled
                ? personalDetail.paymentBankName
                : (data.bank_name===undefined ? "" : data.bank_name )
            }
            disabled={isDisabled}
          />
          <label className="ms-3 position-absolute" htmlFor="paymentBankName">
            Bank Name
          </label>
           <p className="p-error">{errors?.paymentBankName || formErrors?.paymentBankName}</p>
        </div>
        <div className="col position-relative">
          <input
            className="w-100"
            id="recieved_amount"
            type="number"
            name="recieved_amount"
            // placeholder="Rs. 0000"
            onChange={(e) => updateValue("recieved_amount", e)}
            value={
              !isDisabled ? personalDetail.recieved_amount : stringFormat(data.recieved_amount)
            }
            disabled={false}
          />
          {/* <div className="d-flex justify-content-between">
            <div className="label-recieved_amount">Total Amount Rs. {totalAmount}</div>
            <div className="label-recieved_amount">Balance Rs. {balance || 0}</div>
          </div> */}
          <label className="ms-3 position-absolute" htmlFor="Amount">
            Amount Received
          </label>
           <p className="p-error">{errors?.recieved_amount || formErrors?.recieved_amount}</p>
        </div>
      </div>

      <div className="row form col-12 d-flex flex-column">
        <label htmlFor="Upload File" style={{color:"#29166F", fontSize:"18px", marginLeft:"10px"}}>Upload File</label>
        <div>
          {!paymentImg.length > 0 &&
          !personalDetail.payment_details_photo &&
          !isDisabled ? (
            <div>
              <label>
                {!paymentImg.length && paymentLoad ? (
                  <div
                    className="Group"
                    src="images/not-found.png"
                    alt="alternative"
                  >
                    <span className="spinner-border spinner-border-lg"></span>
                  </div>
                ) : (
                  <img
                    style={{ height: "190px" }}
                    src={ImagePlaceholder}
                    alt="alternative"
                  ></img>
                )}
                <input
                  type="file"
                  className="d-none"
                  size="60"
                  name="file"
                  disabled={paymentImg.length === 2}
                  onChange={(e) => uploadSingleFile(e, "payment_details_photo")}
                />
              </label>
              {!paymentImg.length > 0 && (
                <p className="p-error">
                  {errors.paymentImg || formErrors.paymentImg}
                </p>
              )}
            </div>
          ) : (
            <div className="row form-group">
              {isDisabled && data?.payment_details_photo !== null && (
                <div className="img-preview">
                  {data?.payment_details_photo?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(data?.payment_details_photo)}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <LazyLoadImage
                      effect="blur"
                      src={file(data?.payment_details_photo)}
                      alt="preview"
                    />
                  )}
                </div>
              )}
              {!isDisabled && (
                <div className="img-preview">
                  {(
                    paymentImg?.[0] || personalDetail?.payment_details_photo
                  )?.includes(".pdf") == true ? (
                    <a
                      className="btn btn-primary"
                      href={file(
                        paymentImg[0] || personalDetail?.payment_details_photo
                      )}
                      target="_blank"
                    >
                      Preview PDF File
                    </a>
                  ) : (
                    <img
                      src={file(
                        paymentImg?.[0] || personalDetail?.payment_details_photo
                      )}
                      alt="preview"
                    />
                  )}
                  <img
                    onClick={() => deleteFile(0, "payment_details_photo")}
                    className="clear"
                    src={X}
                    alt="clear-preview"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <hr />
    </>
  );
};

export default PaymentDetailsForm;
