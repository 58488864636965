import React from 'react'
import Footer from '../ReusableComponents/Footer'
import NavBar from '../ReusableComponents/NavBar'
import VerticalTabs from '../ReusableComponents/VerticalTabs/VerticalTabs'
import './style.css'

function index() {
    return (
        <div className='main-div'>
            <NavBar />
            <div className="bg_image_container">
                <img src="images/Banner.png" alt="bg" className='img' />
                <h1 className="centered">Legal And Admin Policies</h1>
            </div>

            <VerticalTabs />

            <Footer />
        </div>
    )
}

export default index
