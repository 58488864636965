import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SuperDashboard.css";
//import { makeStyles } from '@material-ui/core';

export default function SuperDashboard() {
  const [carleads, setCarLeads] = useState([]);
  const [bikeleads, setBikeLeads] = useState([]);
  const [total, setTotal] = useState([]);
  useEffect(() => {
    axios
      .get("https://policiecue-api.mobileprogramming.net/motor/carleadsCount")
      .then((res) => {
        setCarLeads(res.data.data);
      });
    axios
      .get("https://policiecue-api.mobileprogramming.net/motor/bikeleadsCount")
      .then((res) => {
        setBikeLeads(res.data.data);
      });
    axios
      .get("https://policiecue-api.mobileprogramming.net/lead/leadsCount")
      .then((res) => {
        setTotal(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, {});

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer" style={{ height: "100vh" }}>
        <div className="row my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>Dashboard</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  {/* <li className="breadcrumb-item text-muted" aria-current="page">LMS</li> */}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
