import { ALERT_FAILURE, ALERT_REQUEST, ALERT_SUCCESS  } from "../actions/types";

const initialState = {
    show: false,
    message: '',
    error: ''
};

export const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_REQUEST:
            return initialState
        case ALERT_SUCCESS:
            return {
                show: true,
                message: action.payload
            }
        case ALERT_FAILURE:
            return {
                show: true,
                message: '',
                error: action.payload
            }
        default:
            return state;
    }
}