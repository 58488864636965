import React, { useState } from "react";
import "./index.css";
import {IoIosArrowDown} from "react-icons/io"

const Accordion = ({ children1,children2,children3,children4, multiple = false ,activeArr,Toggle,titleArr }) => {
    const [active, setActive] = useState(0);

    return (
        <div className="custom-accordion">
                <AccordionItem
                    active={activeArr?true:active === 0}
                    multiple={multiple}
                    title={titleArr[0]}
                    onToggle={(e) =>{ setActive((a) => (a === 0 ? "" : 0));Toggle()}}
                    children={children1}
                />
                <AccordionItem
                     active={activeArr?true:active === 1}
                    multiple={multiple}
                    title={titleArr[1]}
                    onToggle={(e) =>{ setActive((a) => (a === 1 ? "" : 1));Toggle()}}
                    children={children2}
                />
                <AccordionItem
                     active={activeArr?true:active === 2}
                    multiple={multiple}
                    title={titleArr[2]}
                    onToggle={(e) =>{ setActive((a) => (a === 2 ? "" : 2));Toggle()}}
                    children={children3}
                />
                <AccordionItem
                   active={activeArr?true:active === 3}
                    multiple={multiple}
                    title={titleArr[3]}
                    onToggle={(e) =>{ setActive((a) => (a === 3 ? "" : 3));Toggle()}}
                    children={children4}
                />
        </div>
    );
};

const AccordionItem = ({ title, children, active, multiple, onToggle }) => {
    const [visiblity, setVisiblity] = useState(true);

    const isActive = () => (multiple ? visiblity : active);

    const toogleVisiblity = () => {
        setVisiblity((v) => !v);
        onToggle();
    };

    return (
        <div className={`item-card ${isActive() ? "accordion-active" : ""}`}>
            <div className="title-header accordion-headers" onClick={toogleVisiblity}>
                {title}
                <span className="accordion-icon">
                   
                    <IoIosArrowDown color="#707376" size={22}/>
                </span>
            </div>
            <div className="card-body">{children}</div>
        </div>
    );
};

export default Accordion;