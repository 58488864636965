import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCattleDetail, getSearchResult } from "../../../store/actions/posCattleDetail";
import { getCattleInfo } from "../../../store/actions/cattleInfo";
import CattleInsuranceDetailsTable from "../CattleInsuranceDetailsTable";
import Header from "../Header";
import DatePicker from "react-datepicker";
import { cattleformat_date } from "../../../utils/method";
import Loading from "../Loading";
import { getState } from "../../../store/actions/listStates";

const CattleInsuranceDetails = () => {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  console.log('first', useSelector((state) => state))
  const posDetailState = useSelector((state) => state?.cattleDetail?.data?.data);
  const total = useSelector((state) => state.cattleDetail.data.total);
  const [insuranceDate, setInsuranceDate] = useState("");
  const [emailId, setEmailId] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [proposerName, setProposerName] = useState("");
  const [stateName, setStateName] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const SearchResult = useSelector((state) => state.posposSearchResult.data);
  let searchTotal = useSelector((state) => state.posposSearchResult.data.total);
  const [searchPage, setSearchPage] = useState(1);
  const [show, setShow] = useState(false);
  var entries;
  const listState = useSelector((state) => state.posState.data);
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getState());
    }, []);
  useEffect(() => {
    setPageCount(Math.ceil(total / itemsPerPage));
  }, [total]);

  useEffect(() => {
    setPageCount(Math.ceil(searchTotal / itemsPerPage));
  }, [SearchResult]);

  useEffect(() => {
     dispatch(getCattleDetail(currentPage, itemsPerPage));
    if (isSearched) {
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      const payLoad = {
        page: searchPage,
        limit: itemsPerPage,
        stateName: stateName ? stateName : "",
        ownerName: ownerName ? ownerName : "",
        proposerName: proposerName ? proposerName : "",
        insuranceDate: insuranceDate ? cattleformat_date(insuranceDate) : "",
        emailId: emailId ? emailId : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
    } else {
      setPageCount(0);
      setPageCount(Math.ceil(total / itemsPerPage));
    }
  }, [itemsPerPage]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [])

  const setposDetail = (id) => {
    setposDetail(id);
       dispatch(getCattleDetail(id));
  };

  const setposInfo = (id) => {
    setposInfo(id);
    dispatch(getCattleInfo(id));
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    if (isSearched) {
      const payLoad = {
        page: event.selected + 1,
        limit: itemsPerPage,
      stateName: stateName ? stateName : "",
        ownerName: ownerName ? ownerName : "",
        proposerName: proposerName ? proposerName : "",
        insuranceDate: insuranceDate ? cattleformat_date(insuranceDate) : "",
        emailId: emailId ? emailId : "",
      };
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
      dispatch(getSearchResult(payLoad)).then();
      setIsSearched(true);
      setSearchPage(event.selected + 1);
      setPageCount(Math.ceil(searchTotal / itemsPerPage));
    } else {
      dispatch(getCattleDetail(event.selected + 1, itemsPerPage));
      setCurrentPage(event.selected + 1);
    }
  };

  const handleOnSearch = () => {
    const payLoad = {
      page: searchPage,
      limit: itemsPerPage,
        stateName: stateName ? stateName : "",
        ownerName: ownerName ? ownerName : "",
        proposerName: proposerName ? proposerName : "",
        insuranceDate: insuranceDate ? cattleformat_date(insuranceDate) : "",
        emailId: emailId ? emailId : "",
    };
    setPageCount(Math.ceil(searchTotal / itemsPerPage));
    dispatch(getSearchResult(payLoad)).then();
    setIsSearched(true);
  };

  const handleOnClearFilter = () => {
    setIsSearched(false);
    setPageCount(Math.ceil(total / itemsPerPage));
    setEmailId("");
    setOwnerName("");
    setProposerName("")
    setStateName("");
    setInsuranceDate("");
  };

  if (SearchResult.message === "success") {
    entries = SearchResult.data[0]?.length;
  }

  return (
    <>
          <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow rowWidth">
      <main className="mainBodyContainer">
      <Header
        title="Cattle Insurance Details"
        btn={true}
        btnText="Add Cattle Insurance"
        btnLink="/cattle-insurance/add"
        btnClass="p-btn"
      >
        <i className="bi bi-plus-lg"></i>
      </Header>
      <>
        <div className="p-4 bg-white shadow rounded-3">
          <div className="row gy-2 row-cols-1 row-cols-lg-6 gx-2 d-flex justify-content-center">
             <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">Proposer Name</label>
             <input
                type="text"
                name="name"
                placeholder="Proposer Name"
                value={proposerName}
                onChange={(e) => {
                  setProposerName(e.target.value);
                }}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">Owner Name</label>
             <input
                type="text"
                name="name"
                placeholder="Owner Name"
                value={ownerName}
                onChange={(e) => {
                  setOwnerName(e.target.value);
                }}
              />
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">State</label>
             <select
                id="pos_status"
                name="cars"
                value={stateName}
                onChange={(e) => {
                  setStateName(e.target.value);
                }}
              >
                <option value="">Select</option>
                {listState.map((item,idx) => {
                  return  <option key={idx} value={item.value}>{item.value}</option>
                })
                }

              </select>
            </div>
            <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="dropdown">Email ID</label>
              <input
                type="text"
                name="name"
                placeholder="lorem@policicue.com"
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                }}
              />
            </div>
             <div className="col p-2 me-2 pos_filter rounded">
              <label htmlFor="name">Policy Issuance Date</label>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy"
                    selected={insuranceDate}
                     onKeyDown={(e) => {
                  if(e.which == 8 || e.code == "Backspace" ||
                  e.which == 46 || e.code == "Delete") return
                  
                  e.preventDefault();
                }}
                onChange={(date) => setInsuranceDate(date)}
              />
            </div>
            
          </div>
          <div className="d-flex justify-content-center mt-3">
                <button
                  style={{color:"#000", borderRadius:"50%", border:"none"}}
              className="btn btn-sm pos_filter_btn primary_btn me-2"
              onClick={() => handleOnSearch()}
            >
              Search
            </button>
            <button
              className="btn btn-sm pos_filter_btn"
              onClick={handleOnClearFilter}
            >
              Clear Filter
            </button>
          </div>
        </div>
        <div className="p-4 bg-white shadow rounded-3 mt-3">
          <CattleInsuranceDetailsTable
            data={isSearched ? SearchResult.data : posDetailState}
            total={isSearched ? searchTotal : total}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            paginationPageCount={pageCount}
            paginationHandlePageClick={handlePageClick}
          />
        </div>
          </> 
        </main>
        </div>
    </>
  );
};

export default CattleInsuranceDetails;
