import React from "react";

const ReadOnlyRow = ({ contact }) => {
  return (
    <tr>
      <td>{contact.code}</td>
      <td>{contact.namee}</td>
      <td>{contact.email}</td>
      <td>{contact.date}</td>
      <td>{contact.designation}</td>
      <td><span className="badge rounded-pill bg-primary text-uppercase  px-4 py-2 w-75" 
           >{contact.status1}</span>
           <span className="badge rounded-pill bg-secondary  text-uppercase  px-4 py-2 w-75" 
           >{contact.status2}</span>
        </td>
     
     
    </tr>
  );
};

export default ReadOnlyRow;