import React from "react";

const EditableRow2 = ({
  editFormData2,
  handleEditFormChange2,
  handleCancelClick2,
}) => {
  return (
    <tr>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter name"
          name="namee2"
          value={editFormData2.namee2}
          onChange={handleEditFormChange2}
        ></input>
      </td>
     
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter amount"
          name="amount2"
          value={editFormData2.amount2}
          onChange={handleEditFormChange2}
        ></input>
      </td>
   
      <td>
        <button type="submit">Save</button>
        <button type="button" onClick={handleCancelClick2}>
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditableRow2;