import React from 'react'
import './HealthCover.css'
import { useHistory, useLocation } from 'react-router-dom';
import { Select, MenuItem } from '@material-ui/core';

import Stepper from '../ReusableComponents/Stepper'
import NavBar from '../ReusableComponents/NavBar'
import Footer from '../ReusableComponents/Footer'
import FormControlInput from '../ReusableComponents/FormControlInput';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";



export default function HealthCover() {
    const history = useHistory();
    const location = useLocation();

    const SelfValue = ['3 Lakh', '4 Lakh', '5 Lakh', '6 Lakh', '7 Lakh', '8 Lakh']
    const SpouseValue = ['3 Lakh', '4 Lakh', '5 Lakh', '6 Lakh', '7 Lakh', '8 Lakh']
    const ChildrenValue = ['3 Lakh', '4 Lakh', '5 Lakh', '6 Lakh', '7 Lakh', '8 Lakh']
    const MotherValue = ['3 Lakh', '4 Lakh', '5 Lakh', '6 Lakh', '7 Lakh', '8 Lakh']
    const FatherValue = ['3 Lakh', '4 Lakh', '5 Lakh', '6 Lakh', '7 Lakh', '8 Lakh']

    const { Self, Spouse, Mother, Children, Father, Count } = location.state

    // useEffect(() => {
    //     console.log("location", location.state);
    // }, [])


    const healthCoverSchema = yup.object({
        self: Self ? yup.string().required() : yup.string().notRequired(),
        spouse: Spouse ? yup.string().required() : yup.string().notRequired(),
        child: Children ? yup.string().required() : yup.string().notRequired(),
        mother: Mother ? yup.string().required() : yup.string().notRequired(),
        father: Father ? yup.string().required() : yup.string().notRequired(),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(healthCoverSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/HealthDisease")
        }
    }

    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div className='step4-car-insurance'>
                    <div className="container-fluid">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className='H-step1-topimg'><img src='images/Component 17.png' alt="top-img" ></img>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className='col-lg-1 col-md-0' >
                                <Stepper current={2} thirdStep={true} fouthStep={true} src="images/healthActive.png" />
                            </div>
                            <div className="H-step1-content col-lg-6 col-md-6">

                                <h4>How much health cover do you want ?</h4>
                                <div className="col-lg-8 col-md-8">
                                    {Self ? (
                                        <div className="form-input d-flex justify-content-between">
                                            <span>{Self}</span>
                                            <FormControlInput>
                                                <Select {...register("self")}>
                                                    {SelfValue.map((item) => (
                                                        <MenuItem value={item}>Rs {item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <p className="text-danger">{errors.self?.message}</p>
                                            </FormControlInput>
                                        </div>
                                    ) : ("")}
                                    {Spouse ? (
                                        <div className="form-input d-flex justify-content-between">
                                            <span>{Spouse}</span>
                                            <FormControlInput>
                                                <Select {...register("spouse")}>
                                                    {SpouseValue.map((item) => (
                                                        <MenuItem value={item}>Rs {item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <p className="text-danger">{errors.spouse?.message}</p>
                                            </FormControlInput>
                                        </div>
                                    ) : ("")}
                                    {Children ? (
                                         Array(Count).fill(0).map(()=> (
                                             <div className="form-input d-flex justify-content-between">
                                            <span>{Children}</span>
                                            <FormControlInput>
                                                <Select {...register("child")}>
                                                    {ChildrenValue.map((item) => (
                                                        <MenuItem value={item}>Rs {item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <p className="text-danger">{errors.child?.message}</p>
                                            </FormControlInput>
                                        </div>
                                         ))
                                    )
                                      : ("")}
                                    {Mother ? (
                                        <div className="form-input d-flex justify-content-between">
                                            <span>{Mother}</span>
                                            <FormControlInput>
                                                <Select {...register("mother")}>
                                                    {MotherValue.map((item) => (
                                                        <MenuItem value={item}>Rs {item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <p className="text-danger">{errors.mother?.message}</p>
                                            </FormControlInput>
                                        </div>
                                    ) : ("")}
                                    {Father ? (
                                        <div className="form-input d-flex justify-content-between">
                                            <span>{Father}</span>
                                            <FormControlInput>
                                                <Select {...register("father")}>
                                                    {FatherValue.map((item) => (
                                                        <MenuItem value={item}>Rs {item}</MenuItem>
                                                    ))}
                                                </Select>
                                                <p className="text-danger">{errors.father?.message}</p>
                                            </FormControlInput>
                                        </div>
                                    ) : ("")}


                                </div>

                                <div className='H-step1-nextbtn2' >
                                    <button style={{ marginTop: '4rem', marginLeft: "3rem" }} onClick={handleSubmit(onSubmit)}>Next</button>
                                </div>

                            </div>

                            <div className=" H-step1-rightimg col-lg-5 col-md-6">
                                <img src='images/im2 4.png' alt="alternative"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>


    )
}