import React, { useState } from 'react'
import './TLinsured.css'
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { TextField, InputLabel, Select, FormControl, MenuItem } from '@material-ui/core';
import Stepper from '../../ReusableComponents/Stepper';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const TLinsuredSchema = yup.object({
    DOB: yup.string().required(),
    annualIncome: yup.string().required(),
    sumInsured: yup.string().required(),
})

const useStyles = makeStyles({
    relation: {
        width: '230px',
        marginRight: '30px',
        // outline:'red',
        [`& fieldset`]: {
            borderRadius: '10px',
            // borderColor:'red',
            // paddingBottom:'25px
        },

    }
})
export default function TLinsured() {
    const classes = useStyles()
    const history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(TLinsuredSchema)
    });
    const onSubmit = data => {
        if (data) {
            history.push("/TLsaveQuotes")
        }
    }
    return (
        <>
            <div style={{ background: '#F2F6FF' }}>
                <NavBar />
                <br /><br />
                <div className='step4-car-insurance'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='H-step1-topimg'>
                            <img src='images/Component 4.png' alt="topimg"></img>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '50px' }}>
                        <div className='col-lg-1 col-md-0 ' >
                            <Stepper current={1} src="images/healthActive.png" />
                        </div>
                        <div className="H-step1-content col-lg-5 col-md-6">
                            <h6 style={{ color: 'black' }}>Tell us about the insured - </h6>
                            <div className='d-sm-flex mt-4'>
                                <div id='ck-button1' >
                                    <label>
                                        <input type='radio' name='gender' checked /><span className='span'>Male</span>
                                    </label>
                                </div>
                                <div id='ck-button1' >
                                    <label >
                                        <input type='radio' name='gender' /><span className='span'>Female</span>
                                    </label>
                                </div>
                            </div>
                            <div className='d-sm-flex car2-form-groupp' style={{ marginLeft: -1, marginTop: '2rem' }}>
                                    <input
                                        type="date"
                                        id="icueRegistrationNo"
                                        name="icueRegistrationNo"
                                        className="car2-form-controll"
                                        style={{width: "230px"}}
                                        {...register('DOB')}
                                    />
                                    <label className="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                                        DOB
                                    </label>
                                    <p className="text-danger">{errors.DOB?.message}</p>
                                </div>
                                <div className='d-sm-flex' style={{ marginTop: '3rem', width: 700 }}>
                                    <h6 style={{ marginRight: '10px', marginTop: 11 }}>Smoke or Chew Tobacco - </h6>
                                    <div id='ck-button1' >
                                        <label >
                                            <input type='radio' name='chewTomacco' checked /><span className='span'>Yes</span>
                                        </label>
                                    </div>
                                    <div id='ck-button1' >
                                        <label >
                                            <input type='radio' name='chewTomacco' /><span className='span'>No</span>
                                        </label>
                                    </div>
                                </div>

                                <div className='d-sm-flex mt-4' style={{ marginTop: '30px', width: 700 }}>
                                    <h6 style={{ marginRight: '10px', marginTop: 11 }}>Alcohol consumption - </h6>
                                    <div id='ck-button1' style={{ marginLeft: "2rem" }} >
                                        <label >
                                            <input type='radio' name='alcohal' checked /><span className='span'>Yes</span>
                                        </label>
                                    </div>
                                    <div id='ck-button1' >
                                        <label >
                                            <input type='radio' name='alcohal' /><span className='span'>No</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-sm-flex" style={{ marginTop: '2rem' }}>

                                    <div className="health-form-groupp ">
                                        <select
                                            id="ApproxAnnualIncome"
                                            name="ApproxAnnualIncome"
                                            className="health-form-controll "
                                            required
                                            {...register("annualIncome")}
                                        >
                                            <option value="" selected disabled hidden> Approx Annual Income</option>
                                            <option value="1 Lac to 3 Lac">1 Lac to 3 Lac</option>
                                            <option value="5 Lac to 7 Lac">5 Lac to 7 Lac</option>
                                            <option value="8 Lac to 10 Lac">8 Lac to 10 Lac</option>
                                            <option value="11 Lac to 13 Lac">11 Lac to 13 Lac</option>
                                            <option value="14 Lac to 16 Lac">14 Lac to 16 Lac</option>
                                            <option value="17 Lac to 19 Lac">17 Lac to 19 Lac</option>
                                        </select>
                                        <p className="text-danger">{errors.annualIncome?.message}</p>
                                    </div>

                                    <div className="health-form-groupp ">
                                        <select
                                            id="TLsuminsured"
                                            name="TLsuminsured"
                                            className="health-form-controll "
                                            required
                                            {...register("sumInsured")}
                                        >
                                            <option value="" selected disabled hidden>Sum Insured</option>
                                            <option value="10 Lakh">Rs 10 Lakh</option>
                                            <option value="25 Lakh">Rs 25 Lakh</option>
                                            <option value="50 Lakh">Rs 50 Lakh</option>
                                            <option value="80 Lakh">Rs 80 Lakh</option>
                                        </select>
                                        <p className="text-danger">{errors.sumInsured?.message}</p>
                                    </div>
                                </div>
                                <br />

                                <div className='H-step1-nextbtn' style={{marginLeft: '5rem', marginTop: '1.5rem'}} >
                                    <button onClick={handleSubmit(onSubmit)}>Next</button>
                                </div>
                            </div>



                            <div className=" H-step1-rightimg col-lg-6  col-md-6">
                                <img src='images/im2 4.png' style={{ marginLeft: 200 }}></img>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </>
            )
}