import { file } from "../../../utils/method";
import React from 'react';

const CertificateContent = React.forwardRef((props, ref) => {
    const val = props.val;
    const User = props.User;
    return (<div style={{ marginTop: "20px" }} ref={ref} className="to-be-printed">
        <div
            style={{
                padding: "30px",
                border: "solid 1px #000",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
            className="container"
        >
            <div className="main_cont">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        fontSize: "1.2em",
                        textAlign: "center",
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="https://policiecue-api.mobileprogramming.net/images/policicue-logo.png"
                            alt="logo"
                            style={{
                                width: "170px",
                                margin: "0 auto",
                                marginBottom: "10px",
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "1px", textAlign: "center" }} className="t_text">
                        <b>PoliciCue Insurance Brokers Private Ltd.</b>
                    </div>
                    <div style={{ marginBottom: "1px", textAlign: "center" }} className="t_text">
                        A-42/6, Sector-62, Noida, Gautam Buddha Nagar, UP 201301
                        IN
                    </div>
                    <div style={{ marginBottom: "2px", textAlign: "center" }} className="t_text">
                        Email:{" "}
                        <a href="mailto:info@policicue.com">info@policicue.com</a>{" "}
                        Tel: +91-120- 4351523
                    </div>
                    <div style={{ fontSize: "0.9em", textAlign: "center" }} className="t_text">
                        IRDA Reg No. 794 Direct (Life &amp; General) Validity
                        Date: 6-1-2022 to 5-1-2025
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "20px",
                    }}
                >
                    <h3>CERTIFICATE</h3>
                    <h4 style={{ margin: 0 }}>Point Of Sale Person (POSP)</h4>
                    <div
                        style={{
                            height: "100px",
                            width: "80px",
                            margin: "1.5rem auto",
                            border: "1px solid black",
                            overflow: "hidden",
                        }}
                        className="p_div_user_img"
                    >
                        <img
                            src={val?.user_image ? file(val.user_image) : ""}
                            alt="user_image"
                            style={{
                                objectFit: "cover",
                                height: "100px",
                                width: "80px",
                            }}
                            className="p_user_img"
                        />
                    </div>
                </div>
                <div className="text-written">
                    <p
                        style={{
                            marginBottom: "30px",
                            textAlign: "justify",
                            fontSize: "1rem",
                        }}
                    >
                        This is to certify that <b>{val?.name}</b> had registered
                        for the online training program for Point-of-Sale person
                        (POSP) has successfully completed the prescribed 15 Hours
                        training and have also passed the examination conducted by
                        PoliciCue Insurance Brokers Private Ltd. under the
                        guidelines issued by Insurance Regulatory and Development
                        Authority issued under Reference number: IRDAI/Int/
                        GDL/ORD/183/10/2015 dated 26/10/2015 (as amended time to
                        time). Your personal details are as under:
                    </p>
                    <div style={{ fontSize: "1rem" }} className="e_text">
                        <b>Aadhaar Number :</b> {User?.aadhaarNo}
                    </div>
                    <div style={{ marginTop: "2px", fontSize: "1rem" }} className="e_text">
                        <b>POSP Code :</b> {val?.pospCode}
                    </div>
                    <div style={{ marginTop: "2px", fontSize: "1rem" }} className="e_text">
                        <b>Date of Joining :</b> {val?.examFinishedDate}
                    </div>
                    <p
                        style={{
                            marginTop: "30px",
                            textAlign: "justify",
                            fontSize: "1rem",
                        }}
                    >
                        This certification authorizes you to work as Point Of Sale
                        Person (POSP) for PoliciCue Insurance Brokers Private Ltd.
                        to market products categorized and identified under the
                        guideline of POSP by IRDAI. As POSP of PoliciCue you will
                        work under guideline of Brokers Regulation 2018 (F.No.
                        IRDAI/Reg/2/149/2018).
                    </p>
                </div>
            </div>
            <div>
                <div style={{ marginTop: "40px", fontSize: "1rem" }} className="footer_to_be_print">
                    <div style={{ textAlign: "left" }}>
                        <p style={{ margin: 0 }}>Place: Noida</p>
                        <p style={{ marginTop: "2px" }}>Date: {val?.date2}</p>
                    </div>
                    <div style={{ textAlign: "right" }} className="last">
                        <img
                            src="https://policiecue-api.mobileprogramming.net/images/Signature.png"
                            alt="signature"
                            style={{ height: "50px" }}
                        />
                        <p style={{ marginTop: "10px" }}>Principal Officer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>);
})

export default CertificateContent;