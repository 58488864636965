import axios from "axios";
import Constant from "../utils/constant"
import { alertShow } from '../store/actions/alert'

const Register = (firstName, lastName, mobileNo, emailId,) => {
    // let dispatch=useDispatch()
    return axios.post(Constant.BASE_URL + "pos/register", {
        firstName, lastName, mobileNo, emailId,
    })
        .then((response) => {
            if (response.data.data) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                return response;
            }
            else {
                if(response.data?.errors && response.data?.errors[0]){
                    alertShow('err',response.data?.errors[0].msg)
                }else{
                    alertShow('err',response.data.message)
                }
                console.log(response,"===> ")
            }
            return response;
        }).catch((e) => {
            console.log(e);
        })
};


const otp = (phone, email, otp) => {
    return axios
        .patch(Constant.BASE_URL + "pos/verifyOtp", {
            "mobileNo": phone,
            "emailId": email,
            "otp": otp
          })
        .then((response) => {
            if (response.data.data) {
                alertShow('succ',response.data.message)
                return response.data.status
            }
            
            else {
                alertShow('err',response.data.message)
                return response.data.status
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}
const verifyMobileotp = (phone, email, otp) => {
    return axios
        .patch(Constant.BASE_URL + "pos/verifyMobileOtp", {
            "mobileNo": phone,
            "otp": otp
        }, {
            headers: {
                authorization: "Bearer " +localStorage.getItem("token"),          
              }
          })
        .then((response) => {
            if (response.data.data) {
                alertShow('succ', response.data.message)
                localStorage.setItem("user", JSON.stringify(response.data.data));
                return response.data.status
            }
            
            else {
                alertShow('err',"Invalid OTP")
                return response.data.status
            }
            return response.data.data
        }).catch(e => {
            if (e.response.data.message.name === "TokenExpiredError") {
                alertShow('err',"OTP Expired! Please Click on Resend")
            }
            console.log(e);
        })
}
const sendMobileOTP = (phone) => {
    return axios
        .post(Constant.BASE_URL + "pos/posMobileOtpLogin", {
            "mobileNo": phone,
          })
        .then((response) => {
            if (response.data.data) {
                localStorage.setItem("token", response.data.data.token);
                alertShow('succ',"OTP Sent!")
                return response.data.status
            }
            
            else {
                alertShow('err',response.data.message)
                return response.data.status
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}


const resendOtp = (firstName, phone, email) => {
    return axios
        .patch(Constant.BASE_URL + "pos/resendOtp", {
            firstName: firstName,
            mobileNo: phone,
            emailId: email
          })
        .then((response) => {
            if (response.data.data) {
                alertShow('succ',response.data.message)
            }

            else {
                alertShow('err',response.data.message)
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}
const resendMobileOtp = (firstName, phone, email) => {
    return axios
        .patch(Constant.BASE_URL + "pos/resendMobileOtp", {
            mobileNo: phone,
          })
        .then((response) => {
            if (response.data.data) {
                localStorage.setItem("token", response.data.data.token);
                alertShow('succ',"OTP Resent!")
            }

            else {
                alertShow('err',response.data.message)
            }
            return response.data.data
        }).catch(e => {
            console.log(e);
        })
}

const login = (username, password) => {
    return axios
        .post(Constant.BASE_URL + "pos/posLogin", {
            emailId:username,
            password
        })
        .then((response) => {
            console.log(response , "==> " )

            if(response.data.status===0){
                if(response.data?.errors?.length>0){
              alertShow('err',`${JSON.stringify(response.data.errors[0].msg)}`)
                }else{
                    alertShow('err',`${response.data.message}`)
                }
           }
            else{ 
                
            if (response.data.data) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            else {
                alertShow('err',response.data.message )
            }
        }
            return response.data.data;
        })
    };


const logout = () => {
    localStorage.removeItem("user");
};

export default {
    Register,
    login,
    sendMobileOTP,
    otp,
    resendOtp,
    verifyMobileotp,
    resendMobileOtp,
    logout,
};