import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_BIKEPOLICYTYPES_FAILURE, FETCH_BIKEPOLICYTYPES_REQUEST, FETCH_BIKEPOLICYTYPES_SUCCESS } from "./types"

export const fetchBikePolicyTypesRequest = () => {
    return {
        type: FETCH_BIKEPOLICYTYPES_REQUEST
    }
}
export const fetchBikePolicyTypesSuccess = model => {
    return {
        type: FETCH_BIKEPOLICYTYPES_SUCCESS,
        payload: model
    }
}
export const fetchBikePolicyTypesFailure = error => {
    return {
        type: FETCH_BIKEPOLICYTYPES_FAILURE,
        payload: error
    }
}


export const getBikePolicyTypes = () => async (dispatch) => {
    dispatch(fetchBikePolicyTypesRequest)
    await axios.get(Constant.BASE_URL + 'motor/getBikePolicyTypes')
        .then(response => {
            const result = response.data
            dispatch(fetchBikePolicyTypesSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBikePolicyTypesFailure(errorMsg))
        })
}