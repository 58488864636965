import React from 'react';
import './RetailDashboard.css';
import Carousel from "react-elastic-carousel";
import Item from './Item';

const breakPoints=[
    {width:100, itemsToShow:1},
    {width:200, itemsToShow:2},
    {width:300, itemsToShow:3},
    {width:400, itemsToShow:4},
    {width:750, itemsToShow:5},
    {width:800, itemsToShow:6},
    
]
export default function RetailDashboard(){
    return(<div className="RetailDashboard" id="RetailDashboard">
        {/* <!-- Top Bar --> */}
    <div className="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className=" icueMail px-2">
                        <i className="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem active">
                                    <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                                </li>

                                {/* <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li> */}
                                 <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill"></i></a>
                                </li>
                                <hr></hr>
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem active">
                                <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem ">
                                <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                            </li>

                            {/* <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem" style={{marginTop:"-0.75rem"}}>
                                        <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li> */}
                            
                            <li className="icueNavBarItem danger">
                                <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill" ></i></a>
                            </li>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-transparent p-2  rounded-3">
                            <nav style={{bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")'}} aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 bg-transparent">
                                    <li className="breadcrumb-item"><a href="/RetailDashboard" className="text-decoration-none text-info" style={{fontFamily:'Calibri', fontSize:"16px", color: "#3A3A3A", fontSize:"bold"}}>Dashboard</a></li>
                                    {/* <li className="breadcrumb-item text-muted" aria-current="page" style={{fontFamily:'Mulish'}}>LMS</li> */}
                                </ol>
                              </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-2" >
                            <div className="bg-white p-1 shadow icueBorBottomWhite mb-1" style={{marginLeft:"0.8rem"}} >
                                <div className="row py-2">
                                    <div className="col-7" >
                                        <div className="ps-2" >
                                            <h3 className="fs-1 pt-1 icueTextPrimary ">1</h3> <br/>
                                            <h4 className="fs-5 mb-0 text-capitalize icueTextPrimary">health insurance </h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <small><img src="images/health-insurance.svg " alt="health-insurance" className="w-100 rounded-circle "/></small>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-2">
                            <div className="bg-white p-1 shadow icueBorBottomWhite ">
                                <div className="row py-2" >
                                    <div className="col-7" >
                                        <div className="ps-2" >
                                            <h3 className="fs-1 pt-1 icueTextPrimary ">1</h3>  <br/>
                                            <h4 className="fs-5 mb-3 text-capitalize icueTextPrimary">car insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <img src="images/incCar.svg" alt="incLife" className="w-100  rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-2">
                            <div className="bg-white p-1 shadow icueBorBottomWhite " >
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h3 className="fs-1 pt-1 icueTextPrimary ">1</h3>  <br/>
                                            <h4 className="fs-5 mb-3 text-capitalize icueTextPrimary">bike insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <img src="images/incBike.svg " alt="incBike" className="w-100  rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-2">
                            <div className="bg-white p-1 shadow icueBorBottomWhite " style={{marginRight:"0.6rem"}} >
                                <div className="row py-2">
                                    <div className="col-7">
                                        <div className="ps-2">
                                            <h3 className="fs-1 pt-1 icueTextPrimary ">1</h3>  <br/>
                                            <h4 className="fs-5 mb-3 text-capitalize icueTextPrimary">life insurance</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="pe-2">
                                        <img src="images/incLife.svg" alt="incLife" className="w-100 rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 py-2 px-3 ">
                            <div className="bg-transparent p-2  rounded-3 ">
                                <div className="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2">
                               <>
                               <Carousel breakPoints={breakPoints}>
                                {/* <i className="bi bi-chevron-left fas fa-2x" style={{marginTop:"23px", color:" #8692A6"}}></i>             */}
                              
                                 <Item> <div className="pe-2 hover-switch" >
                                     
                                 <img src="images/health-insurance2.svg"  className=" images w-60 rounded-circle" />
                                  <img src="images/health-insurance2-gray.svg"  className=" images w-60 rounded-circle" />
                                 
                                        <label style={{textAlign:"center", color:"#3A3285", fontFamily:"Calibri", fontSize:"12px", fontWeight:"bold", marginTop:"100px"}}>Health Insurance</label>
                                        </div>     
                                        </Item>                               
                                     
                                          <Item>  <div className="pe-2 hover-switch">
                                          <img src="images/life-insurance2.svg" alt="life-insurance2" className="w-60 rounded-circle"/>
                                    <img src="images/life-insurance2-gray.svg" alt="life-insurance2-gray" className="w-60 rounded-circle"/>
                                        <label style={{textAlign:"center", color:" #3A3285", fontFamily:"Calibri", fontSize:"12px", fontWeight:"bold",   marginLeft:"15px", marginTop:"100px"}}>Life Insurance</label>
                                        </div>    
                                        </Item>                                 
                                   
                                    <Item> <div className="pe-2 hover-switch" >
                                    <img src="images/car-insurance2.svg" alt="car-insurance2-gray" className="w-60 rounded-circle" />
                                     <img src="images/car-insurance2-gray.svg" alt="car-insurance2-gray" className="w-60 rounded-circle" />
                                        <label style={{textAlign:"center", color:" #3A3285", fontFamily:"Calibri", fontSize:"12px", fontWeight:"bold", marginLeft:"15px", marginTop:"100px"}}>Car Insurance</label>
                                        </div>                                   
                                      </Item>                                  
                                       
                                        <Item> <div className="pe-2 hover-switch">
                                        <img src="images/bike-insurance2.svg" alt="bike-insurance2" className="w-60 rounded-circle"/> 
                                        <img src="images/bike-insurance2-gray.svg" alt="bike-insurance2-gray" className="w-60 rounded-circle"/>
                                        <label style={{textAlign:"center", color:" #3A3285", fontFamily:"Calibri", fontSize:"12px",  fontWeight:"bold", marginLeft:"15px", marginTop:"100px"}}>Two-wheeler Insurance</label>
                                         </div>                             
                                    </Item>                        
                                        
                                         <Item> <div className="pe-2 hover-switch">
                                        <img src="images/term-insurance2.svg" alt="term-insurance2" className="w-60 rounded-circle"/> 
                                        <img src="images/term-insurance2-gray.svg" alt="term-insurance2" className="w-60 rounded-circle"/> 
                                        <label style={{textAlign:"center", color:" #3A3285", fontFamily:"Calibri", fontSize:"12px",fontWeight:"bold",  marginTop:"100px"}}>Term Insurance</label>
                                         </div>                        
                                     </Item>
                                   
                                         {/* <div className="col-2 text-end">*/}
                                         <Item>
                                    <div className="pe-2 hover-switch"> 
                                    <img src="images/travel-insurance2.svg" alt="travel-insurance2" className="w-60 rounded-circle"/> 
                                    <img src="images/travel-insurance2-gray.svg" alt="travel-insurance2" className="w-60 rounded-circle"/> 
                                        <label style={{textAlign:"center", color:" #3A3285", fontFamily:"Calibri", fontSize:"12px", alignItems:"center", fontWeight:"bold",  marginTop:"100px"}}>Travel Insurance</label>
                                         </div>                                   
                                   {/* </div>  */}
                                     </Item>
                                    </Carousel>
                                 
                                    </> 
                           
                             </div>   
                             <hr style={{marginTop:"-10px"}}></hr>                         
                            </div>
                        </div>
                       
                            

                    <div className="row my-2">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 py-2 px-3 ">
                            <div className=" card bg-white p-2  rounded-3 shadow-sm " style={{height:"90px", marginTop:"-30px"}}>
                                <div className="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2 ">
                                
                                <div className="d-flex justify-content-between text-center" >
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Aviva <br></br> <small> Base Health Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">1CR</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i className="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 4,482/Month <br></br> <small> <small>Rs. 53,781 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" className="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i className="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                 {/* <a href="/Followup" type="button" className="btn btn-sm rounded-3 w-50 pb-1 text-capitalize text-white"    style={{lineHeight:"10px", backgroundColor:"#3A3285"}} ><i className="bi bi-search"> </i> &nbsp;  Search</a> */}

                                   </div>
                            </div>
                            
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 py-2 px-3 ">
                            <div className="card bg-white p-2  rounded-3 shadow-sm" style={{height:"110px"}}>
                                <div className="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2">
                                
                                <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>ICICI Lombard <br></br> <small > Super Topup Health <br ></br>Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">5L</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i className="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 2,615/Month <br></br> <small> <small>Rs. 31,375 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" className="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i className="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                   </div>
                            </div>
                            
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 py-2 px-3 ">
                            <div className="card bg-white p-2  rounded-3 shadow-sm" style={{height:"90px"}}>
                                <div className="d-flex justify-content-around flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column py-2">
                                
                                <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Aviva <br></br> <small> Base Health Insurance</small></h4>
                                 </div>
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Cover <br></br> <small> <small className="icueTextPrimary">1CR</small></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextLight pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Document <br></br> <small> <i className="bi bi-file-earmark-pdf-fill" style={{color:"#FA5457"}}></i></small></h4>
                                 </div> 
                                 <div className="d-flex justify-content-between text-center">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"15px"}}>Rs. 4,482/Month <br></br> <small> <small>Rs. 53,781 Annually</small></small></h4>
                                 </div> 
                                 <a href="/connect" type="button" className="btn icueBgPrimary text-white mt-2 "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"40px"}}> <i className="bi bi-check-circle-fill fas fa-lg" ></i> &nbsp;  &nbsp;  &nbsp;Buy now</a>
                                   </div>
                            </div>
                            
                        </div>
                   

                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3" style={{marginTop:"-360px"}}>
                            <div className="bg-white pt-4 pb-2 px-4 rounded-3 shadow-sm border-bottom"  style={{marginRight:"0.8rem"}}>
                                <div className="d-flex justify-content-between">
                                    <h4 className="icueTextPrimary pb-2" style={{fontFamily:"ErasDemiITC", fontSize:"20px"}}>Policies Due in 60 Days</h4>
                                 </div>                       
                                                                
                            </div>
                            <div className="bg-white px-4 pt-3 pb-3 rounded-3 shadow-sm border-bottom"  style={{marginRight:"0.8rem", height:"230px"}}>
                                <button type="button" className="btn icueBgInfo text-start text-white w-100 mb-2 icueRenewBtn"><div className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"12px"}}>#435216 (CAR INSURANCE)<br></br>
                                DUE ON: 15-OCT-2021
                                {/* <img src="images/Vector.png" className="icueRenewBtn" style={{marginLeft:"9rem"}}/> */}
                                </div>                                                                                        
                                </button>
                                <button type="button" className="btn icueBgInfo text-start text-white w-100 mb-2 icueRenewBtn"><div className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"12px"}}>#895468 (HEALTH INSURANCE) <br></br>
                                DUE ON: 04-NOV-2021
                                {/* <img src="images/Vector.png" className="icueRenewBtn" style={{marginLeft:"9rem"}}/> */}
                                </div></button>
                                
                                <button type="button" className="btn icueBgInfo text-start text-white w-100 icueRenewBtn"><div className="text-uppercase" style={{fontFamily:"Calibri", fontSize:"12px"}}>#564646 (LIFE INSURANCE) <br></br>
                                DUE ON: 15-NOV-2021
                                {/* <img src="images/Vector.png" className="icueRenewBtn" style={{marginLeft:"9rem"}}/> */}
                                </div></button>
                            </div>
                        </div>
                    </div> 
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                    <div className="pe-2" style={{ alignItems:"right"}}>
                                        <img src="images/Help.png" alt="help" className="w-1 rounded-circle" style={{marginLeft:"1000px", height:"125px", marginTop:"-135px"}}/>
                                        </div>                                   
                                    </div> 
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-3">
                        </div>
                    </div>
                     
                    
                    
                </main>
            </div>
        </div>
    </div>
    
    
   
   
    </div>)
}