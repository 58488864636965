import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    OTP_SUCCESS,
    OTP_FAIL,
} from "./types";

import AuthService from "../../services/auth.service";

export const signUp = (firstName, lastName, mobileNo, emailId,) => (dispatch) => {
    return AuthService.Register(firstName, lastName, mobileNo, emailId,).then(
        () => {
            dispatch({
                type: REGISTER_SUCCESS,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};


export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(

        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data.data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};


export const OTP = (phone, email, otp) => (dispatch) => {
    return AuthService.otp(phone, email, otp).then(
        (data) => {
            dispatch({
                type: OTP_SUCCESS,
                payload: { user: data },
            });

            return data;
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: OTP_FAIL,
                payload: message
            });

            return Promise.reject();
        }
    );
};

export const verifyMobileotp = (phone, email, otp) => (dispatch) => {
    return AuthService.verifyMobileotp(phone, email, otp).then(
        (data) => {
            dispatch({
                type: OTP_SUCCESS,
                payload: { user: data },
            });

            return data;
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: OTP_FAIL,
                payload: message
            });

            return Promise.reject();
        }
    );
};

export const sendMobileOTP = (phone) => (dispatch) => {
    return AuthService.sendMobileOTP(phone).then(
        (data) => {
            return data;
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                return message;
        }
    );
};

export const resendOTP = (firstName, phone, email) => (dispatch) => {
    return AuthService.resendOtp(firstName, phone, email).then(
        (data) => {
            dispatch({
                type: OTP_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: OTP_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};
export const resendMobileOtp = (firstName, phone, email) => (dispatch) => {
    return AuthService.resendMobileOtp(firstName, phone, email).then(
        (data) => {
            dispatch({
                type: OTP_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: OTP_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};