import React, { useState, useEffect } from "react";
//import axios from 'axios'
import "./PANDetails.css";
//import { makeStyles } from '@material-ui/core';
import { getPancardDetails } from "../../../store/actions/getPosPersonalDetails";
import { alertShow } from "../../../store/actions/alert";

import { file } from "../../../utils/method";

const PANDetails = (props) => {
  const { pathname } = window.location;
  const [active, setactive] = useState("");
  const [val, setVal] = useState({});

  useEffect(() => {
    getPancardDetails()
      .then((res) => {
        if (res.status) {
          setVal(res.data[0]);
        }
      })
      .catch((error) => {
        alertShow("err", error.message);
      });
  }, []);
  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow">
      <main className="mainBodyContainer">
        <div className="row my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0">
            <div className="bg-transparent p-2 pb-0 rounded-3">
              <nav
              className="useBreadCrumb"
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Profile</h6>
                <ol className="breadcrumb pt-2 pb-0 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    Account Information
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    My Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row my-2 row-top ">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3">
            <div className="p-2 rounded-3 ">
              <div className="py-3">
                <div className="container px-0">
                  <div className="row">
                    <div className="addroles pr-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      <ul className="px-0 m-0 shadow-sm">
                        <li
                          className={
                            pathname == "/MyProfile"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <a href="/#/MyProfile" className="accountNavBarLink" id="">
                            Personal Details{" "}
                          </a>
                        </li>
                        <li
                          className={
                            pathname == "/Aadhar"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <a href="/#/Aadhar" className="accountNavBarLink" id="">
                            Aadhaar Details{" "}
                          </a>
                        </li>
                        <li
                          className={
                            pathname == "/PANDetails"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "white" }}
                        >
                          <a
                            href="/#/PANDetails"
                            className="accountNavBarLink"
                            id=""
                            style={{ color: "#2196F3" }}
                          >
                            PAN Details{" "}
                          </a>
                        </li>
                        <li
                          className={
                            pathname == "/ContactDetails"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <a
                            href="/#/ContactDetails"
                            className="accountNavBarLink"
                            id=""
                          >
                            Contact Details{" "}
                          </a>
                        </li>
                        <li
                          className={
                            pathname == "/EducationDetails"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <a
                            href="/#/EducationDetails"
                            className="accountNavBarLink"
                            id=""
                          >
                            Education Details{" "}
                          </a>
                        </li>
                        <li
                          className={
                            pathname == "/BankDetails"
                              ? "accountNavBarItem active"
                              : "accountNavBarItem"
                          }
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          <a
                            href="/#/BankDetails"
                            className="accountNavBarLink"
                            id=""
                          >
                            Bank Details{" "}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12  bg-white shadow-sm">
                      <div className="tab-content p-4" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                              <div className="bg-white">
                                <h4 className="text-capitalize icueTextPrimary pb-4">
                                  PAN Details
                                </h4>
                              </div>
                              <form id="icueLeadInfoForm">
                                <div
                                  className="row"
                                  style={{ marginLeft: "0.5px" }}
                                >
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-0 full-input">
                                    <label
                                      for="number"
                                      style={{ color: "black" }}
                                    >
                                      PAN Card Number
                                    </label>
                                    <input
                                      type="pannumber"
                                      placeholder="-"
                                      value={val?.pancardNo}
                                      name="number"
                                      style={{ color: "black" }}
                                    ></input>
                                  </div>
                                </div>

                                <h6 style={{ marginTop: "2rem" }}>
                                  PAN Card Image
                                </h6>
                                <div style={{ paddingTop: "1rem" }} className="row">
                                  {val?.pancardImageUrl && <div className="previewbox">
                                    <img
                                      src={
                                        val?.pancardImageUrl
                                          ? file(val.pancardImageUrl)
                                          : "not-found.png"
                                      }
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  </div>}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default PANDetails;
