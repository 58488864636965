import React from 'react';
import './AgentLMS.css';
import Select from 'react-select';
export default function AgentLMS(){
    var Companyname=[{
           value:1,
           label:"HDFC Ergo"
    },
         {
             value:2,
           label:"ICICI Term life"
    },
          {
              value:3,
           label:"Royal Sundram"
    },
          {
               value:4,
           label:"Sompa"
    },
          {
               value:5,
           label:"SBI general"
    }
];
    return(<div className="AgentLMS" id="AgentLMS">
          <div className="container-fluid py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#!" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className=" icueMail px-2">
                        <i className="bi bi-headset"></i>
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem">
                                    <a href="/AgentDshboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#!" className="icueNavBarLink" id="">LMS</a>
                                </li>

                                <li className="active">
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                        </li>
                                    </ul>
                                </li>
    
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#!" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#!" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#!" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem">
                                <a href="/AgentDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem border-bottom-0">
                                <a href="#!" className="icueNavBarLink" id="">LMS</a>
                            </li>

                            <li className="active">
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="/AgentLMS" className="icueSubNavLink  text-capitalize">All Leads</a>
                                    </li>
                                </ul>
                            </li>
                            <hr/>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-12px"}}>
                                <a href="#!" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="#!" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#!" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr/>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="text-capitalize" style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", fontSize:"24px", marginLeft:"45px"}}>leads generated</h4>
                                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"12px"}}>
                                   <div className="icueSearchBox w-75 mx-3">
                                      <div className="input-group ">
                                      <span className="input-group-text" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div> */}
                                <div className="bg-transparent p-2  rounded-3">                           
                               
                                <nav style={{bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")'}} aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 bg-transparent">
                                        <li className="breadcrumb-item"><a href="/AgentDashboard" className="text-decoration-none text-info" style={{fontFamily:"Calibri", fontSize:"16px"}}>Dashboard</a></li>
                                        <li className="breadcrumb-item"><a href="#!" className="text-decoration-none text-info" style={{fontFamily:"Calibri", fontSize:"16px"}}>LMS</a></li>
                                        <li className="breadcrumb-item text-muted text-capitalize" aria-current="page" style={{fontFamily:"Calibri", fontSize:"16px"}}>All Leads</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0">
                            <div className="bg-white p-2  rounded-3 shadow-sm" style={{marginLeft:"0.8rem", marginRight:"0.8rem"}}>
                                <div className="px-4 py-3">
                                    <div className="row">                                       
                                        <div className="row" style={{marginTop:"-45px"}}>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4">
                                             <label className="text-muted" for="">Enter customer name,policy type etc</label>
                                                 <br/>
                                                <div className="form-group pb-0 mb-3" >
                                                   <input type="" className="search form-control icueLoginEmail text-capitalize" name="icueLoginEmail" id="icueLoginEmail" placeholder="" style={{backgroundColor:"#F4F7FC"}} required/>
                                                </div>                                      
                                            </div>
                                            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4">
                                             <label className="text-muted" for="">Lead date</label>
                                                 <br/>
                                                <div className="form pb-0 mb-3" >
                                                   <input type="date" className="form-control "  style={{backgroundColor:"#F4F7FC", fontSize:"normal"}} />
                                                </div>             
                                    
                                            </div> */}
                                            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"-18px"}}>
                                            <label className="text-muted" for=""></label>
                                                 <br/>
                                                <div className="form-group pb-0 mb-3" >
                                                <a href="/Followup" type="button" className="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"1px"}}> <i className="bi bi-search"></i> &nbsp;  Search</a>

                                              
                                                </div>             
                                    
                                            </div> */}
                                              
                                         
                                            <table id="lmsDataTable" className="table results" style={{width:"103%", border:"0",  backgroundColor: "#EBEFF2" }}>
                                                <thead>
                                                    <tr className="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                        <th>ID</th>
                                                        <th style={{width:"12%"}}>customer name</th>
                                                        <th style={{width:"12%"}}>location</th>
                                                        <th style={{width: "85px"}}>policy name </th>
                                                        <th style={{width: "10%"}}>lead status</th>
                                                        <th style={{width:"75px"}}>lead date</th>
                                                        <th>lead from</th>
                                                        <th>rfq status</th>
                                                        <th className="text-center" style={{width:" 20px"}}>rfq</th>
                                                        <th>follow up</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px", backgroundColor:"white"}}>
                                                    <tr>
                                                        <td>100001</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>annie rose</a></td>
                                                        <td className="wrap">Block no. 9/c, Dwaraka, Delhi</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center"  >
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75" >cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td className="textColor text-danger">not sent</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3  w-100 icueFeedBackBtn text-capitalize  " id="sendBtn_1" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px" }}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_1'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100002</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>harry lewis</a></td>
                                                        <td>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            agent
                                                        </td>
                                                        <td className="textColor text-dark"></td>
                                                        <td>
                                                            {/* <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_2" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button> */}
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_2'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100003</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>amelia davies</a></td>
                                                        <td>HIG-277, Shivaji Nagar, indore</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            agent
                                                        </td>
                                                        <td className="textColor text-primary"></td>
                                                        <td>
                                                            {/* <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_3" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button> */}
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_3'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100004</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>emily taylor</a></td>
                                                        <td>Flat No. 412, Green Acres, Chennai</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td className="textColor text-primary">more info</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_4" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}> send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_4'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100005</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>alfie smith</a></td>
                                                        <td>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            agent
                                                        </td>
                                                        <td className="textColor text-info1 ">received</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_5" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_5'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100006</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>mia evans</a></td>
                                                        <td>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td className="textColor text-info1"></td>
                                                        <td>
                                                            {/* <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_6" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button> */}
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_6'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100007</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>ava brown</a></td>
                                                        <td>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td className="textColor text-info1">received</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_7" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_7'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100008</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>johnathan fowler</a></td>
                                                        <td>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            agent
                                                        </td>
                                                        <td className="textColor text-danger"></td>
                                                        <td>
                                                            {/* <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_8" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button> */}
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_8'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100009</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>isla roberts</a></td>
                                                        <td>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            agent
                                                        </td>
                                                        <td className="textColor icueTextPrimary">sent</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_9" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_9'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100010</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>poppy williams</a></td>
                                                        <td>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td className="textColor icueTextPrimary">sent</td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_10" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle active' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_10'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100011</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>annie rose</a></td>
                                                        <td>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_11" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_11'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100012</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>harry lewis</a></td>
                                                        <td>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_12" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_12'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100013</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>amelia davies</a></td>
                                                        <td>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_13" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_13'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100014</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>emily taylor</a></td>
                                                        <td>Flat No. 412, Green Acres, Chennai</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_14" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_14'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100015</td>
                                                        <td><a href="/AgentCustomer" style={{color:"#1565D8"}}>alfie smith</a>      </td>
                                                        <td>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_15" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_15'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100016</td>
                                                        <td>mia evans</td>
                                                        <td>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_16" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_16'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100017</td>
                                                        <td>ava brown</td>
                                                        <td>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_17" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_17'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100018</td>
                                                        <td>johnathan fowler</td>
                                                        <td>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_18" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_18'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100019</td>
                                                        <td>isla roberts</td>
                                                        <td>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_19" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_19'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100020</td>
                                                        <td>poppy williams</td>
                                                        <td>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_20" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_20'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100021</td>
                                                        <td>annie rose</td>
                                                        <td>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_21" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_21'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100022</td>
                                                        <td>harry lewis</td>
                                                        <td>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_22" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_22'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100023</td>
                                                        <td>amelia davies</td>
                                                        <td>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_23" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_23'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100024</td>
                                                        <td>emily taylor</td>
                                                        <td>Flat No. 412, Green Acres, Chennai</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_24" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_24'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100025</td>
                                                        <td>alfie smith</td>
                                                        <td>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_25" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_25'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100026</td>
                                                        <td>mia evans</td>
                                                        <td>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_26" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_26'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100027</td>
                                                        <td>ava brown</td>
                                                        <td>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_27" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_27'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100028</td>
                                                        <td>johnathan fowler</td>
                                                        <td>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warn</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_28" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_28'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100029</td>
                                                        <td>isla roberts</td>
                                                        <td>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_29" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_29'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100030</td>
                                                        <td>poppy williams</td>
                                                        <td>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_30" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_30'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100031</td>
                                                        <td>annie rose</td>
                                                        <td>Block No. 9/C, Dwaraka, Delhi</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_31" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}> send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_31'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100032</td>
                                                        <td>harry lewis</td>
                                                        <td>Flat No. 928/A, Santacruz, Mumbai</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_32" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_32'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100033</td>
                                                        <td>amelia davies</td>
                                                        <td>HIG-277, Shivaji Nagar, Indore</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_33" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_33'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100034</td>
                                                        <td>emily taylor</td>
                                                        <td>Flat No. 412, Green Acres, Chennai</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_34" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_34'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100035</td>
                                                        <td>alfie smith</td>
                                                        <td>H.NO. 78, Plot No. 405, Burail, Chandigarh</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warn</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_35" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_35'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100036</td>
                                                        <td>mia evans</td>
                                                        <td>H.No. 508, KPHB Colony, Kukatpally, Hyderabad</td>
                                                        <td>car insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_36" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_36'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100037</td>
                                                        <td>ava brown</td>
                                                        <td>Flat No. 214/B, Waghoil, Pune</td>
                                                        <td>health insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_37" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_37'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100038</td>
                                                        <td>johnathan fowler</td>
                                                        <td>Flat.No. 401, Dayai Bagh, Agra</td>
                                                        <td>life insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-warning text-uppercase px-3 py-2 w-75">warm</span>
                                                        </td>
                                                        <td>20-jun-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_38" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_38'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100039</td>
                                                        <td>isla roberts</td>
                                                        <td>Flat No. 302, Indira Nagar, Nashik</td>
                                                        <td>marine insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-danger text-uppercase px-3 py-2 w-75">hot</span>
                                                        </td>
                                                        <td>25-aug-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_39" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_39'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>100040</td>
                                                        <td>poppy williams</td>
                                                        <td>H.NO. 500/3, Rathinapuri, Coimbatore</td>
                                                        <td>fire insurance</td>
                                                        <td className="text-center">
                                                            <span className="badge rounded-pill bg-primary text-uppercase px-3 py-2 w-75">cold</span>
                                                        </td>
                                                        <td>15-feb-2021</td>
                                                        
                                                        <td className="text-center">
                                                            website
                                                        </td>
                                                        <td>
                                                            not sent
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-primary rounded-3 w-100 icueFeedBackBtn text-capitalize" id="sendBtn_40" data-bs-toggle="modal" data-bs-target="#sendRfqModal" style={{lineHeight:"10px"}}>send</button>
                                                        </td>
                                                        <td>
                                                            <button type='button' className='btn btn-sm btn-toggle' data-toggle='button' aria-pressed='false' autocomplete='off' id='toggBtn_40'><div className='handle'></div></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
{/* <!-- Assign Lead To Modal --> */}
<div className="modal fade" id="assignModal" tabindex="-1" aria-labelledby="assignModalLabel" aria-hidden="true">
    {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header py-1 shadow-sm">
                <h5 className="modal-title" id="assignModalLabel">Assign Lead to</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form className="">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 mx-auto">
                            <div className="form-group pb-2">
                                <input className="form-control" type="text" name="icueAssLedToNameCode" placeholder="Enter Name/Code" value="" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 py-3 mx-auto">
                            <div className="d-grid gap-2">
                                <button type="button" data-bs-dismiss="modal" className="btn "  id="icueAssLeadToSubBtn" style={{backgroundColor:"#3A3285"}}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  {/* <!-- /Assign Lead To Modal -->

<!-- Send RFQ Modal --> */}
<div className="modal fade" id="sendRfqModal" tabindex="-1" aria-labelledby="sendRfqModalLabel" aria-hidden="true">
    {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header py-1 shadow-sm">
                <h5 className="modal-title" id="sendModalLabel" style={{fontFamily:"ErasDemiITC"}}>RFQ-Marine Insurance</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form className="">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div className="form-group pb-2" style={{fontStyle:"bold"}}>
                                {/* <!-- <input className="form-control" type="text" name="icueMemEmail" placeholder="Enter Name/Code" value="" /> --> */}
                                {/* <select id="icueRfqSelBox" name="icueRfqSelBox" className="form-select text-capitalize" >
                                    <option value="hdfc ergo">HDFC ERGO</option>
                                    <option value="ICICI Term Life">ICICI Term Life</option>
                                    <option value="royal sundaram">royal sundaram</option>
                                    <option value="sompa">sompa</option>
                                    <option value="SBI general">SBI general</option>
                                </select> */}
                                 Insurance Company
                                <Select isMulti options={Companyname}>
                                 
                                </Select>
                                

                                
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div className="form-group pb-2">
                                <textarea className="form-control" id="icueRFQDesc" rows="3" placeholder="Description of quotation" style={{fontFamily:"Calibri", width:"100%"}}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                    <label for="choose-file" className="custom-file-upload bg-primary text-white mt-4 mb-5 " id="choose-file-label" style={{fontFamily:"Calibri"}}>
                                        Upload Document
                                    </label>
                                    <input name="uploadDocument" type="file" id="choose-file" 
                                        accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png" style={{display: "none"}} />
                                    <div style={{width: "55%", marginTop:"27px"}}>
                                        <input type="text" readonly id="icueRfqUploadedDoc" value="document.pdf" style={{fontFamily:"Calibri", fontSize:"16px", color:"#494949"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-3 mx-auto">
                            <div className="d-grid gap-2">
                                <button type="button" className="btn btn-primary " style={{marginTop:"-50px"}}>
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
{/* <!-- /Send RFQ Modal --> */}
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    </div>)
}