import React from "react";

const ReadOnlyRow1 = ({ contact, handleEditClick , handleDeleteClick }) => {
  return (
    <tr>
      <td className="text-left">{contact.namee}</td>
      <td className="text-left">{contact.category}</td>
      <td className="text-right">{contact.amount}</td>
     
      <td className="text-right">
        <button className="btn btn-sm btn-transparent " style={{marginLeft:"120px", marginTop:"-15px"}}
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
         <i className='bi bi-pencil'></i>
        </button>
        <button className="btn btn-sm btn-transparent "  style={{ marginLeft:"-30px", marginTop:"-15px"}}
        type="button" onClick={() => handleDeleteClick(contact.id)}>
       <i className='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow1;