import axios from "axios";
import { useDispatch } from "react-redux";
import Constant from "../utils/constant";


const SubmitQuery = (firstName, lastName, mobileNo, emailId, url) => {

    return fetch(Constant.BASE_URL + url,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
                firstName,
                lastName,
                mobileNo,
                emailId
            })
        }
    )


    // return axios.post(Constant.BASE_URL + url, {
    //     firstName,
    //     lastName,
    //     mobileNo,
    //     emailId
    // },
    //     // {
    //     //     headers: {
    //     //         'Access-Control-Allow-Origin': '*',
    //     //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     //     }
    //     // }
    // )
    //     .then((response) => {
    //         if (response.data.data) {
    //             console.log(response.data.message);
    //         }
    //         else {
    //             alert(response.data.message);
    //         }

    //         return response.data.data;
    //     }).catch((e) => {
    //         console.log(e);
    //     })
};
export default SubmitQuery;