import React, { useState, Fragment } from "react";
import './Policies.css';
// import { nanoid } from "nanoid";
// import data from "./OtherPlatform/mock-data.json";
// import ReadOnlyRow from "./OtherPlatform/ReadOnlyRow";
// import EditableRow from "./OtherPlatform/EditableRow";
const Policies=()=>{  
//     const [contacts, setContacts] = useState(data);
//   const [addFormData, setAddFormData] = useState({
//     policynumber: "",
//     policyname: "",
//    insurancetype: "",
//    policypremium:"",
//    risk:"",
//    expiry:"",
//    auto:""
    
//   });
//   const [editFormData, setEditFormData] = useState({
//     policynumber: "",
//     policyname: "",
//    insurancetype: "",
//    policypremium:"",
//    risk:"",
//    expiry:"",
//    auto:""
//   });
//   const [editContactId, setEditContactId] = useState(null);

//   const handleAddFormChange = (event) => {
//     event.preventDefault();

//     const fieldName = event.target.getAttribute("name");
//     const fieldValue = event.target.value;

//     const newFormData = { ...addFormData };
//     newFormData[fieldName] = fieldValue;

//     setAddFormData(newFormData);
//   };
//   const handleEditFormChange = (event) => {
//     event.preventDefault();

//     const fieldName = event.target.getAttribute("name");
//     const fieldValue = event.target.value;

//     const newFormData = { ...editFormData };
//     newFormData[fieldName] = fieldValue;

//     setEditFormData(newFormData);
//   };
//   const handleAddFormSubmit = (event) => {
//     event.preventDefault();
//     const newContact = {
//         id: nanoid(),
       
//         policynumber: addFormData.policynumber,
//         policyname:addFormData.policyname,
//        insurancetype: addFormData.insurancetype,
//        policypremium:addFormData.policypremium,
//        risk:addFormData.risk,
//        expiry:addFormData.expiry,
//        auto:addFormData.auto
//       };
  
//       const newContacts = [...contacts, newContact];
    
//       setContacts(newContacts);
//     };
//     const handleEditFormSubmit = (event) => {
//         event.preventDefault();
    
//         const editedContact = {
//           id: editContactId,
//          policynumber: editFormData.policynumber,
//         policyname: editFormData.policyname,
//           insurancetype: editFormData.insurancetype,
//           policypremium:editFormData.policypremium,
//           risk:editFormData.risk,
//           expiry:editFormData.expiry,
//           auto:editFormData.auto
         
//         };
    
//         const newContacts = [...contacts];
    
//         const index = contacts.findIndex((contact) => contact.id === editContactId);
    
//         newContacts[index] = editedContact;
    
//         setContacts(newContacts);
//         setEditContactId(null);
//       };
//       const handleEditClick = (event, contact) => {
//         event.preventDefault();
//         setEditContactId(contact.id);
    
//         const formValues = {
//           policynumber: contact.policynumber,
//           policyname: contact.policyname,
//           insurancetype:contact.insurancetype,
//           policypremium:contact.policypremium,
//           risk:contact.risk,
//           epxiry:contact.expiry,
//           auto:contact.auto
         
//         };
    
//         setEditFormData(formValues);
//       };
//       const handleCancelClick = () => {
//         setEditContactId(null);
//       };
//       const handleDeleteClick = (contactId) => {
//         const newContacts = [...contacts];
    
//         const index = contacts.findIndex((contact) => contact.id === contactId);
    
//         newContacts.splice(index, 1);
    
//         setContacts(newContacts);
//       };
    return(<div className="Policies" id="Policies">
          <div className="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className="icueMail px-2">
                        <i className="bi bi-headset"></i>&nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap" id="icueOpenIcon"></i>
                        <i className="bi bi-x-lg" id="icueCloseIcon"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem ">
                                    <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>

                                <li className="icueNavBarItem active">
                                    <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                                </li>                               
                                 <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill" ></i></a>
                                </li>
                                <hr></hr>
                                <li className="icueNavBarItem border-bottom-0">
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                                <hr></hr>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem ">
                                <a href="/RetailDashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem active">
                                <a href="/Policies" className="icueNavBarLink" id="">Policies</a>
                            </li>
                          
                            
                            <li className="icueNavBarItem danger">
                                <a href="#" className="icueNavBarLink" id="">Claims <i className="bi bi-star-fill" ></i></a>
                            </li>
                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-0.625rem"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar" style={{marginTop:"-0.75rem"}}>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="text-captalize"style={{fontFamily:"ErasDemiITC", fontWeight:"bold", marginTop:"1rem", marginLeft:"12px"}}>Policies</h4>
                                <div className="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri", fontSize:"bold"}}><a href="/RetailDashboard" className="text-decoration-none text-info">Dashboard</a></li>
                                        {/* <li className="breadcrumb-item bg-transparent" style={{fontFamily:"Calibri"}}><a href="/lms" className="text-decoration-none text-info">Policies</a></li> */}
                                        <li className="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page" style={{  fontSize:"bold"}}>policies</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div className="bg-white p-2  rounded-3 shadow-sm">
                                <div className="px-2 py-3">                                


<div className="container1">
<div className="row">
<div className=" col-xs-12 bg-white ">
<ul className="nav nav-tabs" id="myTab" role="tablist">
<li className="nav-item">
<a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All Policies</a>
</li>
<li className="nav-item">
<a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">PoliciCue</a>
</li>
<li className="nav-item">
<a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Other Platform</a>
</li>
</ul>
</div>
<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
<div className="tab-content py-1 px-3 px-sm-0" id="myTabContent">
<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
<div className="row my-2" >
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0 mb-0">
                            <div className=" p-2  rounded-3 shadow-sm" style={{marginLeft:"-1rem", marginRight:"-1rem", backgroundColor:"#F1F2F6",marginTop:"-10px"}}>
                                <div className="px-4 py-1">
                                    <div className="row">                                       
                                        <div className="row" style={{marginTop:"-45px"}}>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"52px"}}>
                                   <div className="icueSearchBox w-75 mx-3 " style={{marginLeft:"10px"}}>
                                      <div className="input-group ">
                                      <span className="input-group-text bg-white" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="search form-control icueSearchBoxControl bg-white" placeholder="Search by Policy No., Insurance Type, Company Name" aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div>
                                            
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"118px"}}>
                                            <label className="text-muted" for=""></label>
                                                 <br/>
                                                <div className="form-group pb-0 mb-0" >
                                                <a href="/AddNew" type="button" className="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"-3px", marginLeft:"300px", width:"100px"}}> Add New</a>

                                                
                                                </div>             
                                    
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
<div className="row">              
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">      
                               
                                           
                                            <table id="policyTable" className="table results " width="auto" >
                                                <thead>
                                                    <tr className="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal", textAlign:"left", fontSize:"12px"}}>
                                                        <th style={{width:"12%"}}>Policy number</th>
                                                        <th  width="22%">policy name</th>
                                                        <th Width="18%" >insurance type</th>
                                                        <th width="18%">policy premium</th>
                                                        <th width="15%">risk start date</th>
                                                        <th width="20%">expiry/ Maturity dt.</th>
                                                        <th width="8%">auto renew</th>
                                                        <th className="text-center" width="28%">action</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    <tr>
                                                        <td >54654556878787</td>
                                                         <td> HDFC Argo <div style={{color:"#242424"}}> Insured Declared Value (IDV) Rs. 30,00,000</div> </td>
                                                        <td className="wrap">Life Insurance</td>
                                                        <td  >Rs. 5,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           25-Sep-2011
                                                        </td>
                                                        <td  >25-Sep-2021</td>
                                                        <td>
                                                                                                            
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/PayInstallment" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Pay Installment</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_1" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                         </td>                                                        
                                                    </tr>

                                                    <tr>
                                                        <td >44848998898985</td>
                                                         <td> ICICI <br></br> Insured Declared Value (IDV) Rs. 100,00,000 </td>
                                                        <td className="wrap">Health Insurance</td>
                                                        <td  >Rs. 1,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                          14-Nov-2020
                                                        </td>
                                                        <td  >14-Nov-2021</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>
                                                           
                                                        </td>
                                                        
                                                        <td className="text-center ">
                                                        <a href="/RenewPolicy" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_2" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>   
                                                                                                             
                                                    </tr>
                                                    <tr>
                                                        <td >789151120320323</td>
                                                         <td> Shriram <br></br> Insured Declared Value (IDV) Rs. 5,00,000 </td>
                                                        <td className="wrap">Two-wheeler Insurance</td>
                                                        <td  >Rs. 3,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           30-Oct-2020
                                                        </td>
                                                        <td  > 30-Oct-2021</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>

                                                            
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/Renew2" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_3" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>                                                          
                                                    </tr>
                                                    <tr>
                                                        <td >789151120320323</td>
                                                         <td> TATA AIG <br></br> Insured Declared Value (IDV) Rs. 5,00,000 </td>
                                                        <td className="wrap">Car Insurance</td>
                                                        <td  >Rs. 8,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           27-July-2021
                                                        </td>
                                                        <td  >27-July-2022</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>                                                        
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/Renew3" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_4" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>                                                        
                                                    </tr> 
                                                      
                                                    <tr>
                                                        <td >845689</td>
                                                         <td> aviva <br></br> Insured Declared Value (IDV) Rs. 25,00,000 </td>
                                                        <td className="wrap">Life Insurance</td>
                                                        <td  >Rs. 5,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           25-Sep-2011
                                                        </td>
                                                        <td  >25-Sep-2035</td>
                                                        <td>                                                        
                                                            
                                                        </td>
                                                        <td className="text-center">                                                           
                                                        <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="/EditPolicy"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> 
                                                        </td>                                                        
                                                    </tr>
                                                    <tr>
                                                        <td >123456</td>
                                                         <td> Max New York <br></br> Insured Declared Value (IDV) Rs. 100,00,000 </td>
                                                        <td className="wrap">Health Insurance</td>
                                                        <td  >Rs. 2,2120 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           14-Mar-2021
                                                        </td>
                                                        <td  >14-Mar-2022</td>
                                                        <td>                                                      
                                                        </td>
                                                        <td className="text-center">                                                            
                                                        <button type='button' className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="/Edit2"><i className='bi bi-pencil'></i></a></button> <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='deleteid' style={{marginLeft:"-30px"}}><i className='bi bi-trash' ></i></button> 
                                                        </td>                                                        
                                                    </tr>                               
                                                                     
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>   
                                     
</div>
<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
<div className="row my-2" >
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0 mb-0">
                            <div className=" p-2  rounded-3 shadow-sm" style={{marginLeft:"-1rem", marginRight:"-1rem", backgroundColor:"#F1F2F6",marginTop:"-10px"}}>
                                <div className="px-4 py-1">
                                    <div className="row">                                       
                                        <div className="row" style={{marginTop:"-45px"}}>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"52px"}}>
                                   <div className="icueSearchBox w-75 mx-3 " style={{marginLeft:"10px"}}>
                                      <div className="input-group ">
                                      <span className="input-group-text bg-white" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="search form-control icueSearchBoxControl bg-white" placeholder="Search by Policy No., Insurance Type, Company Name" aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div>
                                            
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"118px"}}>
                                            <label className="text-muted" for=""></label>
                                                 {/* <br/>
                                                <div className="form-group pb-0 mb-0" >
                                                <a href="#" type="button" className="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"15px", marginLeft:"300px", width:"100px"}}> Add New</a>

                                                
                                                </div>              */}
                                    
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
<div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">                                      
                                           
                                            <table id="policyTable" className="table results " width="auto" >
                                                <thead>
                                                    <tr className="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal", textAlign:"left", fontSize:"12px"}}>
                                                        <th style={{width:"12%"}}>Policy number</th>
                                                        <th width="22%">policy name</th>
                                                        <th Width="18%" >insurance type</th>
                                                        <th width="18%">policy premium</th>
                                                        <th width="15%">risk start date</th>
                                                        <th width="20%">expiry/ Maturity dt.</th>
                                                        <th width="8%">auto renew</th>
                                                        <th style={{textAlign:"center", width:"28%"}}>action</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri",fontSize:"16px"}}>
                                                <tr>
                                                        <td >54654556878787</td>
                                                         <td> HDFC Argo <br></br> Insured Declared Value (IDV) Rs. 30,00,000 </td>
                                                        <td className="wrap">Life Insurance</td>
                                                        <td  >Rs. 5,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           25-Sep-2011
                                                        </td>
                                                        <td  >25-Sep-2021</td>
                                                        <td>
                                                                                                            
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/PayInstallment" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Pay Installment</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_1" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                         </td>                                                        
                                                    </tr>

                                                    <tr>
                                                        <td >44848998898985</td>
                                                         <td> ICICI <br></br> Insured Declared Value (IDV) Rs. 100,00,000 </td>
                                                        <td className="wrap">Health Insurance</td>
                                                        <td  >Rs. 1,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                          14-Nov-2020
                                                        </td>
                                                        <td  >14-Nov-2021</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>
                                                           
                                                        </td>
                                                        
                                                        <td className="text-center ">
                                                        <a href="/RenewPolicy" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_2" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>   
                                                                                                             
                                                    </tr>
                                                    <tr>
                                                        <td >789151120320323</td>
                                                         <td> Shriram <br></br> Insured Declared Value (IDV) Rs. 5,00,000 </td>
                                                        <td className="wrap">Two-wheeler Insurance</td>
                                                        <td  >Rs. 3,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           30-Oct-2020
                                                        </td>
                                                        <td  > 30-Oct-2021</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>

                                                            
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/Renew2" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_3" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>                                                          
                                                    </tr>
                                                    <tr>
                                                        <td >789151120320323</td>
                                                         <td> TATA AIG <br></br> Insured Declared Value (IDV) Rs. 5,00,000 </td>
                                                        <td className="wrap">Car Insurance</td>
                                                        <td  >Rs. 8,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           27-July-2021
                                                        </td>
                                                        <td  >27-July-2022</td>
                                                        <td>
                                                        <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="false" autocomplete="off" id="toggBtn_1"><div className="handle"></div></button>                                                        
                                                        </td>
                                                        <td className="text-center ">
                                                        <a href="/Renew3" type="button" className="btn icueBgInfo text-white mb-0 mt-0 "  style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", fontSize:"bold", lineHeight:"12px"}}>Renew Policy</a> <br></br>
                                                        <a href="#" type="button" className="btn icueBgPrimary text-white icueGetPolicy  mb-1 mt-1 " id="getPoliicy_4" data-bs-toggle="modal"  data-bs-target="#getPolicyModal" style={{fontFamily:"Calibri", color:"white", width:"120px", height:"35px", lineHeight:"12px"}}>Get Policy</a>
                                                        </td>                                                        
                                                    </tr>  
                                                                                
                                                                     
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>            
</div>
<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
<div className="row my-2" >
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0 mb-0">
                            <div className=" p-2  rounded-3 shadow-sm" style={{marginLeft:"-1rem", marginRight:"-1rem", backgroundColor:"#F1F2F6",marginTop:"-10px"}}>
                                <div className="px-4 py-1">
                                    <div className="row">                                       
                                        <div className="row" style={{marginTop:"-45px"}}>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{marginTop:"52px"}}>
                                   <div className="icueSearchBox w-75 mx-3 " style={{marginLeft:"10px"}}>
                                      <div className="input-group ">
                                      <span className="input-group-text bg-white" id="basic-addon1">
                                         <i className="bi bi-search"></i>
                                      </span>
                                         <input type="search" className="search form-control icueSearchBoxControl bg-white" placeholder="Search by Policy No., Insurance Type, Company Name" aria-label="Username" aria-describedby="basic-addon1"/>
                                       </div>
                                   </div>
                                </div>
                                            
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-4" style={{marginTop:"10px", marginLeft:"118px"}}>
                                            <label className="text-muted" for=""></label>
                                                 <br/>
                                                <div className="form-group pb-0 mb-0" >
                                                <a href="/AddNew" type="button" className="btn icueBgPrimary text-white  rounded-3 w-78  "  style={{fontFamily:"Calibri", color:"white", fontSize:"bold", height:"35px", lineHeight:"10px", marginTop:"-3px", marginLeft:"300px", width:"100px"}}> Add New</a>

                                                
                                                </div>             
                                    
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                   <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">                             
                                           {/* <form onSubmit={handleEditFormSubmit}> */}
                                            <table id="policyTable" className="table  table-hover results " width="auto" >
                                                <thead>
                                                    <tr className="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal", textAlign:"left",fontSize:"12px"}}>
                                                        <th style={{width:"12%"}}>Policy number</th>
                                                        <th style={{width:"15%", textAlign:"left"}}>policy name</th>
                                                        <th style={{textAlign:"left"}} >insurance type</th>
                                                        <th style={{textAlign:"left"}}>policy premium</th>
                                                        <th style={{textAlign:"left"}}>risk start date</th>
                                                        <th style={{textAlign:"left"}}>expiry/ Maturity dt.</th>
                                                        <th style={{textAlign:"center"}}>auto renew</th>
                                                        <th style={{textAlign:"center"}}>action</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", textAlign:"left",fontSize:"16px"}}>                                    
                                               
                                                    
                                                 <tr>
                                                        <td >845689</td>
                                                         <td> aviva <br></br> Insured Declared Value <br></br>(IDV) Rs. 25,00,000 </td>
                                                        <td className="wrap">Life Insurance</td>
                                                        <td  >Rs. 5,630 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           25-Sep-2011
                                                        </td>
                                                        <td  >25-Sep-2035</td>
                                                        <td>                                                        
                                                            
                                                        </td>
                                                        <td className="text-center">
                                                           
                                                        <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="/EditPolicy"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow ' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> 
                                                        </td>                                                        
                                                    </tr>
                                                    <tr>
                                                        <td >123456</td>
                                                         <td> Max New York <br></br> Insured Declared Value <br></br> (IDV) Rs. 100,00,000 </td>
                                                        <td className="wrap">Health Insurance</td>
                                                        <td  >Rs. 2,2120 <br></br>Monthly</td>
                                                        <td className="text-center">
                                                           14-Mar-2021
                                                        </td>
                                                        <td  >14-Mar-2022</td>
                                                        <td>                                                      
                                                        </td>
                                                        <td className="text-center">                                                            
                                                        <button type='button' className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="/Edit2"><i className='bi bi-pencil'></i></a></button> <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='deleteid' style={{marginLeft:"-30px"}}><i className='bi bi-trash' ></i></button> 
                                                        </td>                                                        
                                                    </tr>                                  
                                                         {/* {contacts.map((contact) => (
              <Fragment>
                {editContactId === contact.id ? (
                  <EditableRow
                  
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                   
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}               */}
                                                </tbody>
                                            </table>
                                            {/* </form> */}
                                        </div>
                                    </div> 
</div>
</div>
</div>
</div>
</div>

        </div>
    </div>
</div>
</div>

{/* <!-- Assign Lead To Modal --> */}
<div className="modal fade" id="assignModal" tabindex="-1" aria-labelledby="assignModalLabel" aria-hidden="true">
    {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header py-1 shadow-sm">
                <h5 className="modal-title" id="assignModalLabel">Assign Lead to</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{backgroundColor: "#edf5fb"}}>
                <form className="">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 mx-auto">
                            <div className="form-group pb-2">
                                <input className="search form-control" type="text" name="icueAssLedToNameCode" placeholder="Enter Name/Code" value="" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 py-3 mx-auto">
                            <div className="d-grid gap-2">
                                <button type="button" className="btn btn-primary btnIcon" id="icueAssLeadToSubBtn">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  {/* <!-- /Assign Lead To Modal -->

<!-- Send RFQ Modal --> */}
<div className="modal fade " id="getPolicyModal" tabindex="-1" aria-labelledby="getPolicyModalLabel" aria-hidden="true" style={{marginTop:"170px"}}> 
    {/* <!-- <div className="modal-dialog modal-dialog-centered"> --> */}
    <div className="modal-dialog modal-sm rounded-circle" >
        <div className="modal-content" style={{borderRadius:"12px"}} >
            {/* <div className="modal-header ">                */}
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{marginLeft:"265px"}}></button>
            {/* </div> */}
            <div className="modal-body " style={{backgroundColor: "white"}}>                
                    <div className="row">                        
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
                            <div className="form-group pb-2">
                            <h2 ><i className="bi bi-check2-circle fas fa-lg" style={{marginLeft:"80px", color:"#00A0E4"}}></i></h2>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto" style={{marginTop:"-15px"}}>
                            <div className="mb-4">
                                <div className="d-flex justify-content-between" >
                                    <label className="" style={{textAlign:"center", fontFamily:"ErasDemiITC", color:"black"}}>
                                       Policy copy sent to your registered email id.
                                    </label>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
               
            </div>
        </div>
    </div>
</div>

{/* <!-- Delete Details Modal --> */}
<div className="modal fade" id="deleteid" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content  rounded-3">
            <div className="modal-header d-flex flex-column" id="deleteModalHeader">
                <div className="text-end w-100">
                    <button type="button" className="btn text-muted" data-bs-dismiss="modal"><i className="bi bi-x-circle"></i></button>
                </div>
                <div className="text-center">
                    <h4 className="modal-title">Delete Member</h4>
                </div>
            </div>
            <div className="modal-body" id="deleteModalBody">
                <form className="">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <h4 className="text-danger text-center">Are you sure, delete this row</h4>  
                            </div>
                        </div>
                        
                        <div className="col-12">
                            <div className="text-center">
                                <button type="button" className="btn btn-danger">Delete</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                                    <div className="pe-2" style={{ alignItems:"right"}}>
                                        <img src="images/Help.png" alt="help" className="w-1 rounded-circle" style={{marginLeft:"970px", height:"125px", marginTop:"-85px"}}/>
                                        </div>                                   
                                    </div> 
{/* <!-- /Send RFQ Modal --> */}
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="bg-white p-2 rounded-3 shadow-sm">
                                
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
    </div>)
}
export default Policies;
