import { Link } from "react-router-dom";

const Header = ({
  title = "",
  btn = false,
  btnText = "",
  btnLink = "",
  btnClass = "",
  children
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center pb-4 pt-4 header">
      <h4 className="mainHeading" style={{color:"#334d6e", fontWeight:"bold", marginBottom:"0"}}>{title}</h4>
      {btn && (
        <Link to={btnLink} className={`btn btn-primary addbtn ${btnClass}`} style={{fontFamily:"ErasDemiITC", marginRight:"20px"}}>
          {children} {btnText}
        </Link>
      )}
    </div>
  );
};

export default Header;
