import React from 'react'

function Terms() {
    return (
        <div>
            <h3 className='heading' style={{paddingRight:"2rem"}}>TERMS AND CONDITIONS</h3>
            <p className='text' style={{paddingRight:"2rem"}}>
                Following are the Terms of Usage/ Service for PoliciCue’s services (“Service,” “PoliciCue” “we,” or “us”).  These terms and conditions govern your use of Service at PoliciCue’s Application (“App”) or Website (“Web”) (collectively as “Platform”); by using the Services on any Platform, you accept these terms and conditions in full.   If you disagree with these terms and conditions or any part of these terms and conditions, you must not use the Services. <br /><br />

                The provision of Services (whether aap or web) uses cookies.  By using this Service and agreeing to these terms and conditions, you consent to our PoliciCue’s use of cookies in accordance with the terms of PoliciCue’s privacy policy.<br /><br />

                By using the Service in any way, you are agreeing to comply with and be bound by this Privacy Policy and Terms of Usage, as applicable (collectively “Related Agreements”), and all rules, policies and disclaimers posted on the Service or about which you are notified (collectively “Terms”). If you do not agree with all the Terms, or if you are under the age of Eighteen (18), do not use the Service. Please review all of the Terms carefully before using the Service.<br /><br />

                By using the Service, you (i) agree to be bound by the Terms and (ii) represent that you are over the age of Eighteen (18) and able to form legally binding contracts.<br /><br />

                Please note that these Terms provide that if you and PoliciCue are unable to resolve any disputes that arise between you and PoliciCue, the dispute will be resolved by a specific dispute resolution process that may include arbitration. Arbitration is binding and is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. You also agree that any claims or disputes cannot be brought as a class action. Please carefully review the Dispute Resolution section.

                <br /><br />
            </p>


            <h5 className='heading' style={{paddingRight:"2rem"}}>License to use application and website</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                IPI: We may collect personal information from you under circumstances, when you provide such information voluntarily at our website and application, such as, <br />
            </p>
            <p className='text'style={{paddingRight:"2rem"}}>
                Unless otherwise stated, PoliciCue and/or its licensors own the intellectual property rights in the Platform and material on the Platform.  Subject to the license below, all these intellectual property rights are reserved.<br /><br />

                PoliciCue hereby grants you a non-exclusive, non-transferable subscription to use the Service during the Term, solely for your personal/ internal use in accordance with the use parameters described in the order form utilized to order such subscription and subject to these Terms of Service.<br /><br />

                You shall not modify, reverse engineer, decompile, disassemble, or attempt to derive the source code of PoliciCue web Service, or assist any other person or entity in doing so. You acknowledge that all content, including but not limited to text, software, music, sound, photographs, video, graphics or other material contained in listings, sponsor advertisements or email-distributed, commercially produced information presented to you by the Service, by PoliciCue, Users, or other content providers, is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute in any way content available through the Service, including code and software for commercial purposes. For permission to use third-party materials appearing on the Service, please contact the copyright owner. You do not acquire ownership rights to any content, document or other materials viewed through the Service. The posting of information or materials on the Service does not constitute a waiver of any right in such information and materials.<br /><br />

                User may categorically note that any use or reliance on any content posted or obtained from usage is completely at your own risk and consequences. PoliciCue do not subscribe, endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content or communications posted or endorse any opinions expressed herein. User must know that they may be exposed to Content that might be unpleasant, offensive, harmful, inaccurate, deceptive, frivolous, fake or otherwise inappropriate. All Content is the sole responsibility of the person who originated, posted, left or published in any manner such Content. PoliciCue may not monitor or control the content posted via the Services and, therefore cannot, in any manner, take responsibility for such content.<br /><br />

                We reserve the right to remove Content that violates the User Agreement, including but not limited to intellectual property violation, infringement, misappropriation, impersonation, unlawful conduct, or harassment. User may report any such content by email at info@policicue.com.<br /><br />


                You grant to PoliciCue a non-exclusive, irrevocable, perpetual, worldwide, royalty-free, sublicensable (through multiple tiers) license to use, reproduce, display, edit, copy, modify, transmit, publicly perform, extract, analyse, research and create derivative works from, content that you have provided or have left in your Posts, in any media now known or not currently known.<br /><br />

                This licensed right may be exercised in order to provide the Service and for other purposes.<br /><br />

                You are consciously aware that while using PoliciCue, you are solely responsible for your act, omission or conduct and PoliciCue shall be responsible in any manner whatsoever. You shall be solely liable for any violation of any applicable central or state laws. You undertake to unconditionally indemnify both PoliciCue against any loss, liability and consequences arising out of your act or omission.<br /><br />

                By using PoliciCue to collect data from and optimize your social media channels or with any third party, you’re also agreeing to be bound by their Terms of Service.
            </p>

            <p className='text'style={{paddingRight:"2rem"}}>
                PoliciCue Services are intended for use by persons over 18 years old. In addition, it does not knowingly solicit or collect IPI from persons under 18 years old. We may delete from the Service IPI, as well as Posts and other information, from users we believe are under the age of 18. <br /><br />

                To enhance and individualize your experience with the Service, we may use small files called “cookies” on our websites that let us know that you are a prior user and help us ensure that your information is directed to you. We also use services on the web that help us improve your customer experience. We do not use these services to collect information about you for any other purpose.
            </p><br />


            <h5 className='heading' style={{paddingRight:"2rem"}}>Setting up Your Account</h5>

            <p className='text' style={{paddingRight:"2rem"}}>You must provide accurate and complete information, including your legal full name, a working email address, and any other information requested during the account signup process to obtain an account and use the Service, and update this information if it changes.<br /><br />

                You must be a human being to set up an account and use the Service. Accounts may not be created by “bots” or other automated methods.<br /><br />

                You are responsible for keeping your account and password secure, and are also responsible for all activities using your account or password. PoliciCue is not liable for any loss or damage that results from your failure to comply with this obligation or unauthorized use of your account.<br /><br />

                You may never use another’s account without permission.<br /><br />

                You must notify PoliciCue immediately of any breach of security or unauthorized use of your account.<br /><br />

                You must inform PoliciCue’s Admin immediately about any sort of harassment, abuse, verbal abuse or trolling etc. However, under any circumstances the user cant held PoliciCue liable for any of aforesaid consequences.<br /><br />
            </p>

            <h5 className='heading' style={{paddingRight:"2rem"}}>Fees and Payment</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                Services offered herein by PoliciCue is free of cost and no payment of any sort is being solicited or provided for the services / tools offered in the PoliciCue sessions. However, in future with due intimation, PoliciCue may introduce certain paid services/ features.<br /><br />

                PoliciCue shall not be liable to you or to any third party for any modifications to the Service or if it opts to charge prices in future.

            </p><br /><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>Cancellation and Termination</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                You are solely responsible for properly closing your account.<br /><br />

                You can close your account at any time by emailing us at info@policicue.com.<br /><br />


                PoliciCue Services are free at  this moment, however, as and when paid services are introduced, Payments made will be applicable for the upcoming billing cycle. PoliciCue does not provide pro-rated refunds for accounts that are cancelled during their subscription term.<br /><br />
            </p>


            <h5 className='heading' style={{paddingRight:"2rem"}}>Acceptable use</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                You must not use this Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or accessibility of the Platform; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.<br /><br />

                You must not use this Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.<br /><br />

                You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this Platform without PoliciCue’s express written consent.<br /><br />

                You must not use this Platform to transmit or send unsolicited commercial communications.<br /><br />

                PoliciCue uses all applicable data protection tools and techniques and is compliant with data protection requirement as per Indian Laws (Information Technology Act, 2000) and the terms of usage is bound to be updated/ amended periodically. As and when terms are amended, updated, an intimation will be sent and even otherwise any subsequent usage of PoliciCue post amendment shall be considered as deemed acceptance. User are advised to check the terms of usage from time to time and shall use PoliciCue only upon acceptance of the terms of usage.<br /><br />
            </p>


            <h5 className='heading' style={{paddingRight:"2rem"}}>Exclusion of Warranties</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                PoliciCue services and related documentation are provided “as is” and without any warranty of any kind either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. No information, whether oral or written, obtained by you from us through this service shall create any warranty, representation or guarantee not expressly stated in these terms. PoliciCue does not represent or warrant that the service will be uninterrupted or error-free, that defects will be corrected, or that this service or the server that makes it available, are free of viruses or other harmful components. PoliciCue does not warrant or represent that the use or the results of the use of the materials available through the service, from third parties or a linked service will be correct, accurate, timely, reliable or otherwise. Some states or other jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to you. You may also have other rights that vary from state to state and jurisdiction to jurisdiction.  However, any action against PoliciCue is unequivocally subject to Dispute Resolution as stated below and to which the User unconditionally agrees. Further, use of PoliciCue services is strictly as per Indian Jurisdiction and therefore PoliciCue shall not be liable in any manner whatsoever for any usage or law outside Indian Jurisdiction. User is strictly cautioned to check their respective jurisdiction and seek necessary legal advice.<br /><br />

                Under no circumstances will PoliciCue be liable for any loss or damage caused by a user’s reliance on information obtained through the service, from third parties (such as professionals or others) or a linked service, or user’s reliance on any product or service obtained from a third party or a linked service. Use of this service is at users’ sole risk.<br /><br />

                No advice or information, whether oral or written, obtained by you from PoliciCue or through or from the PoliciCue services shall create any warranty.
            </p>



            <h5 className='heading' style={{paddingRight:"2rem"}}>Restricted access</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                Access to certain areas of this Platform is restricted.  PoliciCue reserves the right to restrict access to areas of this Platform, or indeed this entire Platform, at PoliciCue’s discretion.<br /><br />

                If PoliciCue provides you with a user ID and password to enable you to access restricted areas of this Platform or other content or services, you must ensure that the user ID and password are kept confidential.<br /><br />

                PoliciCue may disable your user ID and password in PoliciCue’s sole discretion without notice or explanation.
            </p>

            <h5 className='heading' style={{paddingRight:"2rem"}}>Limitations of liability</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                PoliciCue will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this Platform:<br /><br />
            </p>

            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>For any direct loss;</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21 }} />
                <p className='points'>For any indirect, special or consequential loss; or</p>
            </div><br />
            <div className='row_container'>
                <img src="images/Ellipse35.png" alt="circle" style={{ width: 21,  }} />
                <p className='points'style={{paddingRight:"2rem"}}>For any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</p>
            </div><br />

            <p className='text'style={{paddingRight:"2rem"}}>These limitations of liability apply even if PoliciCue has been expressly advised of the potential loss.</p><br />


            <h5 className='heading' style={{paddingRight:"2rem"}}>Reasonableness</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                By using this Platform, you agree that the exclusions and limitations of liability set out in this Platform disclaimer are reasonable.<br /><br />

                If you do not think they are reasonable, you must not use this Platform.
            </p><br />


            <h5 className='heading' style={{paddingRight:"2rem"}}>Other parties</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                You accept that, as a limited liability entity, PoliciCue has an interest in limiting the personal liability of its officers and employees.  You agree that you will not bring any claim personally against PoliciCue’s officers or employees in respect of any losses you suffer in connection with the Platform.<br /><br />

                You agree that the limitations of warranties and liability set out in this Platform disclaimer will protect PoliciCue’s officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as PoliciCue.
            </p><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>Unenforceable provisions</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                If any provision of this Platform disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this Platform disclaimer.
            </p><br />

            <h5 className='heading'style={{paddingRight:"2rem"}}>Indemnity</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                You hereby indemnify PoliciCue and undertake to keep PoliciCue indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by PoliciCue to a third party in settlement of a claim or dispute on the advice of PoliciCue’s legal advisers) incurred or suffered by PoliciCue arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.
            </p><br />

            <h5 className='heading'style={{paddingRight:"2rem"}}>Breaches of these terms and conditions</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                Without prejudice to PoliciCue’s other rights under these terms and conditions, if you breach these terms and conditions in any way, PoliciCue may take such action as PoliciCue deems appropriate to deal with the breach, including suspending your access to the Platform, prohibiting you from accessing the Platform, blocking computers using your IP address from accessing the Platform, contacting your internet service provider to request that they block your access to the Platform and/or bringing court proceedings against you.
            </p><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>Variation</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                PoliciCue may revise these terms and conditions from time-to-time.  Revised terms and conditions will apply to the use of this Platform from the date of the publication of the revised terms and conditions on this Platform.  Please check this page regularly to ensure you are familiar with the current version.
            </p><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>Dispute Resolution</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                Any dispute or differences arising from the Services availed herein or any connected issue shall be referred to adjudication by a sole arbitrator. PoliciCue shall be entitled to nominate the sole arbitrator to adjudicate any dispute differences or claims arising out of this Service, the venue of arbitration shall be at NOIDA and courts at NOIDA, UP shall have the exclusive jurisdiction for all claims arising from this consignment. No other venue for arbitration shall be accepted. User of the service agrees and accepts the same unconditionally.
            </p><br />

            <h5 className='heading'style={{paddingRight:"2rem"}}>Assignment</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                PoliciCue may transfer, sub-contract or otherwise deal with PoliciCue’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.<br /><br />

                You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.
            </p><br />

            <h5 className='heading'style={{paddingRight:"2rem"}}>Severability</h5>

            <p className='text' style={{paddingRight:"2rem"}}>
                If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.  If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.
            </p><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>Entire agreement</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                These terms and conditions constitute the entire agreement between you and PoliciCue in relation to your use of this Platform, and supersede all previous agreements in respect of your use of this Platform.
            </p><br />

            <h5 className='heading' style={{paddingRight:"2rem"}}>PoliciCue’s details</h5>

            <p className='text'style={{paddingRight:"2rem"}}>
                PoliciCue is a service offered by PoliciCue Insurance Brokers Private Limited.<br/><br/>

                You can contact PoliciCue by email at info@policicue.com.
            </p><br />


        </div>
    )
}

export default Terms
