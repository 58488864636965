import React from "react";

const ReadOnlyRow = ({ contact, handleEditClick , handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.namee}</td>
      <td className="text-center">{contact.name1}</td> 
      <td className="text-center">{contact.day}</td>
     
      <td>
        <button className="btn btn-sm btn-transparent " style={{marginLeft:"30px", marginTop:"-15px"}}
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        >
         <i className='bi bi-pencil'></i>
        </button>
        <button className="btn btn-sm btn-transparent "  style={{ marginLeft:"-20px", marginTop:"-15px"}}
        type="button" onClick={() => handleDeleteClick(contact.id)}>
       <i className='bi bi-trash'></i>
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;