import React from "react";
import { useHistory } from "react-router-dom";
import "../BrandNew/CarStep2.css";
import NavBar from "../../ReusableComponents/NavBar";
import Footer from "../../ReusableComponents/Footer";
import Stepper from "../../ReusableComponents/Stepper";
// import { TextField } from "@material-ui/core";
// import { makeStyles } from '@material-ui/core';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import "rsuite/dist/rsuite.min.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// const useStyles = makeStyles({
//     input: {
//         paddingRight: '5.5em',
//         width: '355px',
//         height: '30px',
//         // marginTop:'40px',
//         // outline:'red',
//         [`& fieldset`]: {
//             borderRadius: '10px',
//             // borderColor:'red',
//         }
//     }
// })

const vdSchema = yup
  .object({
    registeration: yup.string().required("registration is a required field"),
    year: yup.string().required(),
  })
  .required();

export default function MoreThan3VD1() {
  // const classes = useStyles();
  const history = useHistory();
  // const isActive = useMediaQuery('(max-width: 1520px)')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(vdSchema),
  });
  const onSubmit = (data) => {
    if (data) {
      history.push("/MoreThan3VD2");
    }
  };
  return (
    <div style={{ background: "#F2F6FF" }}>
      <NavBar />
      <br />
      <br />
      <div className="step2-car-insurance">
        <div className="container-fluid">
          <div style={{ textAlign: "center" }}>
            <img src="images/Component 16.png" className="step3-top-img" alt="comp"></img>
          </div>

          <div
            className="row"
            style={{ marginTop: "50px", display: "flex", flexWrap: "wrap" }}
          >
            <div className="step2-progress-img col-lg-2 col-md-0">
              <Stepper
                current={1}
                thirdStep={true}
                fouthStep={true}
                src="images/active.png"
              />
            </div>
            <div className="step2-content col-lg-5 col-md-7">
              <h2 className="ml-3">Your Vehicle Details - </h2>
              <br />
              <div className="d-sm-flex">
                <div className="car2-form-groupp">
                  <input
                    type="text"
                    id="icueRegistrationNo"
                    name="icueRegistrationNo"
                    className="car2-form-controll"
                    required
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      textTransform: "uppercase",
                    }}
                    {...register("registeration")}
                    // value={brand}
                  />
                  <label
                    className="ms-3 car2-form-control-placeholderr"
                    for="icueRegistrationNo"
                  >
                    Registration No
                  </label>
                  <p className="text-danger">{errors.registeration?.message}</p>
                </div>
                {/* {isActive && <p style={{marginTop:'25px'}}></p>} */}
                <div className="car2-form-groupp ">
                  <select
                    id="carYearOfPurchase"
                    name="carYearOfPurchase"
                    className="car2-form-controll "
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                    required
                    {...register("year")}
                    // value={carMake}
                  >
                    <option value="" selected disabled></option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009</option>
                    <option value="2008">2008</option>
                    <option value="2007">2007</option>
                    <option value="2006">2006</option>
                    <option value="2005">2005</option>
                    <option value="2004">2004</option>
                    <option value="2003">2003</option>
                    <option value="2002">2002</option>
                    <option value="2001">2001</option>
                    <option value="2000">2000</option>
                  </select>

                  <label
                    className="ms-3 car2-form-control-placeholderr"
                    for="carYearOfPurchase"
                  >
                    Year Of Purchase
                  </label>
                  <p className="text-danger">{errors.year?.message}</p>
                </div>
              </div>

              <p className="car2-a">
                <a onClick={() => history.push("/MoreThan3VD2")} href="#!">
                  {" "}
                  CONTINUE WITHOUT REGISTRATION NUMBER
                </a>
              </p>
              <div className="step2-car-nextbtn ">
                <button
                  className="step2-car-nextbtn"
                  onClick={handleSubmit(onSubmit)}
                >
                  Next
                </button>
              </div>
            </div>
            <div className=" step2-img col-lg-5 col-md-5">
              <img className="ml-5 pb-3" src="images/car_step1.jpg" alt="car-step-1"></img>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
