import React, { useState } from "react"

// Styling
import "./ProfileDetail.css"
import { file } from "../../utils/method";

const Form1 = ({personalchk ,handleblue,AadhaarLoadBack,aadharFileBack,photoLoad,AadhaarLoad,panLoad,formErrors, photoFile,uploadSingleFile,deleteFile,cnicFile,aadharFile,personalDetail,errors}) => {
	 return (
		<form className='pl-2'>
			
			<div className='form-row mt-3'>
				<div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100'
								id='firstName'
								type='text'
								name='firstName'
								onChange={(e) => personalchk('firstName', e)}
								value={personalDetail.firstName}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='firstName'>
								First Name as per Aadhaar* 
							</label>
							<p className='p-error'>{errors.firstName || formErrors.firstName}</p>
						</div>
					</div>
				</div>
				<div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100'
								id='middleName'
								type='text'
								name='middleName'
								onChange={(e) => personalchk('middleName', e)}
								value={personalDetail.middleName}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='middleName'>
								Middle Name
							</label>
						</div>
					</div>
				</div>
				<div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100'
								id='lastName'
								type='text'
								name='lastName'
								onChange={(e) => personalchk('lastName', e)}
								value={personalDetail.lastName}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='lastName'>
								Last Name
							</label>
						</div>
					</div>	
				</div>
			</div>
			<form  style={{pointerEvents: "none"}}>
			<div className='form-row'>
				<div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100 disabledfield'
								id='mobile_no'
								type='number'
								name='mobile_no'
								value={personalDetail.mobile}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='mobile_no'>
								Mobile No*
							</label>
							<p className='p-error'>{errors.mobile || formErrors.mobile}</p>
						</div>
					</div>
				</div>
				<div className='col-md-12 col-lg-4 p-3'>
					<div className='car3-form-groupp mt-3 mb-2 w-100'>
						<input
							className='profile-form-controll w-100 disabledfield'
							id='emailId'
							type='email'
							name='emailId'
							value={personalDetail.email}
						/>
						<label className='ms-3 profile-form-control-placeholderr' for='emailId'>
							Email Id*
						</label>
						<p className='p-error'>{errors.email || formErrors.email}</p>
					</div>
				</div>
			</div>
			</form>
			<div className='form-row from-colum'>
				<div>
					<label className="text-secondary fs-6 fw-light ms-3 mb-4 ">
						Upload Your Photo*
					</label>
				</div>
				<div className='col-md-12 col-lg-4 pt-lg-3 pl-3 topstop '>
				
					<label
						className='btn btn-primary me-3 file-btn'
						style={{ borderRadius: '5px', fontFamily: 'ErasMediumITC' }}
					>
						{' '}
						{photoLoad?
						<span className='spinner-border spinner-border-sm'></span>
						:"Choose File"
						}
						
						<input
							type='file'
							className='file_'
							key={photoFile}
							size='60'
							name='file'
							disabled={photoFile.length === 5}
							onChange={(e) => uploadSingleFile(e, 'Photo')}
						/>
					</label>
					
					{!photoFile.length > 0 && (
						<span className='col-md-4 col-lg-4 pt-lg-3 file-choose '>
							<label className='terms'> No file Chosen </label>
						</span>
					)}
					{!photoFile.length > 0 && <p className='p-error'>{errors.photo ||formErrors.photo}</p>}
				</div>
			</div>
			<div className='form-row'>
				<div className='col-md-12 col-lg-12 p-3'>
					<div className='row form-group'>
						{photoFile.length > 0 &&
							photoFile.map((item, index) => {
								return (
									<div className='preview' key={item}>
										<img className='img' src={file(item)} alt='' />
										<img
											onClick={() => deleteFile(index, 'Photo')}
											className='button'
											src='./images/crossbtn.png'
											alt=''
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<div className="line"></div>
			<div className='form-row from-colum'>
      <div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100'
								id='Adhaar_no'
								type='number'
								name='Adhaar_no'
								onBlur={handleblue}
                onChange={(e) => personalchk('Adhaar_no', e)}
								value={personalDetail.Adhaar_no}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='mobile_no'>
              Aadhaar No*
							</label>
							{<p className='p-error'>{errors.Adhaar_no || formErrors.Adhaar_no}</p>}
						</div>
					</div>
				</div>
				<div>
					<label
          className="text-secondary fs-6 fw-light ms-3 mb-4 ">
						Aadhaar Card Image Front & Back*
					</label>
				</div>
				<div className='row form-group'>
				
				<div className='col-3 p-3'  ><span  className="subhead">Front</span></div>
				<div className='col-3 p-3' ><span  className="subhead">Back</span></div>
				
				</div>
				<div className='col-md-12 col-lg-4  pl-3 ' >
					<div className='cardmain' >
					
							{!aadharFile.length > 0?
                                    <div className="part" >
                                    <label>
						{' '}
						{(!aadharFile.length && AadhaarLoad)?
							<div className="Group" src='images/not-found.png' alt="alternative">
								<span	span className='spinner-border spinner-border-lg'></span>
							</div>
							:
							<img className="Group1053" src='images/Group1053.png' alt="alternative"></img>
							}
						<input
							type='file'
							className='file_'
							size='60'
							key={aadharFile}
							name='file'
							disabled={aadharFile.length === 2}
							onChange={(e) => uploadSingleFile(e, 'Aadhaar')}
						/>
					</label>
					{!aadharFile.length > 0 &&	<p className='p-error'>{errors.aadharFile ||formErrors.aadharFile}</p>}
                                   
                                    </div> :
									<div className='row form-group'>
					<div className='preview'>
						<img className='img' src={file(aadharFile[0])} alt='' />
						<img
							onClick={() => deleteFile(0, 'Aadhaar')}
							className='button'
							src='./images/crossbtn.png'
							alt=''
						/>
					</div>
					</div>	
						}
						{/* 2222 */}
							{!aadharFileBack.length > 0?
                                    <div className="part" >
                                    <label>
						{' '}
						{(!aadharFileBack.length && AadhaarLoadBack)?
							<div className="Group" src='images/not-found.png' alt="alternative">
								<span	span className='spinner-border spinner-border-lg'></span>
							</div>
							:
							<img className="Group1053" src='images/Group1053.png' alt="alternative"></img>
							}
						<input
							type='file'
							className='file_'
							size='60'
							key={aadharFileBack}
							name='file'
							disabled={aadharFileBack.length === 2}
							onChange={(e) => uploadSingleFile(e, 'AadhaarBack')}
						/>
					</label>
					{!aadharFileBack.length > 0 &&	<p className='p-error'>{errors.aadharFileBack ||formErrors.aadharFileBack}</p>}

                                   
                                    </div> :
									<div className='row form-group' style={{marginLeft:'3rem'}}>
					<div className='preview'>
						<img className='img' src={file(aadharFileBack[0])} alt='' />
						<img
							onClick={() => deleteFile(0, 'AadhaarBack')}
							className='button'
							src='./images/crossbtn.png'
							alt=''
						/>
					</div>
					</div>	
						}
                        </div>
				
					
					{/* {!aadharFile.length > 0 && (
						<span className='col-md-4 col-lg-4 pt-lg-3 file-choose '>
							<label className='terms'> No file Chosen </label>
						</span>
					)} */}
				<p className='p-error'>{errors.Aadhaar}</p>
				</div>
			</div>
		
		
	<div className="line"></div>
			<div className='form-row from-colum'>
      <div className='col-md-12 col-lg-4 p-3'>
					<div className='profile-form-groupp w-100'>
						<div className='car3-form-groupp mt-3 mb-2 w-100'>
							<input
								className='profile-form-controll w-100'
								id='pan'
								type='text'
								name='pan'
								style={{ textTransform: "uppercase" }}
								onChange={(e) => personalchk('pan', e)}
								onBlur={handleblue}
								value={personalDetail.pan}
							/>
							<label className='ms-3 profile-form-control-placeholderr' for='mobile_no'>
              PAN*
							</label>
							<p className='p-error'>{errors.pan || formErrors.pan}</p>
						</div>
					</div>
				</div>
		
				<div>
					<label className="text-secondary fs-6 fw-light ms-3 mb-4 ">
						PAN Card Image{' '}
					</label>

				</div>
				<div className='col-md-12 col-lg-4 pt-lg-3 pl-3 topstop '>
					<label className='btn btn-primary me-3 file-btn' style={{ borderRadius: '5px' }}>
						{' '}
						{panLoad?
						<span className='spinner-border spinner-border-sm'></span>
						:"Choose File"
						}
						<input
							type='file'
							className='file_'
							size='60'
							name='file'
							disabled={cnicFile.length === 2}
							onChange={(e) => uploadSingleFile(e, 'CNIC')}
						/>
					</label>
					{!cnicFile.length > 0 && (
						<span className='col-md-4 col-lg-4 pt-lg-3 file-choose '>
							<label className='terms'> No file Chosen </label>
						</span>
					)}
					<p className='p-error'>{errors.pan}</p>
				</div>
				{!cnicFile.length > 0 &&	<p className='p-error'>{errors.cnicFile ||formErrors.cnicFile}</p>}

			</div>
			<div className='form-row'>
				<div className='col-md-12 col-lg-12 p-3'>
					<div className='row form-group'>
						{cnicFile.length > 0 &&
							cnicFile.map((item, index) => {
								return (
									<div className='preview' key={item}>
										<img className='img' src={file(item)} alt='' />
										<img key={item}
											onClick={() => deleteFile(index, 'CNIC')}
											className='button'
											src='./images/crossbtn.png'
											alt=''
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</form>
	);
}

export default Form1
