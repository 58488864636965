import React from 'react'
import { useHistory } from 'react-router-dom';
import './footer.css';

export default function Footer() {
  const history = useHistory();

  return (
    <div>
      <div className="footer-content">
        <div className="container  py-5">
          <div className="d-sm-flex col-12" style={{display:"flex", flexWrap:"wrap"}}>
            <div className="col-lg-3">
              {/* <a className="navbar-brand logo-image" href="/"><img className='logo' src="images/policicue_logo.svg" alt="alternative" /></a> */}
              <h6 className='h6-text-a'>About PoliciCue</h6>
              <p className="p-small-footer">
                We, at PoliciCue, are a team of industry professionals having collective insurance industry experience of more than 75 years. Our experts review/understand the need and then connect you with best suited insurance policies and plans.
              </p>

              <p className="p-small-footer">
                We delight our customers with best of post-sale services including claims support through our focused and experienced team of industry professionals.
              </p>
            </div>
            <div className="col-lg-3">
              <h6>Products</h6>
              <ul className="list-unstyled text-content-3">
                {/* <li><a className="a-text" href="/RetailDashboard">About us</a></li> */}
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/HealthInsurance" >Health Insurance</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/carstep1" >Car Insurance</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/Two-wheeler" >Two-wheeler Insurance</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/LifeInsurance" >Term Insurance</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/cattleInsurance">Cattle Insurance</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/OtherInsurance">Other Insurance</a></li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h6>Useful Links</h6>
              <ul className="list-unstyled text-content-3">
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/LegalAdminPolicies?0#">Privacy Policy</a></li>
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/LegalAdminPolicies?1#" >Terms of Usage</a></li>
                {/* <li className='li-item'><a className="a-text" href="/LegalAdminPolicies" onClick={() => history.push("/LegalAdminPolicies")}>Code of Conduct</a></li> */}
                {/* <li className='li-item'><a className="a-text" href="/LegalAdminPolicies" onClick={() => history.push("/LegalAdminPolicies")}>Grievance Redressal</a></li> */}
                <li className='li-item'><a className="a-text" href="https://policicue.com/#/LegalAdminPolicies?2#" >Disclaimer</a></li>

                {/* <li><a className="a-text" href="/AgentDashboard">More Blogs</a></li> */}
                {/* <li><a className="a-text" href="/superadmin">Official Account Login</a></li> */}
              </ul>
            </div>
            <div className="col-lg-3 footer-links">
              <h6>Get In Touch</h6>
              <ul className="list-unstyled text-content-4">
                <li className='li-item'><img className="img-space" src="images/footer/building.png" alt="alternative" /> <a className="a-text" href="#" style={{ fontWeight: 'bold' }}>PoliciCue Insurance Brokers Private Limited.</a> </li>
                <li className='li-item'> <img className="img-space" src="images/footer/location.png" alt="alternative" /><a className="a-text" href="#" style={{marginLeft:"5px"}}>A-42/6,Sector-62 Noida Gautam Buddha Nagar UP 201301 IN</a> </li>
                {/* <li className='li-item'> <img className="img-space" src="" alt="" /><a className="a-text" href="#"> &nbsp; &nbsp;&nbsp; &nbsp; 201301 IN</a> </li> */}
                <li className='li-item'> <img className="img-space" src="images/footer/contact-mail.png" alt="alternative" /><a className="a-text" href="mailto:info@policicue.com">info@policicue.com</a> </li>
                <li className='li-item'><img className="img-space" src="images/footer/site.png" alt="alternative" /> <a className="a-text" href="https://policicue.com/">www.policicue.com</a> </li>
                <li className='li-item'>
                  <div className='social-links'>
                    <a className="a-text" href="https://www.facebook.com/PoliciCue" target="_blank">
                      <img className="img-space" src="images/footer/fb.png" alt="alternative" />
                    </a>
                    <a className="a-text" href="https://www.instagram.com/policicue/" target="_blank">
                      <img className="img-space" src="images/footer/instagram.png" alt="alternative" />
                    </a>
                    <a className="a-text" href="https://www.linkedin.com/company/policicue/" target="_blank">
                      <img className="img-space" src="images/footer/linkedin.png" alt="alternative" />
                    </a>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          
        </div>
        <div className="container  py-5">
        
        <div className='col-12'>
            <div className='licence-container'>
              <div className='licence-block'>
                <h6 className='h6-text h6-size'>Licensed By :</h6> &nbsp; &nbsp;<a href='https://policicue.com/LegalAdminPolicies?3#' > <img src="images/footer/irdai-logo.png" alt="alternative" /></a>
              </div>
              <span className='rights-content'>
                © PoliciCue Insurance Brokers Pvt. Ltd. All Rights Reserved
              </span>
            </div>
          </div>
        </div>
                <div className="container  py-0">
          <div className='col-12'>
            <div className='footer-note-block'>
              <span className='footer-note'>
                Insurance is the subject matter of Solicitation. For more details on risk factors, associate terms and conditions and exclusions, please read the product brochure before concluding a sale | PoliciCue Insurance Brokers Private Limited | Registration No: 794 | Broker Code: IRDAI/DB910/2021, Validity:06/01/2022 to 05/01/2025, Category-Direct Broker | CIN No- U66000UP2021PTC147086 | Email us at info@policicue.com  | | A-42/6,Sector-62 Noida Gautam Buddha Nagar UP 201301 IN
              </span>
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}
