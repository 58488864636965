import React from 'react'
import './SuperAdminLogin.css';
//import 'bootstrap-icons/font/bootstrap-icons.css';
export default function SuperAdminLogin(){
   
    return(<div className="superadmin" >
        <div className="container-fluid1" >
        <div className="row justify-content-center align-content-center" style={{height:"100vh"}} >
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-sm-10 col-12 border bg-white p-5 shadow rounded-3  h-auto">
                <div className="text-center  ">
                    <img src="images/logo.png" className="icueLogo " style={{marginLeft:"4.2rem"}}/>
                </div>
                <form id="icueSuperAdminForm" className="">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-sm-10 col-10 mx-auto">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-2">
                                    <label className="text-muted" for="icueLoginEmail">Email*</label>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 pt-3">
                                    <div className="form-group input-group pb-2">
                                        <input type="email" className="form-control icueLoginEmail text-capitalize" name="icueLoginEmail" id="icueLoginEmail" placeholder="enter email" required/>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-2">
                                    <label className="text-muted" for="icueLoginPwd">Password*</label>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 pt-2">
                                    <div className="form-group input-group pb-2">
                                        <input type="password" className="form-control icueLoginPwd border-end-0 text-capitalize" name="icueLoginPwd" id="icueLoginPwd" placeholder="enter password" required />
                                        <span className="input-group-text bg-white border-start-0" id="show_hide_password">
                                        <div className="input-group-append">
                                        <a href="" className="toggle_hide_password"><i className="fas fa-eye-slash" style={{color:" #494949"}} aria-hidden="true"></i> </a>
                                         </div>
                                            {/* <span id="show_eye" ><i className="bi bi-eye"></i></span>
                                            <span className="d-none" id="hide_eye" ><i className="bi bi-eye-slash"></i></span> */}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-3 pt-2">
                                    <label className="text-muted" for="icueLoginRole">Role*</label>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 pt-2">
                                    <div className="form-group pb-2">
                                        <select className="form-select form-select-sm icueLoginRole text-muted text-capitalize" aria-label="form-select-sm icueLoginRole" name="icueLoginRole" id="icueLoginRole">
                                            <option value="super admin">super admin</option>
                                            <option value="admin">admin</option>
                                            <option value="employee">employee</option>
                                            <option value="agent">agent</option>
                                            {/* <option value="customer">customer</option> */}
                                        </select>
                                    </div>
                                </div>                        

                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 offset-lg-3 offset-md-3 py-1">
                                    <div className="d-grid gap-2">
                                       
                                    <a  href="/Superdashboard"  type="button"  className="btn btn-primary  "  style={{fontSize:"16px", fontFamily:"Calibri"}} >Login</a>  
                                        {/* <button type="button" className="btn btn-primary btnIcon2">
                                            Login
                                        </button> */}
                                        
                                    </div>
                                </div>
                                {/* <div className="col-lg-9 col-md-9 col-sm-12 col-12 pt-2 offset-lg-3 offset-md-3">
                                    <div className="form-group pb-2">
                                        <a href="/Forgotpassword" className="text-capitalize text-decoration-none" style={{color:"#1565D8"}}>forgot password?</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        
        </div>
    </div>
    </div>)
}