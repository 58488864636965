import React from 'react'

function Disclaimer() {
    return (
        <div>
            <h3 className='heading'>Disclaimer</h3>
            <p className='text'>PoliciCue Insurance Brokers Private Limited vide this web portal intends to provide relevant, unambiguous, fair and desired insurance related information comprising of various insurance related products and services. The information published herein is likely to change and updated from time to time.<br /><br />

                USERs at our portal must note that we do not represent any insurance company and are not the agents, corporate agents, related party of the insurers, whose products are dealt with in this website/portal.<br /><br />

                Please be informed that we, including our employees, associates, consultants, officers, are in no manner liable or responsible for any inadvertent mistake, discrepancy in the information furnished herein. Further, we are also not responsible for any incidental, consequential, loss, damages arising out of any usage of information available on our portal. All users are strongly advised and recommended to seek suitable professional help and may exercise their own discretion.<br /><br />

                USERs may kindly note that insurance is a subject matter of solicitation and are subject to market risks. Also Insurance is based on principle of utmost faith.<br /><br />

                USERs must understand the risk involved in insurance policies, associated and consequential risks and we therefore take no responsibility in any manner whatsoever in this regard. Users must read the necessary offer/insurance/proposal and other related documents carefully before taking any call and seeking to invest. You agree that the association of user herein is purely based on sole risk and discretion of the User and we are in no manner responsible.<br /><br />

                Users understand and agree that subject to the Terms of Usage and Privacy Policy of ours, users are solely responsible for any information provided and documents uploaded herein and we i.e. PoliciCue Insurance Brokers Private Limited are only facilitators, enablers and so for any acceptance or rejection is only subject to cost and consequence of the Users which is based on their sole discretion.<br /><br />
            </p><br />
        </div>
    )
}

export default Disclaimer
