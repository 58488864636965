import React, { useState, Fragment } from "react";
import './Department.css';
import { nanoid } from "nanoid";
import data from "./mock-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
const Department=()=>{
    const [contacts, setContacts] = useState(data);
  const [addFormData, setAddFormData] = useState({
    department: "",
    
  });
  const [editFormData, setEditFormData] = useState({
    department: "",
    
  });
  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
        id: nanoid(),
        department: addFormData.department,
       
      };
  
      const newContacts = [...contacts, newContact];
    
      setContacts(newContacts);
    };
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
    
        const editedContact = {
          id: editContactId,
          department: editFormData.department,
         
        };
    
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === editContactId);
    
        newContacts[index] = editedContact;
    
        setContacts(newContacts);
        setEditContactId(null);
      };
    
      const handleEditClick = (event, contact) => {
        event.preventDefault();
        setEditContactId(contact.id);
    
        const formValues = {
          department: contact.department,
         
        };
    
        setEditFormData(formValues);
      };
    
      const handleCancelClick = () => {
        setEditContactId(null);
      };
    
      const handleDeleteClick = (contactId) => {
        const newContacts = [...contacts];
    
        const index = contacts.findIndex((contact) => contact.id === contactId);
    
        newContacts.splice(index, 1);
    
        setContacts(newContacts);
      };

// export default function Department(){
    return(<div className="Department" id="Department">
          {/* <!-- Top Bar --> */}
    <div className="container-fluid1 py-2 icueTopBarContainer bg-white">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="topBarLfContainer d-flex">
                    <div className="icueLogoContainer">
                        <img src="images/logo.png" alt="ICue Logo" className="icueLogo" />
                    </div>
                    <div className="icueSearchBox w-75 mx-3">
                        <div className="input-group ">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-search"></i>
                            </span>
                            <input type="search" id="text-search" className="form-control icueSearchBoxControl" placeholder="Enter keywords..." aria-label="Username" aria-describedby="basic-addon1"/>
                          </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="d-flex flex-row-reverse">
                    <div className="icueProfile px-2">
                        <img src="images/avatar.png" alt="icueProfilePic" className="icueProfilePic border border-dark rounded-circle" />
                    </div>
                    <div className="position-relative px-2">
                        <a href="#" id="icueNotifyBoxHandler">
                            <i className="bi bi-bell">
                                <span className="position-absolute icueNofIndic"></span>
                            </i>
                        </a>
                        <div className="icueNotifyBox" id="icueNotifyBox">
                            Notifications
                        </div>
                    </div>

                    <div className="icueUserName fs-6 px-2">
                        Viral Patel
                    </div>

                    <div className="icueMail px-2">
                        <i className="bi bi-headset"></i> &nbsp;
                        adminhelpdesk@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
    <div className="container-fluid my-2">
        <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white">
                <div className="icueRespSideBar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block" id="icueRespSideBar">
                    <button type="button" className="btn btn-primary" id="icueRespHumburger">
                        <i className="bi bi-grid-3x3-gap"></i>
                    </button>
                    <div className="icueSideBar" id="icueSideBar">
                        <nav className="navbar icueSideNavContainer">
                            <ul className="icueNavBarList">
                                <li className="icueNavBarItem ">
                                    <a href="/Hrdashboard" className="icueNavBarLink" id="">Dashboard</a>
                                </li>
                                <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" className="icueSubNavLink  text-capitalize">Add Members</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/ListMembers" className="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                                <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">Leave Management</a>
                            </li>

                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" className="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/LeaveType" className="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>
                            <li className="icueNavBarItem border-bottom-1">
                                <a href="/Holidays" className="icueNavBarLink" id="">Holidays</a>
                            </li>                            
                            <li className="icueNavBarItem border-bottom-1">
                                <a href="/Attendance" className="icueNavBarLink" id="">Attendance</a>
                            </li>                       
    
                                 
    
                                <li className="icueNavBarItem  active">
                                    <a href="/Department" className="icueNavBarLink" id="">Department</a>
                                </li>
    
                                <li className="icueNavBarItem">
                                    <a href="/AddRoles" className="icueNavBarLink" id="">Roles</a>
                                </li>
                                
                                <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="/EmpSalary" className="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/Payitems" className="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                                
                                <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                    <a href="#" className="icueNavBarLink" id="">Settings</a>
                                </li>
                                <li>
                                    <ul className="icueSubNavBar">
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                        </li>
                                        <li className="icueSubNavItem">
                                            <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="icueNavSideBar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none" id="icueNavSideBar">
                    <nav>
                        <ul className="icueNavBarList">
                            <li className="icueNavBarItem">
                                <a href="/Hrdashboard" className="icueNavBarLink" id="">Dashboard</a>
                            </li>

                            <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">User Management</a>
                            </li>

                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem"  style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="#" className="icueSubNavLink  text-capitalize">Add Member</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/ListMembers" className="icueSubNavLink  text-capitalize">List of Members</a>
                                    </li>
                                </ul>
                            </li>
                            <hr></hr>

                            <li className="icueNavBarItem border-bottom-0" style={{marginTop:"-18px"}}>
                                <a href="#" className="icueNavBarLink" id="" >Leave Management</a>
                            </li>

                            <li>
                                <ul className="icueSubNavBar ">
                                    <li className="icueSubNavItem" style={{marginTop:"-8px"}}>
                                        {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                                        <a href="/Leaves" className="icueSubNavLink  text-capitalize">Leaves</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/LeaveType" className="icueSubNavLink  text-capitalize">Leave Type</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>              

                            <li className="icueNavBarItem "style={{marginTop:"-18px"}}>
                                <a href="/Holidays" className="icueNavBarLink" id="">Holidays</a>
                            </li>   
                            <li className="icueNavBarItem ">
                                <a href="/Attendance" className="icueNavBarLink" id="">Attendance</a>
                            </li>   
                            
                            

                            
                            <li className="icueNavBarItem active" >
                                <a href="/Department" className="icueNavBarLink" id="">Department</a>
                            </li>

                            <li className="icueNavBarItem">
                                <a href="/AddRoles" className="icueNavBarLink" id="">Roles</a>
                            </li>
                            

                            <li className="icueNavBarItem border-bottom-0">
                                <a href="#" className="icueNavBarLink" id="">Payroll</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="/EmpSalary" className="icueSubNavLink  text-capitalize">Employee Salary</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="/Payitems" className="icueSubNavLink  text-capitalize">Payroll Items</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                           
                            <li className="icueNavBarItem border-bottom-0"  style={{marginTop:"-18px"}}>
                                <a href="#" className="icueNavBarLink" id="">Settings</a>
                            </li>
                            <li>
                                <ul className="icueSubNavBar">
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Change Password</a>
                                    </li>
                                    <li className="icueSubNavItem">
                                        <a href="#" className="icueSubNavLink  text-capitalize">Profile</a>
                                    </li>
                                </ul>
                                <hr></hr>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="text-capitalize" style={{fontFamily:"ErasDemiITC", marginLeft:"12px"}}>Add Department</h4>
                                <div className="bg-transparent p-2  rounded-3">
                                <nav style={{bsbreadcrumbdivider: 'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")'}} aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item bg-transparent"><a href="/Superdashboard" className="text-decoration-none text-info">Dashboard</a></li>
                                        <li className="breadcrumb-item text-muted text-capitalize bg-transparent" aria-current="page">Department</li>
                                    </ol>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div className="bg-white p-2  rounded-3 shadow-sm">
                                <div className="px-2 py-3">                  
                                      <div className="container">
                                      <div className="row">
                                          <br/>
                                             
                                      <div className="addroles col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" style={{backgroundColor:"#F4F7FC", marginTop:"-24px", marginLeft:"-28px", marginBottom:"-25px"}}>
                                      <label for="icueLmsUpdFormFName" className="form-label icueTextPrimary  text-capitalize" style={{color:"#2E3B52", fontFamily:"Calibri", fontSize:"18px", marginTop:"40px", marginLeft:"20px", fontSize:"bold"}}>Enter Department Name</label>
                                      <form onSubmit={handleAddFormSubmit}>
                                            <input type="text"  name="department" onChange={handleAddFormChange} className="form-control bg-white text-capitalize"  style={{backgroundColor:"#EBEFF2", width:"80%", marginLeft:"20px"}}/> </form>
                                            <br/>
                                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12" style={{backgroundColor:"#F4F7FC"}}>
                                                <button className=" btn-sm  text-white"  onClick={handleAddFormSubmit}  type="submit" data-bs-toggle="modal" data-bs-target="#icueCreateFollowUpModal" style={{backgroundColor:"#3A3285", width:"140%",  marginLeft:"8px"}}><i className="bi bi-plus-circle"></i> Add Department</button>
                                            </div>
                                          
                                         
                                            </div>
           
                                               <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12  bg-white">
                                                    <div className="tab-content py-1 px-3 px-sm-0" id="myTabContent">
                                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                          <div className="row">              
                                                               <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">              
                                                               <form onSubmit={handleEditFormSubmit}>
                                            <table id="payrollTable" className="table table-striped table-hover " width="auto" >
                                                <thead>
                                                    <tr className="text-uppercase" style={{lineHeight:"15px", fontFamily:"Calibri", fontStyle:"normal",  fontSize:"12px", backgroundColor:"white"}}>
                                                        <th className="py-3">Department</th>
                                                        
                                                       
                                                        <th  className="text-center py-3" >Action</th>
                                                                                                               
                                                    </tr>
                                                </thead>
                                                <tbody style={{lineHeight:"15px", fontFamily:"Calibri", fontSize:"16px"}}>
                                                    {/* <tr  style={{ border:"0" }}>
                                                        <td >Marketing</td>
                                                       
                                                        <td className="text-right  py-0" >  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>

                                                    <tr >
                                                        <td >Operations</td>
                                                        
                                                        <td className="text-right  py-0">  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr  style={{ border:"0"}}>
                                                        <td> Finance</td>
                                                         
                                                        <td className="text-right  py-0" >  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr >
                                                        <td >Sales</td>
                                                        
                                                        <td className="text-right  py-0">  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr >
                                                        <td >IT</td>
                                                        
                                                        <td className="text-right  py-0">  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr >
                                                        <td >Human Resource</td>
                                                        
                                                        <td className="text-right  py-0">  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr>
                                                    <tr>
                                                        <td>Purchase</td>
                                                        
                                                        <td className="text-right  py-0">  <button type='button'className='btn btn-sm btn-transparent editModal text-muted' id='editMod_1'> <a href="#"><i className='bi bi-pencil'></i></a></button> 
                                                        <button type='button' className='btn btn-sm btn-transparent icueDelBtnRow text-muted' id='remMod_1'style={{marginLeft:"-30px"}}><i className='bi bi-trash'></i></button> </td>
                                                                                                            
                                                    </tr> */}
                                                    {contacts.map((contact) => (
              <Fragment>
                {editContactId === contact.id ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    contact={contact}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
                                                   
                                                </tbody>
                                            </table>
                                            </form>
                                        </div>
                                    </div>   
                                      </div>
                                      
                                </div>
                            </div>
                           
                        </div>
                    </div>
               
                </div>
            </div>
            
            </div>
           
        </div>

        
                </main>
            </div>
        </div>
    </div>
    
    </div>)
}
export default Department;