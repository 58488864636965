import { FETCH_CATTLEINFO_FAILURE, FETCH_CATTLEINFO_REQUEST, FETCH_CATTLEINFO_SUCCESS } from "../actions/types";

const initialState = {
    data: {},
    error: ''
};

export const cattleInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATTLEINFO_REQUEST:
            return {
                ...state,
            }
        case FETCH_CATTLEINFO_SUCCESS:
            return {
                data: action.payload
            }
        case FETCH_CATTLEINFO_FAILURE:
            return {
                data: {},
                error: action.payload
            }
        default:
            return state;
    }
}