import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_CARMODELS_FAILURE, FETCH_CARMODELS_REQUEST, FETCH_CARMODELS_SUCCESS } from "./types"

export const fetchCarModelsRequest = () => {
    return {
        type: FETCH_CARMODELS_REQUEST
    }
}
export const fetchCarModelsSuccess = model => {
    return {
        type: FETCH_CARMODELS_SUCCESS,
        payload: model
    }
}
export const fetchCarModelsFailure = error => {
    return {
        type: FETCH_CARMODELS_FAILURE,
        payload: error
    }
}


export const getCarModels = (Id) => async (dispatch) => {
    dispatch(fetchCarModelsRequest)
    await axios.post(Constant.BASE_URL + 'motor/getCarModels', {
        makeId: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchCarModelsSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCarModelsFailure(errorMsg))
        })
}