import { FETCH_CARMAKES_FAILURE, FETCH_CARMAKES_REQUEST, FETCH_CARMAKES_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
    error: ''
};

export const carMakeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARMAKES_REQUEST:
            return {
                ...state,
            }
        case FETCH_CARMAKES_SUCCESS:
            return {
                data: action.payload
            }
        case FETCH_CARMAKES_FAILURE:
            return {
                data: [],
                error: action.payload
            }
        default:
            return state;
    }
}