import './CarStep3.css'
import NavBar from '../../ReusableComponents/NavBar'
import Footer from '../../ReusableComponents/Footer'
import React, { useState, useEffect, Fragment } from 'react'
import Stepper from '../../ReusableComponents/Stepper'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import { getCarMakeModelVariants } from '../../../store/actions/carMakeModalVaraint'
import { getCarMakes } from '../../../store/actions/carMake'
import { getRTO } from '../../../store/actions/rto'
import { getCarModels } from '../../../store/actions/carModel'
import { getCarPolicyTypes } from '../../../store/actions/carPolicyTypes'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";



function CarStep3() {
    const history = useHistory();
    const [carMake, setCarMake] = useState()

    const vd2Schema = yup.object({
        carMake: carMake ? yup.string().notRequired() : yup.string().required(),
        model: yup.string().required(),
        variant: yup.string().required(),
        rto: yup.string().required(),
        policy: yup.string().required()
    })


    const list = useSelector(state => state.carMake.data.data);
    const models = useSelector(state => state.carModels.data.data);
    const carVaraints = useSelector(state => state.makeModalVaraint.data.data)
    const rtoList = useSelector(state => state.rto.data.data);
    const policyTypes = useSelector(state => state.policyTypes.data.data);

    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(vd2Schema)
    });
    const onSubmit = data => {
        if (data) {
            history.push('/CarStep4')
        }
    }


    useEffect(() => {
        dispatch(getCarMakes())
        dispatch(getRTO())
        dispatch(getCarPolicyTypes())
    }, [dispatch])


    const getDataByID = (id) => {
        setCarMake(id)
        dispatch(getCarModels(id))
        dispatch(getCarMakeModelVariants(id))
    }


    return (
        <>
            <NavBar />
            <div style={{ background: '#F2F6FF' }}>
                {/* <NavBar /> */}
                <br /><br />
                <div className='step3-car-insurance'>
                    <div className="container-fluid ">
                        <div style={{ textAlign: 'center' }}><img src='images/Component 16.png' alt="alternative" className='step3-top-img'></img></div>

                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className="step3-progress-img col-lg-2 col-md-0">
                                <Stepper current={2} thirdStep={true} src="images/active.png" />
                            </div>
                            <div className="step3-content col-lg-6 col-md-12">
                                <h2 className="ml-3">Your Vehicle Details   - </h2>

                                {list && list.length > 0 && list.map(({ id, name, logo }) => {
                                    return (

                                        <img
                                            className='car-step3-img car-step3-imgg'
                                            src={`./images/${logo}`}
                                            name={`${name}`}
                                            value={id}
                                            key={id}
                                            onClick={() => getDataByID(id)}
                                            alt="alternative"
                                            style={{ cursor: 'pointer' }} />

                                    )
                                })}

                                <br />
                                <div className='col-lg-10'>
                                    <div className='d-sm-flex'>
                                        <div className="car3-form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarMake"
                                                name="carMake"
                                                className="car3-form-controll BrandNewSelect"
                                                required
                                                value={carMake}
                                                {...register("carMake")}
                                                onChange={(e) => getDataByID(e.target.value)}
                                            >
                                                <option value="" selected></option>
                                                {list && list.length > 0 && list.map(({ id, name }) => {
                                                    return (
                                                        <option
                                                            key={id}
                                                            id={id}
                                                            value={id}
                                                        >
                                                            {name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <label
                                                className="ms-3 car3-form-control-placeholderr"
                                                for="icueCarMake"
                                            >
                                                Car Make
                                            </label>
                                            <p className="text-danger">{errors.carMake?.message}</p>
                                        </div>

                                        <div className="car3-form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarVariant"
                                                name="icueVariant"
                                                className="car3-form-controll BrandNewSelect"
                                                required
                                            >
                                                {models && models.length > 0 ? (
                                                    models.map(({ modelName }) => {
                                                        return (
                                                            <option {...register("model")}>{modelName}</option>
                                                        )
                                                    })) : (<option>{""}</option>)}
                                            </select>
                                            <label
                                                className="ms-3 car3-form-control-placeholderr"
                                                for='icueCarModel'
                                            >
                                                Model
                                            </label>
                                            <p className="text-danger">{errors.model?.message}</p>
                                        </div>
                                    </div>

                                    <div className='d-sm-flex'>
                                        <div className="car3-form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarVariant"
                                                name="icueVariant"
                                                className="car3-form-controll BrandNewSelect"
                                                required
                                            >
                                                {carVaraints && carVaraints.length > 0 ? (
                                                    carVaraints.map(({ variant, model }) => (
                                                        <optgroup label={model}>
                                                            {variant.map(({ variantId, variantName }) => (
                                                                <option {...register("variant")} key={variantId}>{variantName}</option>
                                                            ))}
                                                        </optgroup>
                                                    ))
                                                ) : (<option>{""}</option>)}
                                            </select>
                                            <label
                                                className="ms-3 car3-form-control-placeholderr"
                                                for='icueCarModel'
                                            >
                                                Variant
                                            </label>
                                            <p className="text-danger">{errors.variant?.message}</p>
                                        </div>

                                        <div className="car3-form-groupp mt-3 mb-2">
                                            <select
                                                id="icueCarRto"
                                                name="icueCarRto"
                                                className="car3-form-controll BrandNewSelect"
                                                required
                                                {...register("rto")}
                                            >
                                                <option value="" selected disabled></option>
                                                {rtoList && rtoList.map(({ name, codeName, districts }) => {
                                                    return <optgroup label={name}>
                                                        {districts.map(({ code, districts }) => {
                                                            return <option>
                                                                {`${codeName}-${code} ${districts}`}
                                                            </option>
                                                        })}
                                                    </optgroup>

                                                })}

                                            </select>
                                            <label
                                                className="ms-3 car3-form-control-placeholderr"
                                                for='icueCarRto'
                                            >
                                                RTO
                                            </label>
                                            <p className="text-danger">{errors.rto?.message}</p>
                                        </div>
                                    </div>
                                    <div className='d-sm-flex'>
                                        <div className="car3-form-groupp mt-3 mb-2">
                                            <select
                                                id="icuePolicyType"
                                                name="icuePolicyType"
                                                className="car3-form-controll BrandNewSelect"
                                                required
                                                {...register("policy")}
                                            >
                                                <option value="" selected disabled></option>
                                                {policyTypes && policyTypes.map(({ name }) => {
                                                    return (
                                                        <option>{name}</option>
                                                    )
                                                })}

                                            </select>
                                            <label
                                                className="ms-3 car3-form-control-placeholderr"
                                                for='icuePolicyType'
                                            >
                                                Plan Type
                                            </label>
                                            <p className="text-danger">{errors.policy?.message}</p>
                                        </div>

                                    </div>

                                    <div style={{ textAlign: 'center' }}><button className="step3-car-nextbtn" onClick={handleSubmit(onSubmit)}>Next</button></div>
                                    {/* <p><a>CONTINUE WITHOUT REGISTRATION NUMBER</a></p> */}
                                    {/* <button className='step2-car-nextbtn' onClick={() => history.push("/CarStep3")}>Next</button> */}
                                </div>
                            </div>
                            <div className="step3-img col-lg-4 col-md-12">
                                <img src='images/car_step1.jpg' alt="background"></img>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        </>
    )
}

export default React.memo(CarStep3)



