import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { getCattleInfo } from "../../../store/actions/cattleInfo";
import BankDetailsCattleForm from "../BankDetailsCattleForm";
import Loading from "../Loading";
import { updateBankCopy } from "../../../store/actions/Image";
import { updateBankDetails, updateStatusDetails } from "../../../store/actions/personalDetails";
import ProposerDetails from "../ProposerDetails";
import AnimalDetailsFrom from "../AnimalDetailsFrom";
import CattleUsesDetailsFrom from "../CattleUsesDetailsFrom";
import PaymentDetailsCatttleForm from "../PaymentDetailsCatttleForm";
import Attachments from "../Attachments";
import OwnerDetails from "../OwnerDetails";
import PreviousInsurancePolicyAndClaim from "../PreviousInsurancePolicyAndClaim";
import SourceOfFunds from "../SourceOfFunds";
import PastInsurerDetails from "../PastInsurerDetails";
import AnimalLostDetailsFrom from "../AnimalLostDetailsFrom";
import PolicyIssuance from "../PolicyIssuance";
import InvestigatorDetails from "../InvestigatorDetails";
import DoctorDetails from "../DoctorDetails";
import Header from "../Header";
import { alertShow } from "../../../store/actions/alert";

const ViewCattleDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
   const history = useHistory();
  const result = useSelector((state) => state.cattleInfo.data.data);
  const [show, setShow] = useState(false);
 const [editStatus, setEditStatus] = useState(true);
 const [editTag, setEditTag] = useState([]);
  const [bankLoad, setbankLoad] = useState(false);
  const [chequeFile, setChequeFile] = useState([]);
  const [offersFile, setOffersFile] = useState([]);
    const [eduload, seteduload] = useState(false);
  const [personalDetail, setPersonalDetail] = useState({
    assignedBranch: "",
    branchManager: "",
  });
  const uploadSingleFile = (e, field) => {
    if (field === "Cheque") {
      setbankLoad(true);
      const formData = new FormData();
      formData.append("bankCopy", e.target.files[0]);
      updateBankCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setbankLoad(false);
          } else {
            setbankLoad(false);
            setChequeFile([...chequeFile, res.bankCopyImageUrl]);
            setPersonalDetail({ ...personalDetail, ["bankCopyImageUrl"]: res.bankCopyImageUrl });
          }
        })
        .catch((error) => {
          setbankLoad(false);
        });
    }
  };
  const personalchk = (sectionname, e) => {
   const policyInsuranceArr = ["policy_issuance_status", "policy_number", "policy_remark", "newEar_tag_no", "ear_tag_no_remark"];
    const arrMatchName = sectionname.split("-")[0]
    if ( policyInsuranceArr.includes(arrMatchName) ) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
     if (sectionname === "payment_date" || sectionname === "policy_start_date" || sectionname === "policy_end_date" || sectionname === "policy_issuance_date" || sectionname === "policy_hold_date" || sectionname === "policy_cancel_date") {
      setPersonalDetail({ ...personalDetail, [sectionname]: e });
    }
  };
  const updateStatus = () => {
  const PolicyStatus = result?.PolicyStatus?.[0];
  const editStatusPayload = {

    case_ID:PolicyStatus?.case_ID,
    policy_issuance_status: personalDetail?.policy_issuance_status,
    policy_issuance_date:personalDetail?.policy_issuance_status=="Issued" ? personalDetail?.policy_issuance_date : null,
    policy_start_date: personalDetail?.policy_issuance_status=="Issued" ? personalDetail?.policy_start_date : null,
    policy_end_date:personalDetail?.policy_issuance_status=="Issued" ?  personalDetail?.policy_end_date:null,
    policy_hold_date:personalDetail?.policy_issuance_status=="On Hold" ? personalDetail?.policy_hold_date:null,
    policy_cancel_date: personalDetail?.policy_issuance_status == "Canceled" ? personalDetail?.policy_cancel_date : null,
    policy_number:personalDetail?.policy_issuance_status=="Issued" ? personalDetail?.policy_number:null,
    policy_remark:personalDetail?.policy_remark

    }
    updateStatusDetails(editStatusPayload)
    .then((res) => {
      res?.status === 0 && (alertShow("err",res?.message));
      if (res?.data?.errors) {
        const data = res.data.errors;
        data.forEach((element) => {
          alertShow("err",element.msg);
        });
      }
      if (res?.status === 1) {
        alertShow('succ', "Status updated successfully.")
        history.push("/cattle-insurance/all")
      }
    })
    .catch((error) => {
     alertShow("err",error.message);
    });
  }
  const deleteFile = (e, field) => {
    if (field === "Cheque") {
      const s = chequeFile.filter((item, index) => index !== e);
      setChequeFile(s);
    }
    setPersonalDetail({ ...personalDetail, ["bankCopyImageUrl"]: null });
  };
useEffect(() => {
    if(result) {
      const tempResult = {};
      tempResult.policy_issuance_status = result?.PolicyStatus?.[0].policy_issuance_status;
      tempResult.policy_issuance_date = result?.PolicyStatus?.[0].policy_issuance_date;
      tempResult.policy_cancel_date = result?.PolicyStatus?.[0].policy_cancel_date;
      tempResult.policy_hold_date = result?.PolicyStatus?.[0].policy_hold_date;
      tempResult.policy_start_date = result?.PolicyStatus?.[0].policy_start_date;
      tempResult.policy_end_date = result?.PolicyStatus?.[0].policy_end_date;
      tempResult.policy_remark = result?.PolicyStatus?.[0].policy_remark;
      tempResult.policy_number = result?.PolicyStatus?.[0].policy_number;
      setPersonalDetail(tempResult)

    }
  },[result])
  useEffect(() => {
    dispatch(getCattleInfo(id));
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow ">
      <main className="mainBodyContainer">
     <div className="pos cattle-insurance">
      <Header title="View Cattle Insurance" />
       <div className="p-4 bg-white shadow rounded-3">
        {/* <h4 className="mb-3 title">Please Complete Your Details</h4> */}
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Proposer Details</Accordion.Header>
            <Accordion.Body>
              <ProposerDetails
               isDisabled={true} data={result?.viewProposerResult[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Owner Details</Accordion.Header>
            <Accordion.Body>
              <OwnerDetails
               isDisabled={true} data={result?.viewProposerResult[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Previous Cattle Insurance Policy and Claim Experience (Last 3 Years)</Accordion.Header>
            <Accordion.Body>
              <PreviousInsurancePolicyAndClaim
                isDisabled={true} data={result?.viewPrevClaimsResult}
                
                
              />
            </Accordion.Body>
          </Accordion.Item>
           <Accordion.Item eventKey="3">
            <Accordion.Header>Source of Funds</Accordion.Header>
            <Accordion.Body>
              <SourceOfFunds
               isDisabled={true} data={result?.viewProposerResult[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Past Insurer Details</Accordion.Header>
            <Accordion.Body>
              <PastInsurerDetails
            isDisabled={true} data={result?.viewProposerResult[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item>
          {result?.viewMoreAnimalResult && <Accordion.Item eventKey="5">
            <Accordion.Header>Animal Details</Accordion.Header>
            <Accordion.Body>
              <AnimalDetailsFrom
                editTag={editTag}
                setEditTag={setEditTag}
                 updateValue={personalchk}
                 personalDetail={personalDetail}
                 isDisabled={true} data={result?.viewMoreAnimalResult}
              />
            </Accordion.Body>
          </Accordion.Item> }
          {result?.animalLostIfAny  && <Accordion.Item eventKey="6">
            <Accordion.Header>Animal Lost Details (If Any)</Accordion.Header>
            <Accordion.Body>
              <AnimalLostDetailsFrom
                isDisabled={true} data={result?.animalLostIfAny}
              />
            </Accordion.Body>
          </Accordion.Item> }
          {result?.countAnimalResult && <Accordion.Item eventKey="7">
            <Accordion.Header>Cattle Uses and Category Details</Accordion.Header>
            <Accordion.Body>
              <CattleUsesDetailsFrom
                isDisabled={true} data={result?.countAnimalResult}
              />
            </Accordion.Body>
          </Accordion.Item> }
           {result?.viewPaymentResult?.[0] && <Accordion.Item eventKey="8">
            <Accordion.Header>Payment Details</Accordion.Header>
            <Accordion.Body>
              <PaymentDetailsCatttleForm
               isDisabled={true} data={result?.viewPaymentResult?.[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item> }
           {result?.viewBankDetails?.[0] && <Accordion.Item eventKey="9">
            <Accordion.Header>Bank Details</Accordion.Header>
            <Accordion.Body>
              <BankDetailsCattleForm
               isDisabled={true} data={result?.viewBankDetails?.[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item> }
          {result?.viewAttachments?.[0] && <Accordion.Item eventKey="10">
            <Accordion.Header>Attachments</Accordion.Header>
            <Accordion.Body>
              <Attachments
               isDisabled={true} data={result?.viewAttachments?.[0]}
                
                
              />
            </Accordion.Body>
          </Accordion.Item> }
           {result?.PolicyStatus?.[0] && <Accordion.Item eventKey="11">
            <Accordion.Header>Policy Issuance Status</Accordion.Header>
            <Accordion.Body>
              <PolicyIssuance
                  isDisabled={editStatus}  
                data={result?.PolicyStatus?.[0]}
                updateValue={personalchk}
                 personalDetail={personalDetail}
                
                
              />
               <button className="btn btn-primary" onClick={() => setEditStatus(false)}>Edit Status</button> &nbsp; &nbsp; &nbsp; &nbsp; 
              {editStatus==false && <button className="btn btn-primary" onClick={updateStatus}>Update Status</button> }

            </Accordion.Body>
          </Accordion.Item>}
          {result?.investigator_details && <Accordion.Item eventKey="12">
            <Accordion.Header>Investigator Details </Accordion.Header>
            <Accordion.Body>
              <InvestigatorDetails
                isDisabled={true} 
                data={result?.investigator_details} 
                isShowView={0}
              />
            </Accordion.Body>
          </Accordion.Item> }
          {result?.doctor_details && <Accordion.Item eventKey="13">
            <Accordion.Header>Doctor Details </Accordion.Header>
            <Accordion.Body>
              <DoctorDetails
                isDisabled={true} 
                data={result?.doctor_details} 
                isShowView={0}
              />
            </Accordion.Body>
          </Accordion.Item> }
        </Accordion>
        {/* <BranchForm
          personalchk={personalchk}
          personalDetail={personalDetail}
          
          
        /> */}
        {/* <div className="row row-cols-1 row-cols-lg-1 ">
          <div className="col d-flex align-items-center">
            <input
              className="me-2"
              type="checkbox"
              onChange={() => {
                setChecked(!checked);
                setPersonalDetail({
                  ...personalDetail,
                  isChecked: !personalDetail.isChecked,
                });
              }}
            />
            <span style={{ fontSize: "0.9rem" }}>
              I Hereby declare that above submitted details are valid and
              submitted by myself only.
            </span>
          </div>
          {!checked && <p className="p-error">{formErrors.isChecked}</p>}
        </div> */}
      
          </div> 
        </div>
        </main>
    </div>
  );
};

export default ViewCattleDetails;
