import React, { useEffect, useState } from "react";
import "./RaiseTickets.css";
//import { makeStyles } from '@material-ui/core';
import ReactPaginate from "react-paginate";

const RaiseTickets = () => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>Raise Ticket</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted " aria-current="page">
                    My Queries
                  </li>

                  <li className="breadcrumb-item bg-transparent">RaiseTicket</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </main>
      <div className="raise-ticket-lg">
        <div className="main-section">
          <div className="display_flex">
          <div className="full-input Insurance ">
                      <label for="dropdown" style={{color:"black"}}>Query Type</label>
                      <select
                        id="cars"
                        name="cars"
                        placeholder="Insurance Company"
                        style={{
                          backgroundColor: "#EBEFF2",
                          width: "100%",
                          height: "19px",
                          border: "none",
                          lineHeight: "2.5rem",
                        }}
                      >
                        <option value="select">Select</option>
                        
                      </select>
                    </div>
            <div className="full-input Insurance ">
                      <label for="dropdown" style={{color:"black"}}>Policy</label>
                      <select
                        id="cars"
                        name="cars"
                        placeholder="Insurance Company"
                        style={{
                          backgroundColor: "#EBEFF2",
                          width: "100%",
                          height: "19px",
                          border: "none",
                          lineHeight: "2.5rem",
                        }}
                      >
                        <option value="select">Select</option>
                        
                      </select>
                    </div>
          </div>
          <div className="display_flex">
          <div className="full-input  Insurance">
                <label for="number" style={{color:"black"}}>Topic</label>
                <input type="text" name="number"></input>
          </div>
          <div className="full-input Insurance ">
                      <label for="dropdown" style={{color:"black"}}>Priority</label>
                      <select
                        id="cars"
                        name="cars"
                        placeholder="Insurance Company"
                        style={{
                          backgroundColor: "#EBEFF2",
                          width: "100%",
                          height: "19px",
                          border: "none",
                          lineHeight: "2.5rem",
                        }}
                      >
                        <option value="select">Select</option>
                       
                      </select>
                    </div>
          </div>
          <div className="text-area">
            <textarea rows="10" cols="50">
              Enter Password
            </textarea>
          </div>
          <div className="display_flex m-t">
            <div className="btn-root m-r ">
              <button>Submit</button>
            </div>
            <div className="btn-root  " >
              <button>Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RaiseTickets;
