import axios from "../../config/axios";
import Constant from "../../utils/constant";
import {
  FETCH_CATTLEDETAIL_FAILURE,
  FETCH_CATTLEDETAIL_REQUEST,
  FETCH_CATTLEDETAIL_SUCCESS,
  GET_SEARCH_RESULT_SUCCESS,
  GET_SEARCH_RESULT_FAILURE,
} from "./types";

export const fetchCATTLEDetailRequest = () => {
  return {
    type: FETCH_CATTLEDETAIL_REQUEST,
  };
};
export const fetchCATTLEDetailSuccess = (model) => {
  return {
    type: FETCH_CATTLEDETAIL_SUCCESS,
    payload: model,
  };
};
export const fetchCATTLEDetailFailure = (error) => {
  return {
    type: FETCH_CATTLEDETAIL_FAILURE,
    payload: error,
  };
};

export const getSearchResultSuccess = (model) => {
  return {
    type: GET_SEARCH_RESULT_SUCCESS,
    payload: model,
  };
};
export const getSearchResultFailure = (error) => {
  return {
    type: GET_SEARCH_RESULT_FAILURE,
    payload: error,
  };
};

export const getCattleDetail = (currentPage, pagelimit) => async (dispatch) => {
  const created_by = JSON?.parse?.(localStorage?.getItem("user"))?.emailId
  // dispatch(fetchCATTLEDetailRequest);
  await axios
    .post(Constant.BASE_URL + `cattle/cattleInsuranceDetailsPos/?page=${currentPage}&limit=${pagelimit}&created_by=${created_by}`)
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(fetchCATTLEDetailSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchCATTLEDetailFailure(errorMsg));
    });
};

export const getSearchResult = (payLoad) => async (dispatch) => {
  const created_by = JSON?.parse?.(localStorage?.getItem("user"))?.emailId

  await axios
    .post( 
      Constant.BASE_URL + `cattle/cattleInsuranceDetailsPos/?page=${payLoad.page}&limit=${payLoad.limit}&proposer_state=${payLoad.stateName}&proposer_email=${payLoad.emailId}&proposer_name=${payLoad.proposerName}&owner_name=${payLoad.ownerName}&policy_issuance_date=${payLoad.insuranceDate}&created_by=${created_by}`
    )
    .then((response) => {
      if (response.status) {
        const result = response.data;
        dispatch(getSearchResultSuccess(result));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getSearchResultFailure(errorMsg));
    });
};

