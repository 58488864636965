import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_BIKEMODELS_FAILURE, FETCH_BIKEMODELS_REQUEST, FETCH_BIKEMODELS_SUCCESS } from "./types"

export const fetchBikeModelsRequest = () => {
    return {
        type: FETCH_BIKEMODELS_REQUEST
    }
}
export const fetchBikeModelsSuccess = model => {
    return {
        type: FETCH_BIKEMODELS_SUCCESS,
        payload: model
    }
}
export const fetchBikeModelsFailure = error => {
    return {
        type: FETCH_BIKEMODELS_FAILURE,
        payload: error
    }
}


export const getBikeModels = (Id) => async (dispatch) => {
    dispatch(fetchBikeModelsRequest)
    await axios.post(Constant.BASE_URL + 'motor/getBikeModels', {
        makeId: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchBikeModelsSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBikeModelsFailure(errorMsg))
        })
}