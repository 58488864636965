import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_BIKEMAKES_FAILURE, FETCH_BIKEMAKES_REQUEST, FETCH_BIKEMAKES_SUCCESS } from "./types"

export const fetchBikeMakesRequest = () => {
    return {
        type: FETCH_BIKEMAKES_REQUEST
    }
}
export const fetchBikeMakesSuccess = carMakes => {
    return {
        type: FETCH_BIKEMAKES_SUCCESS,
        payload: carMakes
    }
}
export const fetchBikeMakesFailure = error => {
    return {
        type: FETCH_BIKEMAKES_FAILURE,
        payload: error
    }
}


export const getBikeMakes = () => async (dispatch) => {
    dispatch(fetchBikeMakesRequest)
    await axios.get(Constant.BASE_URL + 'motor/getBikeMakes')
        .then(response => {
            const result = response.data
            dispatch(fetchBikeMakesSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBikeMakesFailure(errorMsg))
        })
}


