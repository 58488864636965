import React from "react";
import { useEffect, useState } from "react";
import moment from 'moment'
import "./MyAgreement.scss";
//import { makeStyles } from '@material-ui/core';
import ReactPaginate from "react-paginate";
import {ImDownload3} from "react-icons/im"; 
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import Constant from "../../../utils/constant";
import file from "../../../assets/file/UserAgreement_POS.docx"
import { useHistory } from "react-router-dom";

const User = JSON.parse(localStorage.getItem("user"));

export const uploadFile = (formData) => {
  
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/agreement', formData, { headers: {"Authorization" : `Bearer ${User?.token}`} } )
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const getAgreement = () => {
  
    return new Promise(resolve => {
     axios.get(Constant.BASE_URL + 'pos/getMyAgreements',{ headers: {"Authorization" : `Bearer ${User?.token}`} } )
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export default function MyAgreement() {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [fileName , setfileName] = useState("");
  const [fileDownloadLink,setFileDownloadLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);
  useEffect(() => {
    // Fetch items from another resources.
    getAgreement().then(res => {
      console.log(res,"resfff")
      if(res.status === 1){
        setItems([...res.data])
      }
    });
  }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  const uploadAgreement = (event) => {
    event.preventDefault();
    var formData = new FormData();
  formData.append("agreement", fileName);
    uploadFile(formData).then(res => {
      if(res.status === 1){
        setFileDownloadLink(res.posagreement);
        setShowModal(true);

      }
    });
  }

  const handleClose = () => {
    history.push('/dashboard')
  }

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 classesRow">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
              className="useBreadCrumb"
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Agreement</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    Account Information
                  </li>
                  <li className="breadcrumb-item bg-transparent">
                      My Agreement
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bg-white nav-data-handling nav-handling">
              <div className="display_flex agreement">
                <div className="agreement__btn-agreement"><button><span><ImDownload3 /></span>        
               <a href={file} download style={{color: "#fff"}}  >Download file</a>
                </button>
                </div>
                <div className="agreement__btn-choose-agreement" >
                    <label
					>
					Choose Agreement
						
						<input
							type='file'
							className='file_'
							size='60'
							name='file'
              onChange={(e) => setfileName(e.target.files[0])}
						/>
					</label>

                </div>
                <div className="agreement__btn-choose-file" ><button>{fileName!=''?fileName.name:"No file choosen"}</button></div>
                <div className="agreement__btn-choose-upload" onClick={uploadAgreement}>
                <button>Upload</button>
                </div>
              </div>
              {/* {fileDownloadLink?<a href={fileDownloadLink}>{fileDownloadLink}</a>:""} */}
              <Modal show={showModal} onHide={handleClose}>
              <Modal.Body>
                    <div className="modal-block">
                      <h3
                        style={{
                          paddingLeft: "0.5rem",
                          fontFamily: "ErasDemiITC",
                          color: "rgb(41, 22, 111)",
                          fontSize: "20px",
                        }}
                      >
                        Agreement Uploaded Successfully
                      </h3>
                      
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
          <button onClick={handleClose}>
            Close
          </button>
         
        </Modal.Footer>
                </Modal>
                <div className=""> 
                <div className="Intro">
                      <br></br>{" "}
                      <h2>Steps to Upload the Agreement</h2>
                      <br></br>  <br></br>
                      <div className="row aggrementUploadStep" style={{marginLeft:"12rem"}}>
                <div className="step1">
                <p className="solid">
                <br/>  <br/> 
                PLEASE DOWNLOAD AGREEMENT
                </p>
                <p className="small1">STEP-1</p>
                <p className="small"></p>
                <div  style={{marginTop:"-1.3rem",marginLeft:"7.3rem" }}>
                <span style={{color:"#94C5F4"}}><big><big><big>&#8594;</big></big></big></span>
                </div>
                </div>
                <div className="step2">
                <p className="solid">
                <br/>  <br/>  
                PRINT WITH RS.100 STAMP PAPER
                </p>
                <p className="small1">STEP-2</p>
                <p className="small"></p>
                <div  style={{marginTop:"-1.3rem",marginLeft:"7.3rem" }}>
                <span style={{color:"#94C5F4"}}><big><big><big>&#8594;</big></big></big></span>
                </div>
                </div>
                <div className="step3">
                <p className="solid" style={{padding: "0rem"}}>
                <br/>  <br/>  
                SIGN ON ALL PAGES/LAST PAGE AT DEFINED PLACE
                </p>
                <p className="small1">STEP-3</p>
                <p className="small"></p>
                <div  style={{marginTop:"-1.3rem", marginLeft:"7.3rem"}}>
                <span style={{color:"#94C5F4"}}><big><big><big>&#8594;</big></big></big></span>
                </div>
                </div>
                <div className="step4">
                <p className="solid">
                <br/>  <br/>  
                GET IT &nbsp; NORTARIZED
                </p>
                <p className="small1">STEP-4</p>
                <p className="small"></p>
                <div  style={{marginTop:"-1.3rem",marginLeft:"7.3rem"}}>
                <span style={{color:"#94C5F4"}}><big><big><big>&#8594;</big></big></big></span>
                </div>
                </div>
                <div className="step5">
                <p className="solid">
                <br/> <br/>  
                UPLOAD ON   POS PORTAL
                </p>
                <p className="small1">STEP-5</p>
                <p className="small"></p>


                </div>
                </div>
                      </div>
            </div>
             <div className="table-responsive">
            <table id="dtBasicExample" className="table results ">
              <thead>
                <tr
                  className="text-uppercase"
                  style={{
                    lineHeight: "15px",
                    fontFamily: "Calibri",
                    fontStyle: "normal",
                    fontSize: "16px",
                  }}
                >
                  <th>SR. NO.</th>
                  <th>POSP NAME</th>
                  <th>POSP CODE</th>
                  <th>AGREEMENT</th>
                  <th>SIGNED DATE</th>
                  <th> DOWNLOAD</th>
                </tr>
              </thead>
              <tbody
                style={{
                  lineHeight: "15px",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                }}
              >
                {items &&
                  items.map((v,k) => (
                    <tr key={k}>
                      <td className="sorting-num" >{k+1}</td>
                      <td className="sorting-num1">{v.firstName}</td>
                      <td className="wrap sorting-num2">{v.pospCode}</td>
                      <td className="wrap  sorting-num3">{v.AGREEMENT}</td>
                      <td className="sorting-num4">{moment(v.SIGNED_DATE).format('MMMM Do YYYY, h:mm:ss a')}
                      </td>
                      <td className="btn-download"> <a 
                          href={Constant.BASE_URL+'pos/file/pos/' + v.AGREEMENT}
                          type="button"
                          className="agreement-dowload"
                          target="_blank"
                          download
                        >
                          Download
                        </a>{" "}
                        <br></br>
                      </td>
                    </tr>
                  ))}
               {!items.length>0 && <tr>No data Found</tr>}
                    {/* <tr>
                {" "}
                <ReactPaginate
                  containerClassName="containerClassName"
                  // className="pagein"
                  nextClassName="nextClassName"
                  pageClassName="pageClassName"
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </tr> */}
              </tbody>
              </table>
              </div>
          </div>
        </div>
      </main>
    </div>
  );
}
