import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_STATE_FAILURE, FETCH_STATE_REQUEST, FETCH_STATE_SUCCESS } from "./types"

export const fetchStateRequest = () => {
    return {
        type: FETCH_STATE_REQUEST
    }
}
export const fetchStateSuccess = getState => {
    return {
        type: FETCH_STATE_SUCCESS,
        payload: getState
    }
}
export const fetchStateFailure = error => {
    return {
        type: FETCH_STATE_FAILURE,
        payload: error
    }
}



export const getStatelogin = () => async (dispatch) => {
    dispatch(fetchStateRequest)
    await axios.get(Constant.BASE_URL + 'pos/listStates')
        .then(response => {
            const result = response.data
            result.data.sort((a, b) => {
                let fa = a.state.toLowerCase(),
                    fb = b.state.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            dispatch(fetchStateSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStateFailure(errorMsg))
        })
}


export const getState = () => async (dispatch) => {
    dispatch(fetchStateRequest)
    await axios.get(Constant.BASE_URL + 'pos/listStates')
        .then(({data}) => {
            const temp = [];
            const d = data.data;
            d.forEach(element => {
                temp.push({
                    value:element.state,
                    label: element.state
                });
            });
            temp.sort((a, b) => {
                let fa = a.value.toLowerCase(),
                    fb = b.value.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            dispatch(fetchStateSuccess(temp))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStateFailure(errorMsg))
        })
}
