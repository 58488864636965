import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
// import MenuIcon from '@mui/icons-material/Menu';
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
// import profile from "../../../assets/bgs/NewProfile.jpg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Alert from "../../ReusableComponents/Alert";
import { getRunningStatus } from "../../../store/actions/getPosPersonalDetails";
// import { getResult} from '../../../store/actions/getPosPersonalDetails';
import "./TopNav.css";
import { useSelector } from "react-redux";


import { file } from "../../../utils/method";

import { useHistory } from "react-router-dom";
const pages = [""];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const TopNav = () => {
  const show = useSelector((state) => state.alertState);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const User = JSON.parse(localStorage.getItem("user"));
  const [runningStatus, setRunningStatus] = React.useState();
  const History = useHistory();
  React.useEffect(() => {
    getStatus();
  });

  const getStatus = () => {
    getRunningStatus()
      .then((res) => {
        if (res.message !== "") {
          setRunningStatus(res.message);
          // console.log("Running status", res);
        }
      })
      .catch((error) => {
        //   alertShow('err',error.message);
      });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logout = () => {
    handleCloseUserMenu();
    localStorage.removeItem("user");
    localStorage.removeItem("SuperDashboard");
    History.push("/");
    window.location.reload();
  };

  return (
    <AppBar id="UsuallyPosition" position="sticky">
      <Container
        className="bgWhite"
        
        style={{ backgroundColor: "#fff" }}
        maxWidth="xl"
      >
        <Toolbar disableGutters>
          <span className="backArrow" onClick={()=>History.goBack()}>&#8592;</span>
          <Typography variant="h6" noWrap component="div">
            <img src="images/logo.png" alt="ICue Logo" className="icueLogo pcLogo" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { md: "flex" } }} >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Typography variant="h5" noWrap component="div" className="me-2">
            <p className="statusText">
              Running Status : <b> {runningStatus}</b>{" "}
            </p>
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  padding: "8px",
                }}
              >
                <img
                  className="userPic"
                  alt={`${User?.firstName}`}
                  src={User?.photoUrl && file(User.photoUrl)}
                  style={{ width: "30px", objectFit: "cover" }}
                />
                {/* <Avatar alt="Remy Sharp" src={User?.photoUrl} /> */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {show.show && <Alert />}
    </AppBar>
  );
};
export default TopNav;
