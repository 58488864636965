import React, { useState } from "react";
import NavBar from "../ReusableComponents/NavBar";
import "./login.css";
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/actions/auth";
import { Redirect } from 'react-router-dom';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const schema = yup.object().shape({
  username: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required(),
  password: yup.string().min(8).required('Please Enter your password')
}).required();

function Login() {
  const [loading, setLoading] = useState(false)

  const { isLoggedIn } = useSelector(state => state.auth);

  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit,
    formState: { errors } } = useForm({
      resolver: yupResolver(schema),
    });

  const handleLogin = ({ username, password }) => {

    setLoading(true);

    if (username, password) {
      dispatch(login(username, password))
        .then(() => {
          history.push("/");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }


  return (
    <>
      <div className='registration-body'>
        <NavBar />
        <br /><br /><br /><br /><br />
        <div className="registration-card">
          <img className='cross-icon' src='images/x-mark.png' alt='x-mark' onClick={() => history.push('/')}></img>
          {/* <CancelOutlinedIcon className="cross-icon" /> */}
          <h3 className="heading">To sign in, please enter your registered Phone Number</h3>
          <hr style={{ width: '60px', height: '4px', backgroundColor: '#0093dd ', border: 'none', marginLeft: '45%' }} />
          <div style={{ display: "flex" }}>
            <div className="login-form d-flex justify-content-center">

              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="car2-form-groupp" >
                  <input
                    type="text"
                    id="icueRegistrationNo"
                    name="icueRegistrationNo"
                    className="car2-form-controll"
                    style={{ width: '350px' }}
                    {...register("username")}
                    required
                  />
                  <label className="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                    Phone Number
                  </label>
                  <br />
                  <p style={{ color: 'red' }}> {errors.username?.message} </p>
                </div>

                <div className="car2-form-groupp" >
                  <input
                    type="password"
                    id="icueRegistrationNo"
                    name="icueRegistrationNo"
                    className="car2-form-controll"
                    style={{ width: '350px' }}
                    {...register("password")}
                    required
                  />
                  <label className="ms-3 car2-form-control-placeholderr" for="icueRegistrationNo">
                    Password
                  </label>
                  <br />
                  <p style={{ color: 'red' }}> {errors.password?.message} </p>
                </div>

                <br />
                <div className="align-row">
                  <div>
                    <input type="checkbox" style={{ width: "15px" }}
                    ></input>
                    <span className="remember-text">
                      Remember me
                    </span>
                  </div>
                  <a className='redirect-text' href="#">Forgot Password?</a>
                </div>

                <div className="new-user">
                  <span className='remember-text'>New User?</span>

                  <a onClick={() => history.push("/Registration")}
                    className='redirect-text'> Sign Up</a>
                </div>
                <div className='column'>
                  <span className='or-text'>OR</span>

                  {!loading ? (
                    <button
                      className='login-btn'
                      type="submit"
                      id='submit'
                    >Login</button>
                  ) : (
                    <span className="spinner-border spinner-border-md"></span>
                  )}
                </div>

                <div className='social-login-btn'>
                  <a href="#"><img style={{ width: '68px', height: '42px' }} alt="alternative" src='images/facebook-logo.png'></img></a>
                  <a href="#"><img style={{ width: '46px', height: '46px' }} alt="alternative" src='images/google-logo.png'></img></a>
                </div>
              </form>
            </div>
            <div className="loginimg">
              <img src="images/im67 1.png" alt="loginimg"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default Login
















