import React from "react";
import "../../../css/styles.css";
import { useHistory } from "react-router-dom";
import "./vision.css";
import Accordion from "react-bootstrap/Accordion";
export default function SellPage() {
  const history = useHistory();

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer">
        <div className="container-fluid1">
          <div className="row " style={{ height: "auto" }}>
            <header id="header" className="pt-5">
              <div className="container header-img">
                <h6>My Quotes</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    Sell
                  </li>
                </ol>

                {/* <!--Cards--> */}
                <div className="row" style={{ cursor: "pointer" }}>
                  <div className="col-md-4 col-xl-3 col-sm-6">
                    <div>
                      <a
                        onClick={() => history.push("/")}
                        style={{ textDecoration: "none" }}
                      >
                        <br />
                        <div className="">
                          <img
                            src="./images/Group 1028.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-3 col-sm-6">
                    <div>
                      <a
                        onClick={() => history.push("/")}
                        style={{ textDecoration: "none" }}
                      >
                        <br />
                        <div className="">
                          <img
                            src="./images/Group 1029.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-3 col-sm-6">
                    <div>
                      <a
                        onClick={() => history.push("/")}
                        style={{ textDecoration: "none" }}
                      >
                        <br />
                        <div className="">
                          <img
                            src="./images/Group 1030.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-3 col-sm-6">
                    <div>
                      <a
                        onClick={() => history.push("/")}
                        style={{ textDecoration: "none" }}
                      >
                        <br />
                        <div className="">
                          <img
                            src="./images/Group 1031.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </a>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-xl-3 col-sm-6">
        <div className="card border-0 text-center card-features bg-c-white order-card">
          <a onClick={() => history.push("/LifeInsurance")} style={{ textDecoration: "none" }}><br />
            <div className="card-block1">
              <h4 className="text-left life-insurance"><img className="f-left img-width-life-insurance" src="images/Component 3.png" style={{ width: "40" }} />

              </h4>
              <div style={{ display: 'flex', flexDirection: 'column', }}>
                <span>Life</span>
                <span>Insurance</span>
              </div>
            <p className="m-b-0">Compare&nbsp;&nbsp;&nbsp;<img src="./images/compare.svg" style={{width: "5%"}}/><span className="f-right">learn more&nbsp;&nbsp;<img src="./images/arrow-right.svg" style={{width: "18%"}}/></span></p> 
            </div>
          </a>
        </div>
      </div> */}
                </div>
              </div>
            </header>
          </div>
        </div>
      </main>
    </div>
  );
}
