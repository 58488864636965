import axios from "axios"
import Constant from "../../utils/constant"
import { ALERT_FAILURE, ALERT_REQUEST, ALERT_SUCCESS } from "./types"
import { Dispatch} from 'redux';
import store from '.././index'


export const fetchBikeMakesRequest = () => {
    return {
        type: ALERT_REQUEST
    }
}
export const fetchBikeMakesSuccess = message => {
    return {
        type: ALERT_SUCCESS,
        payload: message
    }
}
export const fetchBikeMakesFailure = error => {
    return {
        type: ALERT_FAILURE,
        payload: error
    }
}


export const alertShow = (status,message) => {
    let {dispatch}=store
    dispatch(fetchBikeMakesRequest())
        if(status==='err'){
            dispatch(fetchBikeMakesFailure(message))
        }else if(status==='succ'){
            dispatch(fetchBikeMakesSuccess(message))
        }
}


